import { FilterState } from 'components/ui-kit/Filters/types';

export enum KeywordGroupStatuses {
  CREATED = 'CREATED',
  CAMPAIGN_CLONE = 'CAMPAIGN_CLONE',
  CAMPAIGN_CLONE_FAILED = 'CAMPAIGN_CLONE_FAILED',
  CAMPAING_CLONED = 'CAMPAING_CLONED',
  CAMPAIGN_ACTIVATE = 'CAMPAIGN_ACTIVATE',
  CAMPAIGN_ACTIVATE_FAILED = 'CAMPAIGN_ACTIVATE_FAILED',
  TESTING_PHASE = 'TESTING_PHASE',
  TESTING_PHASE_PAUSED = 'TESTING_PHASE_PAUSED',
  TESTING_PHASE_FINISHED = 'TESTING_PHASE_FINISHED',
  LIVE_PHASE = 'APPLY_PHASE',
}

export enum TestingPhaseStatusesActions {
  TESTING_PHASE = 'Pause learning',
  TESTING_PHASE_PAUSED = 'Continue learning',
}

export enum KeywordGroupStatusesSteppers {
  CREATED = 0,
  CAMPAIGN_CLONE = 0,
  CAMPAIGN_CLONE_FAILED = 0,
  CAMPAING_CLONED = 0,
  CAMPAIGN_ACTIVATE = 1,
  CAMPAIGN_ACTIVATE_FAILED = 1,
  TESTING_PHASE = 1,
  TESTING_PHASE_PAUSED = 1,
  TESTING_PHASE_FINISHED = 2,
  APPLY_PHASE = 2,
}

export enum DeviceTypes {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

export interface ITestPhaseInfo {
  daysLeft: number;
  startDate: string;
}

export interface IKeywordGroupInfo {
  id: number;
  name: string;
  status: KeywordGroupStatuses;
  testPhase: ITestPhaseInfo;
  phases: IKeywordGroupPhase[];
  numberOfKeywords: number;
  keywords?: Array<{
    name: string;
    id: number;
  }>;
}

export interface IKeywordGroupPhase {
  phase: KeywordGroupStatuses;
  startDate: string;
}

export interface IKeyword {
  account: string;
  adGroupsShopping: string;
  adGroupStatusShopping: string;
  adGroupStatusText: string;
  adGroupsText: string;
  aov: number;
  avgCPC: number;
  bidVolatility: number;
  campaignsShopping: string;
  campaignStatusShopping: string;
  campaignStatusText: string;
  campaignText: string;
  clicks: number;
  cost: number;
  cpa: number;
  cr: number;
  ctr: number;
  currency: string;
  device: DeviceTypes;
  eligibility: string;
  googleAdsLabel: string;
  id: string | number;
  impressions: number;
  keyword: string;
  keywordStatusText: string;
  maxCPC: number;
  revenue: number;
  roas: number;
  sa360Label: string;
  searchAbsoluteTopImpressionShare: number;
  searchImpressionShare: number;
  searchTopImpressionShare: number;
  transactions: number;
  projectedClicks: number;
  projectedCost: number; // currency
  projectedTrans: number;
  projectedRevenue: number; // currency
  projectedROAS: number; // currency
  projectedImpressionChange: number;
  projectedClicksChange: number;
  projectedCostChange: number; // currency
  projectedTransChange: number;
  projectedRevenueChange: number; // currency
  projectedROASDelta: number; // currency
  totalPlatformValue: number; // currency
  totalKeywords: number;
  groupId?: number;
}

export interface IKeywordsManagementState {
  isLoading: boolean;
  data: IKeyword[] | null;
  error: Error | null;
  page: number;
  limit: number;
  totalItems: number;
  filters: FilterState[];
  subAccountId: number | null;
  accountId: number | null;
  groupId?: number;
  keywordIds?: Array<string | number>;
}

export interface IKeywordsGroupCreationState {
  isLoading: boolean;
  keywords: IKeyword[] | null;
  error: Error | null;
}

export interface KeywordsQueryParams {
  subAccountId: number;
  accountId: number;
  groupId?: number;
  filters: FilterState[];
  sortOrder?: boolean;
  sortBy?: keyof IKeyword;
}

export interface IGetKeywordsSuccessProps {
  subAccountId?: number;
  keywords?: IKeyword[];
  totalItems?: number;
  groupId?: number;
  group?: IKeywordGroupInfo;
  keywordIds?: Array<string | number>;
  suggestedKeywords?: IKeywordNewType[];
  lastIngested?: string;
  keywordsInGroups?: IKeywordNewType[];
}

export interface IKeywordsDetailsPageState {
  subAccountId: number | null;
  keywordsPerSubaccount: {
    isLoading: boolean;
    data: IKeyword[];
    error: Error | null;
  };
  keywordsPerKeywordGroup: {
    isLoading: boolean;
    data: IKeyword[] | null;
    error: Error | null;
    groupId: number | null;
  };
  suggestedKeywords: {
    isLoading: boolean;
    data: IKeywordNewType[];
    error: Error | null;
    lastIngested: string;
  };
  keywordsOfSubaccountInKeywordGroups: {
    isLoading: boolean;
    data: IKeywordNewType[];
    error: Error | null;
  };
  keywordGroupCreationStatus: {
    isLoading: boolean;
    error: Error | null;
    numberOfAddedKeywords: number;
  };
}

export interface RemoveKeywordsParams {
  subAccountId: number;
  keywordsToRemove: Record<string, Array<number | string>>;
  keywordIds: Array<string | number>;
  emptyGroupsToRemove: number[];
  date: string;
  accountId: number;
}

export enum SuggestedKeywordStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  NEW_KEYWORDS = 'NEW',
}

export interface IKeywordNewType {
  keyword: string;
  sconImprSum: number;
  sconClicksSum: number;
  sconPosAvg: number;
  sconCTR: number;
  sconTransSum: number;
  sconRevenueSum: number;
  gadsTextImprSum: number;
  gadsTextClicksSum: number;
  gadsTextCostSum: number;
  gadsTextCTR: number;
  gadsTextCPC: number;
  gadsTextRevenueSum: number;
  totalImprSum: number;
  totalClicksSum: number;
  totalCTR: number;
  totalRevenueSum: number;
  totalMargin: number;
  gadsTextImprShareAvg: number;
  gadsTextTopImprShareAvg: number;
  gadsTextAbsTopImprShareAvg: number;
  status?: SuggestedKeywordStatus;
  id: number;
  keywordGroupId?: number;
  keywordGroupName?: string;
}

export const KEYWORDS_DATA_AVAILABLE_DAYS_BACK: number = 3;
