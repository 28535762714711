import React, { useEffect, useState } from 'react';

import { SideNav as UIKitSideNav } from '@dqna/seamless-suite-ui-kit';
import { VpnKeyOutlined, BusinessCenterOutlined } from '@mui/icons-material';
import { useLocation, useHistory } from 'react-router';
import { Routes } from 'types/app.types';
import { SideNavProps } from './types';
import { INavBarItem } from '@dqna/seamless-suite-ui-kit/dist/components/SideNav/ISideNavProps';

export const SideNav = (props: SideNavProps) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [navItems, setNavItems] = useState<INavBarItem[]>([]);

  useEffect(() => {
    if (pathname.includes(Routes.ADMIN)) {
      setNavItems(adminNavItems);
    } else {
      setNavItems(appNavItems);
    }
  }, [pathname]);

  const appNavItems = [
    {
      icon: <VpnKeyOutlined />,
      label: 'Keywords',
      route: Routes.LINKED_ACCOUNTS,
      isSelected: pathname.includes('linkedAccounts') || pathname.includes('keywordManagement'),
    },
  ];

  const adminNavItems = [
    {
      icon: <BusinessCenterOutlined />,
      label: 'Organisation',
      route: Routes.ADMIN,
      isSelected: true,
    },
  ];

  return (
    <UIKitSideNav
      items={navItems}
      onClick={history.push}
      onClickLogo={() => {
        history.push('/');
      }}
      selectedRouteDefault=""
    />
  );
};
