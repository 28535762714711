import { ReactNode } from 'react';

export enum TabsDirection {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export interface TabsProps {
  activeTabKey: string;
  onChangeTab: (tab: string) => void;
  expandable?: boolean;
  direction?: TabsDirection;
}

export interface TabPaneProps {
  label: ReactNode;
  tabKey: string;
  active?: boolean;
  Icon?: string;
  children: ReactNode;
  isInvisible?: boolean;
}
