import React from 'react';
import { Typography } from '@mui/material';
import {
  Button,
  FormContainer,
  ModalActions,
  TextFieldFormElement,
} from '@dqna/seamless-suite-ui-kit';
import { AccountNameFormData, AccountNameStepProps } from './types';
import { LINK_ACCOUNT_MODAL_NUMBER } from 'constants/subAccount';

export const AccountsNameStep: React.FC<AccountNameStepProps> = (props) => {
  const { onCloseModal, onSubmit, formMethods, accounts, creationError, newAccount } = props;

  const isUnique = (accountName: string) =>
    new Set(accounts.map((account) => account.name))?.has(accountName) &&
    newAccount?.name !== accountName
      ? 'Account name already exists'
      : true;

  return (
    <FormContainer<AccountNameFormData> onSubmit={onSubmit} formMethods={formMethods}>
      <Typography variant="body2" marginBottom={3}>
        1/{LINK_ACCOUNT_MODAL_NUMBER} - General info
      </Typography>
      <TextFieldFormElement
        name="accountName"
        title="Account Name"
        rules={{
          required: true,
          validate: isUnique,
        }}
        inputProps={{ 'aria-label': 'account-name' }}
      />
      <ModalActions>
        <Button variant="outlined" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button variant="contained" type="submit" disabled={!!creationError}>
          Next
        </Button>
      </ModalActions>
    </FormContainer>
  );
};

AccountsNameStep.displayName = 'AccountsNameStep';
