import React, { FC } from "react"
import { Box } from "@mui/material"
import DropdownHeaderScss from "./DropdownHeader.scss"
import { IDropdownHeaderProps } from "./IDropdownHeaderProps"

const DropdownHeader: FC<IDropdownHeaderProps> = ({ title, subtext }) => (
  <Box className={DropdownHeaderScss.header}>
    <h4>{title}</h4>
    <span>{subtext}</span>
  </Box>
)

export default DropdownHeader
