import React, { FC } from "react"
import { Breadcrumbs, Link } from "@mui/material"
import { Typography } from "@mui/material"
import { IPageTitleProps } from "./IPageTitleProps"
import { KeyboardBackspaceOutlined } from "@mui/icons-material"
import styles from "./PageTitle.scss"

const PageTitle: FC<IPageTitleProps> = (props) => {
  const { routes, title, titleAddition, onLinkClick } = props
  const arrLinkElements = routes.map((route, index) => {
    return (
      <Link
        key={index}
        underline="hover"
        color="inherit"
        href={route.breadcrumbUrl}
        className={styles.breadcrumbs}
        onClick={(event) => onAnchorClick(event)}
      >
        {route.hasBackArrow ? <KeyboardBackspaceOutlined /> : null}
        <span>{route.breadcrumbCaption}</span>
      </Link>
    )
  })

  const onAnchorClick = (
    event:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()

    const currentTargetUrl = (
      event.currentTarget as unknown as HTMLAnchorElement
    ).href

    onLinkClick(
      typeof document === "undefined"
        ? currentTargetUrl
        : currentTargetUrl.slice(
            `${window.location.protocol}//${window.location.host}`.length
          )
    )
  }

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">{arrLinkElements}</Breadcrumbs>
      <div className={styles.titleElement}>
        <Typography variant="h5">{title}</Typography>
        {titleAddition}
      </div>
    </>
  )
}

export default PageTitle
