import React, { FC } from "react"
import { Drawer, MenuItem, Box, Tooltip } from "@mui/material"
import SideNavScss from "./SideNav.scss"
import { ISideBarProps, INavBarItem } from "./ISideNavProps"
import IncubetaSvg from "@src/static/svg/incubeta.svg"

const paperProps = {
  className: SideNavScss.navigation
}

const NavButton: FC<INavBarItem> = ({
  icon,
  label,
  route,
  onClick,
  isSelected,
  disabled
}) => {
  const navBarItemClasses = [
    SideNavScss.item,
    isSelected ? SideNavScss.selectedItem : null,
    disabled ? SideNavScss.disabledItem : null
  ].join(" ")
  const handleClick = () => (onClick && !disabled ? onClick(route) : null)

  const menuItem = (
    <MenuItem
      aria-label="navigation-item"
      onClick={handleClick}
      className={navBarItemClasses}
    >
      {icon}
      <span>{label}</span>
    </MenuItem>
  )

  if (!disabled) return menuItem

  return (
    <Tooltip title="Please create an account first" placement="right" arrow>
      {menuItem}
    </Tooltip>
  )
}

const SideNav: FC<ISideBarProps> = ({
  items,
  onClick,
  onClickLogo,
  imageUrl
}) => {
  const logoClasses = [SideNavScss.logo, SideNavScss.item].join(" ")

  const handleItemClick = (itemKey: string) => {
    if (onClick) {
      onClick(itemKey)
    }
  }

  const handleLogoClick = () => {
    if (onClickLogo) {
      onClickLogo()
    }
  }

  return (
    <Drawer
      PaperProps={paperProps}
      aria-label="side-navigation"
      variant={"permanent"}
      anchor={"left"}
      open={true}
    >
      <div>
        <Box
          onClick={handleLogoClick}
          className={logoClasses}
          aria-label="incubeta-logo"
        >
          <img src={imageUrl ? imageUrl : IncubetaSvg} />
        </Box>

        {items.map((item: INavBarItem) => {
          return (
            <NavButton
              {...item}
              key={item.route}
              isSelected={item.isSelected}
              onClick={handleItemClick}
            />
          )
        })}
      </div>
    </Drawer>
  )
}

export default SideNav
