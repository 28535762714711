import { Routes } from 'types/app.types';

export function generateRouteWithIds(params: {
  route: Routes;
  ids: Record<string, number | string>;
}) {
  const { route, ids } = params;

  let routeWithIds: string = route;

  Object.keys(ids).forEach((idKey) => {
    routeWithIds = routeWithIds.replace(`:${idKey}`, ids[idKey].toString());
  });

  return routeWithIds;
}
