import { ChangeEvent, ReactNode, MemoExoticComponent } from 'react';

export enum InputTypes {
  Checkbox = 'checkbox',
  Text = 'text',
  Radio = 'radio',
  Number = 'number',
}

export interface IBaseInputProps {
  readonly title?: string;
  readonly disabled?: boolean;
  readonly label?: string;
  readonly required?: boolean;
  readonly className?: string;
  readonly name?: string;
  readonly testId?: string;
  readonly validationError?: string;
  readonly tip?: ReactNode;
}

export interface ITextProps extends IBaseInputProps {
  readonly type?: InputTypes.Number | InputTypes.Text;
  readonly Icon?: MemoExoticComponent<any>;
  readonly name: string;
  readonly onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  readonly value?: string | number;
  readonly defaultValue?: string;
  readonly outlined?: boolean;
  readonly onEnter?: (target?: HTMLInputElement) => void;
  readonly label: string;
  readonly autoFocus?: boolean;
  readonly onFocus?: (e?: ChangeEvent<HTMLInputElement>) => void;
  readonly readOnly?: boolean;
}

export interface IIncrementalIntegerTextProps extends ITextProps {
  readonly setValue: (value: number) => void;
  readonly value: number;
  readonly incrementValue?: number;
  readonly incrementMaxValue?: number | null;
  readonly incrementMinValue?: number | null;
  readonly decimalInteger?: number;
}

export interface ITextAreaProps extends IBaseInputProps {
  readonly name: string;
  readonly onChange?: (e?: ChangeEvent<HTMLTextAreaElement>) => void;
  readonly value?: string;
  readonly defaultValue?: string;
  readonly onEnter?: (target?: HTMLTextAreaElement) => void;
  readonly label: string;
  readonly minRows?: number;
  readonly maxRows?: number;
  readonly maxLength?: number;
  readonly autoFocus?: boolean;
}

export interface ICheckBoxProps extends IBaseInputProps {
  readonly onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  readonly value: string;
  readonly checked?: boolean;
  readonly name: string;
  readonly intermediate?: boolean;
}

export interface IRadioProps extends IBaseInputProps {
  readonly onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  readonly value: string;
  readonly checked?: boolean;
  readonly name: string;
}

export const inputDisplayNames = {
  checkbox: 'Checkbox',
  radio: 'Radio',
  textInput: 'TextInput',
  textarea: 'Textarea',
  incrementalIntegerInput: 'IncrementalIntegerInput',
};
