import React, { FC, useEffect, createContext, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { useSelector } from 'react-redux';

import { Spinner } from 'components/ui-kit/Spinner';

import { AuthService } from 'services/Auth.service';
import { IRootState } from 'store/reducers';
import { fetchUser } from 'store/reducers/user.reducer';
import { IAuthProviderProps } from './types';

export const AuthContext = createContext<AuthService | null>(null);

export const AuthProvider: FC<IAuthProviderProps> = ({ children, authService, dispatch }) => {
  const { isAuthServiceReady, isAuthenticated, currentUser } = useSelector(
    (state: IRootState) => state.user
  );

  const { search, pathname } = useLocation();
  const { code } = parse(search);
  const { push } = useHistory();

  const handleAuthRedirectCallback = async () => {
    if (code) {
      await authService.handleRedirectCallback();
      // clear query params
      push(pathname);
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    authService.isAuthenticated();
  };

  useEffect(() => {
    if (isAuthServiceReady) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleAuthRedirectCallback();
    }
  }, [isAuthServiceReady]);

  useEffect(() => {
    if (isAuthenticated && isAuthServiceReady) {
      dispatch(fetchUser());
    }
  }, [isAuthServiceReady, isAuthenticated]);

  const isRenderReady = useMemo(() => {
    return isAuthServiceReady && (!isAuthenticated || (isAuthenticated && currentUser));
  }, [isAuthenticated, isAuthServiceReady, currentUser]);

  return (
    <AuthContext.Provider value={authService}>
      {isRenderReady ? children : <Spinner opened={!isRenderReady} />}
    </AuthContext.Provider>
  );
};
