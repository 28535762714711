import { ChangeEvent } from 'react';
import { useHistory, useLocation } from 'react-router';

const useSearchInUrl = () => {
  const { replace } = useHistory();
  const { search } = useLocation();

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    replace({ search: event.target.value });
  };

  return { handleSearch, search: search.slice(1) };
};

export default useSearchInUrl;
