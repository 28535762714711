import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IRootState } from 'store/reducers';
import { Admin } from 'components/pages/Admin';
import { AppPageTitle } from 'types/layout.types';
import { DataSourcesTypes } from 'types/accounts.type';
import {
  getAccountsOfAllOrgs,
  deleteAccount,
  fetchAccounts,
  checkDataSourceIsLinked,
  checkDataSourceIsVerifiedReset,
  newAccountStateReset,
  createAccount,
  editAccountName,
  verifyAdveritySearchConsole,
  verifyAdverityAdWords,
} from 'store/reducers/accounts.reducer';
import { setPageTitle } from 'store/reducers/layout.reducer';
import {
  addUserToOrganisation,
  createOrganisation,
  deleteOrganisation,
  deleteReinvitedUserFromOrganisation,
  deleteUserFromOrganisation,
  reinviteUserToOrganisation,
  getOrganisations,
  getOrganisationDetails,
} from 'store/reducers/admin.reducer';

const mapStateToProps = (state: IRootState) => {
  const {
    isOrgCreationFailed,
    isLoading,
    currentOrganisation,
    isUserAddFailed,
    isOrgsLoading,
    organisations,
    isLoadingSubaccounts,
    isOrgCreationLoading,
  } = state.admin;
  const { accountsOfAllOrgs, isGetAccountsOfAllOrgsLoading } = state.accounts;
  const {
    accounts: {
      accounts,
      newAccount,
      creationError,
      isDataSourceVerified,
      linkDataSourcesStatuses,
    },
    user: { accessToken, authToken },
  } = state;
  return {
    isOrgCreationFailed,
    isLoading,
    currentOrganisation,
    isUserAddFailed,
    organisations,
    isOrgsLoading,
    isLoadingSubaccounts,
    accountsOfAllOrgs,
    isGetAccountsOfAllOrgsLoading,
    accounts,
    newAccount,
    creationError,
    authToken,
    accessToken,
    isDataSourceVerified,
    linkDataSourcesStatuses,
    isOrgCreationLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createOrganisation: (orgName: string, orgEmail: string) =>
    dispatch(createOrganisation({ orgName, orgEmail })),
  getOrganisations: () => dispatch(getOrganisations()),
  getOrgansiation: (orgId: string) => dispatch(getOrganisationDetails({ orgId })),
  deleteOrganisation: (orgId: string) => dispatch(deleteOrganisation({ orgId })),
  getAccountsOfAllOrgs: () => dispatch(getAccountsOfAllOrgs()),
  deleteAccount: (id: number, orgName: string) => dispatch(deleteAccount({ id, orgName })),
  addUser: (orgId: string, email: Array<{ label: string; value: string }>) =>
    dispatch(addUserToOrganisation({ orgId, email })),
  deleteUser: (orgId: string, userId: string) =>
    dispatch(deleteUserFromOrganisation({ orgId, userId })),
  reinviteUser: (orgId: string, userEmail: string) =>
    dispatch(reinviteUserToOrganisation({ orgId, userEmail })),
  deleteReinvitedUser: (orgId: string, userId: string) =>
    dispatch(deleteReinvitedUserFromOrganisation({ orgId, userId })),
  revertBids: (orgName: string, subAccountId: number, date: string) =>
    dispatch(revertSubaccountBids(orgName, subAccountId, date)),
  setPageTitle: (pageTitle: AppPageTitle) => dispatch(setPageTitle(pageTitle)),
  getAccounts: () => dispatch(fetchAccounts()),
  checkDataSourceIsLinked: (
    dataSourceType: DataSourcesTypes,
    id: number,
    currentTry: number,
    orgName: string
  ) => dispatch(checkDataSourceIsLinked({ dataSourceType, id, currentTry, orgName })),
  checkDataSourceIsVerifiedReset: () => dispatch(checkDataSourceIsVerifiedReset()),
  newAccountStateReset: () => dispatch(newAccountStateReset()),
  createAccount: (name: string, orgName: string) => dispatch(createAccount({ name, orgName })),
  editAccountName: (id: number, name: string, orgName: string) =>
    dispatch(editAccountName({ id, name, orgName })),
  verifyAdveritySearchConsole: (accountId: number, orgName: string) =>
    dispatch(verifyAdveritySearchConsole({ accountId, orgName })),
  verifyAdverityAdWords: (accountId: number, orgName: string) =>
    dispatch(verifyAdverityAdWords({ accountId, orgName })),
});

export type AdminContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const AdminContainer = connect(mapStateToProps, mapDispatchToProps)(Admin);
function revertSubaccountBids(orgName: string, subAccountId: number, date: string): any {
  throw new Error('Function not implemented.');
}
