import { Stack, Typography } from "@mui/material"
import React, { FC } from "react"

export interface CheckboxKebabMenuItemProps {
  label: string
  count?: number
}

const CheckboxKebabMenuItem: FC<CheckboxKebabMenuItemProps> = ({
  label,
  count
}) => {
  return (
    <Stack flexDirection="row">
      <Typography variant="body2" color="#333333">
        {label}
      </Typography>
      {count !== undefined && (
        <Typography variant="body2" fontSize="1.2rem" marginLeft="0.4rem">
          ({count})
        </Typography>
      )}
    </Stack>
  )
}

export default CheckboxKebabMenuItem
