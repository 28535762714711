import { Typography } from "@mui/material"
import React, { FC } from "react"
import CompanyElementScss from "./CompanyElement.scss"
import { ICompanyElementProps } from "./ICompanyElementProps"
import NoImage from "@src/static/svg/no-image.svg"

export const CompanyLogo: FC<ICompanyElementProps> = ({ companyObject }) => (
  <object
    className={CompanyElementScss.companyImage}
    aria-label="app-logo"
    data={!companyObject?.imageUrl ? NoImage : companyObject.imageUrl}
    type="image/jpg"
    width={36}
    height={36}
  ></object>
)

export const CompanyLabel: FC<ICompanyElementProps> = ({
  companyObject,
  large
}) => {
  if (large) {
    return <Typography variant="h2">{companyObject?.name}</Typography>
  }
  const companyLabelClassNames = [CompanyElementScss.companyLabel].join(" ")

  return companyObject?.name ? (
    <span className={companyLabelClassNames} aria-label="app-name">
      {companyObject?.name}
    </span>
  ) : null
}

const CompanyElement: FC<ICompanyElementProps> = ({
  companyObject,
  onClick,
  large
}) => {
  const companyElementClassNames = [CompanyElementScss.companyElement].join(" ")

  return (
    <div className={companyElementClassNames} onClick={onClick ?? null}>
      <CompanyLogo companyObject={companyObject} />
      <CompanyLabel companyObject={companyObject} large={large} />
    </div>
  )
}

export default CompanyElement
