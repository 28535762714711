import React, { FC } from "react"
import {
  Step,
  Stepper as MUIStepper,
  StepLabel,
  Typography
} from "@mui/material"
import { IVerticalStepperProps, IVerticalStep } from "./IVerticalStepperProps"
import styles from "./VerticalStepper.scss"

const VerticalStepper: FC<IVerticalStepperProps> = ({ steps, activeStep }) => {
  const stepElements = steps.map((step: IVerticalStep, index: number) => (
    <Step aria-label="step" key={index}>
      <StepLabel>
        <Typography className={styles.title} component="span" variant="body2">
          {step.title}
        </Typography>
        {step.subTitle && (
          <Typography
            className={styles.subTitle}
            component="span"
            variant="body2"
          >
            {" - "}
            {step.subTitle}
          </Typography>
        )}
      </StepLabel>
    </Step>
  ))

  return (
    <MUIStepper
      classes={{
        root: styles.verticalStepper
      }}
      activeStep={activeStep}
      orientation="vertical"
    >
      {stepElements}
    </MUIStepper>
  )
}

export default VerticalStepper
