import React from "react"
import { TableBody, TableRow as MUITableRow, Divider } from "@mui/material"
import { TableCell, TableRow, Table, TableHead } from "@src/components/Table"
import style from "./DetailDisplayPanel.scss"
import {
  TableDetailRowProps,
  DetailDisplayPanelPropsType
} from "./DetailDisplayPanelProps"

export const TableDetailRow = <T,>(props: TableDetailRowProps<T>) => {
  const { rowIndex, columns, numbered } = props
  const rowDataObject = props.rowData

  const cells = columns.map(({ render, name }) => {
    const cellName = `${rowIndex}.${String(name)}`
    const cellProps = rowDataObject[name]
    return (
      <TableCell valign="top" key={cellName}>
        {render(cellProps, rowIndex)}
      </TableCell>
    )
  })

  return (
    <TableRow>
      {numbered ? (
        <TableCell className={style.numberRow} valign="top">
          {rowIndex + 1}.
        </TableCell>
      ) : null}
      {cells}
    </TableRow>
  )
}

const DetailDisplayPanel = <T,>(props: DetailDisplayPanelPropsType<T>) => {
  const { rows, columns, numbered = false, withDivider = false } = props
  const tableHeaders = columns.map(({ header }, index: number) => (
    <TableCell isHeader key={`header.${index}`}>
      {header}
    </TableCell>
  ))

  const dividerColspan = columns.length + Number(numbered)

  const tableRows = rows.map((row, index) => {
    return (
      <React.Fragment key={index}>
        <TableDetailRow<T>
          rowIndex={index}
          rowData={row}
          columns={columns}
          numbered={numbered}
        />
        {withDivider && rows.length - 1 > index ? (
          <MUITableRow>
            <TableCell colSpan={dividerColspan}>
              <Divider
                className={style.divider}
                sx={{ borderStyle: "dashed" }}
              />
            </TableCell>
          </MUITableRow>
        ) : null}
      </React.Fragment>
    )
  })

  return (
    <>
      <Table>
        <TableHead>
          <MUITableRow>
            {numbered ? <TableCell>No.</TableCell> : null}
            {tableHeaders}
          </MUITableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </>
  )
}

export default DetailDisplayPanel
