import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAccountsState, IAccount, DataSourcesStatus, DataSourcesTypes } from 'types/accounts.type';
import { MAX_NUMBER_OF_TRIES } from 'constants/account';
import { getOrg } from 'utils/manageOrganisations';

export type { IAccountsState } from 'types/accounts.type';

const initialState: IAccountsState = {
  isLoading: true,
  isGetAccountsOfAllOrgsLoading: true,
  error: null,
  creationError: null,
  accounts: null,
  newAccount: null,
  isDataSourceVerified: false,
  linkDataSourcesStatuses: {
    ADVERITY_ADWORDS: null,
    ADVERITY_SEARCH_CONSOLE: null,
    ADWORDS: null,
    SEARCH_CONSOLE: null,
  },
  accountsOfAllOrgs: null,
  urlsByAccount: {},
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    fetchAccounts: (state) => ({
      ...state,
      isLoading: true,
    }),
    fetchAccountsSuccess: (state, action: PayloadAction<IAccount[]>) => ({
      ...state,
      isLoading: false,
      error: null,
      accounts: action.payload,
    }),
    fetchAccountsFail: (state, action: PayloadAction<Error>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    createAccount: (state, action: PayloadAction<{ name: string; orgName?: string }>) => ({
      ...state,
      isLoading: true,
      creationError: null,
    }),
    createAccountSuccess: (
      state,
      action: PayloadAction<{ id: number; name: string; orgName: string }>
    ) => {
      const { id, name, orgName } = action.payload;
      const currentOrganisation = getOrg();
      if (currentOrganisation === orgName) {
        state.accounts = [...(state.accounts ?? []), { id, name, data_sources: [] }];
      }

      if (state.accountsOfAllOrgs) {
        state.accountsOfAllOrgs = {
          ...state.accountsOfAllOrgs,
          [orgName]: [...(state.accountsOfAllOrgs[orgName] || []), { id, name, data_sources: [] }],
        };
      }

      state.isLoading = false;
      state.newAccount = { id, name };
    },
    createAccountFail: (state, action: PayloadAction<Error>) => ({
      ...state,
      isLoading: false,
      creationError: action.payload,
    }),
    deleteAccount: (state, action: PayloadAction<{ id: number; orgName?: string }>) => ({
      ...state,
      isLoading: true,
      creationError: null,
    }),
    deleteAccountSuccess: (state, action: PayloadAction<{ id: number; orgName: string }>) => {
      const { id, orgName } = action.payload;
      const accounts = state.accounts?.filter((account) => account.id !== id);
      const currentOrganisation = getOrg();
      if (currentOrganisation === orgName) {
        state.accounts = accounts ?? [];
      }

      if (state.accountsOfAllOrgs) {
        state.accountsOfAllOrgs = {
          ...state.accountsOfAllOrgs,
          [orgName]: state.accountsOfAllOrgs[orgName].filter((account) => account.id !== id),
        };
      }

      state.isLoading = false;
    },
    deleteAccountFail: (state, action: PayloadAction<Error>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    checkDataSourceIsLinked: (
      state,
      action: PayloadAction<{
        dataSourceType: DataSourcesTypes;
        id: number;
        currentTry: number;
        orgName?: string;
      }>
    ) => {
      return {
        ...state,
      };
    },
    checkDataSourceIsLinkedSuccess: (
      state,
      action: PayloadAction<{
        dataSourceType: DataSourcesTypes;
        dataSourceStatus: DataSourcesStatus;
        currentTry: number;
      }>
    ) => {
      const { dataSourceType, dataSourceStatus, currentTry } = action.payload;
      state.isLoading = false;
      state.error = null;
      state.linkDataSourcesStatuses[dataSourceType] = {
        status: dataSourceStatus,
        currentTry: currentTry === MAX_NUMBER_OF_TRIES ? currentTry : currentTry + 1,
      };
    },
    checkDataSourceIsLinkedFail: (
      state,
      action: PayloadAction<{
        dataSourceType: DataSourcesTypes;
        dataSourceStatus: DataSourcesStatus;
        currentTry: number;
        error: Error;
      }>
    ) => {
      const { dataSourceType, dataSourceStatus, currentTry, error } = action.payload;
      state.error = error;
      state.linkDataSourcesStatuses[dataSourceType] = {
        status: dataSourceStatus,
        currentTry: currentTry === MAX_NUMBER_OF_TRIES ? currentTry : currentTry + 1,
      };
    },
    checkDataSourceIsVerifiedReset: (state) => ({
      ...state,
      isDataSourceVerified: false,
    }),
    verifyAdverityAdWords: (
      state,
      action: PayloadAction<{ accountId: number; orgName?: string }>
    ) => {
      return {
        ...state,
      };
    },
    verifyAdverityAdWordsSuccess: (state) => ({
      ...state,
      isDataSourceVerified: true,
    }),
    verifyAdverityAdWordsFail: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload,
    }),
    verifyAdveritySearchConsole: (
      state,
      action: PayloadAction<{ accountId: number; orgName?: string }>
    ) => {
      return {
        ...state,
      };
    },
    verifyAdveritySearchConsoleSuccess: (state, action: PayloadAction<number>) => ({
      ...state,
      isDataSourceVerified: true,
    }),
    verifyAdveritySearchConsoleFail: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload,
    }),
    editAccountName: (
      state,
      action: PayloadAction<{ id: number; name: string; orgName?: string }>
    ) => {
      return {
        ...state,
        creationError: null,
      };
    },
    editAccountNameSuccess: (
      state,
      action: PayloadAction<{ id: number; name: string; orgName: string }>
    ) => {
      const { id, name, orgName } = action.payload;
      const accounts = state.accounts?.map((account) => {
        if (account.id !== id) return account;
        return {
          ...account,
          name,
        };
      });
      const currentOrganisation = getOrg();

      if (currentOrganisation === orgName) {
        state.accounts = accounts ?? [];
      }

      if (state.accountsOfAllOrgs) {
        state.accountsOfAllOrgs = {
          ...state.accountsOfAllOrgs,
          [orgName]: state.accountsOfAllOrgs[orgName].map((account) => {
            if (account.id !== id) return account;
            return {
              ...account,
              name,
            };
          }),
        };
      }

      state.isLoading = false;
    },
    editAccountNameFail: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload,
      creationError: action.payload,
    }),
    newAccountStateReset: (state) => ({
      ...state,
      newAccount: null,
      linkDataSourcesStatuses: {
        ADVERITY_ADWORDS: null,
        ADVERITY_SEARCH_CONSOLE: null,
        ADWORDS: null,
        SEARCH_CONSOLE: null,
      },
    }),
    getAccountsOfAllOrgs: (state) => ({
      ...state,
      isGetAccountsOfAllOrgsLoading: true,
    }),
    getAccountsOfAllOrgsSuccess: (state, action: PayloadAction<Record<string, IAccount[]>>) => ({
      ...state,
      accountsOfAllOrgs: action.payload,
      isGetAccountsOfAllOrgsLoading: false,
    }),
    getAccountsOfAllOrgsFail: (state) => ({
      ...state,
      accountsOfAllOrgs: null,
      isGetAccountsOfAllOrgsLoading: false,
    }),
    fetchSubAccountUrls: (state) => ({
      ...state,
      error: null,
    }),
    fetchSubAccountUrlsSuccess: (state, action: PayloadAction<Record<string, string[]>>) => ({
      ...state,
      urlsByAccount: action.payload,
    }),
    fetchSubAccountUrlsFail: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const {
  fetchAccounts,
  fetchAccountsSuccess,
  fetchAccountsFail,
  createAccount,
  createAccountSuccess,
  createAccountFail,
  deleteAccount,
  deleteAccountSuccess,
  deleteAccountFail,
  checkDataSourceIsLinked,
  checkDataSourceIsLinkedSuccess,
  checkDataSourceIsLinkedFail,
  checkDataSourceIsVerifiedReset,
  verifyAdverityAdWords,
  verifyAdverityAdWordsSuccess,
  verifyAdverityAdWordsFail,
  verifyAdveritySearchConsole,
  verifyAdveritySearchConsoleSuccess,
  verifyAdveritySearchConsoleFail,
  editAccountName,
  editAccountNameSuccess,
  editAccountNameFail,
  newAccountStateReset,
  getAccountsOfAllOrgs,
  getAccountsOfAllOrgsSuccess,
  getAccountsOfAllOrgsFail,
  fetchSubAccountUrls,
  fetchSubAccountUrlsSuccess,
  fetchSubAccountUrlsFail,
} = accountsSlice.actions;
export default accountsSlice.reducer;
