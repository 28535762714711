import React, { FC, useState, useEffect } from "react"
import styles from "./UserInitialsAvatar.scss"

export interface UserInitialsAvatarProps {
  /**
   * The name of the user to take the initials from
   */
  name?: string
  /**
   * Whether the avatar should be rendered in small size
   */
  size?: "small" | "normal"
}

const UserInitialsAvatar: FC<UserInitialsAvatarProps> = ({ name, size }) => {
  const [initials, setInitials] = useState<string>(null)

  useEffect(() => {
    if (!name) {
      setInitials("?")
    } else {
      const words = name.split(" ")
      const namesToUse = words.length === 2 ? words : [words[0]]
      const letters = namesToUse
        .map((nameToUse) => nameToUse.charAt(0).toUpperCase())
        .join("")
      setInitials(letters)
    }
  }, [])

  return (
    <div
      className={`${size === "small" ? styles.smallInitials : styles.initials}`}
    >
      <span className={styles.letters}>{initials}</span>
    </div>
  )
}

export default UserInitialsAvatar
