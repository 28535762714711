import classNames from 'classnames';
import React, {
  MouseEvent,
  RefObject,
  FC,
  useRef,
  useEffect,
  forwardRef,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';

import { Button, ButtonTypes } from 'components/ui-kit/Button';
import { Text, TextTypes } from 'components/ui-kit/Text';

import Cross from 'jsx:assets/icons/cross.svg';

import { KeyCodes } from '../../../constants';
import { IModalProps } from './Modal.types';

import './Modal.scss';

export const Modal: FC<IModalProps> = forwardRef(
  (
    {
      children,
      opened,
      onClose,
      onAccept,
      onDecline,
      title,
      CloseIcon,
      acceptText,
      declineText,
      testId,
      selectedClassName,
      checkIfDisabled,
    },
    ref: RefObject<HTMLDivElement>
  ) => {
    const createDiv = () => {
      const div = document.createElement('div');
      div.setAttribute('id', `modal-${Date.now()}`);

      return div;
    };

    const el = useRef<HTMLElement>(createDiv());
    const root = useRef<HTMLElement | null>(document.body);
    const wrapper = useRef<HTMLDivElement | null>(null);

    const handleClose = useCallback(
      (wasAccepted: boolean, e?: MouseEvent) => {
        e?.stopPropagation();
        e?.preventDefault();
        onClose(wasAccepted);
      },
      [onClose]
    );

    const handleEscClose = useCallback(
      (e: KeyboardEvent) => {
        if (e.keyCode === KeyCodes.Esc && opened) {
          e.preventDefault();
          handleClose(false);
        }
      },
      [handleClose]
    );

    const handleOutsideClick = useCallback(
      ({ target }: MouseEvent) => {
        if (wrapper.current === target) {
          handleClose(false);
        }
      },
      [handleClose]
    );

    const handleAcceptClick = useCallback(() => {
      handleClose(true);
      onAccept?.();
    }, [handleClose, onAccept]);

    const handleDeclineClick = useCallback(() => {
      handleClose(false);
      onDecline?.();
    }, [handleClose, onDecline]);

    useEffect(() => {
      root.current?.appendChild(el.current);
      document.body.addEventListener('keyup', handleEscClose, false);

      return () => {
        root.current?.removeChild(el.current);
        document.body.removeEventListener('keyup', handleEscClose, false);
      };
    }, []);

    useEffect(() => {
      if (opened) {
        wrapper.current?.focus();
      }
    }, [opened]);

    const renderModalHeader = () => {
      const RenderCloseIcon = CloseIcon ?? Cross;

      return (
        <div className="modal__header">
          <Text type={TextTypes.H2} bold={true}>
            {title}
          </Text>
          <RenderCloseIcon
            onClick={(e: MouseEvent) => {
              handleClose(false, e);
            }}
            className="modal__close-btn"
            width="2.4rem"
            height="2.4rem"
          />
        </div>
      );
    };

    const renderModalFooter = () => {
      if (!(acceptText ?? declineText)) {
        return null;
      }

      return (
        <div className="modal__footer">
          {declineText && (
            <Button
              onClick={handleDeclineClick}
              type={ButtonTypes.Secondary}
              name={declineText}
              className="modal__decline">
              {declineText}
            </Button>
          )}
          {acceptText && (
            <Button
              onClick={handleAcceptClick}
              type={ButtonTypes.Primary}
              name={acceptText}
              disabled={checkIfDisabled}
              className="modal__accept">
              {acceptText}
            </Button>
          )}
        </div>
      );
    };

    const renderModalBody = () => {
      const modalBodyClass = classNames('modal__body', {
        'modal__body--extended': !(acceptText ?? declineText),
      });

      return (
        <div className={modalBodyClass} ref={ref}>
          {children}
        </div>
      );
    };

    const renderModal = () => (
      <aside
        aria-labelledby={title}
        aria-describedby={title}
        aria-expanded={opened}
        role="dialog"
        data-testid={testId}
        className="modal"
        onClick={handleOutsideClick}
        ref={wrapper}
        tabIndex={0}>
        <div className={`modal__content ${selectedClassName ?? ''}`} title={title}>
          {renderModalHeader()}
          {renderModalBody()}
          {renderModalFooter()}
        </div>
      </aside>
    );

    return opened ? createPortal(renderModal(), el.current) : null;
  }
);

Modal.displayName = 'Modal';
