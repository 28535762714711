import React from 'react';
import { render } from 'react-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { App } from './components/pages/App';
import { idb } from 'services/IDB.service';
import { registerWorker, startOneSignal } from 'services/ServiceWorker.service';
import './index.scss';

void idb.init().then(() => {
  void registerWorker().then((reg) => {
    if (reg === null) {
      render(
        <p>
          Your browser doesn`&lsquo;t support Service Worker technology, which is mandatory for the
          work of application. Please, update your browser or switch to more up-to-date browser
        </p>,
        document.getElementById('app')
      );
    } else {
      startOneSignal();
      const LDProvider = withLDProvider({
        clientSideID: '6464846fb4e764137e95c125',
      })(App);
      render(<LDProvider />, document.getElementById('app'));
    }
  });
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    OneSignal: any;
  }
}
