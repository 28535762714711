import React, {
  FC,
  useState,
  ReactNode,
  NamedExoticComponent,
  ReactElement,
  useCallback,
  memo,
} from 'react';
import classNames from 'classnames';

import { TabPaneProps, TabsProps, TabsDirection } from './types';

import { Button, ButtonTypes } from 'components/ui-kit/Button';

import Cross from 'jsx:assets/icons/cross.svg';
import LeftArrow from 'jsx:assets/icons/leftArrow.svg';

import './Tabs.scss';

export const Tabs: FC<TabsProps> = memo(
  ({ activeTabKey, onChangeTab, expandable, children, direction = TabsDirection.Vertical }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const navList: ReactNode[] = [];
    const panelList: ReactNode[] = [];

    const onClickNav = useCallback(
      (tab: string) => () => {
        onChangeTab(tab);
      },
      [onChangeTab]
    );
    const onClickCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };

    React.Children.toArray(children)
      .filter(
        (child: ReactElement<any, NamedExoticComponent>) => child.type.displayName === 'TabPane'
      )
      .forEach((child: ReactElement<TabPaneProps>) => {
        const { tabKey, label, children, isInvisible, Icon = Cross } = child.props;

        panelList.push(
          <div className="tabs__panel-content" key={tabKey}>
            {children}
          </div>
        );

        if (isInvisible) {
          return;
        }

        navList.push(
          <li
            tabIndex={0}
            key={label}
            title={label}
            role="tab"
            aria-selected={activeTabKey === tabKey}
            onClick={onClickNav(tabKey)}
            className={classNames('tabs__nav-element', {
              'tabs__nav-element--active': activeTabKey === tabKey,
            })}>
            {expandable && (
              <div>
                <Icon width="2.5rem" height="2.5rem" className="tabs__nav-icon" />
              </div>
            )}
            {label}
          </li>
        );
      });

    return (
      <div
        className={classNames('tabs', {
          'tabs--collapsed': isCollapsed,
          'tabs--horizontal': direction === TabsDirection.Horizontal,
        })}>
        <div className="tabs__nav" role="tablist">
          <ul className="tabs__nav-list">{navList}</ul>
          {expandable && (
            <Button
              name={isCollapsed ? 'Expand' : 'Collapse'}
              className="tabs__collapse-btn"
              type={ButtonTypes.Primary}
              onClick={onClickCollapse}>
              <LeftArrow className="tabs__collapse-icon" />
            </Button>
          )}
        </div>
        <div className={'tabs__panel ' + activeTabKey} role="tabpanel">
          {panelList.find((content: ReactElement) => content.key === activeTabKey)}
        </div>
      </div>
    );
  }
);

Tabs.displayName = 'Tabs';
