import React, {
  NamedExoticComponent,
  ReactElement,
  useState,
  useCallback,
  useRef,
  RefObject,
  UIEvent,
  useLayoutEffect,
} from 'react';
import classNames from 'classnames';
import { unparse } from 'papaparse';
import differenceBy from 'lodash/differenceBy';
import uniqBy from 'lodash/uniqBy';
import upperFirst from 'lodash/upperFirst';
import { IMultiselectItemData } from 'components/ui-kit/Dropdown';

import { Spinner } from 'components/ui-kit/Spinner';
import { Button, ButtonTypes } from 'components/ui-kit/Button';
import { Pagination } from 'components/ui-kit/Pagination';

import Download from 'jsx:assets/icons/download.svg';

import { TableBody } from './TableBody.component';
import {
  TableBaseProps,
  TableElements,
  TableType,
  TableCellAlign,
  TableHeaderCell,
  TableBodyCell,
  TableFooterCell,
} from './types';

import './Table.scss';
import { Alert, Button as MuiButton, Typography } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

export const TableBase = <T extends { id: string | number }>({
  dataSource,
  children,
  className,
  selectable,
  selectionDisabled,
  fullSelectionMetric,
  isLoading,
  scrollable,
  pagination,
  downloadCsvData,
  extras,
  excludedColumns = {},
  columnFilter,
  selectionCommonField = 'id',
  errorTableElement,
  type = TableType.Table,
}: TableBaseProps<T>) => {
  const initialData = useRef<T[]>(dataSource);
  const [selectedMetrics, setSelectedMetrics] = useState<Array<T[keyof T]>>([]);

  useLayoutEffect(() => {
    initialData.current = dataSource;
  }, [dataSource]);

  let isExpandableRows: boolean | undefined = false;

  const onSelectRow = useCallback(
    (row: T, selected: boolean) => {
      setSelectedMetrics((metrics) =>
        selected
          ? [...metrics, row[selectionCommonField]]
          : metrics.filter((metric) => metric !== row[selectionCommonField])
      );
    },
    [selectionCommonField]
  );

  const onSelectAllRows = useCallback(
    (selected: boolean) => {
      const metricsSet = uniqBy(initialData.current, selectionCommonField).map(
        (item) => item[selectionCommonField]
      );

      if (selected) {
        setSelectedMetrics((metrics) => [
          ...metrics,
          ...differenceBy(fullSelectionMetric ?? metricsSet, metrics),
        ]);
      } else {
        setSelectedMetrics((metrics) => differenceBy(metrics, fullSelectionMetric ?? metricsSet));
      }
    },
    [selectionCommonField, initialData, fullSelectionMetric]
  );

  let headersBody: ReactElement | null = null;
  let tableBody: ReactElement | null = null;
  let footerBody: ReactElement | null = null;

  React.Children.toArray(children).forEach((child: ReactElement<any, NamedExoticComponent>) => {
    if (child.type.displayName === TableElements.TableHeader) {
      headersBody = child;
    }

    if (child.type.displayName === TableElements.TableBody) {
      tableBody = child;
    }

    if (child.type.displayName === TableElements.TableFooter) {
      footerBody = child;
    }
  });

  if (!tableBody) {
    return null;
  }

  const headerProps = headersBody ? (headersBody as ReactElement).props : { cells: [] };
  const tableProps = tableBody ? (tableBody as ReactElement).props : { cells: [] };
  const footerProps = footerBody ? (footerBody as ReactElement).props : { cells: [] };

  const tableColumnFilters: IMultiselectItemData[] = headerProps.cells.map(
    ({ columnId, constant, renderer }: TableHeaderCell<T>): IMultiselectItemData => {
      let label = upperFirst(columnId);
      if (renderer) {
        label = typeof renderer === 'function' ? renderer(dataSource) : renderer;
      }
      return {
        value: columnId || '',
        label,
        selected: !excludedColumns[columnId],
        disabled: constant,
      };
    }
  );

  const headerCells: Array<TableHeaderCell<T>> = headerProps.cells.filter(
    (_: any, idx: number) => tableColumnFilters[idx].selected
  );
  const tableCells: Array<TableBodyCell<T>> = tableProps.cells.filter(
    (_: any, idx: number) => tableColumnFilters[idx].selected
  );
  const footerCells: Array<TableFooterCell<T>> = footerProps.cells.filter(
    (_: any, idx: number) => tableColumnFilters[idx].selected
  );

  isExpandableRows = !!(tableBody as ReactElement).props.expandable;

  const intersectedData = initialData.current.filter((tableRow) =>
    selectedMetrics.find((metric) => metric === tableRow[selectionCommonField])
  );

  const headers = headersBody
    ? React.cloneElement(headersBody as ReactElement, {
        ...headerProps,
        cells: headerCells,
        data: dataSource,
        selectable,
        onSelectAllRows,
        expandable: isExpandableRows,
        selected:
          (intersectedData.length >= 0 && intersectedData.length === initialData.current.length) ||
          intersectedData.length > 0,
        intermediate:
          intersectedData.length > 0 && intersectedData.length < initialData.current.length,
      })
    : null;

  const rowReturnNoDataIncluded = dataSource.length ? (
    dataSource.map((data, index: number) => {
      return React.cloneElement(tableBody as ReactElement, {
        ...tableProps,
        cells: tableCells,
        data,
        key: index,
        selectable,
        selectionDisabled,
        onSelectRow,
        selected:
          selectedMetrics.find((metric) => metric === data[selectionCommonField]) !== undefined,
      });
    })
  ) : (
    <TableBody
      cells={[
        {
          renderer: () => <span>Batch job pending</span>,
          className: 'table__cell--no-data',
          colspan: (tableBody as ReactElement).props.cells.length,
          align: TableCellAlign.Center,
        },
      ]}
    />
  );

  const rows = errorTableElement?.enabled ? (
    <TableBody
      cells={[
        {
          renderer: () => (
            <Alert
              sx={{ width: '100%' }}
              severity="error"
              action={
                <MuiButton
                  className="no-data-alert--mui-button"
                  variant="text"
                  color="inherit"
                  size="medium"
                  onClick={errorTableElement?.errorAction}
                  startIcon={<DeleteOutline />}>
                  {errorTableElement?.buttonText}
                </MuiButton>
              }>
              <Typography className="no-data-alert--typography" variant="body2">
                {errorTableElement?.caption}
              </Typography>
            </Alert>
          ),
          className: '',
          colspan: (tableBody as ReactElement).props.cells.length,
          align: TableCellAlign.Center,
        },
      ]}
    />
  ) : (
    rowReturnNoDataIncluded
  );

  const footer = footerBody
    ? React.cloneElement(footerBody as ReactElement, {
        ...footerProps,
        cells: footerCells,
        data: dataSource,
        selectable,
        expandable: isExpandableRows,
      })
    : null;

  const renderPagination = () => {
    if (pagination) {
      return <Pagination containerClass="table__pagination" {...pagination} />;
    }
    return null;
  };

  const renderDownloadCsv = useCallback(() => {
    if (!downloadCsvData) {
      return null;
    }

    const onDownload = () => {
      const csv = unparse(downloadCsvData);
      const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      const link = document.createElement('a');
      const url = URL.createObjectURL(csvBlob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'document.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return (
      <Button
        type={ButtonTypes.Secondary}
        onClick={onDownload}
        name="Download CSV"
        disabled={!downloadCsvData.length}
        className="table__csv">
        Download CSV&nbsp;
        <Download />
      </Button>
    );
  }, [downloadCsvData]);

  const renderExtras = () => {
    return extras
      ? extras({
          selectedItems: intersectedData,
          matchedValues: selectedMetrics,
          setMatchedValues: setSelectedMetrics,
          metric: selectionCommonField,
        })
      : null;
  };

  const renderColumnFilter = columnFilter ? (
    <div className="table table__extra">{columnFilter(tableColumnFilters)}</div>
  ) : null;

  const renderTableExtras = (
    <div className="table table__extra">
      <div className="table__extra--left">
        {renderDownloadCsv()}
        {renderExtras()}
      </div>
      <div className="table__extra--right">{renderPagination()}</div>
    </div>
  );

  const renderBasicTable = () => (
    <div
      className={classNames('table', className, {
        'table--list': type === TableType.List,
      })}>
      {renderColumnFilter}
      {renderTableExtras}
      <table className="table__base">
        {headers}
        <tbody>{rows}</tbody>
        {footer}
      </table>
      <Spinner opened={!!isLoading} />
    </div>
  );

  const renderScrollableTable = () => {
    const headerRef = useRef() as RefObject<HTMLDivElement>;
    const footerRef = useRef() as RefObject<HTMLDivElement>;

    const onBodyScroll = useCallback(
      (event: UIEvent<HTMLDivElement>) => {
        const { scrollLeft } = event.target as HTMLDivElement;

        if (headerRef.current) {
          headerRef.current.scrollLeft = scrollLeft;
        }

        if (footerRef.current) {
          footerRef.current.scrollLeft = scrollLeft;
        }
      },
      [headerRef, footerRef]
    );

    const headersBlock = headers ? (
      <div className="table__headers-wrapper" ref={headerRef}>
        <table className="table__base">{headers}</table>
      </div>
    ) : null;

    const footerBlock = footer ? (
      <div className="table__footer-wrapper" ref={footerRef}>
        <table className="table__base">{footer}</table>
      </div>
    ) : null;

    const bodyBlock = (
      <div className="table__body-wrapper" onScroll={onBodyScroll}>
        <table className="table__base">
          <tbody>{rows}</tbody>
        </table>
      </div>
    );

    return (
      <div className="table table--scrollable">
        {renderColumnFilter}
        {renderTableExtras}
        {headersBlock}
        {bodyBlock}
        {footerBlock}
        <Spinner opened={!!isLoading} />
      </div>
    );
  };

  return scrollable ? renderScrollableTable() : renderBasicTable();
};
