import React, { FC, useState, MouseEvent, BaseSyntheticEvent } from "react"
import Button from "../Button"
import { Button as MuiButton } from "@mui/material"
import { ArrowDropDown } from "@mui/icons-material"
import style from "./ButtonWithDropdown.scss"
import {
  ButtonMenuItem,
  ButtonWithDropdownProps
} from "./ButtonWithDropdownProps"
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"

const ButtonWithDropdown: FC<ButtonWithDropdownProps> = ({
  children,
  menuItems,
  menuMinWidth,
  keepOpen,
  disabled,
  onFileUpload
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (item: ButtonMenuItem) => {
    if (item.action && typeof item.action === "function") {
      item.action()
    }
    if (!keepOpen) {
      close()
    }
  }

  const close = () => {
    setAnchorEl(null)
  }

  const handleFileUpload = (e: BaseSyntheticEvent) => {
    onFileUpload?.(e.target.files)
    close()
  }

  return (
    <div>
      <Button
        className={[
          style.buttonWithDropdown,
          `${!disabled && style.enabled}`
        ].join(" ")}
        endIcon={<ArrowDropDown sx={{ marginLeft: "2.2rem" }} />}
        variant="contained"
        color="secondary"
        aria-controls={open ? "dropdown-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={disabled}
      >
        {children}
      </Button>
      <Menu
        aria-labelledby="dropdown-button"
        anchorEl={anchorEl}
        open={open}
        onClose={close}
        classes={{ paper: style.buttonWithDropdownMenu }}
        sx={{ ".MuiPaper-root": { minWidth: menuMinWidth } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {menuItems.map((item, index) => {
          if (item.fileUpload) {
            return (
              <MenuItem
                aria-label={`action-${item.value}`}
                key={index}
                className={style.fileUploadMenuItem}
              >
                <MuiButton component="label" startIcon={item.icon ?? null}>
                  {item.label}
                  <input
                    type="file"
                    accept={item.acceptedFileTypes ?? undefined}
                    onChange={handleFileUpload}
                    multiple
                    hidden
                  />
                </MuiButton>
              </MenuItem>
            )
          } else {
            return (
              <MenuItem
                aria-label={`action-${item.value}`}
                key={index}
                onClick={() => {
                  handleItemClick(item)
                }}
                disabled={item.disabled}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText>{item.label}</ListItemText>
              </MenuItem>
            )
          }
        })}
      </Menu>
    </div>
  )
}

export default ButtonWithDropdown
