import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Routes } from 'types/app.types';
import { generateRouteWithIds } from 'utils/generateRouteWithIds';
import { OrganisationUserTabProps } from './types';
import { DataTable, Flag } from '@dqna/seamless-suite-ui-kit';
import { IUser, UserStatus, IUserRoleRes } from 'types/user.type';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import './OrganisationUsersTab.scss';
import useSearchInUrl from 'utils/hooks/useSearchInUrl';
import { DeleteUserModal } from '../DeleteUserModal';

export const OrganisationUsersTab: FC<OrganisationUserTabProps> = (props) => {
  const { users, deleteReinvitedUser, deleteUser, reinviteUser } = props;
  const { replace } = useHistory();
  const { orgId } = useParams<{ orgId: string }>();
  const [currentPage, setCurrentPage] = useState(0);
  const { search } = useSearchInUrl();
  const [filteredUsers, setFilteredUsers] = useState<Array<IUser<IUserRoleRes>>>([]);
  const [deleteUserModalOpenState, setDeleteUserModalOpenState] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser<IUserRoleRes>>();

  useEffect(() => {
    const pathWithIds = generateRouteWithIds({
      route: Routes.ORGANISATION_DETAILS_USERS,
      ids: { orgId },
    });
    replace({ pathname: pathWithIds });
  }, [orgId, replace]);

  useEffect(() => {
    if (!users) return;
    if (search) {
      const searchedUsers = users.filter(
        (user) =>
          user.name?.toLowerCase().includes(search) || user.email?.toLowerCase().includes(search)
      );
      setFilteredUsers(searchedUsers);
    } else {
      setFilteredUsers(users);
    }
  }, [search, users]);

  const nameColumn = ({ row }: { row: IUser<IUserRoleRes> }) => {
    return (
      <div className="user-container">
        <Avatar
          className="avatar"
          src={row.status === UserStatus.INVITED ? '/broken-image.jpg' : row.picture}
        />
        <div className="text-container">
          {row.status === UserStatus.ACTIVE ? (
            <>
              <Typography fontSize={'1.4rem'} fontWeight={'500'}>
                {`${row.given_name} ${row.family_name}`}
              </Typography>
              <Typography variant="subtitle2" fontSize={'1rem'} color="#9E9E9E" fontWeight={500}>
                {row.email}
              </Typography>
            </>
          ) : (
            <Typography variant="subtitle2" fontSize={'1.4rem'}>
              {row.email}
            </Typography>
          )}
        </div>
      </div>
    );
  };

  const rowMenuItems = (user: IUser<IUserRoleRes>) => {
    const menuItems = [
      {
        label: 'Remove access',
        value: 'remove',
        action: () => {
          setDeleteUserModalOpenState(true);
          setSelectedUser(user);
        },
      },
    ];

    if (user.status === UserStatus.INVITED) {
      menuItems.push({
        label: 'Resend invitation',
        value: 'resend inivte',
        action: () => {
          reinviteUser(orgId, user.email);
        },
      });
    }
    return menuItems;
  };

  return (
    <>
      <DataTable
        getRowId={(row: IUser<IUserRoleRes>) => `${row.user_id}-${row.email}`}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: ({ row }: { row: IUser<IUserRoleRes> }) => nameColumn({ row }),
          },
          {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }: { row: IUser<IUserRoleRes> }) => {
              const status = row.status;

              return (
                <Flag
                  label={status === UserStatus.INVITED ? 'Invited' : 'Active'}
                  color={status === UserStatus.INVITED ? 'info' : 'success-dark'}
                />
              );
            },
          },
          {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            renderCell: ({ row }: { row: IUser<IUserRoleRes> }) => (
              <Typography variant="body2">
                {row?.roles?.map(({ name }) => name).join(', ')}
              </Typography>
            ),
          },
        ]}
        rows={filteredUsers ?? []}
        rowMenuItems={(row: IUser<IUserRoleRes>) => rowMenuItems(row)}
        pagination
        pageSize={10}
        onPageChange={setCurrentPage}
        page={currentPage}
        rowsPerPageOptions={[10, 20, 30]}
      />
      {selectedUser && (
        <DeleteUserModal
          modalOpenState={deleteUserModalOpenState}
          setModalOpenState={setDeleteUserModalOpenState}
          deleteReinvitedUser={deleteReinvitedUser}
          deleteUser={deleteUser}
          selectedUser={selectedUser}
        />
      )}
    </>
  );
};
