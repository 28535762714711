import React, { FC } from 'react';
import { CreateOrganisationFormDetails, CreateOrganisationModalNewProps } from './types';
import {
  Button,
  FormContainer,
  Modal,
  ModalActions,
  TextFieldFormElement,
  useFormContainer,
} from '@dqna/seamless-suite-ui-kit';
import { emailRegex } from 'constants/regex';
import { Typography } from '@mui/material';

export const CreateOrganisationModalNew: FC<CreateOrganisationModalNewProps> = ({
  modalOpenState,
  setModalOpenState,
  createOrganisation,
}) => {
  const formMethods = useFormContainer<CreateOrganisationFormDetails>({
    defaultValues: {},
  });

  const orgNameInputValue = formMethods.watch('orgName');
  const orgEmailInputValue = formMethods.watch('orgEmail');

  const validateEmail = (email: string) => (!email.match(emailRegex) ? 'Not a viable email' : true);

  const onSubmit = () => {
    createOrganisation(orgNameInputValue, orgEmailInputValue);
    setModalOpenState();
  };

  return (
    <Modal onClose={setModalOpenState} open={modalOpenState} className="create-org-modal">
      <Typography variant="h6" marginBottom={2}>
        Create organisation
      </Typography>
      <FormContainer<CreateOrganisationFormDetails> onSubmit={onSubmit} formMethods={formMethods}>
        <TextFieldFormElement
          name="orgName"
          title="Name"
          inputProps={{ 'aria-label': 'organisation-name' }}
          rules={{
            required: "Organisation name can't be empty!",
          }}
        />
        <TextFieldFormElement
          name="orgEmail"
          title="Email"
          inputProps={{ 'aria-label': 'organisation-email' }}
          rules={{
            required: "E-mail can't be empty!",
            validate: validateEmail,
          }}
        />
        <ModalActions>
          <Button variant="outlined" onClick={setModalOpenState}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Next
          </Button>
        </ModalActions>
      </FormContainer>
    </Modal>
  );
};

CreateOrganisationModalNew.displayName = 'CreateOrganisationModalNew';
