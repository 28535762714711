import React, { FC, useState, MouseEvent, useCallback } from 'react';

import { TabPane, Tabs, TabsDirection } from 'components/ui-kit/Tabs';

import { Filters } from 'components/ui-kit/Filters';

import {
  Dropdown,
  IMultiselectItemData,
  IItemData,
} from 'components/ui-kit/Dropdown';
import { Badge } from 'components/ui-kit/Badge';

import { ExcludedColumnType, IColumnFilterPropsWithTabs } from './types';

const defaultPresetOption = {
  label: 'Default table preset',
  value: '',
};

export const updateExcludedColumns = (
  columnSet: ExcludedColumnType,
  value: string
): ExcludedColumnType => {
  if (columnSet[value]) {
    const updatedState = { ...columnSet };
    delete updatedState[value];

    return updatedState;
  } else {
    return { ...columnSet, [value]: value };
  }
};

export const ColumnFilter: FC<IColumnFilterPropsWithTabs> = ({
  columnSet,
  onExcludeColumn,
  setPresetFilter,
  presetsOptions,
  columnFiltersPresets,
  onSubmitFilter,
  metrics,
  selectOptions,
  defaultValues,
  customActions,
}) => {
  const [columnFilterInfo, setColumnFilterInfo] = useState<IItemData>(
    defaultPresetOption
  );

  const inputContent = columnSet.filter(item => item.selected);

  const onSelectChange = useCallback(
    (arg: IMultiselectItemData) => {
      const presetName = columnFilterInfo.value.toString();
      const value = arg.value.toString();

      if (presetName) {
        const excludedColumns = columnFiltersPresets[presetName];

        onExcludeColumn(updateExcludedColumns(excludedColumns, value));
        setColumnFilterInfo(defaultPresetOption);
      } else {
        onExcludeColumn!(value);
      }

      setPresetFilter(null);
    },
    [columnFilterInfo]
  );

  const [activeTab, setActiveTab] = useState('');

  const handleTabChange = useCallback(
    tab => {
      setActiveTab(activeTab !== tab ? tab : '');
    },
    [activeTab]
  );

  return (
    <>
      <Tabs
        direction={TabsDirection.Horizontal}
        activeTabKey={activeTab}
        onChangeTab={handleTabChange}>
        <TabPane
          tabKey="filters"
          label={`Filters (${defaultValues?.length || 0})`}>
          <Filters
            metrics={metrics}
            onSubmitFilter={onSubmitFilter}
            selectOptions={selectOptions}
            defaultValues={defaultValues}
            customActions={customActions}
          />
        </TabPane>
        <TabPane
          tabKey="tablePresets"
          label={`Table Presets  (${columnFilterInfo.label})`}>
          <Dropdown.Text
            options={presetsOptions}
            name="filters"
            className="table_column-preset"
            value={columnFilterInfo.value}
            onChange={({ label, value }) => {
              setColumnFilterInfo({
                label: label || '',
                value: value.toString(),
              });
              setPresetFilter(columnFiltersPresets[value.toString()]);
            }}>
            {columnFilterInfo.label || 'column filter'}
          </Dropdown.Text>
        </TabPane>
        <TabPane tabKey="columnFilters" label="Select Columns">
          <Dropdown.Multiselect
            options={columnSet}
            name="multyselect"
            value={columnSet}
            className="table__column-filter"
            onChange={onSelectChange}>
            {inputContent.length
              ? inputContent.map(item => {
                  return (
                    <Badge
                      key={item.label}
                      handleRemove={(event: MouseEvent<HTMLDivElement>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onSelectChange(item);
                      }}
                      disabled={item.disabled}>
                      {item.label}
                    </Badge>
                  );
                })
              : 'colums'}
          </Dropdown.Multiselect>
        </TabPane>
      </Tabs>
    </>
  );
};
