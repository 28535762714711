import React from 'react';
import { UserInteractionDialog } from '@dqna/seamless-suite-ui-kit';
import { DeleteAccountModalProps } from './types';
import { Typography } from '@mui/material';
import { CloseActionType } from '@dqna/seamless-suite-ui-kit/dist/components/UserInteractionDialog';

export const DeleteAccountModal: React.FC<DeleteAccountModalProps> = (props) => {
  const { modalOpenState, setModalOpenState, selectedAccount, deleteAccount, organisationName } =
    props;

  const onClose = (closeAction: CloseActionType) => {
    if (closeAction === 'confirm') {
      deleteAccount(selectedAccount.id, organisationName);
    }
  };

  return (
    <UserInteractionDialog
      uiDialogState={modalOpenState}
      setUIDialogState={setModalOpenState}
      title="Delete Account"
      message={undefined}
      buttonCaptions={{
        cancel: 'Cancel',
        confirm: 'Yes, delete',
      }}
      onClose={onClose}>
      <Typography variant="body2" textAlign="center">
        You are about to permanently delete the <strong>{selectedAccount.name}</strong>. This action
        cannot be undone. Are you sure about deleting?
      </Typography>
    </UserInteractionDialog>
  );
};

DeleteAccountModal.displayName = 'DeleteAccountModal';
