import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { switchMap, catchError, filter } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Action } from '@reduxjs/toolkit';

import { HTTPService } from 'services/HTTP.service';
import { abTestRoutes } from 'constants/routes';
import { NotificationQueue, NotificationType } from 'services/NotificationQueue.service';
import { AbGroupStatuses, IAbGroup, IAbTestJob, IActivateAbGroup } from 'types/abtesting.types';
import {
  activateAbGroupError,
  deleteAbGroupError,
  getAbGroupError,
  previewAbTestError,
} from 'constants/errors/abTesting.errors';
import {
  activateAbTest,
  activateAbTestFail,
  activateAbTestSuccess,
  deleteAbGroup,
  deleteAbGroupFail,
  deleteAbGroupSuccess,
  getAbTest,
  getAbTestFail,
  getAbTestSuccess,
  previewAbTest,
  previewAbTestFail,
  previewAbTestSuccess,
} from 'store/reducers/abTesting.reducer';
import { IRootState } from 'store/reducers';

export const previewAbTestEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<IRootState>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(previewAbTest.match),
    switchMap(({ payload: { subAccountId, testName, keywordsPercent, splitKeywordsType } }) => {
      const requestBody = {
        ab_test_name: testName,
        keywords_percent: keywordsPercent,
        split_type: splitKeywordsType,
      };

      return from(
        http.post<IAbTestJob>(abTestRoutes.previewAbTest(subAccountId), requestBody)
      ).pipe(
        switchMap((data) => {
          notificationQueue.showNotification(NotificationType.Toast, 'Ab preview started!');
          return of(previewAbTestSuccess({ subAccountId, abTest: data }));
        }),
        catchError((_) => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Error when creating a preview for ab test group!'
          );
          return of(previewAbTestFail(previewAbTestError));
        })
      );
    })
  );

export const getAbTestEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(getAbTest.match),
    switchMap(({ payload }) => {
      const { accountId, subAccountId, abTestId } = payload;

      return from(http.get<IAbGroup>(abTestRoutes.manageAbGroup(abTestId, subAccountId))).pipe(
        switchMap((data) => {
          if (data.group.status === AbGroupStatuses.ERROR) {
            notificationQueue.showNotification(
              NotificationType.Toast,
              'Error when getting ab test. Please reset ab group!'
            );
          }

          return of(getAbTestSuccess({ accountId, subAccountId, abGroup: data }));
        }),
        catchError((_) => {
          notificationQueue.showNotification(NotificationType.Toast, 'Error when getting ab test!');
          return of(getAbTestFail(getAbGroupError));
        })
      );
    })
  );

export const activateAbTestEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(activateAbTest.match),
    switchMap(({ payload }) => {
      const { subAccountId, abTestId } = payload;
      return from(
        http.put<IActivateAbGroup>(abTestRoutes.manageAbGroup(abTestId, subAccountId))
      ).pipe(
        switchMap((data) => {
          notificationQueue.showNotification(NotificationType.Toast, 'Activating ab test!');
          return of(activateAbTestSuccess({ subAccountId, activatedAbGroup: data }));
        }),
        catchError((_) => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Error when activating ab test group!'
          );
          return of(activateAbTestFail(activateAbGroupError));
        })
      );
    })
  );

export const deleteAbGroupEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(deleteAbGroup.match),
    switchMap(({ payload }) => {
      const { accountId, abGroupId, subAccountId } = payload;

      return from(http.delete(abTestRoutes.manageAbGroup(abGroupId, subAccountId))).pipe(
        switchMap(() => {
          notificationQueue.showNotification(NotificationType.Toast, 'Deleting Ab Group!');
          return of(deleteAbGroupSuccess({ accountId, subAccountId, abGroupId }));
        }),
        catchError((_) => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Error when deleting ab test group!'
          );
          return of(deleteAbGroupFail(deleteAbGroupError));
        })
      );
    })
  );

export default combineEpics(
  previewAbTestEpic,
  getAbTestEpic,
  activateAbTestEpic,
  deleteAbGroupEpic
);
