import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Modal } from '@dqna/seamless-suite-ui-kit';
import { Typography } from '@mui/material';
import getBackDays from 'date-fns/subDays';
import formatDate from 'date-fns/format';
import { CreateKeywordGroupModalProps, CreateGroupFormData, CreationFlowSteps } from './types';
import './CreateKeywordGroupModal.scss';
import { CreationStatus, GroupNameForm, KeywordsModalTable } from '.';
import { KEYWORDS_DATA_AVAILABLE_DAYS_BACK, SuggestedKeywordStatus } from 'types/keywords.types';

export const CreateKeywordGroupModal: React.FC<CreateKeywordGroupModalProps> = (props) => {
  const {
    modalOpenState,
    setModalOpenState,
    keywords,
    subAccount,
    sendKeywordsToTest,
    keywordGroupCreationStatus,
    getSuggestedKeywords,
    keywordListLoadingState,
  } = props;
  const [rowSelectionModel, setRowSelectionModel] = useState<string[]>([]);
  const defaultGroupName = `Keywords-group-${formatDate(new Date(), 'dd-MM')}`;
  const [groupName, setGroupName] = useState<string>(defaultGroupName);
  const shouldAddBeforeUnloadListener = useRef(true);
  const [currentStep, setCurrentStep] = useState<CreationFlowSteps>(
    CreationFlowSteps.KEYWORDS_TABLE
  );

  const date = useMemo(() => {
    const today = new Date();
    const dateKeywordsAvailable = getBackDays(today, KEYWORDS_DATA_AVAILABLE_DAYS_BACK);
    return formatDate(dateKeywordsAvailable, 'yyyy-MM-dd');
  }, []);

  const onCloseModal = () => {
    setModalOpenState(false);
    setCurrentStep(CreationFlowSteps.KEYWORDS_TABLE);
    setRowSelectionModel([]);
    setGroupName(defaultGroupName);
    shouldAddBeforeUnloadListener.current = false;
  };

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (currentStep === CreationFlowSteps.CREATION_STATUS && shouldAddBeforeUnloadListener) {
      e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
    }
  };

  useEffect(() => {
    if (currentStep === CreationFlowSteps.CREATION_STATUS) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentStep]);

  useEffect(() => {
    if (modalOpenState) {
      getSuggestedKeywords({ subAccountId: subAccount.id });
    }
  }, [subAccount?.id, modalOpenState]);

  const onSwitchStep = () => {
    const switchTo =
      currentStep === CreationFlowSteps.KEYWORDS_TABLE
        ? CreationFlowSteps.GROUP_NAME
        : CreationFlowSteps.KEYWORDS_TABLE;
    setCurrentStep(switchTo);
  };

  const onSubmit = (formData: CreateGroupFormData) => {
    shouldAddBeforeUnloadListener.current = true;
    setCurrentStep(CreationFlowSteps.CREATION_STATUS);
    setGroupName(formData.groupName);

    const keywords = rowSelectionModel.map((item) => ({ name: item }));
    sendKeywordsToTest(date, subAccount.account.id, subAccount.id, {
      keywords,
      name: formData.groupName,
    });
    shouldAddBeforeUnloadListener.current = false;
  };

  const activeKeywords = keywords.filter((item) => item.status === SuggestedKeywordStatus.ACTIVE);

  return (
    <Modal
      onClose={onCloseModal}
      open={modalOpenState}
      fullScreen={currentStep === CreationFlowSteps.KEYWORDS_TABLE}
      className={
        currentStep === CreationFlowSteps.CREATION_STATUS
          ? 'create-group-modal__creation-status-step'
          : 'create-group-modal'
      }>
      <Typography variant="h6" marginBottom={2} fontSize="1.6rem">
        Create new keyword group
      </Typography>
      {currentStep === CreationFlowSteps.KEYWORDS_TABLE && (
        <KeywordsModalTable
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          keywords={activeKeywords}
          subAccount={subAccount}
          onCloseModal={onCloseModal}
          onSwitchStep={onSwitchStep}
          keywordListLoadingState={keywordListLoadingState}
        />
      )}
      {currentStep === CreationFlowSteps.GROUP_NAME && (
        <GroupNameForm
          groupName={groupName}
          setGroupName={setGroupName}
          onCloseModal={onCloseModal}
          onSwitchStep={onSwitchStep}
          onSubmit={onSubmit}
          subAccount={subAccount}
        />
      )}
      {currentStep === CreationFlowSteps.CREATION_STATUS && (
        <CreationStatus
          numberOfSelectedKeywords={rowSelectionModel.length}
          groupName={groupName}
          keywordGroupCreationStatus={keywordGroupCreationStatus}
          onCloseModal={onCloseModal}
        />
      )}
    </Modal>
  );
};
