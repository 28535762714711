import React, { FC } from "react"
import { IAppLayoutProps } from "./IAppLayoutProps"
import AppLayoutScss from "./AppLayout.scss"
import SideNav from "@src/components/SideNav"

const AppLayoutContainer: FC = ({ children }) => {
  return <div className={AppLayoutScss.container}>{children}</div>
}

const AppLayoutTopSection: FC = ({ children }) => {
  return <div className={AppLayoutScss.topSection}>{children}</div>
}

const AppLayoutTitleSection: FC = ({ children }) => {
  return <div>{children}</div>
}

const AppLayoutUserSection: FC = ({ children }) => {
  return <div className={AppLayoutScss.userSection}>{children}</div>
}

const AppLayout: FC<IAppLayoutProps> = ({ children }) => {
  const sideNav = children.find((child: JSX.Element) => child.type === SideNav)
  const containerClasses = [
    AppLayoutScss.appLayout,
    sideNav ? AppLayoutScss.sidenavVisible : ""
  ].join(" ")

  const userSection = children.find(
    (child: JSX.Element) => child.type === AppLayoutUserSection
  )
  const titleSection = children.find(
    (child: JSX.Element) => child.type === AppLayoutTitleSection
  )

  const remainingChildren = children.filter(
    (child: JSX.Element) =>
      ![AppLayoutUserSection, AppLayoutTitleSection].includes(child.type)
  )

  return (
    <div className={containerClasses}>
      <svg className={AppLayoutScss.logo} xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M695 1159C1078.84 1159 1390 847.838 1390 464C1390 80.1621 1078.84 -231 695 -231C694.982 -231 694.965 -231 694.947 -231C694.929 -231 694.911 -231 694.893 -231C311.114 -231 0 80.1145 0 463.893C0 463.911 6.58765e-07 463.929 1.99289e-06 463.947C6.64304e-07 463.965 0 463.982 0 464C0 847.838 311.162 1159 695 1159ZM1.99289e-06 463.947C0.0288124 847.701 311.132 1158.79 694.893 1158.79C1078.67 1158.79 1389.79 847.673 1389.79 463.893C1389.79 80.1319 1078.7 -230.971 694.947 -231C311.151 -230.971 0.0287534 80.1511 1.99289e-06 463.947ZM245.072 439.547C200.955 483.665 200.955 555.858 245.073 599.976C289.191 644.094 361.384 644.094 405.501 599.976L750.885 254.592C795.003 210.475 795.003 138.282 750.885 94.1635C706.767 50.0454 634.574 50.0454 590.456 94.1635L245.072 439.547ZM783.574 846.33C840.334 903.09 932.359 903.09 989.119 846.33C1045.88 789.571 1045.88 697.545 989.119 640.786C932.359 584.026 840.334 584.026 783.575 640.786C726.815 697.545 726.815 789.571 783.574 846.33ZM592.398 786.872L1109.06 270.213C1153.17 226.095 1153.17 153.902 1109.06 109.784C1064.94 65.6661 992.746 65.6661 948.628 109.784L431.968 626.443C387.85 670.561 387.85 742.754 431.968 786.872C476.087 830.99 548.28 830.99 592.398 786.872Z"
        />
      </svg>

      <AppLayoutTopSection>
        {titleSection}
        {userSection}
      </AppLayoutTopSection>
      <AppLayoutContainer>{remainingChildren}</AppLayoutContainer>
    </div>
  )
}

export default AppLayout
export {
  AppLayoutContainer,
  AppLayoutTopSection,
  AppLayoutTitleSection,
  AppLayoutUserSection
}
