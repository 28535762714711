export enum TextTypes {
  P = 'p',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
}

export type TextType =
  | TextTypes.P
  | TextTypes.H1
  | TextTypes.H2
  | TextTypes.H3
  | TextTypes.H4
  | TextTypes.H5;

export interface ITextProps {
  type?: TextType;
  italic?: boolean;
  bold?: boolean;
  className?: string;
  onClick?: () => void;
  testId?: string;
  id?: string;
}
