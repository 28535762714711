import { combineReducers } from 'redux';

import userReducer, { IUserReducerState } from './user.reducer';
import notificationsReducer, { INotificationsState } from './notifications.reducer';
import subAccountReducer, { ISubAccountsState } from './subAccounts.reducer';
import accountReducer, { IAccountsState } from './accounts.reducer';
import adwordsReducer, { IAdwordsState } from './adwords.reducer';
import adminReducer, { IAdminState } from './admin.reducer';
import abGroupReducer, { IAbGroupState } from './abTesting.reducer';
import layoutReducer from './layout.reducer';
import { LayoutState } from 'types/layout.types';
import KeywordsGroupCreationReducer, {
  IKeywordsGroupCreationState,
} from './keywords/keywordsGroupCreation.reducer';
import KeywordsManagementReducer, {
  IKeywordsManagementState,
} from './keywords/keywordsManagment.reducer';
import KeywordsDetailsPageReducer, {
  IKeywordsDetailsPageState,
} from './keywords/keywordsDetailsPage.reducer';

export interface IRootState {
  user: IUserReducerState;
  notifications: INotificationsState;
  subAccounts: ISubAccountsState;
  keywords: {
    groupCreation: IKeywordsGroupCreationState;
    management: IKeywordsManagementState;
    detailsPage: IKeywordsDetailsPageState;
  };
  abGroups: IAbGroupState;
  accounts: IAccountsState;
  adwords: IAdwordsState;
  admin: IAdminState;
  layout: LayoutState;
}

export default combineReducers<IRootState>({
  user: userReducer,
  notifications: notificationsReducer,
  subAccounts: subAccountReducer,
  keywords: combineReducers({
    groupCreation: KeywordsGroupCreationReducer,
    management: KeywordsManagementReducer,
    detailsPage: KeywordsDetailsPageReducer,
  }),
  abGroups: abGroupReducer,
  accounts: accountReducer,
  adwords: adwordsReducer,
  admin: adminReducer,
  layout: layoutReducer,
});
