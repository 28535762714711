export const previewAbTestError = {
  name: 'PREVIEW_ERROR',
  message: 'Error when previewing an ab test',
};

export const getAbGroupError = {
  name: 'GET_ERROR',
  message: 'Error when retreiving ab group',
};

export const activateAbGroupError = {
  name: 'ACTIVATE_ERROR',
  message: 'Error when activating ab group',
};

export const deleteAbGroupError = {
  name: 'DELETION_ERROR',
  message: 'Error when deleting ab group',
};
