import { TableBodyCell, TableCellAlign, TableHeaderCell } from 'components/ui-kit/Table';
import { IAbKeyword, IABSplitGroupsMetrics } from 'types/abtesting.types';

export const abKwColumns: Array<TableHeaderCell<IAbKeyword>> = [
  {
    columnId: 'name',
    renderer: 'Name',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'cpc',
    renderer: 'Cpc',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'ctr',
    renderer: 'Ctr',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'roas',
    renderer: 'Roas',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'cost',
    renderer: 'Cost',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'impr',
    renderer: 'Impr',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'revenue',
    renderer: 'Revenue',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'isSeamlessKeyword',
    renderer: 'Is Seamless Keyword',
    align: TableCellAlign.Left,
  },
];

export const abKwRows: Array<TableBodyCell<IAbKeyword>> = [
  {
    field: 'name',
    align: TableCellAlign.Left,
    renderer: ({ name }) => name,
  },
  {
    field: 'cpc',
    align: TableCellAlign.Left,
    renderer: ({ cpc }) => cpc,
  },
  {
    field: 'ctr',
    align: TableCellAlign.Left,
    renderer: ({ ctr }) => ctr,
  },
  {
    field: 'roas',
    align: TableCellAlign.Left,
    renderer: ({ roas }) => roas,
  },
  {
    field: 'cost',
    align: TableCellAlign.Left,
    renderer: ({ cost }) => cost,
  },
  {
    field: 'impr',
    align: TableCellAlign.Left,
    renderer: ({ impr }) => impr,
  },
  {
    field: 'revenue',
    align: TableCellAlign.Left,
    renderer: ({ revenue }) => revenue,
  },
  {
    field: 'isSeamlessKeyword',
    align: TableCellAlign.Left,
    renderer: ({ isSeamlessKeyword }) => isSeamlessKeyword,
  },
];

export const abMetricsColumns: Array<TableHeaderCell<IAbKeyword>> = [
  {
    columnId: 'metric',
    renderer: 'Metric',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'aRatio',
    renderer: 'ARatio',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'aValue',
    renderer: 'AValue',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'abRatioDiff',
    renderer: 'AbRatioDiff',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'bRatio',
    renderer: 'BRatio',
    align: TableCellAlign.Left,
  },
  {
    columnId: 'bValue',
    renderer: 'BValue',
    align: TableCellAlign.Left,
  },
];

export const abMetricsRows: Array<TableBodyCell<IABSplitGroupsMetrics>> = [
  {
    field: 'metric',
    align: TableCellAlign.Left,
    renderer: ({ metric }) => metric,
  },
  {
    field: 'aRatio',
    align: TableCellAlign.Left,
    renderer: ({ aRatio }) => aRatio,
  },
  {
    field: 'aValue',
    align: TableCellAlign.Left,
    renderer: ({ aValue }) => aValue,
  },
  {
    field: 'abRatioDiff',
    align: TableCellAlign.Left,
    renderer: ({ abRatioDiff }) => abRatioDiff,
  },
  {
    field: 'bRatio',
    align: TableCellAlign.Left,
    renderer: ({ bRatio }) => bRatio,
  },
  {
    field: 'bValue',
    align: TableCellAlign.Left,
    renderer: ({ bValue }) => bValue,
  },
];
