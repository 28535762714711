import React from 'react';
import { UserInteractionDialog } from '@dqna/seamless-suite-ui-kit';
import { DeleteOrganisationModalProps } from './types';
import { Typography } from '@mui/material';
import { CloseActionType } from '@dqna/seamless-suite-ui-kit/dist/components/UserInteractionDialog';

export const DeleteOrganisationModal: React.FC<DeleteOrganisationModalProps> = (props) => {
  const { modalOpenState, setModalOpenState, selectedOrganisation, deleteOrganisation } = props;

  const onClose = (closeAction: CloseActionType) => {
    if (closeAction === 'confirm') {
      deleteOrganisation(selectedOrganisation.id);
    }
  };

  return (
    <UserInteractionDialog
      uiDialogState={modalOpenState}
      setUIDialogState={setModalOpenState}
      title="Delete Organisation"
      message={undefined}
      buttonCaptions={{
        cancel: 'Cancel',
        confirm: 'Yes, delete',
      }}
      onClose={onClose}>
      <Typography variant="body2" textAlign="center">
        You are about to permanently delete all data of the{' '}
        <strong>{selectedOrganisation.name}</strong>. This action cannot be undone. Are you sure
        about deleting?
      </Typography>
    </UserInteractionDialog>
  );
};
