import React, { FC, useEffect, useRef, useState } from "react"
import { IconButton, InputAdornment, InputBase } from "@mui/material"
import { SearchOutlined, Close } from "@mui/icons-material"

import styles from "./SearchInput.scss"
import isArray from "lodash/isArray"
import Chip from "../Chip"
import { ISearchInputProps } from "./SearchInputProps"

const SearchInput: FC<ISearchInputProps> = (props) => {
  const {
    classNameInput,
    classNameInputContainer,
    classNameButtons,
    buttons,
    placeholder,
    onEnter,
    onKeyUp,
    onMagnifierClick,
    defaultValue,
    adornmentPosition = "start",
    showMagnifierIcon = true,
    shouldClearSearchField,
    ...restOfProps
  } = props
  const baseInputRef = useRef(null)
  const localButtons = isArray(buttons) ? buttons : [buttons]
  const [localValue, setLocalValue] = useState(defaultValue || "")
  const [isFocused, setFocused] = useState(false)

  const onInputButtonClick = () => {
    if (baseInputRef.current?.value === "") {
      baseInputRef.current?.focus()
    }
    if (onMagnifierClick) {
      onMagnifierClick(baseInputRef.current.value)
    }
  }

  useEffect(() => {
    if (!shouldClearSearchField) return
    onClearClick()
  }, [shouldClearSearchField])

  const onClearClick = () => {
    setLocalValue("")
    if (baseInputRef.current) {
      baseInputRef.current.value = ""
    }
  }

  const onInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isSubmit = event.key === "Enter"
    if (isSubmit) {
      baseInputRef.current.blur()
      setLocalValue(baseInputRef.current.value)
      setFocused(false)
    }
    if (isSubmit && onEnter) {
      onEnter(baseInputRef.current.value)
    }
    if (!isSubmit && onKeyUp) {
      onKeyUp(baseInputRef.current.value)
    }
  }

  const inputAdornment = (
    <InputAdornment position={adornmentPosition}>
      {adornmentPosition === "end" && (
        <IconButton
          aria-label="clear-button"
          className={styles.searchInputIcon}
          onClick={onClearClick}
        >
          <Close />
        </IconButton>
      )}
      {showMagnifierIcon && (
        <IconButton
          aria-label="search-button"
          className={styles.searchInputIcon}
          onClick={onInputButtonClick}
        >
          <SearchOutlined />
        </IconButton>
      )}
    </InputAdornment>
  )

  return (
    <div
      className={`
      ${styles.searchInputContainer}
      ${classNameInputContainer}
    `}
    >
      <InputBase
        id="search-input"
        aria-label="search-input"
        placeholder={placeholder}
        onKeyPress={onInputKeyPress}
        className={`
        ${styles.searchInputBase}
          ${classNameInput} 
          ${!isFocused && localValue ? styles.searchInputHiddenText : ""}
        `}
        inputRef={baseInputRef}
        startAdornment={adornmentPosition === "start" ? inputAdornment : null}
        endAdornment={adornmentPosition === "end" ? inputAdornment : null}
        defaultValue={defaultValue}
        onFocus={() => setFocused(true)}
        {...restOfProps}
      />
      {localValue && !isFocused && (
        <div
          className={`${styles.searchInputChipContainer} ${
            adornmentPosition === "start"
              ? styles.searchInputChipContainerIconLeft
              : ""
          }`}
          onClick={() => baseInputRef.current.focus()}
        >
          <Chip label={localValue} />
        </div>
      )}
      <div
        className={`
        ${styles.searchInputButtons}
        ${classNameButtons}
      `}
      >
        {localButtons?.map((button, index) => (
          <div key={index.toString()}>{button}</div>
        ))}
      </div>
    </div>
  )
}

export default SearchInput
