import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from 'components/pages/App';
import { IRootState } from 'store/reducers';

export const useAuthProvider = () => {
  const context = useContext(AuthContext);
  const { isAuthenticated, isAuthServiceReady, currentUser } = useSelector(
    (state: IRootState) => state.user
  );

  const { login, logout } = context!;

  return {
    login,
    logout,
    isAuthenticated,
    isAuthServiceReady,
    role: currentUser?.roles,
  };
};
