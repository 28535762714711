import React, { FC } from 'react';
import { DeleteAccountModalProps } from './types';
import { Modal } from 'components/ui-kit/Modal';

export const DeleteAccountModal: FC<DeleteAccountModalProps> = (props) => {
  const {
    deleteAccountModalOpenState,
    setDeleteAccountModalOpenState,
    selectedAccount,
    deleteAccount,
  } = props;

  return (
    <Modal
      title="Confirmation"
      opened={deleteAccountModalOpenState}
      onClose={() => {
        setDeleteAccountModalOpenState(false);
      }}
      acceptText="Delete"
      onAccept={() => {
        deleteAccount(selectedAccount.id);
      }}
      declineText="Cancel">
      <div>
        Do you really want to delete &quot;{selectedAccount.name}&quot; account?
        <div>This action is not reversible. </div>
      </div>
    </Modal>
  );
};
