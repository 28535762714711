import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  fetchAccounts,
  createAccount,
  deleteAccount,
  verifyAdveritySearchConsole,
  verifyAdverityAdWords,
  checkDataSourceIsLinked,
  checkDataSourceIsVerifiedReset,
  editAccountName,
  newAccountStateReset,
  fetchSubAccountUrls,
} from 'store/reducers/accounts.reducer';
import { IRootState } from 'store/reducers';
import { CreateSubAccountParams, KeywordBodyType } from 'types/subAccounts.types';
import { DataSourcesTypes } from 'types/accounts.type';
import { AppPageTitle } from 'types/layout.types';
import {
  deleteSubAccount,
  changeSubAccountStatus,
  deleteGroup,
  emptyCreationStatus,
  createSubAccount,
  fetchSubAccounts,
  subAccountCreationTimedOut,
} from 'store/reducers/subAccounts.reducer';
import { setPageTitle } from 'store/reducers/layout.reducer';
import { sendGroupKeywordsToTest } from 'store/reducers/keywords/keywordsManagment.reducer';
import { getSuggestedKeywords } from 'store/reducers/keywords/keywordsDetailsPage.reducer';
import { SubAccountsPage } from 'components/pages/SubAccounts/SubAccountsPage';

const mapStateToProps = (state: IRootState) => {
  const {
    accounts: {
      accounts,
      newAccount,
      creationError,
      isLoading: accountsLoading,
      isDataSourceVerified,
      linkDataSourcesStatuses,
      urlsByAccount,
    },
    subAccounts: { subAccounts },
    adwords: { adwords, isLoading: isLoadingAdwords },
    user: { accessToken, authToken, currentUser },
  } = state;
  const suggestedKeywordsState = state.keywords.detailsPage.suggestedKeywords;
  const subAccountsCreationResults = state.subAccounts.subAccountsCreationResults;
  return {
    accounts,
    subAccounts,
    adwords,
    isLoadingAdwords,
    newAccount,
    creationError,
    authToken,
    accessToken,
    currentUser,
    suggestedKeywordsState,
    accountsLoading,
    keywordGroupCreationStatus: state.keywords.detailsPage.keywordGroupCreationStatus,
    subAccountsCreationResults,
    isDataSourceVerified,
    linkDataSourcesStatuses,
    urlsByAccount,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSubAccounts: (accountId: number) => dispatch(fetchSubAccounts(accountId)),
  getAccounts: () => dispatch(fetchAccounts()),
  checkDataSourceIsLinked: (dataSourceType: DataSourcesTypes, id: number, currentTry: number) =>
    dispatch(checkDataSourceIsLinked({ dataSourceType, id, currentTry })),
  checkDataSourceIsVerifiedReset: () => dispatch(checkDataSourceIsVerifiedReset()),
  newAccountStateReset: () => dispatch(newAccountStateReset()),
  changeSubAccountStatus: (accountId: number, subAccountId: number, isActive: boolean) =>
    dispatch(changeSubAccountStatus({ accountId, subAccountId, isActive })),
  deleteSubAccount: (accountId: number, subAccountId: number) =>
    dispatch(deleteSubAccount({ accountId, subAccountId })),

  createAccount: (name: string) => dispatch(createAccount({ name })),
  editAccountName: (id: number, name: string) => dispatch(editAccountName({ id, name })),
  deleteAccount: (id: number) => dispatch(deleteAccount({ id })),
  deleteGroup: (date: string, accountId: number, subAccountId: number, groupId: number) =>
    dispatch(deleteGroup({ date, accountId, subAccountId, groupId })),
  verifyAdveritySearchConsole: (accountId: number) =>
    dispatch(verifyAdveritySearchConsole({ accountId })),
  verifyAdverityAdWords: (accountId: number) => dispatch(verifyAdverityAdWords({ accountId })),
  sendKeywordsToTest: (
    date: string,
    accountId: number,
    subAccountId: number,
    body: KeywordBodyType,
    redirect: boolean = false
  ) => dispatch(sendGroupKeywordsToTest({ date, accountId, subAccountId, body, redirect })),
  getSuggestedKeywords: ({ subAccountId }: { subAccountId: number }) =>
    dispatch(getSuggestedKeywords(subAccountId)),
  createSubAccount: (params: CreateSubAccountParams) => dispatch(createSubAccount(params)),
  emptyCreationStatus: () => dispatch(emptyCreationStatus()),
  setPageTitle: (pageTitle: AppPageTitle) => dispatch(setPageTitle(pageTitle)),
  fetchSubAccountUrls: () => dispatch(fetchSubAccountUrls()),
  subAccountCreationTimedOut: () => dispatch(subAccountCreationTimedOut()),
});

export type SubAccountsContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const SubAccountsContainer = connect(mapStateToProps, mapDispatchToProps)(SubAccountsPage);
