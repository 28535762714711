import React from 'react';
import { Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  FormContainer,
  TextFieldFormElement,
  Button,
  useFormContainer,
} from '@dqna/seamless-suite-ui-kit';

import { GroupNameFormProps, CreateGroupFormData } from './types';

export const GroupNameForm: React.FC<GroupNameFormProps> = (props) => {
  const { onCloseModal, onSwitchStep, onSubmit, subAccount, groupName, setGroupName } = props;
  const formMethods = useFormContainer<CreateGroupFormData>({
    defaultValues: { groupName },
  });
  const groupNameInputValue = formMethods.watch('groupName');

  const isUnique = (name: string) =>
    new Set(subAccount.keywordGroups?.map((keywordGroup) => keywordGroup.name))?.has(name)
      ? 'Keyword group already exists'
      : true;

  const onBack = () => {
    setGroupName(groupNameInputValue);
    onSwitchStep();
  };

  return (
    <FormContainer<CreateGroupFormData> onSubmit={onSubmit} formMethods={formMethods}>
      <TextFieldFormElement
        name="groupName"
        title="Name"
        rules={{
          required: 'Keyword group name is required',
          validate: isUnique,
        }}
      />
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={onBack} startIcon={<ArrowBackIcon />}>
          Back
        </Button>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={onCloseModal} size="small">
            Cancel
          </Button>
          <Button variant="contained" type="submit" size="small">
            Create
          </Button>
        </Stack>
      </Stack>
    </FormContainer>
  );
};
