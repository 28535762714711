import React, { FC } from 'react';
import { NoDataPlaceholderPanel } from '@dqna/seamless-suite-ui-kit';
import Saly from '../../../assets/saly-is-typing.png';
import { NoDataPlaceholderProps } from './types';
import './NoDataPlaceholder.scss';

export const NoDataPlaceholder: FC<NoDataPlaceholderProps> = ({
  className = '',
  buttonCaption,
  message,
  onButtonClick,
  title,
}) => {
  return (
    <NoDataPlaceholderPanel
      className={`${className} no-data-panel`}
      buttonCaption={buttonCaption}
      message={message}
      imageUrl={Saly}
      onButtonClick={onButtonClick}
      title={title}
    />
  );
};

NoDataPlaceholder.displayName = 'NoDataPlaceholder';
