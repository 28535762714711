import React from 'react';

import { TableBase } from './TableBase.component';
import { TableListProps, TableType, TableElements } from './types';

export const TableList = <T extends { id: string }>({
  dataSource,
  children,
  className,
  isLoading,
  bodyHeight,
}: TableListProps<T>) => (
  <TableBase
    type={TableType.List}
    dataSource={dataSource}
    className={className}
    selectable={false}
    isLoading={isLoading}
    scrollable={false}
    bodyHeight={bodyHeight}>
    {children}
  </TableBase>
);

TableList.displayName = TableElements.TableList;
