import { Typography } from "@mui/material"
import React, { FC } from "react"
import { ITextWithTitleProps } from "./ITextWithTitleProps"
import TextWithTitleScss from "./TextWithTitle.scss"

const TextWithTitle: FC<ITextWithTitleProps> = ({ title, text }) => {
  return (
    <div className={TextWithTitleScss.container}>
      <Typography className={TextWithTitleScss.title} variant="subtitle2">
        {title}
      </Typography>
      <Typography className={TextWithTitleScss.text} variant="body2">
        {text}
      </Typography>
    </div>
  )
}

export default TextWithTitle
