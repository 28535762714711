import React, { FC } from "react"
import styles from "./ChatBoxToggle.scss"
import { ChatBoxToggleProps } from "./ChatBoxToggleProps"
import { ChatBubbleOutline } from "@mui/icons-material"

const ChatBoxToggle: FC<ChatBoxToggleProps> = ({
  onClick,
  hasRedDot,
  isBoldStyle
}) => {
  const handleClick = () => {
    onClick()
  }

  return (
    <div
      className={styles.chatboxContainer}
      onClick={handleClick}
      aria-label={`chat${hasRedDot ? "-mention" : ""}${
        isBoldStyle ? "-unread" : ""
      }`}
    >
      <ChatBubbleOutline
        fontSize="large"
        color={isBoldStyle ? "inherit" : "action"}
      />
      {hasRedDot && <div className={styles.redDot} />}
    </div>
  )
}

export default ChatBoxToggle
