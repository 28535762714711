export const deleteAccountError = {
  name: 'DELETE_ACCOUNT_ERROR',
  message: "There's error while deleting the account. Please try again later",
};

export const verifyAdverityError = {
  name: 'VERIFY_ADVERITY_ERROR',
  message: "There's error while verifying adverity. Please try again later",
};

export const linkDataSourceError = {
  name: 'LINK_DATA_SOURCE_ERROR',
  message: "There's error while linking data source. Please try again later",
};

export const editAccountNameError = {
  name: 'EDIT_ACCOUNT_NAME_ERROR',
  message: "There's error while editing account name. Please try again later",
};
