import React from 'react';
import { CustomAlert as UIKitAlert } from '@dqna/seamless-suite-ui-kit';
import { AlertClasses, AlertColor } from '@mui/material';
import './CustomAlert.scss';

interface CustomAlertProps {
  severity: AlertColor;
  icon?: React.ReactNode;
  classes?: Partial<AlertClasses>;
  children?: React.ReactNode;
}

export const CustomAlert: React.FC<CustomAlertProps> = (props) => {
  const getClasses = (severity: AlertColor): Partial<AlertClasses> => ({
    message: `custom-alert__message custom-alert__message-${severity}`,
    icon: 'custom-alert__icon',
    root: `custom-alert__root custom-alert__root-${severity}`,
    ...props.classes,
  });

  return (
    <UIKitAlert {...props} classes={getClasses(props.severity)}>
      {props.children}
    </UIKitAlert>
  );
};
