import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { History } from 'history';
import { switchMap, map, catchError, withLatestFrom, tap, filter, delay } from 'rxjs/operators';
import { from, of, forkJoin, Observable } from 'rxjs';

import { IDBConnection } from 'services/IDB.service';
import { HTTPService } from 'services/HTTP.service';
import { messageWorker, getWorker, Actions } from 'services/ServiceWorker.service';
import { NotificationQueue, NotificationType } from 'services/NotificationQueue.service';

import { Action } from '@reduxjs/toolkit';
import { IKeyword, KeywordGroupStatuses, IKeywordNewType } from 'types/keywords.types';
import { ISubAccount } from 'types/subAccounts.types';

import { createUrl } from 'utils/createUrl';
import { serializeData } from 'utils/base64serialization';
import { getOrg } from 'utils/manageOrganisations';

import { subAccountsRoutes, keywordsGroupRoutes } from 'constants/routes';
import { pauseGroupTestingPhaseError } from 'constants/errors/keywordsGroup.error';
import {
  sendKeywordsToTestError,
  deleteKeywordsError,
} from 'constants/errors/linkedAccount.errors';
import { keywordFetchError, keywordRemoveError } from 'constants/errors/keywords.error';
import { FilterState, StringValues } from 'components/ui-kit/Filters';
import { API_URL } from '../../config';
import { AuthService } from 'services/Auth.service';
import { IRootState } from 'store/reducers';
import { Routes } from 'types/app.types';
import {
  deleteGroupKeywords,
  deleteGroupKeywordsSuccess,
  getKeywords,
  getKeywordsSuccess,
  keywordActionFail,
  keywordsActionFinally,
  pauseGroupKeywordsTestingPhase,
  sendGroupKeywordsToTest,
  sendGroupKeywordsToTestSuccess,
} from 'store/reducers/keywords/keywordsManagment.reducer';
import {
  getKeywordsOfKeywordGroup,
  getKeywordsOfKeywordGroupFail,
  getKeywordsOfKeywordGroupSuccess,
  getKeywordsOfSubaccount,
  getKeywordsOfSubaccountFail,
  getKeywordsOfSubaccountInKeywordGroups,
  getKeywordsOfSubaccountInKeywordGroupsFail,
  getKeywordsOfSubaccountInKeywordGroupsSuccess,
  getKeywordsOfSubaccountSuccess,
  getSuggestedKeywords,
  getSuggestedKeywordsFail,
  getSuggestedKeywordsSuccess,
  removeKeywords,
  removeKeywordsFail,
  removeKeywordsSuccess,
  setKeywordGroupCreationStatus,
  setKeywordGroupCreationStatusFail,
  setKeywordGroupCreationStatusSuccess,
} from 'store/reducers/keywords/keywordsDetailsPage.reducer';
import {
  updateKeywordsGroupCreation,
  updateKeywordsGroupCreationFail,
  updateKeywordsGroupCreationSuccess,
} from 'store/reducers/keywords/keywordsGroupCreation.reducer';
import {
  addGroup,
  changeSubAccountGroup,
  deleteGroupSuccess,
} from 'store/reducers/subAccounts.reducer';

export const getGroupKeywordsEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  {
    idb,
    auth,
    notificationQueue,
  }: {
    idb: IDBConnection;
    auth: AuthService;
    notificationQueue: NotificationQueue;
  }
) =>
  actions$.pipe(
    filter(getKeywords.match),
    switchMap(({ payload }) =>
      forkJoin([of(payload), from(auth.getIdTokenClaims()), from(auth.getTokenSilently())])
    ),
    switchMap(
      ([payload, { __raw: authToken }, accessToken]: [
        {
          accountId: number;
          subAccountId: number;
          date: string;
          groupId?: number;
          pagination?: { page: number; limit: number };
          filters?: FilterState[];
          sorting?: {
            sortBy?: keyof IKeyword;
            sortOrder?: boolean;
          };
        },
        { __raw: string },
        string,
      ]) => {
        const { accountId, subAccountId, groupId, date, pagination, filters, sorting } = payload;

        const dbConfig = {
          dbName: 'seamless',
          store: 'keywords',
        };

        const creds = {
          endpoint: createUrl(
            API_URL,
            groupId != null
              ? subAccountsRoutes.groupKeywords(subAccountId, groupId)
              : subAccountsRoutes.subAccountKeywords(subAccountId),
            {
              date,
            }
          ),
          authToken,
          accessToken,
        };

        const filterHandlers = filters
          ? filters.map(({ metric, matcher, value }) =>
              idb.idbFilters[matcher](metric as keyof IKeyword, value)
            )
          : [];

        const { page = 1, limit = 30 } = pagination ?? {};

        return forkJoin([
          from(idb.checkDataExistenceForDate(date)),
          from(idb.keywordsLength()),
          from(idb.checkDataExistence(date, accountId, subAccountId, groupId)),
        ]).pipe(
          switchMap(([isExistingByDate, kwLength, isExisting]) => {
            if (!isExistingByDate && kwLength) {
              return from(idb.removeDBData()).pipe(() =>
                of(
                  getKeywords({
                    accountId,
                    subAccountId,
                    date,
                    groupId,
                    pagination,
                    filters,
                    sorting,
                  })
                )
              );
            }
            if (isExisting) {
              return from(
                idb.filterItems(
                  date,
                  accountId,
                  subAccountId,
                  groupId ?? 0,
                  (page - 1) * limit,
                  limit,
                  sorting?.sortBy,
                  sorting?.sortOrder
                )(...filterHandlers)
              ).pipe(
                map(({ data, totalItems, keywordIds }) =>
                  getKeywordsSuccess({
                    keywords: data,
                    totalItems,
                    subAccountId,
                    groupId,
                    keywordIds,
                  })
                ),
                catchError((_) => of(keywordActionFail(keywordFetchError)))
              );
            }

            void messageWorker({
              action: Actions.Fetch,
              data: {
                creds,
                dbConfig,
                payload: {
                  orgName: getOrg(),
                  accountId,
                  subAccountId,
                  groupId,
                  date,
                },
              },
            });

            return from(
              new Promise<void>((resolve, reject) => {
                const sw = getWorker();
                sw?.addEventListener('message', (event: { data: any }) => {
                  const { data: responseData } = event;
                  if (responseData.action === Actions.FetchResponseSuccess) {
                    resolve();
                  }
                  if (responseData.action === Actions.FetchResponseFail) {
                    reject(responseData.data);
                  }
                });
              })
            ).pipe(
              switchMap(() =>
                from(
                  idb.filterItems(
                    date,
                    accountId,
                    subAccountId,
                    groupId ?? 0,
                    0,
                    limit,
                    sorting?.sortBy,
                    sorting?.sortOrder
                  )(...filterHandlers)
                ).pipe(
                  map(({ data, totalItems, keywordIds }) =>
                    getKeywordsSuccess({
                      keywords: data,
                      totalItems,
                      subAccountId,
                      groupId,
                      keywordIds,
                    })
                  ),
                  catchError(() => {
                    notificationQueue.showNotification(
                      NotificationType.Toast,
                      keywordFetchError.message
                    );
                    return of(keywordActionFail(keywordFetchError));
                  })
                )
              ),
              catchError(() => {
                notificationQueue.showNotification(
                  NotificationType.Toast,
                  keywordFetchError.message
                );
                return of(keywordActionFail(keywordFetchError));
              })
            );
          }),
          catchError(() => {
            notificationQueue.showNotification(NotificationType.Toast, keywordFetchError.message);
            return of(keywordActionFail(keywordFetchError));
          })
        );
      }
    )
  );

export const sendGroupKeywordsToTestSuccessEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { idb, history }: { idb: IDBConnection; history: History }
) =>
  actions$.pipe(
    filter(sendGroupKeywordsToTestSuccess.match),
    switchMap(({ payload }) => {
      const { subAccountId, groupId, updatedKeywordIds, date, accountId } = payload;
      const kwNames = updatedKeywordIds.map(({ name }) => name);

      return from(
        idb.updateEntities(
          [date, accountId, subAccountId, 0],
          ({ keyword }) => kwNames.includes(keyword),
          (keyword) => {
            const currentKW = updatedKeywordIds.find(({ name }) => name === keyword.keyword);
            keyword.groupId = groupId;
            if (currentKW) {
              keyword.id = currentKW.id;
            }
          }
        )
      ).pipe(
        switchMap((updatedKwsAmount) => {
          const allSucceeded = updatedKwsAmount === kwNames.length * 3;
          if (!allSucceeded) {
            return of(keywordActionFail(sendKeywordsToTestError));
          }

          return of(keywordsActionFinally()).pipe(
            tap(() => {
              const query = serializeData({
                accountId,
                subAccountId,
                groupId,
                filters: [],
              });
              history.push({
                pathname: Routes.KEYWORD_MANAGEMENT,
                search: `?${query}`,
              });
            })
          );
        }),
        catchError((err) => of(keywordActionFail(err)))
      );
    })
  );

export const setKeywordGroupCreationStatusEpic = (actions$: ActionsObservable<Action>) =>
  actions$.pipe(
    filter(sendGroupKeywordsToTest.match),
    switchMap(() => {
      return of(setKeywordGroupCreationStatus());
    })
  );

const retryWithCondition =
  <T>(
    maxRetryCount: number,
    delayTime: number,
    conditionFn: (data: any) => boolean,
    getRequest: () => Observable<any>,
    onFail: (error: Error) => void
  ) =>
  (source: Observable<T>) =>
    new Observable<T>((observer) => {
      let attempts = 0;

      const attemptRequest = () => {
        getRequest().subscribe({
          next(data) {
            if (conditionFn(data)) {
              observer.next(data);
              observer.complete();
            } else {
              retry(new Error('Retry condition not met'));
            }
          },
          error: retry,
        });
      };

      const retry = (error: Error) => {
        if (attempts >= maxRetryCount) {
          onFail(error);
          observer.error(error);
        } else {
          attempts++;
          setTimeout(attemptRequest, delayTime);
        }
      };

      attemptRequest();
    });

const postKeywordGroup = async (
  http: HTTPService,
  body: any,
  subAccountId: number,
  accountId: number
) =>
  await http
    .post(subAccountsRoutes.keywordGroup(subAccountId), body, { account_id: accountId })
    .catch((err) => of(keywordActionFail(err)));

const getSubAccounts = (http: HTTPService, accountId: number) =>
  from(http.get<ISubAccount[]>(subAccountsRoutes.getSubAccountsList(accountId)));

const findKeywordGroup = (data: ISubAccount[], subAccountId: number, groupName: string) => {
  const subAccount = data.find((account) => account.id === subAccountId);
  return subAccount?.keywordGroups?.find((group) => group.name === groupName);
};

export const sendGroupKeywordsWithRetryEpic = (
  action$: ActionsObservable<Action>,
  _: StateObservable<null>,
  { http }: { http: HTTPService }
) =>
  action$.pipe(
    filter(sendGroupKeywordsToTest.match),
    switchMap(({ payload: { body, subAccountId, accountId } }) => {
      const newKeywordGroupName = body.name;
      void postKeywordGroup(http, body, subAccountId, accountId);

      return of(null).pipe(
        delay(5000),
        switchMap(() =>
          getSubAccounts(http, accountId).pipe(
            retryWithCondition(
              120,
              1000,
              (data) => !!findKeywordGroup(data, subAccountId, newKeywordGroupName),
              () => getSubAccounts(http, accountId),
              setKeywordGroupCreationStatusFail
            ),
            switchMap((data) => {
              const keywordGroup = findKeywordGroup(data, subAccountId, newKeywordGroupName);
              if (!keywordGroup) throw new Error('Keyword group not found');
              return of(
                addGroup({ accountId, subAccountId, group: keywordGroup }),
                setKeywordGroupCreationStatusSuccess(keywordGroup.numberOfKeywords)
              );
            }),
            catchError((error) => of(getKeywordsOfKeywordGroupFail(error)))
          )
        )
      );
    })
  );

export const deleteGroupKeywordsSuccessEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { idb }: { idb: IDBConnection }
) =>
  actions$.pipe(
    filter(deleteGroupKeywordsSuccess.match),
    switchMap(({ payload }) => {
      const { subAccountId, groupId, deletedKeywordIds, date, accountId } = payload;

      return from(
        idb.updateEntities(
          [date, accountId, subAccountId, groupId],
          ({ id }) => deletedKeywordIds.includes(id as number),
          (keyword) => {
            keyword.groupId = 0;
            keyword.id = `${keyword.keyword}-${keyword.device}`;
          }
        )
      ).pipe(
        switchMap((updatedAmount) => {
          const allSucceeded = updatedAmount === deletedKeywordIds.length * 3;
          if (!allSucceeded) {
            return of(keywordActionFail(deleteKeywordsError));
          }
          return of(updateKeywordsGroupCreationSuccess(null));
        }),
        catchError(() => of(keywordActionFail(deleteKeywordsError)))
      );
    })
  );

export const deleteGroupKeywordsEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(deleteGroupKeywords.match),
    switchMap(({ payload }) => {
      const { deletedKeywordIds, subAccountId, groupId, date, accountId } = payload;
      const deletedKeywords = { keyword_id: deletedKeywordIds };
      return from(
        http.delete(subAccountsRoutes.deleteGroup(subAccountId, groupId), deletedKeywords)
      ).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            `Group keywords are successfully deleted!"`
          );
          return of(
            deleteGroupKeywordsSuccess({
              date,
              accountId,
              subAccountId,
              groupId,
              deletedKeywordIds,
            })
          );
        }),
        catchError(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            `Fail while deleting keywords!`
          );
          return of(keywordActionFail(deleteKeywordsError));
        })
      );
    })
  );

export const pauseGroupKeywordsTestingPhaseEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<IRootState>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(pauseGroupKeywordsTestingPhase.match),
    withLatestFrom(state$),
    switchMap(
      ([
        { payload },
        {
          subAccounts: { subAccounts },
        },
      ]) => {
        const { subAccountId, groupId, accountId } = payload;

        const currentGroup = subAccounts[accountId]
          .find((subAcc) => subAcc.id === subAccountId)
          ?.keywordGroups?.find((group) => group.id === groupId);

        if (!currentGroup) {
          return of(keywordActionFail(pauseGroupTestingPhaseError));
        }

        const updatedStatus =
          currentGroup?.status === KeywordGroupStatuses.TESTING_PHASE_PAUSED
            ? KeywordGroupStatuses.TESTING_PHASE
            : KeywordGroupStatuses.TESTING_PHASE_PAUSED;

        return from(
          http.patch(
            keywordsGroupRoutes.updateGroupData(subAccountId),
            {
              status: updatedStatus,
              id: groupId,
            },
            { account_id: accountId }
          )
        ).pipe(
          switchMap(() => {
            notificationQueue.showNotification(
              NotificationType.Toast,
              `${
                currentGroup?.status === KeywordGroupStatuses.TESTING_PHASE_PAUSED
                  ? 'Learning phase continues'
                  : 'Learning phase paused'
              }`
            );
            return of(
              changeSubAccountGroup({
                subAccountId,
                groupId,
                accountId,
                group: { ...currentGroup, status: updatedStatus },
              })
            );
          }),
          catchError(() => of(keywordActionFail(pauseGroupTestingPhaseError)))
        );
      }
    )
  );

const updateKeywordsGroupCreationEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { idb }: { idb: IDBConnection }
) =>
  actions$.pipe(
    filter(updateKeywordsGroupCreation.match),
    switchMap(({ payload }) => {
      const { accountId, searchString, metric, subAccountId, groupId = 0, date } = payload;

      const filterHandler = idb.idbFilters[StringValues.EQ](metric, searchString);

      return from(idb.filterItems(date, accountId, subAccountId, groupId)(filterHandler)).pipe(
        map(({ data }) => updateKeywordsGroupCreationSuccess(data)),
        catchError((err) => of(updateKeywordsGroupCreationFail(err)))
      );
    })
  );

export const getKeywordsOfSubaccountEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http }: { http: HTTPService }
) => {
  return actions$.pipe(
    filter(getKeywordsOfSubaccount.match),
    switchMap(({ payload }) => {
      const url = createUrl('', subAccountsRoutes.subAccountKeywords(payload?.subAccountId), {
        date: payload?.date ?? '',
      });
      return from(http.get<IKeyword[]>(url)).pipe(
        map((data) => {
          return getKeywordsOfSubaccountSuccess(data);
        }),
        catchError((_) => of(getKeywordsOfSubaccountFail(keywordFetchError)))
      );
    })
  );
};

export const getKeywordsOfKeywordGroupEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http }: { http: HTTPService }
) => {
  return actions$.pipe(
    filter(getKeywordsOfKeywordGroup.match),
    switchMap(({ payload }) => {
      const url = createUrl(
        '',
        subAccountsRoutes.groupKeywords(payload?.subAccountId, payload?.groupId),
        {
          date: payload?.date ?? '',
        }
      );
      return from(http.get<IKeyword[]>(url)).pipe(
        map((data) => {
          return getKeywordsOfKeywordGroupSuccess(data);
        }),
        catchError((_) => of(getKeywordsOfKeywordGroupFail(keywordFetchError)))
      );
    })
  );
};

export const getSuggestedKeywordsEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http }: { http: HTTPService }
) => {
  return actions$.pipe(
    filter(getSuggestedKeywords.match),
    switchMap(({ payload }) => {
      const url = subAccountsRoutes.suggestedKeywords(payload);
      return from(http.get<{ keywords: IKeywordNewType[]; lastIngested: string }>(url)).pipe(
        map((data) => {
          return getSuggestedKeywordsSuccess({
            suggestedKeywords: data.keywords,
            lastIngested: data.lastIngested,
          });
        }),
        catchError(() => of(getSuggestedKeywordsFail(keywordFetchError)))
      );
    })
  );
};

export const removeKeywordsEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<IRootState>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) => {
  return actions$.pipe(
    filter(removeKeywords.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const url = subAccountsRoutes.subAccountKeywords(action.payload.subAccountId);
      const { subAccountId, date, accountId } = action.payload;

      const keywordsAfterDeletion: IKeyword[] =
        state.keywords.detailsPage.keywordsPerSubaccount.data.filter(
          (keyword) => !action.payload?.keywordIds?.includes(keyword.id)
        );

      return from(http.delete(url, undefined, { keywords: action.payload?.keywordsToRemove })).pipe(
        switchMap(() => {
          const emptyKeywordGroupIds = action.payload?.emptyGroupsToRemove || [];
          const removeGroupActions = [];
          if (emptyKeywordGroupIds.length > 0) {
            for (const groupId of emptyKeywordGroupIds) {
              removeGroupActions.push(
                deleteGroupSuccess({ date, accountId, subAccountId, groupId })
              );
            }
          }
          notificationQueue.showNotification(NotificationType.Toast, 'Keywords removed');
          return [removeKeywordsSuccess(keywordsAfterDeletion), ...removeGroupActions];
        }),
        catchError(() => {
          notificationQueue.showNotification(NotificationType.Toast, keywordRemoveError.message);
          return of(removeKeywordsFail(keywordRemoveError));
        })
      );
    })
  );
};

export const getKeywordsOfSubaccountInKeywordGroupsEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http }: { http: HTTPService }
) => {
  return actions$.pipe(
    filter(getKeywordsOfSubaccountInKeywordGroups.match),
    switchMap(({ payload }) => {
      const url = subAccountsRoutes.subAccountKeywordsInKeywordGroups(payload);

      return from(http.get<IKeywordNewType[]>(url)).pipe(
        map((data) => {
          return getKeywordsOfSubaccountInKeywordGroupsSuccess({ keywordsInGroups: data });
        }),
        catchError((_) => of(getKeywordsOfSubaccountInKeywordGroupsFail(keywordFetchError)))
      );
    })
  );
};

export default combineEpics(
  getGroupKeywordsEpic,
  sendGroupKeywordsToTestSuccessEpic,
  sendGroupKeywordsWithRetryEpic,
  pauseGroupKeywordsTestingPhaseEpic,
  updateKeywordsGroupCreationEpic,
  deleteGroupKeywordsSuccessEpic,
  deleteGroupKeywordsEpic,
  getKeywordsOfSubaccountEpic,
  getKeywordsOfKeywordGroupEpic,
  getSuggestedKeywordsEpic,
  removeKeywordsEpic,
  getKeywordsOfSubaccountInKeywordGroupsEpic,
  setKeywordGroupCreationStatusEpic
);
