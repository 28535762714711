import React, { FC } from "react"
import { Button as MuiButton, ButtonProps } from "@mui/material"
import ButtonScss from "./Button.scss"

const Button: FC<ButtonProps> = (buttonProps) => {
  const getClasses = () => {
    const classes = [ButtonScss.customButton, buttonProps.className]

    if (buttonProps.size === "small") {
      classes.push(ButtonScss.customButtonSmall)
    }

    return classes.join(" ")
  }

  return (
    <MuiButton aria-label="button" {...buttonProps} className={getClasses()} />
  )
}

export default Button
