import {
  Autocomplete,
  TextField,
  AutocompleteRenderGetTagProps,
  AutocompleteValue,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Box
} from "@mui/material"
import React, { FC, ReactNode, useEffect, useState } from "react"
import Chip from "@src/components/Chip"
import styles from "./ChipInput.scss"

export interface Option {
  label: string
  value: string
  image?: string | null
}

export interface ChipInputPropsType {
  placeholder?: string
  defaultValue?: Option[]
  disabled?: boolean
  onChange?: (value: Option[]) => void
  popupIcon?: ReactNode | Element | JSX.Element
}

const ChipInput: FC<ChipInputPropsType> = ({
  placeholder,
  defaultValue,
  popupIcon,
  disabled,
  onChange
}) => {
  const [localOptions, setLocalOptions] = useState<Option[]>([])
  const [localValue, setLocalValue] = useState<Option[]>(
    !defaultValue || (defaultValue as Option[]).length === 0 ? [] : defaultValue
  )
  const [localPlaceholder, setLocalPlaceholder] = useState<string>(
    !defaultValue || (defaultValue as Option[]).length === 0 ? placeholder : ""
  )
  const [splitValues, setSplitValues] = useState<string[]>(null)
  const [copyPasteWarning, setCopyPasteWarning] = useState<boolean>(false)

  useEffect(() => {
    if (!localValue || localValue.length === 0) setLocalPlaceholder(placeholder)
  }, [localValue])

  useEffect(() => {
    defaultValue ? setLocalValue(defaultValue) : []
  }, [defaultValue])

  const onInputChange = (
    _event: React.SyntheticEvent,
    value: AutocompleteValue<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    if ((value as Option[]).length === -1) {
      setLocalPlaceholder(placeholder)
    } else {
      setLocalPlaceholder("")
    }

    if (disabled) return

    const clonedOption = [...localOptions]
    const clonedValue: Option[] = [...value]

    if (reason === "createOption") {
      // When at least 2 items separated by /n were pasted into the input
      if (splitValues?.length > 1) {
        // Remove the value as a whole text
        clonedValue.splice(clonedValue.length - 1, 1)
        // And push each value as a separate chip instead
        for (const [index, value] of splitValues.entries()) {
          clonedOption.push({
            label: value,
            value: (localOptions.length + index + 1).toString()
          })
          clonedValue.push({
            label: value,
            value: clonedOption.length.toString()
          })
        }
        // Empty the 'clipboard'
        setSplitValues(null)
        setCopyPasteWarning(false)
        setLocalOptions(clonedOption)
        setLocalValue(clonedValue)
        if (onChange) onChange(clonedValue)
      } else {
        // When a single entry is entered into the input
        clonedOption.push({
          label: details.option,
          value: (localOptions.length + 1).toString()
        })

        setLocalOptions(clonedOption)
        clonedValue[clonedValue.length - 1] = {
          label: details.option,
          value: clonedOption.length.toString()
        }
        setLocalValue(clonedValue)
        if (onChange) onChange(clonedValue)
      }
    } else {
      setLocalValue(value)
      if (onChange) onChange(value)
    }
  }

  const handleKeyDown = async (event: any) => {
    // If text is copied with ctrl+v or cmd+v, check for newline characters
    if ((event.ctrlKey || event.metaKey) && event.key === "v") {
      const clipboardText = await navigator.clipboard.readText()
      setSplitValues(clipboardText.split("\n"))
      setCopyPasteWarning(true)
    }
  }

  const onRenderTags = (
    value: Option[] = [],
    getTagProps: AutocompleteRenderGetTagProps
  ): ReactNode =>
    value.map((option, index) => (
      <Box key={index} className={styles.chipContainer}>
        <Chip
          label={option.label}
          value={option.value}
          aria-label="chip"
          isSmall
          {...getTagProps({ index })}
        />
      </Box>
    ))

  return (
    <Autocomplete
      freeSolo={true}
      multiple={true}
      value={localValue}
      options={localOptions}
      popupIcon={popupIcon}
      classes={{
        inputRoot: styles.inputContainer
      }}
      forcePopupIcon={popupIcon ? true : false}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={localPlaceholder}
          helperText={
            copyPasteWarning
              ? "Pasted values can only be entered as is. You can remove any chips afterwards."
              : null
          }
        />
      )}
      renderTags={onRenderTags}
      onChange={onInputChange}
      open={false}
      disabled={disabled}
      onKeyDown={handleKeyDown}
    />
  )
}

export default ChipInput
