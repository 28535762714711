import { DataTable } from '@dqna/seamless-suite-ui-kit';
import React from 'react';
import { StatusRowType, statusLabels, SuggestedKeywordsStatusTableProps } from './types';
import { SuggestedKeywordStatus } from 'types/keywords.types';

export const SuggestedKeywordsStatusTable: React.FC<SuggestedKeywordsStatusTableProps> = (
  props
) => {
  const { selectedStatus, setSelectedStatus } = props;

  return (
    <DataTable
      getRowId={(row: StatusRowType) => row.value}
      onRowClick={({ row }: { row: StatusRowType }) => {
        setSelectedStatus(row.value);
      }}
      selectionModel={selectedStatus}
      columns={[
        {
          field: 'label',
          flex: 1,
          headerName: 'Google Ads Keyword Status',
        },
      ]}
      rows={[
        {
          value: SuggestedKeywordStatus.ACTIVE,
          label: statusLabels[SuggestedKeywordStatus.ACTIVE],
        },
        {
          value: SuggestedKeywordStatus.PAUSED,
          label: statusLabels[SuggestedKeywordStatus.PAUSED],
        },
        {
          value: SuggestedKeywordStatus.NEW_KEYWORDS,
          label: statusLabels[SuggestedKeywordStatus.NEW_KEYWORDS],
        },
      ]}
    />
  );
};
