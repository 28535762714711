import find from "lodash/find"
import React, { FC, useEffect, useState } from "react"
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput
} from "@mui/material"
import Chip from "@src/components/Chip"
import { CustomSelectType, SingleSelectType, Option } from ".."
import styles from "./SingleSelect.scss"
import { ArrowDropDown } from "@mui/icons-material"

type SingleSelectPropsType = SingleSelectType & CustomSelectType

const SingleSelect: FC<SingleSelectPropsType> = ({
  options,
  defaultValue,
  defaultImage,
  placeholder,
  renderImage,
  onChange,
  disabled,
  className,
  withNoneOption = true,
  startAdornment,
  ariaLabel,
  error,
  useNullForNone
}) => {
  const [localOptions, setLocalOptions] = useState<Option[]>([])
  const [localValue, setLocalValue] = useState<Option>({
    value: "",
    label: ""
  })

  useEffect(() => {
    const clonedOptions = [...options]
    if (withNoneOption) {
      clonedOptions.unshift({
        label: "None",
        value: useNullForNone ? null : ""
      })
    }
    setLocalOptions(clonedOptions)
  }, [options])

  useEffect(() => {
    defaultValue
      ? setLocalValue(defaultValue)
      : setLocalValue({
          value: useNullForNone ? null : "",
          label: ""
        })
  }, [defaultValue])

  const onSelectChange = (
    _event: SelectChangeEvent<Option>,
    child: React.ReactNode
  ) => {
    const selectedOption = find(options, {
      value: (child as any).props.value
    })

    if (selectedOption) {
      setLocalValue(selectedOption)

      if (onChange) onChange(selectedOption)
    } else {
      setLocalValue(
        useNullForNone
          ? { value: null, label: "None" }
          : { value: "", label: "None" }
      )

      if (onChange) onChange(null)
    }
  }

  const renderInputValue = (option: Option) => {
    const emptyValue = useNullForNone ? null : ""
    if (option.value !== emptyValue) {
      return (
        <Chip
          label={option.label}
          value={option.value}
          avatar={renderImage(option, defaultImage)}
          aria-label="option-chip-value"
          isSmall
        />
      )
    } else {
      return <span className={styles.placeholder}>{placeholder}</span>
    }
  }

  return (
    <Select
      error={error}
      className={`${styles.singleSelect} ${className}`}
      renderValue={(option) => renderInputValue(option)}
      value={localValue}
      onChange={onSelectChange}
      displayEmpty={true}
      input={<OutlinedInput className={styles.input} />}
      IconComponent={ArrowDropDown}
      disabled={disabled}
      startAdornment={startAdornment}
      inputProps={{ "aria-label": `single-select-${ariaLabel}` }}
    >
      {localOptions.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {renderImage(option, defaultImage)}
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SingleSelect
