import React, { FC } from "react"
import { IModalProps, IModalContainerPropsType } from "./IModalProps"
import {
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Typography
} from "@mui/material"
import ModalScss from "./Modal.scss"
import { Close } from "@mui/icons-material"
import { Button } from "@src/index"

export const ModalContainer: FC<IModalContainerPropsType> = ({
  title,
  fixedTitle,
  headerBtnText,
  onHeaderBtnClick,
  children
}) => {
  return (
    <DialogContent className={ModalScss.content}>
      <div className={fixedTitle ? ModalScss.fixedTitle : ""}>
        <Typography variant="h6">{title}</Typography>
        {headerBtnText && (
          <Button
            color="primary"
            variant="contained"
            onClick={onHeaderBtnClick}
          >
            {headerBtnText}
          </Button>
        )}
      </div>
      <Box className={fixedTitle ? ModalScss.fixedTitleContent : ""}>
        {children}
      </Box>
    </DialogContent>
  )
}

export const ModalActions: FC = ({ children }) => {
  return <DialogActions>{children}</DialogActions>
}

const Modal: FC<IModalProps> = (props) => {
  const { onClose, children, className, fullWidth, ...rest } = props

  return (
    <Dialog
      onClose={onClose}
      className={className}
      classes={{
        paper: `${ModalScss.modal} ${fullWidth ? "" : ModalScss.customWidth}`
      }}
      fullWidth={fullWidth}
      {...rest}
    >
      <span
        className={ModalScss.close}
        onClick={onClose}
        aria-label="close-button"
      >
        <Close />
      </span>

      {children}
    </Dialog>
  )
}

export default Modal
