import React from 'react';
import { RemoveGroupModalProps } from './type';
import { UserInteractionDialog } from '@dqna/seamless-suite-ui-kit';
import { CloseActionType } from '@dqna/seamless-suite-ui-kit/dist/components/UserInteractionDialog';
import { Typography } from '@mui/material';

export const RemoveGroupModal: React.FC<RemoveGroupModalProps> = (props) => {
  const { isOpen, setOpen, deleteGroup, group } = props;

  const onClose = (closeAction: CloseActionType) => {
    if (closeAction === 'confirm') {
      deleteGroup(group.id);
    }
  };

  return (
    <UserInteractionDialog
      uiDialogState={isOpen}
      setUIDialogState={setOpen}
      title="Confirmation?"
      message={undefined}
      buttonCaptions={{
        cancel: 'Cancel',
        confirm: 'Yes, delete',
      }}
      onClose={onClose}>
      <Typography variant="body2" textAlign="center">
        Are you sure you want to delete <strong>{group.name}</strong> keyword group?
      </Typography>
    </UserInteractionDialog>
  );
};
