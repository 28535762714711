import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { CompanyElement, DataTable } from '@dqna/seamless-suite-ui-kit';
import { generateRouteWithIds } from 'utils/generateRouteWithIds';
import { NoDataPlaceholder } from 'components/ui-kit/NoDataPlaceholder';
import { NoSearchResult } from 'components/ui-kit/NoSearchResult';
import { DeleteAccountModal } from '../DeleteAccountModal';
import useSearchInUrl from 'utils/hooks/useSearchInUrl';
import { OrganisationDetailsAccountPanelProps } from './types';
import { IAccount } from 'types/accounts.type';
import { Routes } from 'types/app.types';

export const OrganisationAccountsTab: React.FC<OrganisationDetailsAccountPanelProps> = (props) => {
  const { organisation, accountsOfAllOrgs, isGetAccountsOfAllOrgsLoading, deleteAccount } = props;
  const { replace } = useHistory();
  const { orgId } = useParams<{ orgId: string }>();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState<IAccount>();
  const [deleteModalOpenState, setDeleteModalOpenState] = useState(false);
  const { search } = useSearchInUrl();
  const [filteredAccounts, setFilteredAccounts] = useState<IAccount[]>([]);

  useEffect(() => {
    const pathWithIds = generateRouteWithIds({
      route: Routes.ORGANISATION_DETAILS_ACCOUNTS,
      ids: { orgId },
    });
    replace({ pathname: pathWithIds });
  }, []);

  const onDeleteOrganisationAction = (account: IAccount) => {
    setSelectedAccount(account);
    setDeleteModalOpenState(true);
  };

  useEffect(() => {
    if (!organisation || !accountsOfAllOrgs) {
      return;
    }

    if (search) {
      const searchResult = accountsOfAllOrgs[organisation?.name].filter((account) =>
        account.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredAccounts(searchResult);
    } else {
      setFilteredAccounts(accountsOfAllOrgs[organisation?.name]);
    }
  }, [organisation, accountsOfAllOrgs, search]);

  if (!filteredAccounts && !isGetAccountsOfAllOrgsLoading) {
    return <NoDataPlaceholder message="" title="No accounts available" />;
  }

  return (
    <>
      <DataTable
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: ({ row }: { row: IAccount }) => (
              <CompanyElement companyObject={{ name: row.name }} />
            ),
          },
        ]}
        loading={isGetAccountsOfAllOrgsLoading}
        rows={filteredAccounts}
        rowMenuItems={(row: IAccount) => [
          {
            label: `Delete ${row.name}`,
            value: 'delete',
            action: () => {
              onDeleteOrganisationAction(row);
            },
          },
        ]}
        className="organisations-accounts-table"
        pagination
        onPageChange={setCurrentPage}
        page={currentPage}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 30]}
      />
      {search && !filteredAccounts.length && !isGetAccountsOfAllOrgsLoading && <NoSearchResult />}
      {selectedAccount && (
        <DeleteAccountModal
          modalOpenState={deleteModalOpenState}
          setModalOpenState={setDeleteModalOpenState}
          deleteAccount={deleteAccount}
          selectedAccount={selectedAccount}
          organisationName={organisation?.name}
        />
      )}
    </>
  );
};
