import { ChangeEvent, MouseEvent, SVGProps, ComponentType } from 'react';
import { DataType } from '../List';

export type Value = string | boolean | number;

export type ChangeInputHandler = (
  e?: MouseEvent | ChangeEvent<HTMLElement | HTMLInputElement>
) => void;

export interface IDropdownItemProps {
  selected?: boolean;
  value: Value;
  className?: string;
  name: string;
  label?: string;
  disabled?: boolean;
}

export type IDdItemTextProps = IDropdownItemProps & {
  onChange: () => void;
  dataType?: DataType;
};

export type IDdItemSelectProps = IDropdownItemProps & {
  onChange: ChangeInputHandler;
};

export interface IDdActionItemProps {
  label: string;
  link: string;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
}
