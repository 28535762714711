import { useParams } from 'react-router';
import React, { useState, useEffect, useMemo } from 'react';
import { Alert, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, Modal, ModalActions, UserInteractionDialog } from '@dqna/seamless-suite-ui-kit';
import { CloseActionType } from '@dqna/seamless-suite-ui-kit/dist/components/UserInteractionDialog';
import './RemoveKeywordsModal.scss';
import RemoveKeywordsDataTable from './RemoveKeywordsDataTable.component';
import { RemoveKeywordsModalProps } from './types';
import formatDate from 'date-fns/format';
import getBackDays from 'date-fns/subDays';
import { KEYWORDS_DATA_AVAILABLE_DAYS_BACK } from 'types/keywords.types';

export const RemoveKeywordsModal: React.FC<RemoveKeywordsModalProps> = (props) => {
  const {
    modalOpenState,
    setModalOpenState,
    getKeywordsOfSubaccount,
    keywords = [],
    keywordsLoading,
    removeKeywords,
    subAccount,
  } = props;
  const { subAccountId } = useParams<{
    subAccountId: string;
  }>();
  const [confirmationDialogOpenState, setConfirmationDialogOpenState] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<number[]>([]);
  const [groupsToRemove, setGroupsToRemove] = useState<string[]>([]);
  const [keywordsByGroupSelection, setKeywordsToRemoveByGroup] = useState<Record<string, number[]>>(
    {}
  );
  const [emptyKeywordGroupIds, setEmptyKeywordGroupIds] = useState<number[]>([]);

  const { accountId } = useParams<{
    accountId: string;
  }>();

  const date = useMemo(() => {
    const today = new Date();
    const dateKeywordsAvailable = getBackDays(today, KEYWORDS_DATA_AVAILABLE_DAYS_BACK);
    return formatDate(dateKeywordsAvailable, 'yyyy-MM-dd');
  }, []);

  useEffect(() => {
    if (modalOpenState && getKeywordsOfSubaccount) {
      getKeywordsOfSubaccount(parseInt(subAccountId));
    }
  }, [modalOpenState]);

  const onCloseModal = () => {
    setModalOpenState(false);
  };

  const onRemove = () => {
    const selectedKeywordsByGroup = keywords.reduce(
      (grouped: Record<string, number[]>, keyword) => {
        const keywordInSelection = rowSelectionModel.find(
          (selectedId) => selectedId === keyword.id
        );
        if (!keywordInSelection) return grouped;
        const { keywordGroupId } = keyword;
        const stringGroupId = keywordGroupId.toString();
        grouped[stringGroupId] = grouped[stringGroupId] ?? [];
        grouped[stringGroupId].push(keyword.id);
        return grouped;
      },
      {}
    );

    setKeywordsToRemoveByGroup(selectedKeywordsByGroup);

    const groupsWithoutKeywordsLeft = subAccount?.keywordGroups?.filter((group) => {
      return (
        selectedKeywordsByGroup[group.id] &&
        selectedKeywordsByGroup[group.id].length === group.numberOfKeywords
      );
    });
    const groupsWithoutKeywordsNames = groupsWithoutKeywordsLeft?.map((group) => group.name);

    const groupsWithoutKeywordsIds = groupsWithoutKeywordsLeft?.map((group) => group.id);

    setEmptyKeywordGroupIds(groupsWithoutKeywordsIds ?? []);

    setGroupsToRemove(groupsWithoutKeywordsNames ?? []);

    setConfirmationDialogOpenState(true);
  };

  const onConfirmationModalClose = (closeAction: CloseActionType) => {
    if (closeAction === 'confirm') {
      removeKeywords({
        subAccountId: parseInt(subAccountId),
        keywordIds: rowSelectionModel,
        keywordsToRemove: keywordsByGroupSelection,
        emptyGroupsToRemove: emptyKeywordGroupIds,
        accountId: parseInt(accountId),
        date,
      });
    }
    setModalOpenState(false);
  };

  return (
    <Modal onClose={onCloseModal} open={modalOpenState} fullScreen className="remove-keywords">
      <Typography variant="h6">Remove keyword(s)</Typography>
      <RemoveKeywordsDataTable
        keywordsOfSubaccount={keywords}
        keywordsOfSubaccountLoading={keywordsLoading}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        subAccount={subAccount}
      />
      <ModalActions>
        <Button onClick={onCloseModal} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={onRemove}
          variant="contained"
          disabled={!rowSelectionModel.length}
          startIcon={<DeleteOutlineIcon />}>
          Remove ({rowSelectionModel.length})
        </Button>
      </ModalActions>
      <UserInteractionDialog
        uiDialogState={confirmationDialogOpenState}
        setUIDialogState={setConfirmationDialogOpenState}
        title="Heads up"
        buttonCaptions={{ cancel: 'Cancel', confirm: 'Yes, remove' }}
        onClose={onConfirmationModalClose}>
        <Typography variant="body2" textAlign="center">
          Are you sure about removing the <strong>{rowSelectionModel.length}</strong> selected
          keyword(s)?
        </Typography>
        {!!groupsToRemove.length && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <Typography variant="body2">
              The following keyword group(s) will be also deleted to avoid having empty groups:{' '}
              <strong>{groupsToRemove.join(', ')}</strong>
            </Typography>
          </Alert>
        )}
      </UserInteractionDialog>
    </Modal>
  );
};
