import { createAction } from 'utils/createAction';

export enum NotifsActions {
  FetchNotifs = 'FETCH_NOTIFS',
  FetchNotifsSuccess = 'FETCH_NOTIFS__SUCCESS',
  FetchNotifsFail = 'FETCH_NOTIFS__FAIL',
}

export enum NotificationStatus {
  Success = 'Success',
  Error = 'Error',
  Pending = 'Pending',
  Regular = 'Regular',
}
export interface INotification {
  body: string;
  date: string;
  id: string;
  notification_id: string;
  recipient: string;
  severity: NotificationStatus;
  subject: string;
  user_id: string;
}



export const fetchNotifs = () => createAction(NotifsActions.FetchNotifs);
export const fetchNotifsSuccess = (data: INotification[]) =>
  createAction(NotifsActions.FetchNotifsSuccess, { data });
export const fetchNotifsFail = (error: Error) =>
  createAction(NotifsActions.FetchNotifsFail, error);
