/* eslint-disable */
import createAuth0Client, { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { Dispatch } from 'redux';
import { setIsAuthServiceReady, setIsUserAuthenticated } from 'store/reducers/user.reducer';

import { clearOrg } from 'utils/manageOrganisations';

export interface IAuthServiceConfig {
  domain: string;
  clientId: string;
  redirectUri: string;
  logoutUri: string;
  nonInvitedUserUri: string;
}

export class AuthService implements IAuthServiceConfig {
  public readonly domain: string;
  public readonly clientId: string;
  public readonly redirectUri: string;
  public readonly logoutUri: string;
  public readonly nonInvitedUserUri: string;

  private isAuthServiceReady: boolean = false;
  private authClient: Auth0Client | undefined;
  private dispatcher: Dispatch;

  constructor(config: IAuthServiceConfig) {
    this.domain = config.domain;
    this.clientId = config.clientId;
    this.redirectUri = config.redirectUri;
    this.logoutUri = config.logoutUri;
    this.nonInvitedUserUri = config.nonInvitedUserUri;
  }

  public get getIsAuthServiceReady(): boolean {
    return this.isAuthServiceReady;
  }

  public run = async (dispatcher: Dispatch) => {
    const authClientConfig: Auth0ClientOptions = {
      domain: this.domain,
      client_id: this.clientId,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    };

    let authClient = null;

    try {
      authClient = await createAuth0Client(authClientConfig);
    } catch {
      authClient = new Auth0Client(authClientConfig);
      localStorage.clear();
      authClient.logout({
        returnTo: `${this.logoutUri}${window.location.search}`,
      });
    }
    this.dispatcher = dispatcher;
    this.authClient = authClient!;

    await this.isAuthenticated();

    this.isAuthServiceReady = true;
    dispatcher(setIsAuthServiceReady(true));
  };

  public login = async () => {
    await this.authClient?.loginWithRedirect({
      redirect_uri: this.redirectUri,
      prompt: 'select_account',
    });
  };
  
  public logout = async () => {
    await this.authClient?.logout({
      returnTo: this.logoutUri,
    });
    clearOrg();
  };

  public logoutUnAuthorizedUser = async () => {
    await this.authClient?.logout({
      returnTo: this.nonInvitedUserUri,
    });
    clearOrg();
  };

  public isAuthenticated = async () => {
    const isAuthenticated = await this.authClient?.isAuthenticated();

    this.dispatcher(setIsUserAuthenticated(!!isAuthenticated));
    return isAuthenticated;
  };

  public getTokenSilently = async () => {
    try {
      return await this.authClient?.getTokenSilently();
    } catch {
      this.dispatcher(setIsUserAuthenticated(false));
    }
  };

  public getIdTokenClaims = async () => {
    try {
      return await this.authClient?.getIdTokenClaims();
    } catch {
      this.dispatcher(setIsUserAuthenticated(false));
    }
  };

  public getUser = async () => {
    return await this.authClient?.getUser();
  };

  public handleRedirectCallback = async () => {
    return await this.authClient?.handleRedirectCallback();
  };
}
