import { IKeywordNewType, SuggestedKeywordStatus } from 'types/keywords.types';
import { ISubAccount, KeywordBodyType } from 'types/subAccounts.types';

export interface SuggestedKeywordsPanelProps {
  subAccount: ISubAccount;
  getSuggestedKeywords: (params: { subAccountId: number }) => void;
  suggestedKeywordsState: {
    isLoading: boolean;
    data: IKeywordNewType[];
    error: Error | null;
    lastIngested: string;
  };
  sendKeywordsToTest: (
    date: string,
    accountId: number,
    subAccountId: number,
    body: KeywordBodyType,
    redirect?: boolean
  ) => void;
  date: string;
  keywordGroupCreationStatus: {
    isLoading: boolean;
    error: Error | null;
    numberOfAddedKeywords: number;
  };
}

export interface SuggestedKeywordsStatusTableProps {
  selectedStatus: SuggestedKeywordStatus;
  setSelectedStatus: (status: SuggestedKeywordStatus) => void;
}

export interface SuggestedKeywordsDetilsProps {
  selectedStatus: SuggestedKeywordStatus;
  keywordsOfCurrentStatus: IKeywordNewType[];
  lastIngested: string;
  subAccount: ISubAccount;
  sendKeywordsToTest: (
    date: string,
    accountId: number,
    subAccountId: number,
    body: KeywordBodyType,
    redirect?: boolean
  ) => void;
  date: string;
  keywordGroupCreationStatus: {
    isLoading: boolean;
    error: Error | null;
    numberOfAddedKeywords: number;
  };
  getSuggestedKeywords: (params: { subAccountId: number }) => void;
  keywordListLoadingState: boolean;
}

export interface StatusRowType {
  label: string;
  value: SuggestedKeywordStatus;
}

export interface SuggestedKeywordsTableProps {
  keywords: IKeywordNewType[];
  subAccount: ISubAccount;
  displayedRows: IKeywordNewType[];
  setDisplayedRows: (rows: IKeywordNewType[]) => void;
  isTableExpanded: boolean;
  onCloseModal: () => void;
}

export const statusDescriptions = {
  [SuggestedKeywordStatus.ACTIVE]:
    'These are keywords in active Google Ads campaigns and can be added to Seamless right away',
  [SuggestedKeywordStatus.PAUSED]:
    'These are keywords that exist in Google Ads but in paused campaigns. These keywords need moving/rebuilding in currently active campaigns before adding to Seamless Search',
  [SuggestedKeywordStatus.NEW_KEYWORDS]:
    "These are keywords which have high organic volume but don't yet exist in Google Ads. Please export the list to add the keywords to existing campaigns so they can be added to Seamless Search",
};

export const statusLabels = {
  [SuggestedKeywordStatus.ACTIVE]: 'Active keywords',
  [SuggestedKeywordStatus.PAUSED]: 'Paused keywords',
  [SuggestedKeywordStatus.NEW_KEYWORDS]: 'New keywords',
};

export const AGGREGATED_PERIOD_IN_DAYS = 90;
