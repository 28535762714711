import { TableBody, TableRow as MUITableRow, Divider } from "@mui/material"
import React from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { DetailInputPanelPropsType } from "./DetailInputPanelProps"
import Button from "@src/components/Button"
import style from "./DetailInputPanel.scss"
import { AddOutlined } from "@mui/icons-material"
import { Table, TableHead, TableCell } from "@src/components/Table"
import TableFormRow from "./TableFormRow"
import { DndContext, DragEndEvent } from "@dnd-kit/core"
import { SortableContext } from "@dnd-kit/sortable"

const DetailInputPanel = <T,>({
  name,
  columns,
  children,
  allowRemove = false,
  firstRowDisabled = false,
  allowAdd = false,
  addingCaption = "Add",
  numbered = false,
  withDivider = false,
  disableAdding = false,
  draggable = false,
  allowSublines = false,
  ...props
}: DetailInputPanelPropsType<T>) => {
  const { control } = useFormContext()
  const { fields, remove, append, move } = useFieldArray({
    control,
    name
  })

  const tableHeaders = columns.map(({ header }, index: number) => (
    <TableCell isHeader key={`header.${index}`}>
      {header}
    </TableCell>
  ))

  const dividerColspan = columns.length + Number(allowRemove) + Number(numbered)

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    const activeIndex = fields.findIndex((field) => field.id === active.id)
    const overIndex = fields.findIndex((field) => field.id === over.id)

    move(activeIndex, overIndex)
  }

  const addNewRow = () => {
    const newEmptyRow: Record<any, any> = {}

    columns.forEach((column) => {
      newEmptyRow[column.name] = null
    })

    append(newEmptyRow)
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext items={fields}>
          <Table {...props}>
            <TableHead>
              <MUITableRow>
                {numbered ? <TableCell valign="top">No.</TableCell> : null}
                {tableHeaders}
                <TableCell></TableCell>
              </MUITableRow>
            </TableHead>

            <TableBody>
              {fields.map((field, index) => (
                <React.Fragment key={field.id}>
                  <TableFormRow<T>
                    dragId={field.id}
                    fieldName={name}
                    rowIndex={index}
                    columns={columns}
                    numbered={numbered}
                    disabled={firstRowDisabled && index === 0}
                    allowRemove={allowRemove}
                    draggable={draggable}
                    allowSublines={allowSublines}
                    disableRemove={
                      fields.length === 1 || (firstRowDisabled && index === 0)
                    }
                    onRemove={remove}
                  />
                  {withDivider && fields.length - 1 > index ? (
                    <MUITableRow>
                      <TableCell valign="top" colSpan={dividerColspan}>
                        <Divider className={style.divider} />
                      </TableCell>
                    </MUITableRow>
                  ) : null}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>{" "}
        </SortableContext>
      </DndContext>
      <div className={style.buttonContainer}>
        {allowAdd ? (
          <Button
            onClick={addNewRow}
            className={style.addButton}
            disabled={disableAdding}
          >
            <AddOutlined className={style.icon} /> {addingCaption}
          </Button>
        ) : null}
        {children}
      </div>
    </div>
  )
}

export default DetailInputPanel
