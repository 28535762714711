export const existingOrgError = {
  name: 'ORGANISATION_EXIST',
  message: 'Organisation with this name already exists!',
};

export const orgCreationError = {
  name: 'ORGANISATION_CREATION_FAIL',
  message: 'Error when creating organisation!',
};

export const existingUserError = {
  name: 'USER_EXIST',
  message: 'This user already exist in the organisation!',
};

export const userCreationError = {
  name: 'USER_ADD_FAIL',
  message: 'Error when adding user to organisation!',
};

export const userReinviteError = {
  name: 'USER_REINVITE_FAIL',
  message: 'Error when reinviting user to organisation!',
};

export const fetchOrganisationError = {
  name: 'FETCH_ORGANISATION_FAIL',
  message: 'Error when getting all organisations!',
};
