import React, { FC, useState, useEffect } from "react"
import KebabMenu from "@src/components/KebabMenu"
import DataTableScss from "./DataTable.scss"
import IDataTableProps from "./IDataTableProps"
import {
  GridCellCheckboxRenderer,
  GridHeaderCheckbox,
  GridRowId,
  GridValueGetterParams,
  selectedIdsLookupSelector,
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  GridRowClassNameParams,
  getGridStringOperators,
  GridSlotsComponent
} from "@mui/x-data-grid-pro"
import BranchSvg from "@src/static/svg/branch.svg"
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  DeleteOutline
} from "@mui/icons-material"
import { ButtonBase, ButtonProps, Box, OutlinedInput } from "@mui/material"
import FilterInputWithSeparator from "../FilterInputWithSeparator"

const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0
  const arrow = rowNode.childrenExpanded ? (
    <KeyboardArrowUp className={DataTableScss.arrow} />
  ) : (
    <KeyboardArrowDown className={DataTableScss.arrow} />
  )
  const isExpandable = filteredDescendantCount > 0
  const hasNoParent = !rowNode.parent

  const handleClick: ButtonProps["onClick"] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box className={DataTableScss.groupIndicator}>
      <div>
        {isExpandable ? (
          <ButtonBase onClick={handleClick}>{arrow}</ButtonBase>
        ) : hasNoParent ? (
          <span />
        ) : (
          <embed src={BranchSvg} />
        )}
      </div>
    </Box>
  )
}

const disableKebab = (
  rowMenuItems: IDataTableProps["rowMenuItems"],
  row: unknown
) => {
  return (
    (Array.isArray(rowMenuItems) && rowMenuItems.length === 0) ||
    (rowMenuItems instanceof Function && rowMenuItems(row).length === 0)
  )
}

const createRowMenu = (
  rowMenuItems: IDataTableProps["rowMenuItems"]
): GridColDef => {
  return {
    field: "actions",
    headerName: "",
    width: 10,
    type: "actions",
    editable: false,
    renderCell: ({ row }: { row: unknown }) => {
      return (
        <KebabMenu
          aria-label="menu-element"
          menuItems={
            rowMenuItems instanceof Function ? rowMenuItems(row) : rowMenuItems
          }
          data={row}
          isKebabDisabled={disableKebab(rowMenuItems, row)}
        />
      )
    }
  }
}

export const NewFlag: FC<{ value: string }> = ({ value }) => {
  return (
    <div className={DataTableScss.newFlag}>
      <span>{value}</span>
    </div>
  )
}

const DataTable: FC<IDataTableProps> = ({
  focusBorder = true,
  flaggedRowClass = DataTableScss.flaggedRow,
  pinnedRowClass = DataTableScss.pinnedRow,
  markedRowClass = DataTableScss.markedRow,
  markedInactiveRowClass = DataTableScss.markedInactiveRow,
  rowMenuItems,
  withRowBorder,
  treeData,
  checkboxHeaderWithDropdown,
  checkboxHeaderDropdownMenuItems,
  showPinning,
  showInactiveRowStyle,
  withFilterPanel,
  columnsWithCustomSeparatorFiltering = [],
  noRowsText,
  hideRowCheckbox,
  ...props
}) => {
  const checkboxCellWithDropdown: GridColDef = {
    field: "__check__",
    type: "checkboxSelection",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    disableExport: true,
    resizable: true,
    cellClassName: DataTableScss.checkboxWithDropdown,
    headerClassName: DataTableScss.checkboxWithDropdown,
    valueGetter: (params) => {
      const selectionLookup: Record<GridRowId, GridRowId> =
        selectedIdsLookupSelector((params as GridValueGetterParams).api.state)
      return selectionLookup[params.id] !== undefined
    },

    renderHeader: (params) => (
      <React.Fragment>
        <GridHeaderCheckbox {...params} />
        <KebabMenu
          withArrowIcon={true}
          menuItems={checkboxHeaderDropdownMenuItems || []}
          data={undefined}
          className={DataTableScss.dropdownCheckboxArrowButton}
        />
      </React.Fragment>
    ),
    renderCell: (params) => <GridCellCheckboxRenderer {...params} />
  }

  const columns: GridColDef[] = checkboxHeaderWithDropdown
    ? [checkboxCellWithDropdown, ...props.columns]
    : props.columns

  const columnsWithCustomFiltering: GridColDef[] = columns.map((column) => {
    if (!columnsWithCustomSeparatorFiltering.includes(column.field))
      return column
    return {
      ...column,
      filterOperators: getGridStringOperators().map((operator) => {
        if (operator.value !== "isAnyOf") return operator
        return {
          ...operator,
          InputComponent: (props: any) => FilterInputWithSeparator(props)
        }
      })
    }
  })

  const defaultPageSize = 5
  const [showPagination, setShowPagination] = useState(true)

  const menuItemsAdded = columns.find((column) => column.field === "actions")
  if (rowMenuItems && !menuItemsAdded) {
    columns.push(createRowMenu(rowMenuItems))
  }

  const rowClassName = [
    DataTableScss.dataRow,
    focusBorder ? DataTableScss.focusBorder : null,
    withRowBorder
      ? DataTableScss.dataRowOutlined
      : DataTableScss.dataRowNoBorder,
    treeData ? DataTableScss.groupedTableRow : null
  ].join(" ")

  const headerClassName = [
    DataTableScss.dataHeaderRow,
    withRowBorder
      ? DataTableScss.dataRowOutlined
      : DataTableScss.dataRowNoBorder
  ].join(" ")

  const rootClassName = [
    DataTableScss.root,
    treeData ? DataTableScss.groupedTable : null
  ].join(" ")

  useEffect(() => {
    const usedPageSize = props.pageSize || defaultPageSize
    const isPaginated = props.rows.length >= usedPageSize || !!props.page
    setShowPagination(isPaginated)
  }, [props.pageSize, props.rows, props.page])

  const isRowFlagged = (row: unknown) => {
    return (row as { flagged: undefined | boolean }).flagged
  }

  const isRowPinned = (row: unknown) => {
    return (row as { isPinned: undefined | boolean }).isPinned
  }

  const isRowMarkedWithError = (row: unknown) => {
    return (row as { isMarkedWithError: undefined | boolean }).isMarkedWithError
  }

  const isRowMarkedInactive = (row: unknown) => {
    return (
      ((row as { isActive: undefined | boolean }).isActive === false ||
        disableKebab(rowMenuItems, row)) &&
      showInactiveRowStyle
    )
  }

  const CustomNoRowsOverlay = () => {
    return <div className={DataTableScss.noRowsContainer}>{noRowsText}</div>
  }

  const getComponents = (): Partial<GridSlotsComponent> => {
    const data: Partial<GridSlotsComponent> = {}
    if (withFilterPanel) {
      data.FilterPanelDeleteIcon = DeleteOutline
    }
    if (noRowsText) {
      data.NoRowsOverlay = CustomNoRowsOverlay
    }
    return data
  }

  return (
    <DataGridPro
      classes={{
        row: rowClassName,
        columnHeaders: headerClassName,
        columnSeparator: DataTableScss.columnSeparator,
        sortIcon: DataTableScss.sortIcon,
        footerContainer: showPagination ? null : DataTableScss.hidden,
        cell: DataTableScss.dataRowNoBorder,
        root: rootClassName,
        checkboxInput: DataTableScss.datatableCheckbox
      }}
      disableColumnMenu={!withFilterPanel}
      disableColumnSelector={!withFilterPanel}
      disableColumnPinning
      pageSize={defaultPageSize}
      rowsPerPageOptions={[defaultPageSize]}
      checkboxSelection={false}
      hideFooterSelectedRowCount={true}
      getRowClassName={(
        params: GridRowClassNameParams<{
          path?: string[]
          withoutChildren?: boolean
          isRowSelectable?: boolean
        }>
      ) =>
        `${isRowFlagged(params.row) ? flaggedRowClass : ""} 
        ${
          Array.isArray(params.row.path) && params.row.path?.length > 1
            ? DataTableScss.indentedRow
            : DataTableScss.notIndentedRow
        }
        ${
          params.row.withoutChildren
            ? DataTableScss.parentRowWithoutChevron
            : ""
        }
        ${isRowPinned(params.row) && showPinning ? pinnedRowClass : ""}
        ${isRowMarkedWithError(params.row) ? markedRowClass : ""}
        ${
          isRowMarkedInactive(params.row)
            ? disableKebab(rowMenuItems, params.row)
              ? DataTableScss.markedDisabledRow
              : markedInactiveRowClass
            : ""
        }
        ${
          hideRowCheckbox && hideRowCheckbox(params.row)
            ? DataTableScss.hideRowCheckbox
            : ""
        }
        `
      }
      defaultGroupingExpansionDepth={1}
      groupingColDef={{
        headerName: "",
        width: 60,
        minWidth: 60,
        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
      }}
      treeData={treeData}
      getTreeDataPath={(row: unknown) => {
        const rowWithPath = row as { path: string[]; id: number }
        return rowWithPath.path ?? [`${rowWithPath.id}`]
      }}
      disableVirtualization={true}
      sx={{
        "& .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root":
          checkboxHeaderWithDropdown && {
            cursor: "not-allowed",
            pointerEvents: "none"
          }
      }}
      components={getComponents()}
      componentsProps={{
        baseSelect: {
          native: false,
          variant: "outlined",
          input: <OutlinedInput />
        },
        baseTextField: { variant: "outlined" },
        filterPanel: {
          sx: filterPanelStyling,
          filterFormProps: {
            valueInputProps: { variant: "outlined" }
          }
        },
        columnsPanel: {
          sx: columnPanelStyling
        }
      }}
      {...props}
      columns={withFilterPanel ? columnsWithCustomFiltering : columns}
    />
  )
}

export default DataTable

const filterPanelStyling = {
  "& .MuiDataGrid-filterForm": {
    padding: "1.6rem",
    paddingTop: "3.2rem",
    gap: "1.6rem",
    width: "90rem",
    alignItems: "center",
    label: { top: "-1.6rem", color: "#333333" },
    ".MuiSelect-select": {
      padding: "0 0.9rem"
    },
    ".MuiDataGrid-filterFormColumnInput": {
      width: "20rem",
      svg: { fontSize: "2.2rem" }
    },
    ".MuiDataGrid-filterFormOperatorInput": {
      width: "20rem",
      svg: { fontSize: "2.2rem" }
    },
    ".MuiDataGrid-filterFormDeleteIcon": {
      order: 10,
      button: {
        height: "4rem",
        width: "2.8rem",
        svg: { fontSize: "2.2rem", height: "4rem" }
      }
    },
    ".MuiDataGrid-filterFormLinkOperatorInput": {
      width: "11rem",
      svg: { fontSize: "2.2rem" }
    },
    ".MuiDataGrid-filterFormValueInput": {
      flex: 1,
      fieldset: { margin: "auto", top: 0 },
      label: { top: "-1rem", left: "-1.2rem" },
      ".MuiOutlinedInput-notchedOutline legend": { display: "none" },
      ".MuiAutocomplete-inputRoot": { padding: "0.4rem" }
    }
  },
  "& .MuiDataGrid-panelFooter": {
    padding: "1.6rem",
    button: { fontSize: "1.4rem", textTransform: "initial" }
  }
}

const columnPanelStyling = {
  "& .MuiDataGrid-panelFooter": {
    padding: "1.6rem",
    button: { fontSize: "1.4rem", textTransform: "initial" }
  },
  "& .MuiDataGrid-panelHeader": {
    paddingLeft: "1.6rem",
    paddingRight: "1.6rem",
    paddingBottom: 0,
    paddingTop: "1.6rem",

    ".MuiInputLabel-root[data-shrink=false]": {
      transform: "translate(14px, 8px) scale(1)"
    }
  },
  "& .MuiDataGrid-panelContent": {
    padding: "1.6rem",

    ".MuiDataGrid-columnsPanel": {
      padding: 0
    },
    ".MuiDataGrid-columnsPanelRow": {
      padding: "0.8rem 0",
      ".MuiFormControlLabel-root": {
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        width: "100%",
        margin: 0
      }
    }
  }
}
