import React, { FC, useState } from "react"
import { IDetailPanelProps } from "./IDetailPanelProps"
import { Box, Card, Typography } from "@mui/material"
import { Replay } from "@mui/icons-material"
import { Tabs, TabsList, Tab } from "@mui/base"
import KebabMenu from "@src/components/KebabMenu"
import DetailPanelScss from "./DetailPanel.scss"
import { IKebabMenuItem } from "@src/components/KebabMenu/IKebabMenuProps"
import ChatBoxToggle from "@src/components/ChatBoxToggle"
import FrameSelector from "../FrameSelector"
import { FrameSelectorMenuItem } from "../FrameSelector/FrameSelectorProps"
import { PrefillSelectorProps } from "../PrefillSelector/PrefillSelectorProps"
import PrefillSelector from "../PrefillSelector"

const getDetailPanelMenu = (
  menuItems: IKebabMenuItem[] | FrameSelectorMenuItem[],
  menuData: unknown,
  chevronMenu: boolean,
  tabSelectorMenu: boolean,
  onChatBoxToggle: () => void,
  onReplay: () => void,
  hasUnreadComments: boolean,
  hasMentions: boolean,
  isPrefillConfigured?: boolean,
  linkIconDisabled?: boolean,
  prefillSelectorProps?: PrefillSelectorProps
) => {
  if (!menuItems) {
    return null
  }

  if (chevronMenu) {
    return (
      <div className={DetailPanelScss.menuContainer}>
        <Replay fontSize="large" color="action" onClick={onReplay} />
        {onChatBoxToggle ? (
          <ChatBoxToggle
            hasRedDot={hasMentions}
            isBoldStyle={hasUnreadComments}
            onClick={onChatBoxToggle}
          />
        ) : null}
        <FrameSelector
          data={menuData}
          menuItems={menuItems}
          isPrefillConfigured={isPrefillConfigured}
          linkIconDisabled={linkIconDisabled}
        />
        {isPrefillConfigured && <PrefillSelector {...prefillSelectorProps} />}
      </div>
    )
  } else if (tabSelectorMenu) {
    const tabs = (menuItems as IKebabMenuItem[]).map((item, index) => {
      return (
        <Tab
          key={index}
          value={index}
          onClick={() => {
            if (item.action && typeof item.action === "function") {
              item.action(menuData)
            }
          }}
        >
          <div>{item.label}</div>
        </Tab>
      )
    })

    const [currentTab, setCurrentTab] = useState<string | number>(0)

    return (
      <div className={DetailPanelScss.menuContainer}>
        <Replay fontSize="large" color="action" onClick={onReplay} />
        {onChatBoxToggle && (
          <ChatBoxToggle
            hasRedDot={hasMentions}
            isBoldStyle={hasUnreadComments}
            onClick={onChatBoxToggle}
          />
        )}
        <div>
          <Tabs
            className={DetailPanelScss.tabSelectorContainer}
            value={currentTab}
            onChange={(_event, value) => setCurrentTab(value)}
          >
            <TabsList>{tabs}</TabsList>
          </Tabs>
        </div>
      </div>
    )
  }

  return (
    <KebabMenu
      data={menuData}
      menuItems={menuItems as IKebabMenuItem[]}
      className={DetailPanelScss.cardEdit}
    />
  )
}

const DetailPanel: FC<IDetailPanelProps> = ({
  title,
  titleCaption,
  children,
  menuItems,
  menuData,
  chevronMenu = false,
  tabSelectorMenu = false,
  onChatBoxToggle,
  onReplay,
  hasUnreadComments,
  hasMentions,
  linkIconDisabled,
  isPrefillConfigured,
  prefillSelectorProps
}) => {
  const menuElement = getDetailPanelMenu(
    menuItems,
    menuData,
    chevronMenu,
    tabSelectorMenu,
    onChatBoxToggle,
    onReplay,
    hasUnreadComments,
    hasMentions,
    isPrefillConfigured,
    linkIconDisabled,
    prefillSelectorProps
  )

  return (
    <Card className={DetailPanelScss.card}>
      <div className={DetailPanelScss.cardHeader}>
        {!titleCaption ? (
          <Typography variant="h6">{title}</Typography>
        ) : (
          <Box>
            <Typography variant="caption">{titleCaption}</Typography>
            <Typography variant="h6">{title}</Typography>
          </Box>
        )}
        {menuElement}
      </div>
      <div className={DetailPanelScss.cardContent}>{children}</div>
    </Card>
  )
}

export default DetailPanel
