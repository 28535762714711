import React, { PropsWithChildren } from "react"
import { FormHelperText } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import FormGroup from "@src/components/FormGroup"
import { FormElementPropsType, useDefaultValue } from "../FormContainer"
import { ErrorMessage } from "@hookform/error-message"
import { ChipInput } from "@src/index"
import { ChipInputPropsType } from "@src/components/ChipInput"

export const ChipInputFormElement = <T,>(
  props: PropsWithChildren<FormElementPropsType<T> & ChipInputPropsType>
) => {
  const { name, rules, title, disabled } = props

  const formContext = useFormContext()
  const {
    control,
    formState: { errors }
  } = formContext

  const defaultValue = useDefaultValue(formContext, name as string)

  return (
    <FormGroup title={title}>
      <Controller
        name={name.toString()}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <ChipInput
            {...props}
            onChange={onChange}
            defaultValue={value}
            disabled={disabled}
          />
        )}
      />

      <ErrorMessage
        errors={errors}
        name={name.toString()}
        render={({ message }) => (
          <FormHelperText error={true}>{message}</FormHelperText>
        )}
      />
    </FormGroup>
  )
}

export default ChipInputFormElement
