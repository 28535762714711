import React from 'react';
import { Typography } from '@mui/material';
import { UserInteractionDialog } from '@dqna/seamless-suite-ui-kit';
import { CloseActionType } from '@dqna/seamless-suite-ui-kit/dist/components/UserInteractionDialog';
import { KeywordGroupStatuses } from 'types/keywords.types';
import { ChangeLearningPhaseStatusModalProps } from './type';

export const ChangeLearningPhaseStatusModal: React.FC<ChangeLearningPhaseStatusModalProps> = (
  props
) => {
  const { isOpen, setOpen, pauseKeywordsGroupTestingPhase, group } = props;

  const onClose = (closeAction: CloseActionType) => {
    if (closeAction === 'confirm') {
      pauseKeywordsGroupTestingPhase(group.id);
    }
  };

  return (
    <UserInteractionDialog
      buttonCaptions={{
        cancel: 'Cancel',
        confirm: 'Yes',
      }}
      onClose={onClose}
      setUIDialogState={setOpen}
      title="Heads up"
      uiDialogState={isOpen}>
      <Typography variant="body2" textAlign="center">
        Are you sure about{' '}
        {group.status === KeywordGroupStatuses.TESTING_PHASE ? 'pausing' : 'continuing'} Learning
        phase of the <strong>{group.name}</strong>?
      </Typography>
    </UserInteractionDialog>
  );
};

ChangeLearningPhaseStatusModal.displayName = 'ChangeLearningPhaseStatusModal';
