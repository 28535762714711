import classNames from 'classnames';
import React, { FC } from 'react';

import { ITextProps, TextTypes } from './Text.types';

import './Text.scss';

export const Text: FC<ITextProps> = ({
  type = TextTypes.P,
  italic = false,
  bold = false,
  onClick,
  className,
  children,
  testId,
  id,
}) => {
  if (!children || !type) {
    return null;
  }

  const textClass = classNames(
    'text',
    `text--${type}`,
    {
      'text--bold': bold,
      'text--italic': italic,
    },
    className
  );
  const props = {
    className: textClass,
    'data-testid': testId,
    id,
    onClick,
  };

  const TextTag = type;

  return <TextTag {...props}>{children}</TextTag>;
};
