import React, { FC, useCallback, MouseEvent, memo } from 'react';
import classNames from 'classnames';
import Cross from 'jsx:assets/icons/cross.svg';
import { IBadgeProps } from './types';

import './Badge.scss';

export const Badge: FC<IBadgeProps> = memo(({ children, handleRemove, disabled = false }) => {
  const badgeClasses = classNames('filter__tile', {
    disabled,
  });

  const handleRemoveBadge = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      handleRemove(e);
    },
    [handleRemove]
  );

  return (
    <div className={badgeClasses}>
      {children}
      {!disabled && (
        <Cross className="filter__tile-remove" onClick={handleRemoveBadge} title="Remove filter" />
      )}
    </div>
  );
});

Badge.displayName = 'Badge';
