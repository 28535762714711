import classNames from 'classnames';
import React, { FC, useRef, useCallback } from 'react';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { RenderDDList, RenderDDInput } from './DropdownCommon.component';
import { Button, ButtonTypes } from 'components/ui-kit/Button';
import { ActionItem } from '../ListItem';
import { useFlag } from 'utils/hooks/useFlag';

import { IDropdownActionProps, DropdownActionTypes } from './types';

export const DropdownAction: FC<IDropdownActionProps> = ({
  className,
  options,
  name,
  children,
  type = DropdownActionTypes.List,
  buttonContent,
  search,
}) => {
  const [opened, toggleVisible] = useFlag(false);
  const btnClass = classNames('dropdown__btn', 'dropdown__btn--left', {
    'dropdown__btn--opened': opened,
  });

  const wrapperClass = classNames('dropdown dropdown-action', className);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const onClickOutside = useCallback(() => {
    toggleVisible(false);
  }, []);
  useOnClickOutside(dropdownRef, onClickOutside);

  return (
    <div className={wrapperClass} ref={dropdownRef}>
      <Button
        type={ButtonTypes.Secondary}
        className={btnClass}
        onClick={toggleVisible}
        name={name}>
        <RenderDDInput value={null} name={name}>
          {buttonContent}
        </RenderDDInput>
      </Button>
      <RenderDDList opened={opened} stickyItem={search}>
        {type === DropdownActionTypes.List
          ? options.map(option => <ActionItem key={option.label} {...option} />)
          : children}
      </RenderDDList>
    </div>
  );
};

DropdownAction.displayName = 'DropdownAction';
