import { IItemData } from 'components/ui-kit/Dropdown';
import { RefObject, ReactElement } from 'react';
import { DataSourcesTypes, IAccount } from 'types/accounts.type';

export interface ILinkAccountsModalProps {
  readonly onClose: () => void;
  readonly opened: boolean;
  readonly accounts: IAccount[] | null;
  readonly outerRef?: RefObject<HTMLDivElement>;
  readonly error: Error | null;
  readonly createAccount: (name: string) => void;
  readonly newAccount: { id: number; name: string } | null;
  readonly accessToken?: string;
  readonly authToken?: string;
  readonly verifyAdverityAdWords: (accountId: number) => void;
  readonly verifyAdveritySearchConsole: (accountId: number) => void;
}

export interface ILAAddAccountProps {
  readonly currentAccount: IItemData | null;
  readonly currentNewAccount: string;
  readonly accountsList: IItemData[];
  readonly onChangeAccount: (account: IItemData | null) => void;
  readonly onChangeAccountName: (name: string) => void;
  readonly onCreateAccount: () => void;
  readonly error: Error | null;
}

export interface ITileData {
  img: string;
  disabled: boolean;
  title: AccountTypes;
  type: DataSourcesTypes;
  isVerified?: boolean;
}
export interface ILAAddAccountSourcesProps {
  readonly tileData: ITileData[];
  readonly currentTile: AccountTypes | null;
  readonly currentAccountId?: number;
  readonly onClickTile: (acc: AccountTypes) => void;
  readonly onLinkAccount: () => void;
  readonly verifyAdveritySearchConsole: (id: number) => void;
  readonly verifyAdverityAdWords: (id: number) => void;
}

export enum AccountTypes {
  GoogleSearch = 'Google Search Console*',
  AdWords = 'Google Ads*',
  AdverityGoogleSearch = 'Adverity Search Console*',
  AdverityAdWords = 'Adverity Google Ads*',
  GA = 'Google Analytics',
  SA360 = 'Search Ads 360',
  Adobe = 'Adobe Analytics',
  Offline = 'Offline Conversions',
}

export enum LinkAccountResponseStatus {
  Ok = 'ok',
  Error = 'error',
}

export enum LinkAccountResponseAccount {
  Adwords = 'ADWORDS',
  SearchConsole = 'SEARCH_CONSOLE',
}

export interface ILinkAccountResponse {
  status: LinkAccountResponseStatus;
  'account-type': LinkAccountResponseAccount;
  message: string;
  error?: string;
  error_description?: string;
}
