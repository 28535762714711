import classNames from 'classnames';
import React, { FC, useRef, useCallback, useMemo } from 'react';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { RenderDDList } from './DropdownCommon.component';
import { Button, ButtonTypes } from 'components/ui-kit/Button';
import { useFlag } from 'utils/hooks/useFlag';

import { DropdownTextItem } from '../ListItem';
import { IDropdownTextProps, IItemData, ListPositions } from './types';

import './Dropdown.scss';

export const DropdownIcon: FC<IDropdownTextProps> = ({
  listClass,
  listItemClass,
  className,
  options,
  defaultOpened,
  disabled,
  name,
  onChange,
  value,
  position = ListPositions.Bottom,
  children,
}) => {
  const [opened, toggleVisible] = useFlag(defaultOpened);

  const btnClass = classNames('dropdown__btn', {
    'dropdown__btn--opened': opened,
  });
  const wrapperClass = classNames(
    'dropdown dropdown--without-input',
    className
  );

  const onItemClick = useCallback(
    (data: IItemData) => {
      onChange(data);
      toggleVisible();
    },
    [onChange, toggleVisible]
  );
  const items = useMemo(
    () =>
      options.map(data => {
        return (
          <DropdownTextItem
            key={`${data.value}`}
            className={listItemClass}
            name={name}
            onChange={() => onItemClick(data)}
            value={data.value}
            selected={data.value === value}
            label={data.label}
            dataType={data.dataType}
          />
        );
      }),
    [options]
  );

  const dropdownRef = useRef<HTMLDivElement>(null);
  const onClickOutside = useCallback(() => {
    toggleVisible(false);
  }, []);
  useOnClickOutside(dropdownRef, onClickOutside);

  return (
    <div className={wrapperClass} ref={dropdownRef}>
      <Button
        type={ButtonTypes.Secondary}
        className={btnClass}
        onClick={toggleVisible}
        disabled={disabled}
        name={name}>
        {children}
      </Button>
      <RenderDDList opened={opened} listClass={listClass} position={position}>
        {items}
      </RenderDDList>
    </div>
  );
};

DropdownIcon.displayName = 'DropdownIcon';
