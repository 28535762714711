import React, { memo, ChangeEvent } from 'react';
import classNames from 'classnames';

import { TableElements, TableHeaderProps, TableCellAlign } from './types';

import { Checkbox } from 'components/ui-kit/Input';

import ArrowUp from 'jsx:assets/icons/arrow-up.svg';
import ArrowDown from 'jsx:assets/icons/arrow-down.svg';

export const TableHeader = memo(
  <T extends { id: string | number }>({
    className,
    selectable,
    selectionDisabled,
    data = [],
    onSelectAllRows,
    selected,
    cells = [],
    intermediate,
    expandable,
  }: TableHeaderProps<T>) => {
    if (!cells.length) {
      return null;
    }
    const headerCells = cells.map((cell, index) => {
      const {
        colspan = 1,
        rowspan = 1,
        className,
        renderer,
        onSort,
        sortOrder,
        align = TableCellAlign.Left,
      } = cell;

      const cellData = typeof renderer === 'function' ? renderer(data) : renderer;
      const isBooleanOrder = typeof sortOrder === 'boolean';

      return (
        <th
          key={index}
          colSpan={colspan}
          rowSpan={rowspan}
          onClick={() => {
            onSort?.(data);
          }}
          className={classNames('table__header-cell', {
            'table__header-cell--sortable': onSort,
          })}>
          <div
            className={classNames('table__content', className, {
              'table__content--left': align === TableCellAlign.Left,
              'table__content--right': align === TableCellAlign.Right,
              'table__content--center': align === TableCellAlign.Center,
            })}>
            {cellData}
            {onSort && isBooleanOrder && sortOrder && <ArrowUp className="table__sort-icon" />}
            {onSort && isBooleanOrder && !sortOrder && <ArrowDown className="table__sort-icon" />}
            {onSort && !isBooleanOrder && (
              <ArrowUp className="table__sort-icon table__sort-icon--fake" />
            )}
          </div>
        </th>
      );
    });

    const renderInput = () =>
      selectable ? (
        <th colSpan={1} rowSpan={1} className="table__header-cell table__header-cell--selectable">
          <Checkbox
            name="table header all"
            value={JSON.stringify(data)}
            className="table__cell-checkbox"
            title="Choose all"
            checked={selected}
            intermediate={intermediate}
            disabled={selectionDisabled}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (onSelectAllRows) {
                onSelectAllRows(e.target.checked);
              }
            }}
          />
        </th>
      ) : null;
    return (
      <thead>
        <tr className={classNames('table__header', className)}>
          {renderInput()}
          {headerCells}
          {expandable && <th className="table__cell--expand" />}
        </tr>
      </thead>
    );
  },
  (prevProps, nextProps) =>
    prevProps.selected === nextProps.selected &&
    prevProps.data === nextProps.data &&
    prevProps.cells === nextProps.cells
);

TableHeader.displayName = TableElements.TableHeader;
