import React from 'react';
import {
  Button,
  ChipGroup,
  DetailSection,
  FormContainer,
  Modal,
  ModalActions,
  TextFieldFormElement,
  useFormContainer,
} from '@dqna/seamless-suite-ui-kit';
import { EditDetailsFormData, EditDetailsModalProps } from './types';
import { Stack, Typography } from '@mui/material';

export const EditDetailsModal: React.FC<EditDetailsModalProps> = (props) => {
  const { modalOpenState, setModalOpenState, subAccount, changeSubAccountDetails } = props;
  const formMethods = useFormContainer<EditDetailsFormData>({
    defaultValues: {
      maximumIncrementRoas: subAccount.maximumIncrementRoas,
      maximumCpcBidInTestingPhase: subAccount.maximumCpcBidInTestingPhase,
      maximumCpcBidInLivePhase: subAccount.maximumCpcBidInLivePhase,
      maximumCpcBidChangeInLivePhase: subAccount.maximumCpcBidChangeInLivePhase,
    },
  });

  const onSubmit = (formData: EditDetailsFormData) => {
    changeSubAccountDetails(
      subAccount.account.id,
      subAccount.id,
      formData.maximumCpcBidInTestingPhase,
      formData.maximumCpcBidInLivePhase,
      formData.maximumCpcBidChangeInLivePhase,
      formData.maximumIncrementRoas
    );
    setModalOpenState(false);
  };

  const onCloseModal = () => {
    setModalOpenState(false);
  };

  return (
    <Modal onClose={onCloseModal} open={modalOpenState} title="Edit details">
      <Typography variant="h6" sx={{ marginBottom: '2rem' }}>
        Edit details
      </Typography>
      <FormContainer<EditDetailsFormData> onSubmit={onSubmit} formMethods={formMethods}>
        <DetailSection withDivider>
          <ChipGroup
            label="Brand filters"
            chipDataWithImage={subAccount.bgCampaignsFilters.map((item) => {
              return { textValue: item };
            })}
            className="chip-group"
          />
        </DetailSection>

        <DetailSection
          numberOfColumns={2}
          colWidth="49%"
          sectionCaption="Bid (?) settings"
          withDivider={false}>
          <Stack direction="column">
            <TextFieldFormElement
              inputProps={{
                step: 0.01,
              }}
              name="maximumIncrementRoas"
              title="Incrementality Value"
              type="number"
              rules={{
                required: 'Please enter a valid number',
                min: {
                  value: 0,
                  message: 'Please enter a number above 0',
                },
              }}
            />
            <TextFieldFormElement
              inputProps={{
                step: 0.01,
              }}
              name="maximumCpcBidInTestingPhase"
              title="Max cpc bid in testing phase"
              type="number"
              rules={{
                required: 'Please enter a valid number',
                min: {
                  value: 0,
                  message: 'Please enter a number above 0',
                },
              }}
            />
          </Stack>
          <Stack direction="column">
            <TextFieldFormElement
              inputProps={{
                step: 0.01,
              }}
              name="maximumCpcBidInLivePhase"
              title="Max cpc bid in live phase"
              type="number"
              rules={{
                required: 'Please enter a valid number',
                min: {
                  value: 0,
                  message: 'Please enter a number above 0',
                },
              }}
            />
            <TextFieldFormElement
              inputProps={{
                step: 0.01,
              }}
              name="maximumCpcBidChangeInLivePhase"
              title="Max cpc bid change in live phase"
              type="number"
              rules={{
                required: 'Please enter a valid number',
                min: {
                  value: 0,
                  message: 'Please enter a number above 0',
                },
              }}
            />
          </Stack>
        </DetailSection>
        <ModalActions>
          <Button onClick={onCloseModal} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </ModalActions>
      </FormContainer>
    </Modal>
  );
};

EditDetailsModal.displayName = 'EditDetailsModal';
