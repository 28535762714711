import { Action } from 'store/types';

export const createReducer = <
  A extends string,
  P extends object,
  S extends object
>(
  initialState: S,
  handlers: { [key: string]: (state: S, action: Action<A, P>) => S }
) => (state: S = initialState, action: Action<A, P>) => {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  }
  return state;
};

/*
Example usage


enum ActionTypes {
  Fetch = 'FETCH',
  FetchSuccess = 'FETCH__SUCCESS',
}

interface State {
  isLoading: boolean;
  data: string[] | null;
}
const initialState: State = {
  isLoading: false,
  data: null,
};

const action1 = createAction(ActionTypes.Fetch);
const action2 = createAction(ActionTypes.FetchSuccess, { data: ['1'] });

const reducer = createReducer<ActionTypes, { data: string[] } & {}, State>(
  initialState,
  {
    [ActionTypes.Fetch]: state => ({
      ...state,
      isLoading: true,
    }),
    [ActionTypes.FetchSuccess]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      data: payload?.data || state.data,
    }),
  }
);
*/
