import {
  Autocomplete,
  Typography,
  Box,
  TextField,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
  AutocompleteValue,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Checkbox,
  PaperProps,
  Stack,
  createFilterOptions
} from "@mui/material"
import React, { FC, ReactNode, useEffect, useMemo, useState } from "react"
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import Chip from "@src/components/Chip"
import { CustomSelectType, MultiSelectType, Option } from ".."
import styles from "./MultiSelect.scss"
import PopperComponent from "../PopperComponent"
import PaperComponent from "../PaperComponent"

type MultiSelectPropsType = MultiSelectType & CustomSelectType

const MultiSelect: FC<MultiSelectPropsType> = ({
  options,
  variant,
  placeholder,
  defaultValue,
  defaultImage,
  withCheckBox,
  withKeysAsLabels,
  limitTags,
  onChange,
  renderImage,
  disabled,
  className,
  searchInKeys,
  ariaLabel,
  error,
  includeSelectAllOption,
  selectAllOptionLabel
}) => {
  const [localOptions, setLocalOptions] = useState<Option[]>(
    includeSelectAllOption
      ? [
          { value: selectAllOptionLabel, label: selectAllOptionLabel },
          ...options
        ]
      : options
  )
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [localValue, setLocalValue] = useState<Option[]>(
    defaultValue ? defaultValue : []
  )
  const [localPlaceholder, setLocalPlaceholder] = useState<string>(
    !defaultValue || (defaultValue as Option[]).length === 0 ? placeholder : ""
  )
  const [isSelectAllCurrentlySelected, setIsSelectAllCurrentlySelected] =
    useState(false)

  const cbUncheckedIcon = <CheckBoxOutlineBlank fontSize="medium" />
  const cbCheckedIcon = <CheckBox fontSize="medium" />

  useEffect(() => {
    if (!localValue || localValue.length === 0) setLocalPlaceholder(placeholder)
  }, [localValue])

  useEffect(() => {
    defaultValue ? setLocalValue(defaultValue) : []
  }, [defaultValue])

  useEffect(() => {
    setLocalOptions(
      includeSelectAllOption
        ? [
            { value: selectAllOptionLabel, label: selectAllOptionLabel },
            ...options
          ]
        : options
    )
  }, [options])

  /**
   * This function handles the logic of a multi select dropdown with a "Select All" option.
   * @param value > The selected value(s) from the dropdown options
   * @returns > An object with the new value(s) for the multi select input field and a boolean indicating if the "Select All" option is selected
   */
  const handleFieldWithSelectAll = (value: Option[]) => {
    const selectAllPrevState = isSelectAllCurrentlySelected

    const isAllOtherOptionsSelected =
      (value as Option[]).filter(
        (option) => option.label !== selectAllOptionLabel
      ).length === options.length

    // Find the "Select All" option
    const selectAllOption = localOptions.find(
      (option) => option.label === selectAllOptionLabel
    )

    // Check if the "Select All" option is currently checked
    const isSelectAllSelected = value.some(
      (option) => option.label === selectAllOptionLabel
    )

    if (isSelectAllSelected && !selectAllPrevState) {
      // If the "Select All" option was just selected, select all options including the "Select All" option
      return { newFieldValue: localOptions, isSelectAllSelected }
    } else if (!isSelectAllSelected && selectAllPrevState) {
      // If the "Select All" option was just deselected, deselect all options including the "Select All" option
      return { newFieldValue: [], isSelectAllSelected }
    } else if (!selectAllPrevState && isAllOtherOptionsSelected) {
      // If all other options are selected and the "Select All" option is not yet selected,
      // select the "Select All" option too
      return {
        newFieldValue: [...value, selectAllOption],
        isSelectAllSelected: !isSelectAllSelected
      }
    } else if (selectAllPrevState && !isAllOtherOptionsSelected) {
      // If deselecting an option while everything (including "Select All") was selected, deselect the "Select All" option
      return {
        newFieldValue: value.filter(
          (option) => option.label !== selectAllOptionLabel
        ),
        isSelectAllSelected: false
      }
    } else {
      // If none of the above conditions are met, return the current value
      return { newFieldValue: value, isSelectAllSelected }
    }
  }

  const onInputChange = (
    _event: React.SyntheticEvent,
    value: AutocompleteValue<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    if ((value as Option[]).length === -1) {
      setLocalPlaceholder(placeholder)
    } else {
      setLocalPlaceholder("")
    }

    const clonedOption = [...localOptions]
    const clonedValue: Option[] = [...value]

    if (reason === "createOption") {
      clonedOption.push({
        label: details.option,
        value: (localOptions.length + 1).toString()
      })
      setLocalOptions(clonedOption)
      clonedValue[clonedValue.length - 1] = {
        label: details.option,
        value: clonedOption.length.toString()
      }
      setLocalValue(clonedValue)
      if (onChange) onChange(clonedValue)
    } else if (includeSelectAllOption) {
      /** Handle multi select with "Select all" as an option */
      const { newFieldValue, isSelectAllSelected } =
        handleFieldWithSelectAll(value)

      setIsSelectAllCurrentlySelected(isSelectAllSelected)
      setLocalValue(newFieldValue)
      if (onChange) onChange(newFieldValue)
    } else {
      setLocalValue(value)
      if (onChange) onChange(value)
    }
  }

  const onRenderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
    state: AutocompleteRenderOptionState
  ): ReactNode => {
    const styleOption = (option: Option): JSX.Element => (
      <Box
        className={`${styles.optionText} ${
          withKeysAsLabels
            ? styles.optionTextTwoLiner
            : styles.optionTextOneLiner
        }`}
      >
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography component="span" variant="body1">
            {option.label}
          </Typography>
          {searchInKeys && (
            <Typography component="span" variant="body1" color="#666666">
              {option.value}
            </Typography>
          )}
        </Stack>
        {withKeysAsLabels && (
          <Typography component="span" variant="body2">
            {option.value}
          </Typography>
        )}
      </Box>
    )

    if (withCheckBox)
      return (
        <li {...props}>
          <Checkbox
            icon={cbUncheckedIcon}
            checkedIcon={cbCheckedIcon}
            checked={state.selected}
          />
          {renderImage(option, defaultImage)}
          {styleOption(option)}
        </li>
      )

    return (
      <li {...props} key={option.value}>
        {renderImage(option, defaultImage)}
        {styleOption(option)}
      </li>
    )
  }

  const onRenderTags = (
    value: Option[] = [],
    getTagProps: AutocompleteRenderGetTagProps
  ): ReactNode =>
    value
      .filter((option) => option.label !== selectAllOptionLabel)
      .map((option, index) => {
        const tagProps = getTagProps({ index })
        return (
          <Chip
            key={index}
            label={
              searchInKeys ? `${option.label} - ${option.value}` : option.label
            }
            value={option.value}
            avatar={renderImage(option, defaultImage)}
            aria-label="chip"
            isSmall
            {...tagProps}
            onDelete={!tagProps.disabled ? tagProps.onDelete : null}
          />
        )
      })

  const renderMemoizedPaper = useMemo(() => {
    // eslint-disable-next-line react/display-name
    return (props: PaperProps) => (
      <PaperComponent parentRef={containerRef} {...props} />
    )
  }, [containerRef])

  const filterOptions = createFilterOptions({
    stringify: (option: Option) => option.value + option.label
  })

  return (
    <div ref={setContainerRef}>
      <Autocomplete
        className={`${styles.autoComplete} ${
          localValue.length > 0 ? styles.filled : ""
        } ${styles.multiSelect} ${className}`}
        freeSolo={variant === "creatable"}
        forcePopupIcon={true}
        multiple={true}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value
        }}
        value={localValue}
        limitTags={limitTags}
        options={localOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={localPlaceholder}
            error={error}
            inputProps={{
              ...params.inputProps,
              "aria-label": `multi-select-${ariaLabel}-input`
            }}
            InputProps={{
              ...params.InputProps,
              "aria-label": `multi-select-${ariaLabel}-container`
            }}
          />
        )}
        disableCloseOnSelect={true}
        renderTags={onRenderTags}
        renderOption={onRenderOption}
        onChange={onInputChange}
        disabled={disabled}
        PopperComponent={PopperComponent}
        PaperComponent={renderMemoizedPaper}
        filterOptions={searchInKeys && filterOptions}
      />
    </div>
  )
}

export default MultiSelect
