import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { switchMap, catchError, filter } from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  verifyAdveritySearchConsoleSuccess,
  verifyAdverityAdWordsSuccess,
  verifyAdveritySearchConsoleFail,
  verifyAdverityAdWordsFail,
  verifyAdveritySearchConsole,
  verifyAdverityAdWords,
} from 'store/reducers/accounts.reducer';
import { IAccountsVerification } from 'types/accounts.type';
import { HTTPService } from 'services/HTTP.service';
import { accountsRoutes } from 'constants/routes';
import { NotificationQueue, NotificationType } from 'services/NotificationQueue.service';
import { verifyAdverityError } from 'constants/errors/accounts.errors';
import { Action } from '@reduxjs/toolkit';
import { getOrg } from 'utils/manageOrganisations';

export const verifyAdveritySearchConsoleEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(verifyAdveritySearchConsole.match),
    switchMap(({ payload }) => {
      const { accountId, orgName } = payload;
      const organizationName = orgName ?? getOrg();
      return from(
        http.get<IAccountsVerification>(
          accountsRoutes.verifyAdveritySearchConsole(accountId),
          undefined,
          {
            'X-Organization': organizationName,
          }
        )
      ).pipe(
        switchMap((data) => {
          if (data.is_authorized) {
            notificationQueue.showNotification(
              NotificationType.Toast,
              'Adverity Search console account is successfully verified!'
            );
            return of(verifyAdveritySearchConsoleSuccess(accountId));
          } else {
            notificationQueue.showNotification(
              NotificationType.Toast,
              'Verification of Adverity Search Console datasource may take some time. Try again later!'
            );
            return of(verifyAdveritySearchConsoleFail(verifyAdverityError));
          }
        }),
        catchError((_) => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Failure when verifying Adverity Search Console datasource!'
          );
          return of(verifyAdveritySearchConsoleFail(verifyAdverityError));
        })
      );
    })
  );

export const verifyAdverityAdWordsEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(verifyAdverityAdWords.match),
    switchMap(({ payload }) => {
      const { accountId, orgName } = payload;
      const organizationName = orgName ?? getOrg();
      return from(
        http.get<IAccountsVerification>(
          accountsRoutes.verifyAdverityAdWords(accountId),
          undefined,
          {
            'X-Organization': organizationName,
          }
        )
      ).pipe(
        switchMap((data) => {
          if (data.is_authorized) {
            notificationQueue.showNotification(
              NotificationType.Toast,
              'Account is successfully verified!'
            );
            return of(verifyAdverityAdWordsSuccess());
          } else {
            notificationQueue.showNotification(
              NotificationType.Toast,
              'Verification of Adverity Adwords datasource may take some time. Try again later!'
            );
            return of(verifyAdverityAdWordsFail(verifyAdverityError));
          }
        }),
        catchError((_) => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Failure when verifying Adverity Adwords datasource!'
          );
          return of(verifyAdverityAdWordsFail(verifyAdverityError));
        })
      );
    })
  );
export default combineEpics(verifyAdverityAdWordsEpic, verifyAdveritySearchConsoleEpic);
