import React, {
  FC,
  useState,
  ChangeEvent,
  useCallback,
  useMemo,
  MouseEvent,
  useEffect,
} from 'react';

import { Badge } from '../Badge';
import { Dropdown, DataType } from '../Dropdown';
import { TextInput } from '../Input';

import { FilterItemProps } from './types';
import { filterPatterns, StringValues, NumberValues, OptionsValues } from 'constants/filters';
import Cross from 'jsx:assets/icons/cross.svg';

interface SelectData<T> {
  value: T;
  label: string;
  dataType?: DataType;
}

export const FilterItem: FC<FilterItemProps> = ({
  metrics,
  selectOptions,
  onChangeFilter,
  onRemoveFilter,
  editMode,
  index,
  defaultValues,
  onError,
}) => {
  const [metric, setMetric] = useState('');
  const [pattern, setPattern] = useState<StringValues | NumberValues | OptionsValues>();
  const [matchValue, setMatchValue] = useState('');
  const [dataType, setDataType] = useState<DataType>();
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    if (defaultValues) {
      const { metric, matcher, dataType, value } = defaultValues;
      setMetric(metric);
      setDataType(dataType);
      setPattern(matcher);
      setMatchValue(value);
    }
  }, [defaultValues]);

  const handleSelectMetric = useCallback(
    ({ value, dataType }: SelectData<string>) => {
      setMetric(value);
      setDataType(dataType);
      setPattern(undefined);
      setMatchValue('');
      setValidationError('');
      onError(value, '');
    },
    [onError]
  );

  const handleSelectPattern = useCallback(
    ({ value }: SelectData<StringValues | NumberValues | OptionsValues>) => {
      setPattern(value);
      setMatchValue('');
      setValidationError('');
      onError(metric, '');
    },
    [onError, metric]
  );

  const handleValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;

      const numberError = 'Value should be a number';
      const valuesAmountError = 'There should be only 2 values';

      let error = '';

      if (dataType === DataType.Number) {
        if (pattern === NumberValues.GTLT) {
          const splittedValue = value.replace(/\s/, '').split('|');
          const valuesAreNumbers = splittedValue.every((item) => !isNaN(Number(item)));
          if (splittedValue.length > 2) {
            error = valuesAmountError;
          }
          if (!valuesAreNumbers) {
            error = numberError;
          }
        }
        if (pattern !== NumberValues.GTLT && isNaN(Number(value))) {
          error = numberError;
        }
      }
      setValidationError(error);
      onError(metric, error);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onChangeFilter(index, metric, value, dataType!, pattern!);
      setMatchValue(value);
    },
    [dataType, pattern, metric, index, onError, onChangeFilter]
  );

  const handleSelectValueChange = useCallback(
    ({ value }: SelectData<string>) => {
      const optionsPattern = filterPatterns[DataType.Options][0];
      setPattern(optionsPattern.value);

      const prevValue = matchValue.split('|').filter(Boolean);
      const newValue = prevValue.includes(value)
        ? prevValue.filter((val) => val !== value).join('|')
        : [...prevValue, value].join('|');
      setMatchValue(newValue);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onChangeFilter(index, metric, newValue, dataType!, optionsPattern.value);
    },
    [matchValue, onChangeFilter, index, dataType]
  );

  const handleRemoveTileOptions = useCallback(
    (value: string) => {
      const prevValue = matchValue.split('|').filter(Boolean);
      const newValue = prevValue.filter((val) => val !== value);

      setMatchValue(newValue.join('|'));
    },
    [matchValue]
  );

  const handleRemoveFilter = useCallback(() => {
    onRemoveFilter(index);
  }, [onRemoveFilter, index]);

  const handleRemoveTile = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onRemoveFilter(index, true);
    },
    [onRemoveFilter, index]
  );

  const findMetricLabel = useCallback(() => {
    let appropriateMetricLabel: string = '';
    metrics.forEach((metricItem) => {
      if (metricItem.value === metric) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appropriateMetricLabel = metricItem.label!;
      } else if (metricItem.sublist) {
        metricItem.sublist.forEach((sublistItem) => {
          if (sublistItem.value === metric) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            appropriateMetricLabel = sublistItem.label!;
          }
        });
      }
    });
    return appropriateMetricLabel;
  }, [metric]);

  const metricsList = useMemo(() => {
    const metricLabel = findMetricLabel();
    return (
      <Dropdown.Text
        className="filter__element"
        options={metrics}
        value={metric}
        onChange={handleSelectMetric}
        searchable={true}
        name="filter-metric">
        {metricLabel || 'Attribute or metric'}
      </Dropdown.Text>
    );
  }, [findMetricLabel, metrics, metric, handleSelectMetric]);

  const matcherList = useMemo(() => {
    if (dataType && [DataType.String, DataType.Number].includes(dataType)) {
      const options = filterPatterns[dataType].map((filterPattern) => {
        const { label, value } = filterPattern;
        return { label, value };
      });
      const patternLabel = options.find((option) => option.value === pattern)?.label;
      return (
        <Dropdown.Text
          className="filter__element"
          options={options}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          value={pattern!}
          onChange={handleSelectPattern}
          name="filter-matcher">
          {patternLabel ?? 'Select filter'}
        </Dropdown.Text>
      );
    }

    if (dataType && dataType === DataType.Options && selectOptions) {
      const options = selectOptions[metric];

      if (!options?.length) return null;

      const selectedOptions = matchValue.split('|');
      const selectedOptionsLabel = options
        .filter(({ value }) => selectedOptions.includes(value as string))
        .map((option) => ({ ...option, selected: true }));
      const checkboxOptions = options.map((option) => {
        if (selectedOptions.includes(option.value as string)) {
          return { ...option, selected: true };
        }

        return option;
      });
      return (
        <Dropdown.Multiselect
          className="filter__element"
          options={checkboxOptions}
          value={selectedOptionsLabel}
          onChange={handleSelectValueChange}
          name="filter-value">
          {selectedOptionsLabel.length
            ? selectedOptionsLabel.map(({ label, value }) => (
                <Badge
                  key={value.toString()}
                  handleRemove={() => {
                    handleRemoveTileOptions(value as string);
                  }}>
                  {label}
                </Badge>
              ))
            : 'Select options'}
        </Dropdown.Multiselect>
      );
    }

    return null;
  }, [
    dataType,
    metric,
    pattern,
    matchValue,
    handleSelectPattern,
    handleSelectValueChange,
    handleRemoveTileOptions,
    selectOptions,
  ]);

  const valueInput = useMemo(() => {
    if (dataType && [DataType.String, DataType.Number].includes(dataType) && pattern) {
      const matchingPattern = filterPatterns[dataType].find(
        (filterPattern) => filterPattern.value === pattern
      );

      if (!matchingPattern) return null;

      return (
        <TextInput
          className="filter__element"
          name="filter-value"
          label={matchingPattern.placeholder}
          onChange={handleValueChange}
          value={matchValue}
          validationError={validationError}
        />
      );
    }

    return null;
  }, [dataType, pattern, handleValueChange, matchValue, validationError]);

  const filterTile = useMemo(() => {
    if (matchValue == null || matchValue === '') return null;

    const label = findMetricLabel();
    const patternLabel = dataType
      ? filterPatterns[dataType].find((filterPattern) => filterPattern.value === pattern)?.label
      : '';
    const valueText = matchValue
      .replace(/\s+/gm, ' ')
      .split('|')
      .join(pattern === NumberValues.GTLT ? ' and ' : ' or ');
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const text = `${label} ${patternLabel?.toLowerCase()} ${valueText}`;
    return <Badge handleRemove={handleRemoveTile}>{text}</Badge>;
  }, [matchValue, dataType, pattern, metric, handleRemoveTile]);

  const filterItem = useMemo(
    () => (
      <div className="filter__item-container">
        <div className="filter__item">
          {metricsList}
          {matcherList}
          {valueInput}
        </div>
        <Cross className="filter__remove" title="Remove filter" onClick={handleRemoveFilter} />
      </div>
    ),
    [metric, dataType, pattern, matchValue, handleRemoveFilter]
  );

  return editMode ? filterItem : filterTile;
};

FilterItem.displayName = 'FilterItem';
