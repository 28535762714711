import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { switchMap, catchError, mergeMap, filter } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { Action } from '@reduxjs/toolkit';
import { IAdwordsAccount } from 'types/adwards.types';
import { HTTPService } from 'services/HTTP.service';
import { accountsRoutes } from 'constants/routes';
import { fetchAccountAdwordsError } from 'constants/errors/linkedAccount.errors';
import {
  fetchAdwords,
  fetchAdwordsFail,
  fetchAdwordsSuccess,
} from 'store/reducers/adwords.reducer';

export const getAdwordsEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http }: { http: HTTPService }
) =>
  actions$.pipe(
    filter(fetchAdwords.match),
    mergeMap(({ payload }) => {
      return from(http.get<IAdwordsAccount[]>(accountsRoutes.getAccountAdwords(payload))).pipe(
        switchMap((data) => {
          const filteredAdwords = Array.from(
            new Set<string>(data.map((item) => JSON.stringify(item)))
          ).map((item) => JSON.parse(item));
          return of(fetchAdwordsSuccess({ accountId: payload, adwords: filteredAdwords }));
        }),
        catchError((_) =>
          of(fetchAdwordsFail({ accountId: payload, error: fetchAccountAdwordsError }))
        )
      );
    })
  );

export default combineEpics(getAdwordsEpic);
