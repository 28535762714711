import { DataTable } from '@dqna/seamless-suite-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import { ISubAccount } from 'types/subAccounts.types';
import { SubAccountsTableProps } from './types';
import { generateRouteWithIds } from 'utils/generateRouteWithIds';
import { Routes } from 'types/app.types';
import { useHistory, useLocation } from 'react-router';
import { CreateKeywordGroupModal } from 'components/pages/KeywordManagement/CreateKeywordGroupModal';
import { DeleteSubAccountModal } from '../DeleteSubAccountModal';
import { PauseActivateSubAccountModal } from '../PauseActivateSubAccountModal';
import { Box, Chip, Link } from '@mui/material';
import { PauseCircleOutline } from '@mui/icons-material';

export const SubAccountsDataTable: FC<SubAccountsTableProps> = (props) => {
  const {
    account,
    subAccountList,
    isLoading,
    deleteSubAccount,
    suggestedKeywordsState,
    sendKeywordsToTest,
    keywordGroupCreationStatus,
    getSuggestedKeywords,
    changeSubAccountStatus,
  } = props;
  const { replace } = useHistory();
  const { search } = useLocation();
  const [createKeywordGroupModalOpenState, setCreateKeywordGroupModalOpenState] = useState(false);
  const [deleteSubAccountModalOpenState, setDeleteSubAccountModalOpenState] = useState(false);
  const [pauseActivateSubAccountModalOpenState, setPauseActivateSubAccountModalOpenState] =
    useState(false);
  const [selectedSubAccount, setSelectedSubAccount] = useState<ISubAccount>();
  const [filteredSubAccounts, setFilteredSubAccounts] = useState<ISubAccount[]>([]);

  useEffect(() => {
    if (search) {
      const searchedSubAccounts = subAccountList.filter((subAccount) =>
        subAccount.name.toLowerCase().includes(search.slice(1))
      );
      setFilteredSubAccounts(searchedSubAccounts);
    } else {
      setFilteredSubAccounts(subAccountList);
    }
  }, [search, subAccountList]);

  const handleViewAction = (row: ISubAccount, groupId?: number) => {
    const pathname = generateRouteWithIds({
      route: Routes.KEYWORD_MANAGEMENT_GROUPS,
      ids: {
        accountId: account.id,
        subAccountId: row.id,
        groupId: !groupId ? (row.keywordGroups?.length ? row.keywordGroups[0].id : '') : groupId,
      },
    });
    replace({ pathname });
  };

  const handleViewDetailsAction = (row: ISubAccount) => {
    const pathname = generateRouteWithIds({
      route: Routes.KEYWORD_MANAGEMENT_SUB_ACCOUNT_DETAILS,
      ids: {
        accountId: account.id,
        subAccountId: row.id,
      },
    });
    replace({ pathname });
  };

  const handleAddKWGroupAction = (row: ISubAccount) => {
    setSelectedSubAccount(row);
    setCreateKeywordGroupModalOpenState(true);
  };

  const handleDeleteSubAccountAction = (row: ISubAccount) => {
    setSelectedSubAccount(row);
    setDeleteSubAccountModalOpenState(true);
  };

  const handlePauseOrActivateAction = (row: ISubAccount) => {
    setSelectedSubAccount(row);
    setPauseActivateSubAccountModalOpenState(true);
  };

  return (
    <>
      <DataTable
        columns={[
          {
            field: 'name',
            headerName: 'Sub-Account',
            flex: 1,
            renderCell: ({ row }: { row: ISubAccount }) => {
              return (
                <>
                  <Link
                    component="button"
                    variant="body2"
                    underline="none"
                    color="inherit"
                    sx={{ marginRight: '0.5rem' }}
                    onClick={() => {
                      handleViewDetailsAction(row);
                    }}>
                    {row.name}
                  </Link>
                  {!row.isActive && <PauseCircleOutline />}
                </>
              );
            },
          },
          {
            field: 'keywordGroups',
            headerName: 'Keyword Groups',
            flex: 3,
            renderCell: ({ row }: { row: ISubAccount }) => {
              if (!row.keywordGroups?.length) return 'No keyword groups';
              return (
                <Box sx={{ display: 'block' }}>
                  {row.keywordGroups?.map((group, index) => {
                    return (
                      <Chip
                        key={index}
                        label={group.name}
                        clickable={true}
                        onClick={() => {
                          handleViewAction(row, group.id);
                        }}
                        color="default"
                        variant="filled"
                        sx={{ margin: '0.1rem', fontSize: '1.3rem', lineHeight: '1.8rem' }}
                      />
                    );
                  })}
                </Box>
              );
            },
          },
        ]}
        rows={filteredSubAccounts || []}
        loading={isLoading}
        pageSize={filteredSubAccounts.length}
        autoHeight={true}
        getRowHeight={() => 'auto'}
        sx={{
          '.MuiDataGrid-virtualScrollerContent': {
            paddingBottom: `${filteredSubAccounts.length + 1 * 0.8}rem`,
            boxSizing: 'content-box',
          },
          height: 'auto !important',
        }}
        hideFooter={true}
        rowMenuItems={(row: ISubAccount) => [
          {
            label: 'View',
            value: 'view',
            action: () => {
              handleViewAction(row);
            },
          },
          {
            label: 'Add keyword group',
            value: 'addKWGroup',
            action: () => {
              handleAddKWGroupAction(row);
            },
          },
          {
            label: 'Pause / Run',
            value: 'pauseOrRun',
            action: () => {
              handlePauseOrActivateAction(row);
            },
          },
          {
            label: 'Delete',
            value: 'delete',
            action: () => {
              handleDeleteSubAccountAction(row);
            },
          },
        ]}
      />
      {selectedSubAccount && (
        <>
          <CreateKeywordGroupModal
            modalOpenState={createKeywordGroupModalOpenState}
            setModalOpenState={setCreateKeywordGroupModalOpenState}
            subAccount={selectedSubAccount}
            keywords={suggestedKeywordsState.data}
            sendKeywordsToTest={sendKeywordsToTest}
            keywordGroupCreationStatus={keywordGroupCreationStatus}
            getSuggestedKeywords={getSuggestedKeywords}
            keywordListLoadingState={suggestedKeywordsState.isLoading}
          />

          <DeleteSubAccountModal
            deleteSubAccountModalOpenState={deleteSubAccountModalOpenState}
            setDeleteSubAccountModalOpenState={setDeleteSubAccountModalOpenState}
            selectedSubAccount={selectedSubAccount}
            deleteSubAccount={deleteSubAccount}
          />

          <PauseActivateSubAccountModal
            pauseActivateModalOpenState={pauseActivateSubAccountModalOpenState}
            setPauseActivateModalOpenState={setPauseActivateSubAccountModalOpenState}
            selectedSubAccount={selectedSubAccount}
            changeSubAccountStatus={changeSubAccountStatus}
          />
        </>
      )}
    </>
  );
};
