import React, { FC, useState } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';
import { Button, DataTable, Modal, ModalActions } from '@dqna/seamless-suite-ui-kit';
import { IDataTableModal } from './types';

import './DataTableModal.scss';

export const DataTableModal: FC<IDataTableModal> = ({
  className,
  onClose,
  open,
  fullScreen,
  fullWidth,
  title,
  checkboxSelection,
  disableColumnFilter,
  columns,
  data,
  onCancel,
  onAccept,
  onBack,
  acceptButtonTitle = 'Accept',
  checkboxHeaderWithDropdown,
  pageSize,
  rowsPerPageOptions,
  withRowBorder = false,
  enableFiltering = true,
  enableModalButtons,
  disableButton = false,
  withBackButton = false,
}) => {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridSelectionModel>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const onSelectAllFromThisPage = () => {
    setRowSelectionModel(
      data.map((item) => item.id).slice(currentPage * pageSize, pageSize * (currentPage + 1))
    );
  };

  return (
    <Modal
      onClose={onClose}
      className={`${className} data-table-modal`}
      open={open}
      fullScreen={fullScreen}
      fullWidth={fullWidth}>
      <Typography variant="h6">{title}</Typography>
      <DataTable
        checkboxHeaderDropdownMenuItems={[
          {
            label: `All ${data.length}`,
            value: 'all',
            action: () => {
              setRowSelectionModel(data.map((item) => item.id));
            },
          },
          {
            label: 'All from this page',
            value: 'allFromThisPage',
            action: () => {
              onSelectAllFromThisPage();
            },
          },
          {
            label: 'Deselect all',
            value: 'deselectAll',
            action: () => {
              setRowSelectionModel([]);
            },
          },
        ]}
        onSelectionModelChange={setRowSelectionModel}
        selectionModel={rowSelectionModel}
        checkboxHeaderWithDropdown={checkboxHeaderWithDropdown}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={setCurrentPage}
        pagination
        withRowBorder={withRowBorder}
        rowCount={data.length}
        components={enableFiltering ? { Toolbar: GridToolbar } : {}}
        checkboxSelection={checkboxSelection}
        disableColumnFilter={disableColumnFilter}
        columns={columns}
        rows={data}
        rowsPerPageOptions={rowsPerPageOptions}
        withFilterPanel
      />
      {enableModalButtons && (
        <ModalActions>
          {withBackButton && (
            <Button onClick={onBack} variant="outlined">
              Back
            </Button>
          )}
          <div
            style={{
              flex: '1 0 0',
            }}
          />
          <Button onClick={onCancel} variant="outlined">
            Cancel
          </Button>
          <Button onClick={onAccept} variant="contained" disabled={disableButton}>
            {acceptButtonTitle}
          </Button>
        </ModalActions>
      )}
    </Modal>
  );
};

DataTableModal.displayName = 'DataTableModal';
