import { AbTypes, IAbGroup } from 'types/abtesting.types';
import { ISubAccount } from 'types/subAccounts.types';

export interface IAbTestingPanelProps {
  subAccount: ISubAccount | null;
  newAbTestId: number | null;
  abGroupSubAccountId: number | null;
  abGroup: IAbGroup | null;
  isAbGroupLoading: boolean;
  getAbTest: (accountId: number, subAccountId: number, abGroupId: number) => void;
  changeSubAccountBgFilters: (
    accountId: number,
    subAccountId: number,
    bgCampaignsFilters: string[]
  ) => void;
  previewAbTest: (
    subAccountId: number,
    testName: string,
    keywordPercentage: number,
    selectedGroupOption: AbTypes
  ) => void;
  deleteAbGroup: (accountId: number, abGroupId: number, subAccountId: number) => void;
  activateAbTest: (subAccountId: number, abGroupId: number) => void;
  resetAbTestState: () => void;
}

export interface IAbGroupTablesProps {
  abGroup: IAbGroup | null;
  isAbGroupLoading: boolean;
  subAccount: ISubAccount | null;
  abGroupSubAccountId: number | null;
}

export enum AbKeywordTypes {
  AKwGroup = 'A',
  BKwGroup = 'B',
}
