import React, { FC } from 'react';
import classNames from 'classnames';

import Hexagon from 'jsx:assets/icons/hexagon.svg';

import { ChainLinkProps, ChainLinkName } from './types';

export const ChainLink: FC<ChainLinkProps> = ({ link, index, children, className }) => {
  const content = (
    <>
      {index > 1 && <div className="chain__connector" />}
      <Hexagon className="chain__index" />
      <span className="chain__label">{children}</span>
    </>
  );
  const wrapperClass = classNames('chain__link', className);

  return link ? (
    <a className={wrapperClass} href={link}>
      {content}
    </a>
  ) : (
    <div className={wrapperClass}>{content}</div>
  );
};

ChainLink.displayName = ChainLinkName;
