import React, { FC, useEffect, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { Dropdown, IDdActionItemProps, DropdownActionTypes } from 'components/ui-kit/Dropdown';
import { Button } from 'components/ui-kit/Button';
import { NotificationsContainer } from 'components/containers/Notifications';

import { useAuthProvider } from 'utils/hooks/useAuthProvider';
import { useOrganisation } from 'utils/hooks/useOrganisation';

import HorizontalLogoSvg from 'jsx:assets/SeamlessSearch_horiz.svg';

import LinkedAccountsIcon from 'jsx:assets/icons/linkedAccounts.svg';
import LogoutIcon from 'jsx:assets/icons/logout.svg';
import NewWindow from 'jsx:assets/icons/newWindow.svg';
import ProfileIcon from 'jsx:assets/icons/profile.svg';
import SettingsIcon from 'jsx:assets/icons/settings.svg';
import UsersIcon from 'jsx:assets/icons/users.svg';

import { IHeaderProps } from './types';
import { UserRole } from 'types/user.type';

import './Header.scss';
import 'components/ui-kit/Dropdown/List/Dropdown.scss';
import { Routes, blockedRoutes } from 'types/app.types';

export const Header: FC<IHeaderProps> = ({ currentUser, isRequiredAccountsLinked }) => {
  const { logout, login, isAuthenticated, isAuthServiceReady } = useAuthProvider();
  const { currentOrg, changeOrganisation } = useOrganisation();
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const ldClient = useLDClient();

  window.addEventListener('storage', (event) => {
    if (event.key === 'organization' && event.newValue !== currentOrg) {
      window.location.reload();
    }
  });

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    void ldClient?.identify({
      kind: 'user',
      key: currentUser.user_id,
      email: currentUser.email,
      name: currentUser.name,
    });
  }, [currentUser]);

  useEffect(() => {
    blockedRoutes.map((route) => {
      if (pathname.includes(route)) {
        window.close();
      }
      return route;
    });
  }, [pathname]);

  const settingsOptions: IDdActionItemProps[] = [
    {
      label: 'Linked Accounts',
      link: Routes.LINKED_ACCOUNTS,
      Icon: LinkedAccountsIcon,
      onClick: () => {
        if (pathname === Routes.LINKED_ACCOUNTS) {
          window.location.reload();
        } else {
          replace({
            pathname: Routes.LINKED_ACCOUNTS,
            search: undefined,
          });
        }
        localStorage.setItem('isStorageAddAccountModalOpen', 'true');
      },
    },
  ];

  /*
  Archived profile options
  const profileOptions: IDdActionItemProps[] = useMemo(
    () => [
      { label: 'My Profile', link: '', Icon: ProfileIcon },
      { label: 'Notifications', link: '', Icon: NotificationsIcon },
      { label: 'New Support Request', link: 'javascript:_hdp_newticket();', Icon: NewWindow },
      { label: 'Logout', link: '', Icon: LogoutIcon, onClick: logout },
    ],
    [logout]
  );
  
 //help menu items
  const helpOptions: IDdActionItemProps[] = useMemo(
    () => [
      { label: 'View Tutorial', link: '', Icon: ProfileIcon },
      { label: 'Contact Support', link: 'javascript:_hdp_newticket();', Icon: NewWindow },
      {
        label: 'Privacy', link: '', Icon: NewWindow, onClick: () => {
          replace({
            pathname: '/privacy',
            search: undefined,
          });
        }
      },
      { label: 'About Seamless Search', link: 'https://seamlesspro.io/', Icon: LogoutIcon },
    ],
    [logout]
  );
  */
  const isSwitchingOrgs = useMemo(() => {
    const permittedRoles = [UserRole.Admin, UserRole.Customer, UserRole.Sales];
    return currentUser?.roles
      ? currentUser.roles.some((role) => permittedRoles.includes(role))
      : false;
  }, [currentUser?.roles]);

  const organisations = useMemo(
    () =>
      currentUser?.groups
        ? currentUser.groups.map(({ name }) => ({ value: name, label: name }))
        : [],
    [currentUser?.groups]
  );

  const isInviteExpired = pathname === Routes.INVITE_EXPIRED;

  const isNoninvitedUser = pathname === Routes.NON_INIVTED_USER;

  const shouldDisplayOldHeader = () => {
    if (pathname === Routes.HOME) return true;

    const routesWithOldHeader = [
      Routes.INVITE_EXPIRED,
      Routes.NON_INIVTED_USER,
      Routes.PRIVACY,
      Routes.TERMS_AND_CONDITIONS,
    ];

    let isInRouteList = false;

    routesWithOldHeader.forEach((route) => {
      if (pathname.includes(route)) {
        isInRouteList = true;
      }
    });

    return isInRouteList;
  };

  const handleSupportPopup = (e: { preventDefault: () => void }) => {
    const winWidth = window.innerWidth < 480 ? window.innerWidth : 725;

    window.open(
      'https://forms.monday.com/forms/016f83dbaf6faf8774a3f1795953fac1?r=use1',
      '_hdp_newticket',
      `height=700,width=${winWidth},scrollbars=1`
    );
  };

  if (!shouldDisplayOldHeader()) return <></>;

  return (
    <div className="header-old">
      <Link
        to={isAuthenticated && isRequiredAccountsLinked ? Routes.LINKED_ACCOUNTS : Routes.HOME}
        title="Seamless Logo">
        <HorizontalLogoSvg className="home__logoheader">
          <desc>Seamless Search</desc>
        </HorizontalLogoSvg>
      </Link>

      <div className="header-old__actions">
        {/* <Dropdown.Action
          options={helpOptions}
          name="help"
          buttonContent={
            <>
              <HelpIcon className="header__action-icon" />
              Help
            </>
          }
        /> */}
        {isAuthenticated ? (
          <>
            <NotificationsContainer />
            <Dropdown.Action
              options={settingsOptions}
              name="settings"
              buttonContent={
                <>
                  <SettingsIcon className="header-old__action-icon" />
                  Settings
                </>
              }
            />
            {isSwitchingOrgs && organisations.length > 1 ? (
              <Dropdown.Text
                options={organisations}
                value={currentOrg || null}
                name="Change organisation"
                className="dropdown-action"
                onChange={({ value }) => {
                  changeOrganisation(value as string);
                }}>
                <UsersIcon className="header-old__action-icon" />
                Change group
              </Dropdown.Text>
            ) : null}
            <Dropdown.Action
              options={[]}
              name="profile"
              type={DropdownActionTypes.Custom}
              buttonContent={
                <>
                  {currentUser?.picture ? (
                    <img src={currentUser?.picture} alt="" className="header-old__action-icon" />
                  ) : (
                    <ProfileIcon className="header-old__action-icon" />
                  )}
                  <span>{currentUser?.name}</span>
                </>
              }>
              <li className="dropdown__item dropdown__action-item">
                <a href="#" className="dropdown__item-link" onClick={handleSupportPopup}>
                  <NewWindow className="dropdown__item-icon" />
                  <span className="dropdown__item-data">New Support Request</span>
                </a>
              </li>
              <li className="dropdown__item dropdown__action-item">
                <Link
                  to=""
                  className="dropdown__item-link"
                  onClick={() => {
                    void logout();
                  }}>
                  <LogoutIcon className="dropdown__item-icon" />
                  <span className="dropdown__item-data">Logout</span>
                </Link>
              </li>
            </Dropdown.Action>
          </>
        ) : null}

        {!isNoninvitedUser && !isInviteExpired && isAuthServiceReady && !isAuthenticated ? (
          <Button
            name="Sign in button"
            disabled={!isAuthServiceReady}
            onClick={() => {
              void login();
            }}>
            Sign in
          </Button>
        ) : null}
      </div>
    </div>
  );
};

Header.displayName = 'Header';
