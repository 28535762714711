import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import format from 'date-fns/format';
import {
  ChipGroup,
  CounterGroup,
  DetailPanel,
  DetailSection,
  TextWithTitle,
} from '@dqna/seamless-suite-ui-kit';
import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material';
import { getOrg } from 'utils/manageOrganisations';
import { generateRouteWithIds } from 'utils/generateRouteWithIds';
import { Routes } from 'types/app.types';
import { SubAccountDetailsPanelProps } from './types';
import { IKeywordGroupInfo, KeywordGroupStatuses } from 'types/keywords.types';
import './SubAccountDetailsPanel.scss';
import { EditDetailsModal } from './EditDetailsModal.component';

export const SubAccountDetailsPanel: React.FC<SubAccountDetailsPanelProps> = (props) => {
  const { subAccount, changeSubAccountDetails, subAccountDetailsUpdateLoading } = props;
  const { replace } = useHistory();
  const { accountId, subAccountId } = useParams<{
    accountId: string;
    subAccountId: string;
  }>();
  const [isEditDetailsModalOpen, setEditDetailsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const routeWithIds = generateRouteWithIds({
      route: Routes.KEYWORD_MANAGEMENT_SUB_ACCOUNT_DETAILS,
      ids: { accountId, subAccountId },
    });
    replace({
      pathname: routeWithIds,
    });
  }, []);

  const keywordsReturn = (data: IKeywordGroupInfo[], phase?: KeywordGroupStatuses) => {
    const groups = phase ? data.filter((item) => item.status === phase) : data;
    return groups.reduce((prev, next) => prev + next.numberOfKeywords, 0);
  };

  return (
    <>
      <Backdrop open={subAccountDetailsUpdateLoading} sx={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      {subAccount && (
        <div className="sub-account-details">
          <DetailPanel
            menuItems={[
              {
                label: 'Edit details',
                value: 'edit',
                action: () => {
                  setEditDetailsModalOpen(true);
                },
              },
            ]}
            title="Sub-account details">
            <DetailSection withDivider={false} numberOfColumns={1}>
              <CounterGroup
                counterData={[
                  {
                    counterArg: subAccount?.keywordGroups?.length ?? 0,
                    textSingular: 'Group',
                    textPlural: 'Groups',
                  },
                  {
                    counterArg: keywordsReturn(subAccount.keywordGroups ?? []),
                    textSingular: 'Keyword',
                    textPlural: 'Keywords',
                  },
                  {
                    counterArg: keywordsReturn(
                      subAccount.keywordGroups ?? [],
                      KeywordGroupStatuses.TESTING_PHASE_PAUSED
                    ),
                    textSingular: 'Paused',
                    textPlural: 'Pause',
                  },
                  {
                    counterArg: keywordsReturn(
                      subAccount.keywordGroups ?? [],
                      KeywordGroupStatuses.TESTING_PHASE
                    ),
                    textSingular: 'Learning',
                    textPlural: 'Learning',
                  },
                  {
                    counterArg: keywordsReturn(
                      subAccount.keywordGroups ?? [],
                      KeywordGroupStatuses.LIVE_PHASE
                    ),
                    textSingular: 'Live',
                    textPlural: 'Live',
                  },
                ]}
              />
            </DetailSection>
            <DetailSection
              numberOfColumns={3}
              colWidth="30%"
              sectionCaption="General info"
              withDivider={false}>
              <TextWithTitle text={subAccount.name ?? ''} title="Sub-account" />
              <TextWithTitle text={subAccount.account.name ?? ''} title="Account" />
              <TextWithTitle text={getOrg()} title="Organisation" />
            </DetailSection>
            <DetailSection numberOfColumns={3} colWidth="30%" withDivider={false}>
              <ChipGroup
                label="Country"
                chipDataWithImage={[{ textValue: subAccount.timezone }]}
                className="chip-group"
              />
              <TextWithTitle text={subAccount.currencyCode} title="Currency" />
            </DetailSection>
            <DetailSection withDivider>
              <ChipGroup label="Url" chipDataWithImage={[]} className="chip-group" />
            </DetailSection>
            <DetailSection sectionCaption="Brand filters" withDivider={false}>
              <Typography variant="body2">
                &quot;B&quot;, &quot;BRAND&quot;, and &quot;PB&quot; filters are Pure Brand
                campaigns.
              </Typography>
              <Typography variant="body2">
                &quot;BG&quot; campaigns are Brand Generic campaigns.
              </Typography>
              <Typography variant="body2">
                &quot;BL&quot; campaigns are Brand Location campaigns.
              </Typography>
            </DetailSection>
            <DetailSection withDivider>
              <ChipGroup
                chipDataWithImage={subAccount.bgCampaignsFilters.map((item) => {
                  return { textValue: item };
                })}
                className="chip-group"
              />
            </DetailSection>
            <DetailSection
              numberOfColumns={4}
              colWidth="24%"
              sectionCaption="Bid (?) settings"
              withDivider>
              <TextWithTitle
                text={String(subAccount.maximumIncrementRoas)}
                title="Incrementality value"
              />
              <TextWithTitle
                text={String(subAccount.maximumCpcBidInTestingPhase)}
                title="Max cpc bid in testing phase"
              />
              <TextWithTitle
                text={String(subAccount.maximumCpcBidInLivePhase)}
                title="Max cpc bid in live phase"
              />
              <TextWithTitle
                text={String(subAccount.maximumCpcBidChangeInLivePhase)}
                title="Max cpc bid change in live phase"
              />
            </DetailSection>
            <DetailSection
              numberOfColumns={2}
              colWidth="24%"
              sectionCaption="Details / History"
              withDivider>
              <TextWithTitle text={subAccount.created.user_id ?? ''} title="Created by" />
              <Stack direction="row" spacing={3}>
                <TextWithTitle
                  text={format(new Date(subAccount.created.date), 'MM.dd.yyyy') ?? ''}
                  title="Created on"
                />
                <TextWithTitle
                  text={format(new Date(subAccount.updated.date), 'MM.dd.yyyy') ?? ''}
                  title="Last modidfied"
                />
              </Stack>
            </DetailSection>
          </DetailPanel>

          <EditDetailsModal
            modalOpenState={isEditDetailsModalOpen}
            setModalOpenState={setEditDetailsModalOpen}
            subAccount={subAccount}
            changeSubAccountDetails={changeSubAccountDetails}
          />
        </div>
      )}
    </>
  );
};

SubAccountDetailsPanel.displayName = 'SubAccountDetailsPanel';
