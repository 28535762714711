import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IRootState } from 'store/reducers';
import { Main } from 'components/pages/Main';
import { fetchNotifs } from 'store/actions/notifications.actions';
import {
  checkDataSourceIsLinked,
  checkDataSourceIsVerifiedReset,
  createAccount,
  deleteAccount,
  editAccountName,
  fetchAccounts,
  getAccountsOfAllOrgs,
  newAccountStateReset,
  verifyAdverityAdWords,
  verifyAdveritySearchConsole,
} from 'store/reducers/accounts.reducer';
import { DataSourcesTypes } from 'types/accounts.type';

const mapStateToProps = (state: IRootState) => {
  return {
    accounts: state.accounts.accounts,
    currentUser: state.user.currentUser,
    pageTitle: state.layout.pageTitle,
    accountsByOrg: state.accounts.accountsOfAllOrgs,
    notifications: state.notifications.data,
    accessToken: state.user.accessToken,
    authToken: state.user.authToken,
    createAccountModalProps: {
      newAccount: state.accounts.newAccount,
      linkDataSourcesStatuses: state.accounts.linkDataSourcesStatuses,
      creationError: state.accounts.creationError,
      isDataSourceVerified: state.accounts.isDataSourceVerified,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAccountsOfAllOrgs: () => dispatch(getAccountsOfAllOrgs()),
  getNotifications: () => dispatch(fetchNotifs()),
  createAccountModalActions: {
    checkDataSourceIsLinked: (
      dataSourceType: DataSourcesTypes,
      id: number,
      currentTry: number,
      orgName?: string
    ) => dispatch(checkDataSourceIsLinked({ dataSourceType, id, currentTry, orgName })),
    createAccount: (name: string, orgName?: string) => dispatch(createAccount({ name, orgName })),
    deleteAccount: (id: number, orgName?: string) => dispatch(deleteAccount({ id, orgName })),
    checkDataSourceIsVerifiedReset: () => dispatch(checkDataSourceIsVerifiedReset()),
    editAccountName: (id: number, name: string, orgName?: string) =>
      dispatch(editAccountName({ id, name, orgName })),
    verifyAdveritySearchConsole: (accountId: number, orgName?: string) =>
      dispatch(verifyAdveritySearchConsole({ accountId, orgName })),
    verifyAdverityAdWords: (accountId: number, orgName?: string) =>
      dispatch(verifyAdverityAdWords({ accountId, orgName })),
    getAccounts: () => dispatch(fetchAccounts()),
    newAccountStateReset: () => dispatch(newAccountStateReset()),
  },
});

export type MainContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const MainContainer = connect(mapStateToProps, mapDispatchToProps)(Main);
