import React, { ChangeEvent, useState, useMemo, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { INotificationsProps } from './types';

interface NewNotificationsSearchProps {
  notifications: INotificationsProps['notifications'];
  onSearch: (filteredNotifs: INotificationsProps['notifications']) => void;
}

export const NewNotificationsSearch: React.FC<NewNotificationsSearchProps> = ({
  notifications,
  onSearch,
}) => {
  const [searchNotifs, setSearchNotifs] = useState('');

  const handleSearchNotifs = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target?.value;
      setSearchNotifs(value);

      const valueRegex = new RegExp(value, 'ig');
      const filteredData =
        notifications?.filter(
          (option) => valueRegex.test(option.subject) || valueRegex.test(option.body)
        ) ?? null;

      onSearch(filteredData);
    },
    [notifications, onSearch]
  );

  const notificationSearchBar = useMemo(() => {
    return (
      <TextField
        onChange={handleSearchNotifs}
        variant="standard"
        placeholder="Search in notifications"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ width: '2.4rem', height: '2.4rem' }} />
            </InputAdornment>
          ),
          disableUnderline: true,
          sx: {
            fontSize: '1.3rem',
          },
        }}
        className="notifications-search-bar"
      />
    );
  }, [searchNotifs, handleSearchNotifs]);

  return notificationSearchBar;
};
