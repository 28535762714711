import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IKeywordsDetailsPageState,
  IKeywordNewType,
  RemoveKeywordsParams,
  IKeyword,
} from 'types/keywords.types';

export type { IKeywordsDetailsPageState } from 'types/keywords.types';

export const keywordsDetailsPageInitialState: IKeywordsDetailsPageState = {
  subAccountId: null,
  keywordsPerSubaccount: {
    isLoading: false,
    data: [],
    error: null,
  },
  keywordsPerKeywordGroup: {
    isLoading: false,
    data: [],
    error: null,
    groupId: null,
  },
  suggestedKeywords: {
    isLoading: false,
    data: [],
    error: null,
    lastIngested: '',
  },
  keywordsOfSubaccountInKeywordGroups: { isLoading: false, data: [], error: null },
  keywordGroupCreationStatus: {
    isLoading: false,
    error: null,
    numberOfAddedKeywords: 0,
  },
};

const keywordsDetailsPageSlice = createSlice({
  name: 'keywordsDetailsPage',
  initialState: keywordsDetailsPageInitialState,
  reducers: {
    getKeywordsOfSubaccount: (
      state,
      action: PayloadAction<{ subAccountId: number; date: string }>
    ) => {
      state.subAccountId = action.payload.subAccountId;
      state.keywordsPerSubaccount.isLoading = true;
      state.keywordsPerSubaccount.error = null;
    },
    getKeywordsOfSubaccountSuccess: (state, action: PayloadAction<IKeyword[] | null>) => {
      state.keywordsPerSubaccount.data = action.payload ?? [];
      state.keywordsPerSubaccount.isLoading = false;
    },
    getKeywordsOfSubaccountFail: (state, action: PayloadAction<Error>) => {
      state.keywordsPerSubaccount.error = action.payload;
      state.keywordsPerSubaccount.isLoading = false;
    },
    getKeywordsOfKeywordGroup: (
      state,
      action: PayloadAction<{ subAccountId: number; groupId: number; date: string }>
    ) => {
      state.subAccountId = action.payload.subAccountId;
      state.keywordsPerKeywordGroup.groupId = action.payload.groupId;
      state.keywordsPerKeywordGroup.isLoading = true;
      state.keywordsPerKeywordGroup.error = null;
    },
    getKeywordsOfKeywordGroupSuccess: (state, action: PayloadAction<IKeyword[] | null>) => {
      state.keywordsPerKeywordGroup.data = action.payload ?? [];
      state.keywordsPerKeywordGroup.isLoading = false;
    },
    getKeywordsOfKeywordGroupFail: (state, action: PayloadAction<Error>) => {
      state.keywordsPerKeywordGroup.error = action.payload;
      state.keywordsPerKeywordGroup.isLoading = false;
    },
    getSuggestedKeywords: (state, action: PayloadAction<number>) => {
      state.subAccountId = action.payload;
      state.suggestedKeywords.isLoading = true;
      state.suggestedKeywords.error = null;
    },
    getSuggestedKeywordsSuccess: (
      state,
      action: PayloadAction<{
        suggestedKeywords: IKeywordNewType[];
        lastIngested: string;
      }>
    ) => {
      const { suggestedKeywords, lastIngested } = action.payload;
      state.suggestedKeywords.isLoading = false;
      state.suggestedKeywords.data = suggestedKeywords ?? [];
      state.suggestedKeywords.lastIngested = lastIngested ?? '';
    },
    getSuggestedKeywordsFail: (state, action: PayloadAction<Error>) => {
      state.suggestedKeywords.isLoading = false;
      state.suggestedKeywords.error = action.payload;
    },
    removeKeywords: (state, action: PayloadAction<RemoveKeywordsParams>) => {
      const { subAccountId } = action.payload;
      state.subAccountId = subAccountId;
      state.keywordsPerSubaccount.isLoading = true;
      state.keywordsPerSubaccount.error = null;
    },
    removeKeywordsSuccess: (state, action: PayloadAction<IKeyword[]>) => {
      state.keywordsPerSubaccount.isLoading = false;
      state.keywordsPerSubaccount.data = action.payload;
    },
    removeKeywordsFail: (state, action: PayloadAction<Error>) => {
      state.keywordsPerSubaccount.isLoading = false;
      state.keywordsPerSubaccount.error = action.payload;
    },
    getKeywordsOfSubaccountInKeywordGroups: (state, action: PayloadAction<number>) => {
      state.subAccountId = action.payload;
      state.keywordsOfSubaccountInKeywordGroups.isLoading = true;
      state.keywordsOfSubaccountInKeywordGroups.error = null;
    },
    getKeywordsOfSubaccountInKeywordGroupsSuccess: (
      state,
      action: PayloadAction<{ keywordsInGroups: IKeywordNewType[] }>
    ) => {
      state.keywordsOfSubaccountInKeywordGroups.isLoading = false;
      state.keywordsOfSubaccountInKeywordGroups.data = action.payload.keywordsInGroups ?? [];
    },
    getKeywordsOfSubaccountInKeywordGroupsFail: (state, action: PayloadAction<Error>) => {
      state.keywordsOfSubaccountInKeywordGroups.isLoading = false;
      state.keywordsOfSubaccountInKeywordGroups.error = action.payload;
    },
    setKeywordGroupCreationStatus: (state) => {
      state.keywordGroupCreationStatus.isLoading = true;
      state.keywordGroupCreationStatus.error = null;
    },
    setKeywordGroupCreationStatusSuccess: (state, action: PayloadAction<number>) => {
      state.keywordGroupCreationStatus.isLoading = false;
      state.keywordGroupCreationStatus.numberOfAddedKeywords = action.payload ?? 0;
    },
    setKeywordGroupCreationStatusFail: (state, action: PayloadAction<Error>) => {
      state.keywordGroupCreationStatus.isLoading = false;
      state.keywordGroupCreationStatus.error = action.payload;
    },
  },
});

export const {
  getKeywordsOfKeywordGroup,
  getKeywordsOfKeywordGroupFail,
  getKeywordsOfKeywordGroupSuccess,
  getKeywordsOfSubaccount,
  getKeywordsOfSubaccountFail,
  getKeywordsOfSubaccountInKeywordGroups,
  getKeywordsOfSubaccountInKeywordGroupsFail,
  getKeywordsOfSubaccountInKeywordGroupsSuccess,
  getKeywordsOfSubaccountSuccess,
  getSuggestedKeywords,
  getSuggestedKeywordsFail,
  getSuggestedKeywordsSuccess,
  setKeywordGroupCreationStatus,
  setKeywordGroupCreationStatusFail,
  setKeywordGroupCreationStatusSuccess,
  removeKeywords,
  removeKeywordsFail,
  removeKeywordsSuccess,
} = keywordsDetailsPageSlice.actions;
export default keywordsDetailsPageSlice.reducer;
