import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { CircularProgress } from '@mui/material';
import { MasterDetailPanelContainer } from '@dqna/seamless-suite-ui-kit';
import { IKeywordNewType, SuggestedKeywordStatus } from 'types/keywords.types';
import { SuggestedKeywordsPanelProps } from './types';
import { SuggestedKeywordsDetails, SuggestedKeywordsStatusTable } from '.';
import { generateRouteWithIds } from 'utils/generateRouteWithIds';
import { Routes } from 'types/app.types';
import './SuggestedKeywords.scss';
import { NoDataPlaceholder } from 'components/ui-kit/NoDataPlaceholder';

export const SuggestedKeywordsPanel: React.FC<SuggestedKeywordsPanelProps> = (props) => {
  const {
    suggestedKeywordsState,
    subAccount,
    date,
    sendKeywordsToTest,
    keywordGroupCreationStatus,
    getSuggestedKeywords,
  } = props;
  const { replace } = useHistory();
  const { accountId, subAccountId } = useParams<{
    accountId: string;
    subAccountId: string;
  }>();
  const [selectedStatus, setSelectedStatus] = useState<SuggestedKeywordStatus>(
    SuggestedKeywordStatus.ACTIVE
  );
  const [keywordsByStatus, setKeywordsByStatus] =
    useState<Record<SuggestedKeywordStatus, IKeywordNewType[]>>();
  const [isInitialDataLoading, setInitialDataLoading] = useState(true);

  useEffect(() => {
    const routeWithIds = generateRouteWithIds({
      route: Routes.KEYWORD_MANAGEMENT_SUGGESTED_KEYWORDS,
      ids: { accountId, subAccountId },
    });
    replace({
      pathname: routeWithIds,
    });
  }, []);

  useEffect(() => {
    if (!suggestedKeywordsState.isLoading && keywordsByStatus) {
      setInitialDataLoading(false);
    }
  }, [suggestedKeywordsState.isLoading, keywordsByStatus]);

  useEffect(() => {
    const keywordsGroupedByStatus: Record<SuggestedKeywordStatus, IKeywordNewType[]> = {
      [SuggestedKeywordStatus.ACTIVE]: [],
      [SuggestedKeywordStatus.PAUSED]: [],
      [SuggestedKeywordStatus.NEW_KEYWORDS]: [],
    };
    suggestedKeywordsState.data.forEach((keyword) => {
      if (!keyword.status) return;
      keywordsGroupedByStatus[keyword.status] = [
        ...keywordsGroupedByStatus[keyword.status],
        keyword,
      ];
    });

    setKeywordsByStatus(keywordsGroupedByStatus);
  }, [suggestedKeywordsState.data]);

  if (isInitialDataLoading || !keywordsByStatus) {
    return <CircularProgress />;
  }

  if (!suggestedKeywordsState.data.length && !suggestedKeywordsState.isLoading) {
    return (
      <NoDataPlaceholder
        message="You have successfully added all eligible keywords to Seamless!Please check back here in future to see if any new search terms start seeing significant Organic traffic and are worth managing with Seamless."
        title="There are no Suggested keywords"
      />
    );
  }

  return (
    <MasterDetailPanelContainer leftPanelSize={30}>
      <SuggestedKeywordsStatusTable
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
      <SuggestedKeywordsDetails
        selectedStatus={selectedStatus}
        lastIngested={suggestedKeywordsState.lastIngested}
        keywordsOfCurrentStatus={keywordsByStatus[selectedStatus]}
        subAccount={subAccount}
        date={date}
        sendKeywordsToTest={sendKeywordsToTest}
        keywordGroupCreationStatus={keywordGroupCreationStatus}
        getSuggestedKeywords={getSuggestedKeywords}
        keywordListLoadingState={suggestedKeywordsState.isLoading}
      />
    </MasterDetailPanelContainer>
  );
};
