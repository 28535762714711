export enum DataSourcesTypes {
  GOOGLE_ADWORDS = 'ADWORDS',
  GOOGLE_SEARCH_CONSOLE = 'SEARCH_CONSOLE',
  ADVERITY_SEARCH_CONSOLE = 'ADVERITY_SEARCH_CONSOLE',
  ADVERITY_ADWORDS = 'ADVERITY_ADWORDS',
}

export enum DataSourcesStatus {
  LOADING = 'LOADING',
  LINKED = 'LINKED',
  ERROR = 'ERROR',
}

export interface IDataSources {
  name: string;
  type: DataSourcesTypes;
  isRequired: boolean;
  isVerified?: boolean;
}

export interface IAccount {
  id: number;
  name: string;
  data_sources: IDataSources[];
}

export interface LinkDataSourceStatus {
  status: DataSourcesStatus;
  currentTry: number;
}

export interface IAccountsState {
  isLoading: boolean;
  isGetAccountsOfAllOrgsLoading: boolean;
  error: Error | null;
  creationError: Error | null;
  accounts: IAccount[] | null;
  newAccount: { id: number; name: string } | null;
  isDataSourceVerified: boolean;
  linkDataSourcesStatuses: Record<DataSourcesTypes, LinkDataSourceStatus | null>;
  accountsOfAllOrgs: Record<string, IAccount[]> | null;
  urlsByAccount: Record<string, string[]>;
}

export interface IAccountsVerification {
  id: number;
  is_authorized: boolean;
  accountType: string;
}
