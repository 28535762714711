import { Box } from "@mui/material"
import React, { FC, ReactNode } from "react"
import styles from "./MasterDetailPanelContainer.scss"

export interface MasterDetailPanelContainerType {
  children: JSX.Element[] | ReactNode[]
  className?: string
  leftPanelSize?: number
}

const MasterDetailPanelContainer: FC<MasterDetailPanelContainerType> = ({
  children,
  className,
  leftPanelSize = 50
}) => {
  const rightPanelSize = 100 - leftPanelSize
  return (
    <div className={`${styles.panelContainer} ${className}`}>
      {children.map((child, index) => (
        <Box
          key={index}
          sx={{
            width: `${index === 0 ? leftPanelSize : rightPanelSize}%`,
            margin: "0 0.2rem",
            ":only-child": {
              width: `calc(${rightPanelSize} - .8rem)`
            }
          }}
        >
          {child}
        </Box>
      ))}
    </div>
  )
}

export default MasterDetailPanelContainer
