import classNames from 'classnames';
import React, {
  FC,
  KeyboardEvent,
  ChangeEvent,
  useState,
  createRef,
  useLayoutEffect,
  useCallback,
  useMemo,
} from 'react';

import { Text } from 'components/ui-kit/Text';
import { getId } from 'utils/getId';

import { ITextAreaProps, inputDisplayNames } from './types';
import { KeyCodes } from '../../../constants';

import './Input.scss';

export const Textarea: FC<ITextAreaProps> = ({
  disabled,
  onChange,
  value,
  defaultValue,
  name,
  testId,
  onEnter,
  label,
  maxLength,
  minRows = 3,
  maxRows,
  validationError,
  tip,
  autoFocus,
}) => {
  const id = useMemo(() => getId(), []);

  const textarea = createRef<HTMLTextAreaElement>();

  const [rows, setRows] = useState(minRows);
  const [scrollHeight, setScrollHeight] = useState(0);

  useLayoutEffect(() => {
    if (textarea && textarea.current && !scrollHeight) {
      const { scrollHeight: height } = textarea.current;
      setScrollHeight(height);
    }
  }, [textarea, scrollHeight, textarea.current]);

  const onEnterPress = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      e.keyCode === KeyCodes.Enter && onEnter?.(e.currentTarget);
    },
    [onEnter]
  );

  const handleChangeRowsSize = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (maxRows) {
        const { scrollHeight: height } = e.currentTarget;
        setScrollHeight(height);
        if (height > scrollHeight && rows < maxRows) {
          setRows(rows + 1);
        }
      }
    },
    [scrollHeight, rows, maxRows]
  );
  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      handleChangeRowsSize(e);
      onChange?.(e);
    },
    [onChange, handleChangeRowsSize]
  );

  const wrapperClass = classNames('input-textarea', {
    'input-textarea--error': !!validationError,
  });
  return (
    <div className={wrapperClass}>
      <div className="input-textarea__wrapper">
        <textarea
          id={id}
          disabled={disabled}
          onChange={onChangeInput}
          defaultValue={defaultValue}
          value={value}
          onKeyDown={onEnterPress}
          data-testid={testId}
          className="input-textarea__field"
          name={name}
          placeholder={label}
          title={label}
          maxLength={maxLength}
          rows={rows}
          ref={textarea}
          autoFocus={autoFocus}
        />
        <label htmlFor={id} className="input-textarea__label">
          {label}
        </label>
      </div>
      {tip && <div className="input-textarea__tip">{tip}</div>}
      {validationError && (
        <Text className="input-textarea__error-text">{validationError}</Text>
      )}
    </div>
  );
};

Textarea.displayName = inputDisplayNames.textarea;
