import React from 'react';

import { AppLayoutTitleSection, PageTitle, UserDropdown } from '@dqna/seamless-suite-ui-kit';
import { useAuthProvider } from 'utils/hooks/useAuthProvider';
import { Stack } from '@mui/material';
import { TitledHeaderProps } from './types';
import './AppLayout.scss';
import { AdminDropDown } from './AdminDropDown.component';
import { UserRole } from 'types/user.type';
import { useHistory } from 'react-router';
import { useOrganisation } from 'utils/hooks/useOrganisation';
import { NewNotifications } from '../Header/NewNotifications.component';
import { setOrg } from 'utils/manageOrganisations';
import { Routes } from 'types/app.types';

const ORGANIZATION_ID_SEPARATOR = '|';

export const TitledHeader = (props: TitledHeaderProps) => {
  const {
    currentUser,
    pageTitle,
    accountsByOrg,
    setAddAccountModalOpenState,
    getNotifications,
    notifications,
    setLinkAccountOrganisationName,
  } = props;
  const { replace } = useHistory();
  const { logout } = useAuthProvider();
  const { currentOrg } = useOrganisation();

  const formattedOrgs = accountsByOrg
    ? Object.keys(accountsByOrg).map((orgName) => ({
        orgName,
        managedCompanies: accountsByOrg[orgName].map((account) => ({
          name: account.name,
          id: `${orgName}${ORGANIZATION_ID_SEPARATOR}${String(account.id)}`,
        })),
      }))
    : [];

  const handleRedirect = (selectedOrg: string) => {
    replace({ pathname: Routes.LINKED_ACCOUNTS });

    if (selectedOrg && selectedOrg !== currentOrg) {
      setOrg(selectedOrg);
      window.location.reload();
    }
  };

  const handleAccountPage = (accountIdentifier: string) => {
    const [selectedOrg, accountId] = accountIdentifier.split(ORGANIZATION_ID_SEPARATOR);
    handleRedirect(selectedOrg);

    const selectedAccountSection = document.getElementById(accountId);

    selectedAccountSection?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSupportLinkClick = () => {
    const winWidth = window.innerWidth < 480 ? window.innerWidth : 725;

    window.open(
      'https://forms.monday.com/forms/016f83dbaf6faf8774a3f1795953fac1?r=use1',
      '_hdp_newticket',
      `height=700,width=${winWidth},scrollbars=1`
    );
  };

  return (
    <div className="header">
      <AppLayoutTitleSection>
        <PageTitle
          onLinkClick={(url: string) => {
            replace({ pathname: url });
          }}
          {...pageTitle}
        />
      </AppLayoutTitleSection>

      <Stack flexDirection="row">
        <NewNotifications
          notifications={notifications}
          currentUser={currentUser}
          getNotifications={getNotifications}
        />
        {currentUser?.roles.find((role) => role === UserRole.Admin) && <AdminDropDown />}
        <UserDropdown
          isUsingAdminService
          onOrgNameClick={(orgName: string) => {
            handleRedirect(orgName);
          }}
          userEmail={currentUser?.email ?? ''}
          userFullName={currentUser?.name ?? ''}
          userImage={currentUser?.picture}
          onLogout={() => {
            void logout();
          }}
          managedCompaniesByOrg={formattedOrgs}
          onChange={handleAccountPage}
          onSupport={handleSupportLinkClick}
          onAddButtonClick={(orgName) => {
            setAddAccountModalOpenState(true);
            setLinkAccountOrganisationName(orgName);
          }}
        />
      </Stack>
    </div>
  );
};
