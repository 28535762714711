[
  { "fullCountryName": "Afghanistan", "alpha2Code": "AF", "alpha3Code": "AFG" },
  { "fullCountryName": "Albania", "alpha2Code": "AL", "alpha3Code": "ALB" },
  { "fullCountryName": "Algeria", "alpha2Code": "DZ", "alpha3Code": "DZA" },
  {
    "fullCountryName": "American Samoa",
    "alpha2Code": "AS",
    "alpha3Code": "ASM"
  },
  { "fullCountryName": "Andorra", "alpha2Code": "AD", "alpha3Code": "AND" },
  { "fullCountryName": "Angola", "alpha2Code": "AO", "alpha3Code": "AGO" },
  { "fullCountryName": "Anguilla", "alpha2Code": "AI", "alpha3Code": "AIA" },
  { "fullCountryName": "Antarctica", "alpha2Code": "AQ", "alpha3Code": "ATA" },
  {
    "fullCountryName": "Antigua and Barbuda",
    "alpha2Code": "AG",
    "alpha3Code": "ATG"
  },
  { "fullCountryName": "Argentina", "alpha2Code": "AR", "alpha3Code": "ARG" },
  { "fullCountryName": "Armenia", "alpha2Code": "AM", "alpha3Code": "ARM" },
  { "fullCountryName": "Aruba", "alpha2Code": "AW", "alpha3Code": "ABW" },
  { "fullCountryName": "Australia", "alpha2Code": "AU", "alpha3Code": "AUS" },
  { "fullCountryName": "Austria", "alpha2Code": "AT", "alpha3Code": "AUT" },
  { "fullCountryName": "Azerbaijan", "alpha2Code": "AZ", "alpha3Code": "AZE" },
  { "fullCountryName": "Bahamas ", "alpha2Code": "BS", "alpha3Code": "BHS" },
  { "fullCountryName": "Bahrain", "alpha2Code": "BH", "alpha3Code": "BHR" },
  { "fullCountryName": "Bangladesh", "alpha2Code": "BD", "alpha3Code": "BGD" },
  { "fullCountryName": "Barbados", "alpha2Code": "BB", "alpha3Code": "BRB" },
  { "fullCountryName": "Belarus", "alpha2Code": "BY", "alpha3Code": "BLR" },
  { "fullCountryName": "Belgium", "alpha2Code": "BE", "alpha3Code": "BEL" },
  { "fullCountryName": "Belize", "alpha2Code": "BZ", "alpha3Code": "BLZ" },
  { "fullCountryName": "Benin", "alpha2Code": "BJ", "alpha3Code": "BEN" },
  { "fullCountryName": "Bermuda", "alpha2Code": "BM", "alpha3Code": "BMU" },
  { "fullCountryName": "Bhutan", "alpha2Code": "BT", "alpha3Code": "BTN" },
  {
    "fullCountryName": "Bolivia (Plurinational State of)",
    "alpha2Code": "BO",
    "alpha3Code": "BOL"
  },
  {
    "fullCountryName": "Bonaire, Sint Eustatius and Saba",
    "alpha2Code": "BQ",
    "alpha3Code": "BES"
  },
  {
    "fullCountryName": "Bosnia and Herzegovina",
    "alpha2Code": "BA",
    "alpha3Code": "BIH"
  },
  { "fullCountryName": "Botswana", "alpha2Code": "BW", "alpha3Code": "BWA" },
  {
    "fullCountryName": "Bouvet Island",
    "alpha2Code": "BV",
    "alpha3Code": "BVT"
  },
  { "fullCountryName": "Brazil", "alpha2Code": "BR", "alpha3Code": "BRA" },
  {
    "fullCountryName": "British Indian Ocean Territory ",
    "alpha2Code": "IO",
    "alpha3Code": "IOT"
  },
  {
    "fullCountryName": "Brunei Darussalam",
    "alpha2Code": "BN",
    "alpha3Code": "BRN"
  },
  { "fullCountryName": "Bulgaria", "alpha2Code": "BG", "alpha3Code": "BGR" },
  {
    "fullCountryName": "Burkina Faso",
    "alpha2Code": "BF",
    "alpha3Code": "BFA"
  },
  { "fullCountryName": "Burundi", "alpha2Code": "BI", "alpha3Code": "BDI" },
  { "fullCountryName": "Cabo Verde", "alpha2Code": "CV", "alpha3Code": "CPV" },
  { "fullCountryName": "Cambodia", "alpha2Code": "KH", "alpha3Code": "KHM" },
  { "fullCountryName": "Cameroon", "alpha2Code": "CM", "alpha3Code": "CMR" },
  { "fullCountryName": "Canada", "alpha2Code": "CA", "alpha3Code": "CAN" },
  {
    "fullCountryName": "Cayman Islands ",
    "alpha2Code": "KY",
    "alpha3Code": "CYM"
  },
  {
    "fullCountryName": "Central African Republic ",
    "alpha2Code": "CF",
    "alpha3Code": "CAF"
  },
  { "fullCountryName": "Chad", "alpha2Code": "TD", "alpha3Code": "TCD" },
  { "fullCountryName": "Chile", "alpha2Code": "CL", "alpha3Code": "CHL" },
  { "fullCountryName": "China", "alpha2Code": "CN", "alpha3Code": "CHN" },
  {
    "fullCountryName": "Christmas Island",
    "alpha2Code": "CX",
    "alpha3Code": "CXR"
  },
  {
    "fullCountryName": "Cocos (Keeling) Islands ",
    "alpha2Code": "CC",
    "alpha3Code": "CCK"
  },
  { "fullCountryName": "Colombia", "alpha2Code": "CO", "alpha3Code": "COL" },
  { "fullCountryName": "Comoros ", "alpha2Code": "KM", "alpha3Code": "COM" },
  {
    "fullCountryName": "Congo (the Democratic Republic of the)",
    "alpha2Code": "CD",
    "alpha3Code": "COD"
  },
  { "fullCountryName": "Congo ", "alpha2Code": "CG", "alpha3Code": "COG" },
  {
    "fullCountryName": "Cook Islands ",
    "alpha2Code": "CK",
    "alpha3Code": "COK"
  },
  { "fullCountryName": "Costa Rica", "alpha2Code": "CR", "alpha3Code": "CRI" },
  { "fullCountryName": "Croatia", "alpha2Code": "HR", "alpha3Code": "HRV" },
  { "fullCountryName": "Cuba", "alpha2Code": "CU", "alpha3Code": "CUB" },
  { "fullCountryName": "Curaçao", "alpha2Code": "CW", "alpha3Code": "CUW" },
  { "fullCountryName": "Cyprus", "alpha2Code": "CY", "alpha3Code": "CYP" },
  { "fullCountryName": "Czechia", "alpha2Code": "CZ", "alpha3Code": "CZE" },
  {
    "fullCountryName": "Côte d'Ivoire",
    "alpha2Code": "CI",
    "alpha3Code": "CIV"
  },
  { "fullCountryName": "Denmark", "alpha2Code": "DK", "alpha3Code": "DNK" },
  { "fullCountryName": "Djibouti", "alpha2Code": "DJ", "alpha3Code": "DJI" },
  { "fullCountryName": "Dominica", "alpha2Code": "DM", "alpha3Code": "DMA" },
  {
    "fullCountryName": "Dominican Republic ",
    "alpha2Code": "DO",
    "alpha3Code": "DOM"
  },
  { "fullCountryName": "Ecuador", "alpha2Code": "EC", "alpha3Code": "ECU" },
  { "fullCountryName": "Egypt", "alpha2Code": "EG", "alpha3Code": "EGY" },
  { "fullCountryName": "El Salvador", "alpha2Code": "SV", "alpha3Code": "SLV" },
  {
    "fullCountryName": "Equatorial Guinea",
    "alpha2Code": "GQ",
    "alpha3Code": "GNQ"
  },
  { "fullCountryName": "Eritrea", "alpha2Code": "ER", "alpha3Code": "ERI" },
  { "fullCountryName": "Estonia", "alpha2Code": "EE", "alpha3Code": "EST" },
  { "fullCountryName": "Eswatini", "alpha2Code": "SZ", "alpha3Code": "SWZ" },
  { "fullCountryName": "Ethiopia", "alpha2Code": "ET", "alpha3Code": "ETH" },
  {
    "fullCountryName": "Falkland Islands  [Malvinas]",
    "alpha2Code": "FK",
    "alpha3Code": "FLK"
  },
  {
    "fullCountryName": "Faroe Islands ",
    "alpha2Code": "FO",
    "alpha3Code": "FRO"
  },
  { "fullCountryName": "Fiji", "alpha2Code": "FJ", "alpha3Code": "FJI" },
  { "fullCountryName": "Finland", "alpha2Code": "FI", "alpha3Code": "FIN" },
  { "fullCountryName": "France", "alpha2Code": "FR", "alpha3Code": "FRA" },
  {
    "fullCountryName": "French Guiana",
    "alpha2Code": "GF",
    "alpha3Code": "GUF"
  },
  {
    "fullCountryName": "French Polynesia",
    "alpha2Code": "PF",
    "alpha3Code": "PYF"
  },
  {
    "fullCountryName": "French Southern Territories ",
    "alpha2Code": "TF",
    "alpha3Code": "ATF"
  },
  { "fullCountryName": "Gabon", "alpha2Code": "GA", "alpha3Code": "GAB" },
  { "fullCountryName": "Gambia ", "alpha2Code": "GM", "alpha3Code": "GMB" },
  { "fullCountryName": "Georgia", "alpha2Code": "GE", "alpha3Code": "GEO" },
  { "fullCountryName": "Germany", "alpha2Code": "DE", "alpha3Code": "DEU" },
  { "fullCountryName": "Ghana", "alpha2Code": "GH", "alpha3Code": "GHA" },
  { "fullCountryName": "Gibraltar", "alpha2Code": "GI", "alpha3Code": "GIB" },
  { "fullCountryName": "Greece", "alpha2Code": "GR", "alpha3Code": "GRC" },
  { "fullCountryName": "Greenland", "alpha2Code": "GL", "alpha3Code": "GRL" },
  { "fullCountryName": "Grenada", "alpha2Code": "GD", "alpha3Code": "GRD" },
  { "fullCountryName": "Guadeloupe", "alpha2Code": "GP", "alpha3Code": "GLP" },
  { "fullCountryName": "Guam", "alpha2Code": "GU", "alpha3Code": "GUM" },
  { "fullCountryName": "Guatemala", "alpha2Code": "GT", "alpha3Code": "GTM" },
  { "fullCountryName": "Guernsey", "alpha2Code": "GG", "alpha3Code": "GGY" },
  { "fullCountryName": "Guinea", "alpha2Code": "GN", "alpha3Code": "GIN" },
  {
    "fullCountryName": "Guinea-Bissau",
    "alpha2Code": "GW",
    "alpha3Code": "GNB"
  },
  { "fullCountryName": "Guyana", "alpha2Code": "GY", "alpha3Code": "GUY" },
  { "fullCountryName": "Haiti", "alpha2Code": "HT", "alpha3Code": "HTI" },
  {
    "fullCountryName": "Heard Island and McDonald Islands",
    "alpha2Code": "HM",
    "alpha3Code": "HMD"
  },
  { "fullCountryName": "Holy See ", "alpha2Code": "VA", "alpha3Code": "VAT" },
  { "fullCountryName": "Honduras", "alpha2Code": "HN", "alpha3Code": "HND" },
  { "fullCountryName": "Hong Kong", "alpha2Code": "HK", "alpha3Code": "HKG" },
  { "fullCountryName": "Hungary", "alpha2Code": "HU", "alpha3Code": "HUN" },
  { "fullCountryName": "Iceland", "alpha2Code": "IS", "alpha3Code": "ISL" },
  { "fullCountryName": "India", "alpha2Code": "IN", "alpha3Code": "IND" },
  { "fullCountryName": "Indonesia", "alpha2Code": "ID", "alpha3Code": "IDN" },
  { "fullCountryName": "Iran", "alpha2Code": "IR", "alpha3Code": "IRN" },
  { "fullCountryName": "Iraq", "alpha2Code": "IQ", "alpha3Code": "IRQ" },
  { "fullCountryName": "Ireland", "alpha2Code": "IE", "alpha3Code": "IRL" },
  { "fullCountryName": "Isle of Man", "alpha2Code": "IM", "alpha3Code": "IMN" },
  { "fullCountryName": "Israel", "alpha2Code": "IL", "alpha3Code": "ISR" },
  { "fullCountryName": "Italy", "alpha2Code": "IT", "alpha3Code": "ITA" },
  { "fullCountryName": "Jamaica", "alpha2Code": "JM", "alpha3Code": "JAM" },
  { "fullCountryName": "Japan", "alpha2Code": "JP", "alpha3Code": "JPN" },
  { "fullCountryName": "Jersey", "alpha2Code": "JE", "alpha3Code": "JEY" },
  { "fullCountryName": "Jordan", "alpha2Code": "JO", "alpha3Code": "JOR" },
  { "fullCountryName": "Kazakhstan", "alpha2Code": "KZ", "alpha3Code": "KAZ" },
  { "fullCountryName": "Kenya", "alpha2Code": "KE", "alpha3Code": "KEN" },
  { "fullCountryName": "Kiribati", "alpha2Code": "KI", "alpha3Code": "KIR" },
  {
    "fullCountryName": "Korea (the Democratic People's Republic of)",
    "alpha2Code": "KP",
    "alpha3Code": "PRK"
  },
  {
    "fullCountryName": "Korea (the Republic of)",
    "alpha2Code": "KR",
    "alpha3Code": "KOR"
  },
  { "fullCountryName": "Kuwait", "alpha2Code": "KW", "alpha3Code": "KWT" },
  { "fullCountryName": "Kyrgyzstan", "alpha2Code": "KG", "alpha3Code": "KGZ" },
  {
    "fullCountryName": "Lao People's Democratic Republic ",
    "alpha2Code": "LA",
    "alpha3Code": "LAO"
  },
  { "fullCountryName": "Latvia", "alpha2Code": "LV", "alpha3Code": "LVA" },
  { "fullCountryName": "Lebanon", "alpha2Code": "LB", "alpha3Code": "LBN" },
  { "fullCountryName": "Lesotho", "alpha2Code": "LS", "alpha3Code": "LSO" },
  { "fullCountryName": "Liberia", "alpha2Code": "LR", "alpha3Code": "LBR" },
  { "fullCountryName": "Libya", "alpha2Code": "LY", "alpha3Code": "LBY" },
  {
    "fullCountryName": "Liechtenstein",
    "alpha2Code": "LI",
    "alpha3Code": "LIE"
  },
  { "fullCountryName": "Lithuania", "alpha2Code": "LT", "alpha3Code": "LTU" },
  { "fullCountryName": "Luxembourg", "alpha2Code": "LU", "alpha3Code": "LUX" },
  { "fullCountryName": "Macao", "alpha2Code": "MO", "alpha3Code": "MAC" },
  { "fullCountryName": "Madagascar", "alpha2Code": "MG", "alpha3Code": "MDG" },
  { "fullCountryName": "Malawi", "alpha2Code": "MW", "alpha3Code": "MWI" },
  { "fullCountryName": "Malaysia", "alpha2Code": "MY", "alpha3Code": "MYS" },
  { "fullCountryName": "Maldives", "alpha2Code": "MV", "alpha3Code": "MDV" },
  { "fullCountryName": "Mali", "alpha2Code": "ML", "alpha3Code": "MLI" },
  { "fullCountryName": "Malta", "alpha2Code": "MT", "alpha3Code": "MLT" },
  {
    "fullCountryName": "Marshall Islands ",
    "alpha2Code": "MH",
    "alpha3Code": "MHL"
  },
  { "fullCountryName": "Martinique", "alpha2Code": "MQ", "alpha3Code": "MTQ" },
  { "fullCountryName": "Mauritania", "alpha2Code": "MR", "alpha3Code": "MRT" },
  { "fullCountryName": "Mauritius", "alpha2Code": "MU", "alpha3Code": "MUS" },
  { "fullCountryName": "Mayotte", "alpha2Code": "YT", "alpha3Code": "MYT" },
  { "fullCountryName": "Mexico", "alpha2Code": "MX", "alpha3Code": "MEX" },
  {
    "fullCountryName": "Micronesia (Federated States of)",
    "alpha2Code": "FM",
    "alpha3Code": "FSM"
  },
  {
    "fullCountryName": "Moldova (the Republic of)",
    "alpha2Code": "MD",
    "alpha3Code": "MDA"
  },
  { "fullCountryName": "Monaco", "alpha2Code": "MC", "alpha3Code": "MCO" },
  { "fullCountryName": "Mongolia", "alpha2Code": "MN", "alpha3Code": "MNG" },
  { "fullCountryName": "Montenegro", "alpha2Code": "ME", "alpha3Code": "MNE" },
  { "fullCountryName": "Montserrat", "alpha2Code": "MS", "alpha3Code": "MSR" },
  { "fullCountryName": "Morocco", "alpha2Code": "MA", "alpha3Code": "MAR" },
  { "fullCountryName": "Mozambique", "alpha2Code": "MZ", "alpha3Code": "MOZ" },
  { "fullCountryName": "Myanmar", "alpha2Code": "MM", "alpha3Code": "MMR" },
  { "fullCountryName": "Namibia", "alpha2Code": "NA", "alpha3Code": "NAM" },
  { "fullCountryName": "Nauru", "alpha2Code": "NR", "alpha3Code": "NRU" },
  { "fullCountryName": "Nepal", "alpha2Code": "NP", "alpha3Code": "NPL" },
  {
    "fullCountryName": "Netherlands ",
    "alpha2Code": "NL",
    "alpha3Code": "NLD"
  },
  {
    "fullCountryName": "New Caledonia",
    "alpha2Code": "NC",
    "alpha3Code": "NCL"
  },
  { "fullCountryName": "New Zealand", "alpha2Code": "NZ", "alpha3Code": "NZL" },
  { "fullCountryName": "Nicaragua", "alpha2Code": "NI", "alpha3Code": "NIC" },
  { "fullCountryName": "Niger ", "alpha2Code": "NE", "alpha3Code": "NER" },
  { "fullCountryName": "Nigeria", "alpha2Code": "NG", "alpha3Code": "NGA" },
  { "fullCountryName": "Niue", "alpha2Code": "NU", "alpha3Code": "NIU" },
  {
    "fullCountryName": "Norfolk Island",
    "alpha2Code": "NF",
    "alpha3Code": "NFK"
  },
  {
    "fullCountryName": "North Macedonia",
    "alpha2Code": "MK",
    "alpha3Code": "MKD"
  },
  {
    "fullCountryName": "Northern Mariana Islands ",
    "alpha2Code": "MP",
    "alpha3Code": "MNP"
  },
  { "fullCountryName": "Norway", "alpha2Code": "NO", "alpha3Code": "NOR" },
  { "fullCountryName": "Oman", "alpha2Code": "OM", "alpha3Code": "OMN" },
  { "fullCountryName": "Pakistan", "alpha2Code": "PK", "alpha3Code": "PAK" },
  { "fullCountryName": "Palau", "alpha2Code": "PW", "alpha3Code": "PLW" },
  {
    "fullCountryName": "Palestine, State of",
    "alpha2Code": "PS",
    "alpha3Code": "PSE"
  },
  { "fullCountryName": "Panama", "alpha2Code": "PA", "alpha3Code": "PAN" },
  {
    "fullCountryName": "Papua New Guinea",
    "alpha2Code": "PG",
    "alpha3Code": "PNG"
  },
  { "fullCountryName": "Paraguay", "alpha2Code": "PY", "alpha3Code": "PRY" },
  { "fullCountryName": "Peru", "alpha2Code": "PE", "alpha3Code": "PER" },
  {
    "fullCountryName": "Philippines ",
    "alpha2Code": "PH",
    "alpha3Code": "PHL"
  },
  { "fullCountryName": "Pitcairn", "alpha2Code": "PN", "alpha3Code": "PCN" },
  { "fullCountryName": "Poland", "alpha2Code": "PL", "alpha3Code": "POL" },
  { "fullCountryName": "Portugal", "alpha2Code": "PT", "alpha3Code": "PRT" },
  { "fullCountryName": "Puerto Rico", "alpha2Code": "PR", "alpha3Code": "PRI" },
  { "fullCountryName": "Qatar", "alpha2Code": "QA", "alpha3Code": "QAT" },
  { "fullCountryName": "Romania", "alpha2Code": "RO", "alpha3Code": "ROU" },
  {
    "fullCountryName": "Russian Federation ",
    "alpha2Code": "RU",
    "alpha3Code": "RUS"
  },
  { "fullCountryName": "Rwanda", "alpha2Code": "RW", "alpha3Code": "RWA" },
  { "fullCountryName": "Réunion", "alpha2Code": "RE", "alpha3Code": "REU" },
  {
    "fullCountryName": "Saint Barthélemy",
    "alpha2Code": "BL",
    "alpha3Code": "BLM"
  },
  {
    "fullCountryName": "Saint Helena, Ascension and Tristan da Cunha",
    "alpha2Code": "SH",
    "alpha3Code": "SHN"
  },
  {
    "fullCountryName": "Saint Kitts and Nevis",
    "alpha2Code": "KN",
    "alpha3Code": "KNA"
  },
  { "fullCountryName": "Saint Lucia", "alpha2Code": "LC", "alpha3Code": "LCA" },
  {
    "fullCountryName": "Saint Martin (French part)",
    "alpha2Code": "MF",
    "alpha3Code": "MAF"
  },
  {
    "fullCountryName": "Saint Pierre and Miquelon",
    "alpha2Code": "PM",
    "alpha3Code": "SPM"
  },
  {
    "fullCountryName": "Saint Vincent and the Grenadines",
    "alpha2Code": "VC",
    "alpha3Code": "VCT"
  },
  { "fullCountryName": "Samoa", "alpha2Code": "WS", "alpha3Code": "WSM" },
  { "fullCountryName": "San Marino", "alpha2Code": "SM", "alpha3Code": "SMR" },
  {
    "fullCountryName": "Sao Tome and Principe",
    "alpha2Code": "ST",
    "alpha3Code": "STP"
  },
  {
    "fullCountryName": "Saudi Arabia",
    "alpha2Code": "SA",
    "alpha3Code": "SAU"
  },
  { "fullCountryName": "Senegal", "alpha2Code": "SN", "alpha3Code": "SEN" },
  { "fullCountryName": "Serbia", "alpha2Code": "RS", "alpha3Code": "SRB" },
  { "fullCountryName": "Seychelles", "alpha2Code": "SC", "alpha3Code": "SYC" },
  {
    "fullCountryName": "Sierra Leone",
    "alpha2Code": "SL",
    "alpha3Code": "SLE"
  },
  { "fullCountryName": "Singapore", "alpha2Code": "SG", "alpha3Code": "SGP" },
  {
    "fullCountryName": "Sint Maarten (Dutch part)",
    "alpha2Code": "SX",
    "alpha3Code": "SXM"
  },
  { "fullCountryName": "Slovakia", "alpha2Code": "SK", "alpha3Code": "SVK" },
  { "fullCountryName": "Slovenia", "alpha2Code": "SI", "alpha3Code": "SVN" },
  {
    "fullCountryName": "Solomon Islands",
    "alpha2Code": "SB",
    "alpha3Code": "SLB"
  },
  { "fullCountryName": "Somalia", "alpha2Code": "SO", "alpha3Code": "SOM" },
  {
    "fullCountryName": "South Africa",
    "alpha2Code": "ZA",
    "alpha3Code": "ZAF"
  },
  {
    "fullCountryName": "South Georgia and the South Sandwich Islands",
    "alpha2Code": "GS",
    "alpha3Code": "SGS"
  },
  { "fullCountryName": "South Sudan", "alpha2Code": "SS", "alpha3Code": "SSD" },
  { "fullCountryName": "Spain", "alpha2Code": "ES", "alpha3Code": "ESP" },
  { "fullCountryName": "Sri Lanka", "alpha2Code": "LK", "alpha3Code": "LKA" },
  { "fullCountryName": "Sudan ", "alpha2Code": "SD", "alpha3Code": "SDN" },
  { "fullCountryName": "Suriname", "alpha2Code": "SR", "alpha3Code": "SUR" },
  {
    "fullCountryName": "Svalbard and Jan Mayen",
    "alpha2Code": "SJ",
    "alpha3Code": "SJM"
  },
  { "fullCountryName": "Sweden", "alpha2Code": "SE", "alpha3Code": "SWE" },
  { "fullCountryName": "Switzerland", "alpha2Code": "CH", "alpha3Code": "CHE" },
  {
    "fullCountryName": "Syrian Arab Republic ",
    "alpha2Code": "SY",
    "alpha3Code": "SYR"
  },
  {
    "fullCountryName": "Taiwan (Province of China)",
    "alpha2Code": "TW",
    "alpha3Code": "TWN"
  },
  { "fullCountryName": "Tajikistan", "alpha2Code": "TJ", "alpha3Code": "TJK" },
  {
    "fullCountryName": "Tanzania, the United Republic of",
    "alpha2Code": "TZ",
    "alpha3Code": "TZA"
  },
  { "fullCountryName": "Thailand", "alpha2Code": "TH", "alpha3Code": "THA" },
  { "fullCountryName": "Timor-Leste", "alpha2Code": "TL", "alpha3Code": "TLS" },
  { "fullCountryName": "Togo", "alpha2Code": "TG", "alpha3Code": "TGO" },
  { "fullCountryName": "Tokelau", "alpha2Code": "TK", "alpha3Code": "TKL" },
  { "fullCountryName": "Tonga", "alpha2Code": "TO", "alpha3Code": "TON" },
  {
    "fullCountryName": "Trinidad and Tobago",
    "alpha2Code": "TT",
    "alpha3Code": "TTO"
  },
  { "fullCountryName": "Tunisia", "alpha2Code": "TN", "alpha3Code": "TUN" },
  { "fullCountryName": "Turkey", "alpha2Code": "TR", "alpha3Code": "TUR" },
  {
    "fullCountryName": "Turkmenistan",
    "alpha2Code": "TM",
    "alpha3Code": "TKM"
  },
  {
    "fullCountryName": "Turks and Caicos Islands ",
    "alpha2Code": "TC",
    "alpha3Code": "TCA"
  },
  { "fullCountryName": "Tuvalu", "alpha2Code": "TV", "alpha3Code": "TUV" },
  { "fullCountryName": "Uganda", "alpha2Code": "UG", "alpha3Code": "UGA" },
  { "fullCountryName": "Ukraine", "alpha2Code": "UA", "alpha3Code": "UKR" },
  {
    "fullCountryName": "United Arab Emirates ",
    "alpha2Code": "AE",
    "alpha3Code": "ARE"
  },
  {
    "fullCountryName": "United Kingdom",
    "alpha2Code": "GB",
    "alpha3Code": "GBR"
  },
  {
    "fullCountryName": "United States Minor Outlying Islands ",
    "alpha2Code": "UM",
    "alpha3Code": "UMI"
  },
  {
    "fullCountryName": "United States of America ",
    "alpha2Code": "US",
    "alpha3Code": "USA"
  },
  { "fullCountryName": "Uruguay", "alpha2Code": "UY", "alpha3Code": "URY" },
  { "fullCountryName": "Uzbekistan", "alpha2Code": "UZ", "alpha3Code": "UZB" },
  { "fullCountryName": "Vanuatu", "alpha2Code": "VU", "alpha3Code": "VUT" },
  {
    "fullCountryName": "Venezuela (Bolivarian Republic of)",
    "alpha2Code": "VE",
    "alpha3Code": "VEN"
  },
  { "fullCountryName": "Viet Nam", "alpha2Code": "VN", "alpha3Code": "VNM" },
  {
    "fullCountryName": "Virgin Islands (British)",
    "alpha2Code": "VG",
    "alpha3Code": "VGB"
  },
  {
    "fullCountryName": "Virgin Islands (U.S.)",
    "alpha2Code": "VI",
    "alpha3Code": "VIR"
  },
  {
    "fullCountryName": "Wallis and Futuna",
    "alpha2Code": "WF",
    "alpha3Code": "WLF"
  },
  {
    "fullCountryName": "Western Sahara",
    "alpha2Code": "EH",
    "alpha3Code": "ESH"
  },
  { "fullCountryName": "Yemen", "alpha2Code": "YE", "alpha3Code": "YEM" },
  { "fullCountryName": "Zambia", "alpha2Code": "ZM", "alpha3Code": "ZMB" },
  { "fullCountryName": "Zimbabwe", "alpha2Code": "ZW", "alpha3Code": "ZWE" },
  {
    "fullCountryName": "Åland Islands",
    "alpha2Code": "AX",
    "alpha3Code": "ALA"
  }
]
