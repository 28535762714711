import React from 'react';
import { Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useSearchInUrl from 'utils/hooks/useSearchInUrl';
import './NoSearchResult.scss';

export const NoSearchResult = () => {
  const { search } = useSearchInUrl();

  return (
    <Stack className="no-search-result">
      <SearchIcon />
      <Typography variant="body1" fontWeight={600}>
        No result
      </Typography>
      <Typography fontSize="1.4rem">Sorry we coudn’t find anything for “{search}”</Typography>
    </Stack>
  );
};
