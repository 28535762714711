import { IAdminState, IOrganisation } from 'types/admin.types';
import { IUser, IUserRoleRes } from 'types/user.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IAdminState = {
  organisations: null,
  isLoading: false,
  isOrgCreationFailed: false,
  isOrgsLoading: false,
  currentOrganisation: null,
  isUserAddFailed: false,
  isLoadingSubaccounts: false,
  error: null,
  isOrgCreationLoading: false,
};

export type { IAdminState } from 'types/admin.types';

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    createOrganisation: (state, action: PayloadAction<{ orgName: string; orgEmail: string }>) => {
      state.isOrgCreationLoading = true;
      state.isOrgCreationFailed = false;
    },
    createOrganisationSuccess: (state, action: PayloadAction<IOrganisation>) => {
      state.isOrgCreationLoading = false;
      state.isOrgCreationFailed = false;
      state.organisations = state.organisations
        ? [...state.organisations, action.payload]
        : [action.payload];
    },
    createOrganisationFail: (state, action: PayloadAction<Error>) => {
      state.isOrgCreationLoading = false;
      state.isOrgCreationFailed = true;
      state.error = action.payload;
    },
    getOrganisationDetails: (state, action: PayloadAction<{ orgId: string }>) => {
      state.isLoading = true;
      state.error = null;
    },
    getOrganisationDetailsSuccess: (state, action: PayloadAction<IOrganisation>) => {
      state.isLoading = false;
      state.currentOrganisation = action.payload;
    },
    getOrganisationDetailsFail: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addUserToOrganisation: (
      state,
      action: PayloadAction<{ orgId: string; email: Array<{ label: string; value: string }> }>
    ) => {
      state.isLoading = true;
      state.error = null;
    },
    addUserToOrganisationSuccess: (
      state,
      action: PayloadAction<{ newUserList: Array<IUser<IUserRoleRes>> }>
    ) => {
      if (state.currentOrganisation) {
        state.currentOrganisation.users = action.payload.newUserList;
      }
      state.isLoading = false;
    },
    addUserToOrganisationFail: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.isUserAddFailed = true;
      state.error = action.payload;
    },
    reinviteUserToOrganisation: (
      state,
      action: PayloadAction<{ orgId: string; userEmail: string }>
    ) => {
      state.isLoading = true;
      state.error = null;
    },
    reinviteUserToOrganisationSuccess: (state, action: PayloadAction<{ userEmail: string }>) => {
      state.isLoading = true;
      state.error = null;
    },
    reinviteUserToOrganisationFail: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteReinvitedUserFromOrganisation: (
      state,
      action: PayloadAction<{ orgId: string; userId: string }>
    ) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteReinvitedUserFromOrganisationSuccess: (
      state,
      action: PayloadAction<{ orgId: string; userId: string }>
    ) => {
      if (!state.currentOrganisation?.users) {
        return state;
      }

      const updatedCurrentOrganisation = {
        ...state.currentOrganisation,
        users: state.currentOrganisation.users.filter(
          ({ user_id: currentUserId }) => currentUserId !== action.payload.userId
        ),
      };
      const updatedOrganisations = state.organisations
        ? state.organisations.map((org) =>
            org.id === action.payload.orgId ? updatedCurrentOrganisation : org
          )
        : null;

      return {
        ...state,
        isLoading: false,
        organisations: updatedOrganisations,
        currentOrganisation: updatedCurrentOrganisation,
      };
    },
    deleteReinvitedUserFromOrganisationFail: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteUserFromOrganisation: (
      state,
      action: PayloadAction<{ orgId: string; userId: string }>
    ) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteUserFromOrganisationSuccess: (
      state,
      action: PayloadAction<{ orgId: string; userId: string }>
    ) => {
      if (!state.currentOrganisation?.users) {
        return state;
      }

      const updatedCurrentOrganisation = {
        ...state.currentOrganisation,
        users: state.currentOrganisation.users.filter(
          ({ user_id: currentUserId }) => currentUserId !== action.payload.userId
        ),
      };
      const updatedOrganisations = state.organisations
        ? state.organisations.map((org) =>
            org.id === action.payload.orgId ? updatedCurrentOrganisation : org
          )
        : null;

      return {
        ...state,
        isLoading: false,
        organisations: updatedOrganisations,
        currentOrganisation: updatedCurrentOrganisation,
      };
    },
    deleteUserFromOrganisationFail: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getOrganisations: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isOrgsLoading = true;
    },
    getOrganisationsSuccess: (state, action: PayloadAction<IOrganisation[]>) => {
      state.isLoading = false;
      state.isOrgsLoading = false;
      state.organisations = action.payload;
    },
    getOrganisationsFail: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.isOrgsLoading = false;
      state.error = action.payload;
    },
    deleteOrganisation: (state, action: PayloadAction<{ orgId: string }>) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteOrganisationSuccess: (state, action: PayloadAction<{ orgId: string }>) => {
      const { orgId } = action.payload;
      const updatedOrgs = state.organisations?.filter(({ id }) => id !== orgId);

      return {
        ...state,
        organisations: updatedOrgs ?? null,
        currentOrganisation: null,
        isLoading: false,
      };
    },
    deleteOrganisationFail: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createOrganisation,
  createOrganisationSuccess,
  createOrganisationFail,
  getOrganisationDetails,
  getOrganisationDetailsSuccess,
  getOrganisationDetailsFail,
  addUserToOrganisation,
  addUserToOrganisationSuccess,
  addUserToOrganisationFail,
  reinviteUserToOrganisation,
  reinviteUserToOrganisationSuccess,
  reinviteUserToOrganisationFail,
  deleteReinvitedUserFromOrganisation,
  deleteReinvitedUserFromOrganisationSuccess,
  deleteReinvitedUserFromOrganisationFail,
  deleteUserFromOrganisation,
  deleteUserFromOrganisationSuccess,
  deleteUserFromOrganisationFail,
  getOrganisations,
  getOrganisationsSuccess,
  getOrganisationsFail,
  deleteOrganisation,
  deleteOrganisationSuccess,
  deleteOrganisationFail,
} = adminSlice.actions;
export default adminSlice.reducer;
