import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AbGroupStatuses,
  IAbGroup,
  IAbGroupState,
  IAbTestJob,
  IActivateAbGroup,
} from 'types/abtesting.types';

const initialState: IAbGroupState = {
  isAbGroupLoading: false,
  error: null,
  newAbTestId: null,
  abGroup: null,
  abGroupSubAccountId: null,
};

export type { IAbGroupState } from 'types/abtesting.types';

const abTestingSlice = createSlice({
  name: 'abGroup',
  initialState,
  reducers: {
    previewAbTest: (
      state,
      action: PayloadAction<{
        subAccountId: number;
        testName: string;
        keywordsPercent: number;
        splitKeywordsType: string;
      }>
    ) => {
      state.isAbGroupLoading = true;
    },
    previewAbTestSuccess: (
      state,
      action: PayloadAction<{ subAccountId: number; abTest: IAbTestJob }>
    ) => {
      state.error = null;
      state.abGroupSubAccountId = action.payload.subAccountId;
      state.newAbTestId = action.payload.abTest.abGroupId;
    },
    previewAbTestFail: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
      state.isAbGroupLoading = false;
    },
    getAbTest: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number; abTestId: number }>
    ) => {
      state.newAbTestId = null;
    },
    getAbTestSuccess: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number; abGroup: IAbGroup }>
    ) => {
      state.isAbGroupLoading = action.payload.abGroup.group.status === AbGroupStatuses.PENDING;
      state.abGroup = action.payload.abGroup;
      state.abGroupSubAccountId = action.payload.abGroup.group.subAccountId;
      state.error = null;
    },
    getAbTestFail: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
      state.abGroup = null;
      state.isAbGroupLoading = false;
      state.abGroupSubAccountId = null;
    },
    deleteAbGroup: (
      state,
      action: PayloadAction<{ accountId: number; abGroupId: number; subAccountId: number }>
    ) => {
      state.isAbGroupLoading = true;
    },
    deleteAbGroupSuccess: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number; abGroupId: number }>
    ) => {
      state.error = null;
      state.isAbGroupLoading = false;
      state.newAbTestId = null;
      state.abGroupSubAccountId = null;
      state.abGroup = null;
    },
    deleteAbGroupFail: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    resetAbTestState: (state) => {
      state.error = null;
      state.isAbGroupLoading = false;
      state.newAbTestId = null;
      state.abGroupSubAccountId = null;
      state.abGroup = null;
    },
    activateAbTest: (state, action: PayloadAction<{ subAccountId: number; abTestId: number }>) => {
      state.isAbGroupLoading = true;
    },
    activateAbTestSuccess: (
      state,
      action: PayloadAction<{ subAccountId: number; activatedAbGroup: IActivateAbGroup }>
    ) => {
      state.isAbGroupLoading = false;
    },
    activateAbTestFail: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
  },
});

export const {
  previewAbTest,
  previewAbTestSuccess,
  previewAbTestFail,
  getAbTest,
  getAbTestSuccess,
  getAbTestFail,
  deleteAbGroup,
  deleteAbGroupFail,
  deleteAbGroupSuccess,
  resetAbTestState,
  activateAbTest,
  activateAbTestFail,
  activateAbTestSuccess,
} = abTestingSlice.actions;

export default abTestingSlice.reducer;
