import React, { useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { CloseFullscreen, OpenInFull, SyncDisabledOutlined } from '@mui/icons-material';
import { DetailDisplayPanel } from '@dqna/seamless-suite-ui-kit';
import { DataTableModal } from 'components/ui-kit/DataTableModal';
import { useFlag } from 'utils/hooks/useFlag';
import { floatNumberFixer } from 'utils/floatNumberFixer';
import { formatCurrency } from 'country-currency-map';
import { KeywordGroupKeywordDisplayWithModalProps } from './type';
import { IKeywordNewType } from 'types/keywords.types';
import { LOW_VOLUME_LIMIT } from 'constants/keywords';
import './KeywordGroupDetails.scss';

export const KeywordGroupKeywordDisplayWithModal: React.FC<
  KeywordGroupKeywordDisplayWithModalProps
> = (props) => {
  const { keywords, groupId, subAccount } = props;
  const [isDataTableModalOpen, setIsDataTableModalOpen] = useFlag(false);

  const filteredKeywords = useMemo(() => {
    return keywords.filter((keyword: IKeywordNewType) => keyword.keywordGroupId === groupId);
  }, [keywords]);

  const currencySymbol = formatCurrency('', subAccount?.currencyCode);
  const cellWithLowVolumeForDataTable = (row: IKeywordNewType) => {
    return (
      <>
        <Typography variant="subtitle2">{row.keyword}</Typography>
        {row.totalClicksSum < LOW_VOLUME_LIMIT && (
          <SyncDisabledOutlined className="sync-disabled-svg" />
        )}
      </>
    );
  };

  const cellWithLowVolumeForDetailTable = (cell: string | number, index: number) => {
    return (
      <div className="low-volume-display-keywords">
        <Typography variant="subtitle2">{cell}</Typography>
        {filteredKeywords[index].totalClicksSum < LOW_VOLUME_LIMIT && (
          <SyncDisabledOutlined className="sync-disabled-svg" />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="keyword-group-details-data-table">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="95%"
          className="keyword-group-details-data-table__header">
          <Typography variant="subtitle2">List of keywords</Typography>
          <Button
            variant="text"
            endIcon={isDataTableModalOpen ? <CloseFullscreen /> : <OpenInFull />}
            onClick={setIsDataTableModalOpen}>
            Expand view
          </Button>
        </Stack>
        <DetailDisplayPanel<IKeywordNewType>
          name="groupKeywordsList"
          rows={filteredKeywords}
          columns={[
            {
              name: 'keyword',
              header: 'Keyword',
              render: (row, index) => cellWithLowVolumeForDetailTable(row, index),
            },
            {
              name: 'sconImprSum',
              header: 'Org. Imp.',
              render: (row) => <Typography variant="body2">{floatNumberFixer(row)}</Typography>,
            },
            {
              name: 'sconClicksSum',
              header: 'Org. Clicks',
              render: (row) => <Typography variant="body2">{floatNumberFixer(row)}</Typography>,
            },
            {
              name: 'sconPosAvg',
              header: 'Org. Pos.',
              render: (row) => <Typography variant="body2">{floatNumberFixer(row)}</Typography>,
            },
            {
              name: 'gadsTextClicksSum',
              header: 'Paid clicks',
              render: (row) => <Typography variant="body2">{floatNumberFixer(row)}</Typography>,
            },
            {
              name: 'gadsTextCPC',
              header: `Paid CPC (${currencySymbol})`,
              render: (row) => <Typography variant="body2">{floatNumberFixer(row)}</Typography>,
            },
          ]}
        />
      </div>
      <DataTableModal
        open={isDataTableModalOpen}
        onClose={setIsDataTableModalOpen}
        enableModalButtons={false}
        fullScreen
        fullWidth
        title="View keywords(s)"
        columns={[
          {
            field: 'keyword',
            headerName: 'Name',
            renderCell: (row) => cellWithLowVolumeForDataTable(row.row),
            flex: 2,
          },
          {
            field: 'keywordGroupName',
            headerName: 'Keyword group',
            renderCell: (row) => <Typography variant="body1">{row.value}</Typography>,
            flex: 1,
          },
          {
            field: 'sconClicksSum',
            headerName: 'Organic clicks',
            renderCell: (row) => (
              <Typography variant="body1">{floatNumberFixer(row.value)}</Typography>
            ),
            flex: 1,
          },
          {
            field: 'gadsTextClicksSum',
            headerName: 'Paid clicks',
            renderCell: (row) => (
              <Typography variant="body1">{floatNumberFixer(row.value)}</Typography>
            ),
            flex: 1,
          },
          {
            field: 'totalCTR',
            headerName: 'Total CTR',
            renderCell: (row) => (
              <Typography variant="body1">{floatNumberFixer(row.value)}</Typography>
            ),
            flex: 1,
          },
          {
            field: 'gadsTextCPC',
            headerName: 'Paid CPC',
            renderCell: (row) => (
              <Typography variant="body1">{floatNumberFixer(row.value)}</Typography>
            ),
            flex: 1,
          },
          {
            field: 'totalRevenueSum',
            headerName: `Ttl. Rev. (${currencySymbol})`,
            renderCell: (row) => (
              <Typography variant="body1">{floatNumberFixer(row.value)}</Typography>
            ),
            flex: 1,
          },
          {
            field: 'totalMargin',
            headerName: `Ttl. Mrg. (${currencySymbol})`,
            renderCell: (row) => (
              <Typography variant="body1">{floatNumberFixer(row.value)}</Typography>
            ),
            flex: 1,
          },
        ]}
        data={filteredKeywords}
        checkboxHeaderWithDropdown={false}
        checkboxSelection={false}
        withRowBorder={true}
        rowsPerPageOptions={[10, 20, 30]}
        pageSize={10}
        enableFiltering={false}></DataTableModal>
    </>
  );
};

KeywordGroupKeywordDisplayWithModal.displayName = 'KeywordGroupKeywordDisplayWithModal';
