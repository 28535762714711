import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdwordsAccount, IAdwordsState } from 'types/adwards.types';

const initialState: IAdwordsState = {
  error: null,
  adwords: {},
  isLoading: {},
};

export type { IAdwordsState } from 'types/adwards.types';

const adwordsSlice = createSlice({
  name: 'adwords',
  initialState,
  reducers: {
    fetchAdwords: (state, action: PayloadAction<number>) => {
      state.isLoading[action.payload] = true;
    },
    fetchAdwordsSuccess: (
      state,
      action: PayloadAction<{ accountId: number; adwords: IAdwordsAccount[] }>
    ) => {
      const { accountId, adwords } = action.payload;
      state.adwords[accountId] = adwords;
      state.isLoading[accountId] = false;
    },
    fetchAdwordsFail: (state, action: PayloadAction<{ accountId: number; error: Error }>) => {
      state.error = action.payload.error;
      state.isLoading[action.payload.accountId] = false;
    },
  },
});

export const { fetchAdwords, fetchAdwordsSuccess, fetchAdwordsFail } = adwordsSlice.actions;
export default adwordsSlice.reducer;
