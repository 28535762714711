import React, { FC } from 'react';
import { DeleteSubAccountModalProps } from './types';
import { UserInteractionDialog } from '@dqna/seamless-suite-ui-kit';
import { Typography } from '@mui/material';
import { CloseActionType } from '@dqna/seamless-suite-ui-kit/dist/components/UserInteractionDialog';

export const DeleteSubAccountModal: FC<DeleteSubAccountModalProps> = (props) => {
  const {
    deleteSubAccountModalOpenState,
    setDeleteSubAccountModalOpenState,
    selectedSubAccount,
    deleteSubAccount,
  } = props;

  const onClose = (closeAction: CloseActionType) => {
    if (closeAction === 'confirm') {
      deleteSubAccount(selectedSubAccount.account.id, selectedSubAccount.id);
    }
  };

  return (
    <UserInteractionDialog
      uiDialogState={deleteSubAccountModalOpenState}
      setUIDialogState={() => {
        setDeleteSubAccountModalOpenState(false);
      }}
      title="Confirmation?"
      message={undefined}
      buttonCaptions={{
        cancel: 'Cancel',
        confirm: 'Yes, delete',
      }}
      onClose={onClose}>
      <Typography variant="body2" textAlign="center">
        Are you sure you want to delete <strong>{selectedSubAccount.name}</strong> subaccount?
      </Typography>
    </UserInteractionDialog>
  );
};
