import React, { FC, useState, useEffect } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router';
import { ProgressChain, ChainLink } from 'components/ui-kit/ProgressChain';
import { HomeContainerProps } from 'components/containers/Home';
import { useAuthProvider } from 'utils/hooks/useAuthProvider';
import { useSourceRedirect } from 'utils/hooks/useSourceRedirect';
import VerticalLogoSvg from 'jsx:assets/SeamlessSearch_vert.svg';
import './Home.scss';
import { Link } from 'react-router-dom';
import { Routes } from 'types/app.types';
import { LinkAccountsNewModal } from '../SubAccounts/LinkAccountsModal/LinkAccountsModal.component';

export const Home: FC<HomeContainerProps> = ({
  getAccounts,
  isRequiredAccountsLinked,
  accounts,
  accessToken,
  authToken,
  createAccountModalActions,
  linkDataSourcesStatuses,
  creationError,
  isDataSourceVerified,
  newAccount,
}) => {
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const { isExact } = useRouteMatch();
  const [openedModal, setOpenedModal] = useState(false);

  const { isAuthenticated } = useAuthProvider();

  useEffect(() => {
    if (!accounts) {
      getAccounts();
    }
  }, [accounts, getAccounts]);

  useEffect(() => {
    if (isExact && isAuthenticated && isRequiredAccountsLinked) {
      replace({ pathname: Routes.LINKED_ACCOUNTS });
    }

    if (isExact && isAuthenticated && !isRequiredAccountsLinked) {
      replace({ pathname: Routes.HOME });
    }

    if (isAuthenticated) {
      replace({ pathname: Routes.LINKED_ACCOUNTS });
    }
  }, [accounts, isExact, isAuthenticated, pathname, isRequiredAccountsLinked]);

  useSourceRedirect();

  const chainSteps = [
    'Link accounts',
    'Auto keywords download',
    'Send keywords to test',
    'Push keywords to go LIVE',
    'Keywords engine LIVE',
  ];
  const unlinkedLayout = (
    <>
      <VerticalLogoSvg className="home__logo" role="img">
        <desc>Seamless Search</desc>
      </VerticalLogoSvg>
      <p className="home__text">
        Welcome to Seamless Search, the first platform to help marketers maximize performance of SEO
        and PPC channels.
      </p>
      <ProgressChain className="home__timeline">
        {chainSteps.map((step, stepIdx) => (
          <ChainLink index={stepIdx + 1} key={stepIdx}>
            {step}
          </ChainLink>
        ))}
      </ProgressChain>
      <p className="home__text">
        Get started by linking an account (3 steps).<br></br>
        To link accounts, please log in or&nbsp;
        <a href="https://seamlesspro.io/#contact" target="_blank" rel="noreferrer">
          contact us
        </a>
        &nbsp;to create an account.
      </p>

      <LinkAccountsNewModal
        modalOpenState={openedModal}
        setModalOpenState={setOpenedModal}
        accounts={accounts ?? []}
        accessToken={accessToken ?? ''}
        authToken={authToken ?? ''}
        {...createAccountModalActions}
        newAccount={newAccount}
        linkDataSourcesStatuses={linkDataSourcesStatuses}
        creationError={creationError}
        isDataSourceVerified={isDataSourceVerified}
      />
    </>
  );

  const homePage = (
    <>
      <div className="home">{unlinkedLayout}</div>
      <div className="bottom">
        <Link className="bottom__link" to={Routes.PRIVACY}>
          Privacy
        </Link>
        <Link className="bottom__link" to={Routes.TERMS_AND_CONDITIONS}>
          Terms And Conditions
        </Link>
      </div>
    </>
  );

  return <div>{homePage}</div>;
};

Home.displayName = 'Home';
