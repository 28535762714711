import React, { useEffect, useState } from 'react';

import { MainContainerProps } from 'components/containers/Main';
import { TitledHeader, SideNav } from 'components/ui-kit/AppLayout';
import { LinkAccountsNewModal } from '../SubAccounts/LinkAccountsModal/LinkAccountsModal.component';
import { getOrg } from 'utils/manageOrganisations';

export const Main = (props: MainContainerProps) => {
  const {
    accounts,
    currentUser,
    pageTitle,
    getAccountsOfAllOrgs,
    accountsByOrg,
    notifications,
    getNotifications,
    createAccountModalActions,
    createAccountModalProps,
    accessToken,
    authToken,
  } = props;
  const [addNewAccountModalOpenState, setAddNewAccountModalOpenState] = useState(false);
  const [linkAccountOrganisationName, setLinkAccountOrganisationName] = useState(getOrg());

  useEffect(() => {
    getAccountsOfAllOrgs();
  }, [currentUser?.groups]);

  return (
    <>
      <SideNav accounts={accounts ?? []} />
      <TitledHeader
        currentUser={currentUser}
        pageTitle={pageTitle}
        accountsByOrg={accountsByOrg}
        notifications={notifications}
        getNotifications={getNotifications}
        setAddAccountModalOpenState={setAddNewAccountModalOpenState}
        setLinkAccountOrganisationName={setLinkAccountOrganisationName}
      />
      <LinkAccountsNewModal
        modalOpenState={addNewAccountModalOpenState}
        setModalOpenState={setAddNewAccountModalOpenState}
        accounts={accountsByOrg ? accountsByOrg[linkAccountOrganisationName] : []}
        accessToken={accessToken ?? ''}
        authToken={authToken ?? ''}
        {...createAccountModalActions}
        {...createAccountModalProps}
        orgName={linkAccountOrganisationName}
      />
    </>
  );
};
