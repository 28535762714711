import classNames from 'classnames';
import React, {
  FC,
  useCallback,
  useMemo,
  MouseEvent,
  ChangeEvent,
} from 'react';

import { Checkbox } from 'components/ui-kit/Input';

import { IDdItemSelectProps, ChangeInputHandler } from './types';

export const DropdownCheckBoxItem: FC<IDdItemSelectProps> = ({
  onChange,
  selected,
  value,
  name,
  disabled,
  className,
  label,
}) => {
  const itemClass = classNames('dropdown__item', className);

  const handleItemChange: ChangeInputHandler = useCallback(
    (e?: MouseEvent<HTMLElement> | ChangeEvent<HTMLElement>) => {
      onChange(e);
    },
    [onChange]
  );

  const itemTitle = useMemo(() => label || value.toString(), [value, label]);

  return (
    <li
      title={itemTitle}
      className={itemClass}
      role="menuitemchackbox"
      aria-labelledby={name}
      aria-describedby={`${value}`}
      aria-checked={selected}
      tabIndex={-1}>
      <Checkbox
        onChange={handleItemChange}
        name={name}
        value={`${value}`}
        checked={selected}
        disabled={disabled}>
        {label}
      </Checkbox>
    </li>
  );
};

DropdownCheckBoxItem.displayName = 'DropdownCheckBoxItem';
