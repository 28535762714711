import React, { forwardRef } from 'react';

import {
  InputTypes,
  inputDisplayNames,
  IIncrementalIntegerTextProps,
} from './types';

import './Input.scss';
import { Button } from 'components/ui-kit/Button';
import { TextInput } from 'components/ui-kit/Input';
import classNames from 'classnames';

export const IncrementalIntegerInput = forwardRef<
  React.Component,
  IIncrementalIntegerTextProps
>(
  (
    {
      Icon,
      disabled,
      setValue,
      className,
      incrementValue = 0.1,
      incrementMaxValue = null,
      incrementMinValue = null,
      decimalInteger = 2,
      value,
      defaultValue,
      name,
      onEnter,
      outlined,
      label,
      validationError,
      tip,
      autoFocus,
      onFocus,
      readOnly,
    },
    ref
  ) => {
    const decrementCount = () => {
      if (incrementMinValue == null || value > incrementMinValue!) {
        setValue(Number((value - incrementValue).toFixed(decimalInteger)));
      }
    };

    const incrementCount = () => {
      if (incrementMaxValue == null || value < incrementMaxValue!) {
        setValue(Number((value + incrementValue).toFixed(decimalInteger)));
      }
    };

    const rangeError =
      incrementMinValue !== null &&
      incrementMaxValue !== null &&
      (value < incrementMinValue || value > incrementMaxValue)
        ? `Value mustbe between [${incrementMinValue} -  ${incrementMaxValue}]`
        : '';

    return (
      <div className={classNames('plus-minus-container__button', className)}>
        <Button name="Decrease" onClick={decrementCount}>
          -
        </Button>

        <TextInput
          type={InputTypes.Number}
          value={value}
          onChange={event => {
            const value = Number(event!.target.value);
            setValue(value);
          }}
          name={name}
          label={label}
          className={className}
          validationError={validationError ? validationError : rangeError}
          disabled={disabled}
          defaultValue={defaultValue}
          Icon={Icon}
          onEnter={onEnter}
          outlined={outlined}
          tip={tip}
          autoFocus={autoFocus}
          onFocus={onFocus}
          readOnly={readOnly}
        />
        <Button name="Increase" onClick={incrementCount}>
          +
        </Button>
      </div>
    );
  }
);

IncrementalIntegerInput.displayName = inputDisplayNames.incrementalIntegerInput;
