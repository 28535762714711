import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { IKeywordsManagementState, IKeyword } from 'types/keywords.types';
import { TABLE_PAGE_KEYWORD_LIMIT } from 'constants/filters';
import { FilterState } from 'components/ui-kit/Filters';
import { KeywordBodyType } from 'types/subAccounts.types';
import { IExtendedKeyword } from 'services/IDB.service';

export type { IKeywordsManagementState } from 'types/keywords.types';

export const keywordsManagmentInitialState: IKeywordsManagementState = {
  isLoading: false,
  data: null,
  error: null,
  page: 1,
  limit: TABLE_PAGE_KEYWORD_LIMIT,
  totalItems: 0,
  filters: [],
  accountId: null,
  subAccountId: null,
  keywordIds: [],
};

const keywordsManagmentSlice = createSlice({
  name: 'keywordsManagment',
  initialState: keywordsManagmentInitialState,
  reducers: {
    getKeywords: (
      state,
      action: PayloadAction<{
        accountId: number;
        subAccountId: number;
        date: string;
        groupId?: number;
        pagination?: { page: number; limit: number };
        filters?: FilterState[];
        sorting?: {
          sortBy?: keyof IKeyword;
          sortOrder?: boolean;
        };
      }>
    ) => {
      const { accountId, groupId, subAccountId, filters, pagination } = action.payload;
      state.isLoading = true;
      state.error = null;
      state.page = pagination?.page ?? 1;
      state.limit = pagination?.limit ?? TABLE_PAGE_KEYWORD_LIMIT;
      state.filters = filters ?? [];
      state.subAccountId = subAccountId;
      state.groupId = groupId;
      state.accountId = accountId;
    },
    getKeywordsSuccess: (
      state,
      action: PayloadAction<{
        subAccountId: number;
        keywords: IExtendedKeyword[];
        totalItems: number;
        groupId?: number;
        keywordIds: Array<string | number>;
      }>
    ) => {
      const { keywordIds, totalItems, keywords } = action.payload;
      state.isLoading = false;
      state.data = keywords;
      state.totalItems = totalItems;
      state.keywordIds = keywordIds;
    },
    sendGroupKeywordsToTest: (
      state,
      action: PayloadAction<{
        date: string;
        accountId: number;
        subAccountId: number;
        body: KeywordBodyType;
        redirect?: boolean;
      }>
    ) => {
      state.isLoading = true;
      state.error = null;
    },
    sendGroupKeywordsToTestSuccess: (
      state,
      action: PayloadAction<{
        date: string;
        accountId: number;
        subAccountId: number;
        groupId: number;
        updatedKeywordIds: Array<{
          name: string;
          id: number;
        }>;
      }>
    ) => {
      const { accountId, subAccountId, groupId } = action.payload;
      state.subAccountId = subAccountId;
      state.groupId = groupId;
      state.accountId = accountId;
      state.isLoading = false;
    },
    deleteGroupKeywords: (
      state,
      action: PayloadAction<{
        date: string;
        accountId: number;
        subAccountId: number;
        groupId: number;
        deletedKeywordIds: number[];
      }>
    ) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteGroupKeywordsSuccess: (
      state,
      action: PayloadAction<{
        date: string;
        accountId: number;
        subAccountId: number;
        groupId: number;
        deletedKeywordIds: number[];
      }>
    ) => {
      const { accountId, subAccountId, groupId } = action.payload;
      state.subAccountId = subAccountId;
      state.groupId = groupId;
      state.accountId = accountId;
      state.isLoading = false;
    },
    pauseGroupKeywordsTestingPhase: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number; groupId: number }>
    ) => {
      state.isLoading = true;
      state.error = null;
    },
    pauseGroupKeywordsTestingPhaseSuccess: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number; groupId: number }>
    ) => {
      state.isLoading = false;
      state.error = null;
    },
    keywordActionFail: (state, action: PayloadAction<Error>) => {
      state.isLoading = true;
      state.error = action.payload;
    },
  },
});

export const keywordsActionFinally = createAction('KEYWORDS_ACTION__FINALLY');

export const {
  getKeywords,
  getKeywordsSuccess,
  sendGroupKeywordsToTest,
  sendGroupKeywordsToTestSuccess,
  deleteGroupKeywords,
  deleteGroupKeywordsSuccess,
  pauseGroupKeywordsTestingPhase,
  pauseGroupKeywordsTestingPhaseSuccess,
  keywordActionFail,
} = keywordsManagmentSlice.actions;
export default keywordsManagmentSlice.reducer;
