import React, { FC, ReactElement, NamedExoticComponent } from 'react';
import classNames from 'classnames';

import {
  ProgressChainProps,
  ChainLinkName,
  ChainLinkStatus,
  ChainLinkProps,
} from './types';

import './ProgressChain.scss';

export const ProgressChain: FC<ProgressChainProps> = ({
  className,
  children,
  indexes = [],
  status = ChainLinkStatus.Empty,
}) => {
  const validLinks = React.Children.toArray(children).filter(
    (child: ReactElement<ChainLinkProps, NamedExoticComponent>) =>
      child.type.displayName === ChainLinkName
  );

  const chainLinks: ReactElement<ChainLinkProps>[] = [];

  if (status === ChainLinkStatus.Empty) {
    validLinks.forEach(link => {
      chainLinks.push(
        React.cloneElement<ChainLinkProps>(link as ReactElement, {
          ...(link as ReactElement).props,
          className: `chain__link--${ChainLinkStatus.Empty}`,
        })
      );
    });
  }

  if (status === ChainLinkStatus.Done) {
    validLinks.forEach(link => {
      chainLinks.push(
        React.cloneElement<ChainLinkProps>(link as ReactElement, {
          ...(link as ReactElement).props,
          className: `chain__link--${ChainLinkStatus.Done}`,
        })
      );
    });
  }

  if (
    indexes.length &&
    [
      ChainLinkStatus.Active,
      ChainLinkStatus.Error,
      ChainLinkStatus.Middone,
    ].includes(status)
  ) {
    validLinks.forEach((link, index) => {
      if (index + 1 < indexes[0].indexKey) {
        chainLinks.push(
          React.cloneElement<ChainLinkProps>(link as ReactElement, {
            ...(link as ReactElement).props,
            className: `chain__link--${ChainLinkStatus.Done}`,
          })
        );
      }

      if (index + 1 > indexes[indexes.length - 1].indexKey) {
        chainLinks.push(
          React.cloneElement<ChainLinkProps>(link as ReactElement, {
            ...(link as ReactElement).props,
            className: `chain__link--${ChainLinkStatus.Empty}`,
          })
        );
      }

      const statusIndex = indexes.find(
        ({ indexKey }) => indexKey === index + 1
      );
      if (statusIndex) {
        chainLinks.push(
          React.cloneElement<ChainLinkProps>(link as ReactElement, {
            ...(link as ReactElement).props,
            className: `chain__link--${status}`,
            link: statusIndex.link,
          })
        );
      }
    });
  }
  const wrapperClass = classNames('chain', className);

  return <div className={wrapperClass}>{chainLinks}</div>;
};
