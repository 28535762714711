import { NotifsActions, INotification } from '../actions/notifications.actions';
import { createReducer } from 'utils/createReducer';

export interface INotificationsState {
  isLoading: boolean;
  data: INotification[] | null;
  error: Error | null;
}

const initialState: INotificationsState = {
  data: null,
  isLoading: false,
  error: null,
};

export default createReducer<
  NotifsActions,
  { data: INotification[] } & { error: Error },
  INotificationsState
>(initialState, {
  [NotifsActions.FetchNotifs]: state => ({
    ...state,
    isLoading: true,
    error: null,
  }),
  [NotifsActions.FetchNotifsSuccess]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    data: payload!.data,
  }),
  [NotifsActions.FetchNotifsFail]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload!.error,
  }),
});
