import classNames from 'classnames';
import React, {
  FC,
  useRef,
  useState,
  ReactNode,
  ChangeEvent,
  useCallback,
  useMemo,
  Fragment,
} from 'react';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { RenderDDList, RenderDDInput } from './DropdownCommon.component';
import { Button, ButtonTypes } from 'components/ui-kit/Button';
import { TextInput } from 'components/ui-kit/Input';
import { Text } from 'components/ui-kit/Text';
import { useFlag } from 'utils/hooks/useFlag';

import { DropdownTextItem } from '../ListItem';
import { IDropdownTextProps, IItemData, ListPositions, TitlePositions } from './types';

import Search from 'jsx:assets/icons/search.svg';

import './Dropdown.scss';

export const DropdownText: FC<IDropdownTextProps> = ({
  listClass,
  listItemClass,
  className,
  options,
  defaultOpened,
  disabled,
  name,
  onChange,
  value,
  error,
  searchable = false,
  position = ListPositions.Bottom,
  titlePosition = TitlePositions.Left,
  onClearValue,
  children,
}) => {
  const [opened, toggleVisible] = useFlag(defaultOpened);

  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState<IItemData[] | null>(null);

  const btnClass = classNames('dropdown__btn', `dropdown__btn--${titlePosition}`, {
    'dropdown__btn--opened': opened,
  });
  const wrapperClass = classNames('dropdown dropdown-text', className, {
    'dropdown--error': error,
  });

  const handleSearchItems = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;
      setSearchValue(value);

      const valueRegex = new RegExp(value, 'ig');

      const filteredData = options
        .map((option) => {
          if (option.sublist) {
            const subOptions = option.sublist.filter(
              (subOption) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                valueRegex.test(subOption.label!) || valueRegex.test(subOption.value as string)
            );
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return {
              ...option,
              sublist: subOptions,
            } as IItemData;
          }

          return option;
        })
        .filter((option) => {
          if (!option.sublist) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return valueRegex.test(option.label!) || valueRegex.test(option.value as string);
          }
          return option;
        });

      setFilteredItems(filteredData);
    },
    [options]
  );

  const onItemClick = useCallback(
    (data: IItemData, parentData?: IItemData): void => {
      onChange(data, parentData);
      toggleVisible();
    },
    [onChange, toggleVisible]
  );
  const renderItems = (dataSet: IItemData[], parentDataSet?: IItemData): ReactNode =>
    dataSet.map((data) => {
      if (data.sublist) {
        return (
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          <Fragment key={`${data.value}-${data?.label ?? ''}`}>
            <DropdownTextItem
              className={classNames(listItemClass)}
              name={name}
              onChange={() => {
                onItemClick(data);
              }}
              value={data.value}
              label={data.label}
              disabled={true}
            />
            {renderItems(data.sublist, data)}
          </Fragment>
        );
      }
      return (
        <DropdownTextItem
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`${data.value}-${data?.label ?? ''}`}
          className={listItemClass}
          name={name}
          onChange={() => {
            onItemClick(data, parentDataSet);
          }}
          value={data.value}
          selected={data.value === value}
          label={data.label}
          dataType={data.dataType}
        />
      );
    });

  const stickyItem = useMemo(
    () =>
      searchable ? (
        <TextInput
          key="search_input"
          name="Search"
          Icon={Search}
          value={searchValue}
          label=""
          onChange={handleSearchItems}
          autoFocus={true}
        />
      ) : null,
    [searchable, handleSearchItems, searchValue]
  );

  const dropdownRef = useRef<HTMLDivElement>(null);
  const onClickOutside = useCallback(() => {
    toggleVisible(false);
  }, []);
  useOnClickOutside(dropdownRef, onClickOutside);

  return (
    <div className={wrapperClass} ref={dropdownRef}>
      <Button
        type={ButtonTypes.Secondary}
        className={btnClass}
        onClick={toggleVisible}
        disabled={disabled}
        name={name}>
        <RenderDDInput value={value} name={name} onClearValue={onClearValue}>
          {children}
        </RenderDDInput>
      </Button>
      <RenderDDList opened={opened} listClass={listClass} stickyItem={stickyItem}>
        {renderItems(filteredItems ?? options)}
      </RenderDDList>
      {error && !opened && <Text className="dropdown__error-text">{error}</Text>}
    </div>
  );
};

DropdownText.displayName = 'DropdownText';
