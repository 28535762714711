import React, { MouseEvent, FC, memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import Arrow from 'jsx:assets/icons/arrow.svg';
import Cross from 'jsx:assets/icons/cross.svg';

import { IDDListProps, IDDInputProps, ListPositions } from './types';
import { Value } from '../ListItem';

export const RenderDDList: FC<IDDListProps> = memo(
  ({ opened, children, listClass, stickyItem, position = ListPositions.Bottom }) => {
    const listWrapperClass = classNames(
      'dropdown__list-wrapper',
      listClass,
      `dropdown__list--${position}`,
      {
        'dropdown__list--opened': opened,
      }
    );

    return opened ? (
      <div className={listWrapperClass}>
        <>
          {stickyItem}
          <ul role="menu" aria-expanded={opened} className="dropdown__list">
            {children}
          </ul>
        </>
      </div>
    ) : null;
  }
);
RenderDDList.displayName = 'RenderDDList';

const isValueValid = (value: Value | Value[] | null): boolean =>
  Array.isArray(value) ? !!value.length : !!value || value === 0;

export const RenderDDInput: FC<IDDInputProps> = memo(
  ({ children, value, name, onClearValue, iconSize = '2rem' }) => {
    const onClearValueClick = useCallback(
      (e: MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onClearValue?.(name);
      },
      [onClearValue]
    );

    const crossButton = useMemo(
      () =>
        onClearValue &&
        isValueValid(value) && (
          <Cross
            className="dropdown__cross"
            width={iconSize}
            height={iconSize}
            onClick={onClearValueClick}
          />
        ),
      [onClearValue, value, onClearValueClick]
    );

    const arrowButton = useMemo(
      () =>
        !(onClearValue && isValueValid(value)) && (
          <Arrow className="dropdown__arrow" width={iconSize} height={iconSize} />
        ),
      [onClearValue, value]
    );

    return (
      <>
        <div className="dropdown__btn-content">{children}</div>
        <div className="dropdown__icons">
          {crossButton}
          {arrowButton}
        </div>
      </>
    );
  }
);
RenderDDInput.displayName = 'RenderDDInput';
