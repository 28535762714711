import React, { useEffect, useState } from "react"
import CustomSelect, { Option } from "../CustomSelect"
import { FrameSelectorProps } from "./FrameSelectorProps"
import style from "./FrameSelector.scss"
import { Link, LinkOff } from "@mui/icons-material"

const FrameSelector = (props: FrameSelectorProps) => {
  const { menuItems, isPrefillConfigured, linkIconDisabled } = props
  const [defaultSelected, setDefaultSelected] = useState(null)

  const handleFrameChange = (selectedFrame: Option) => {
    const selectedMenuOption = menuItems.find(
      (item) => selectedFrame.value === item.value
    )
    if (selectedMenuOption) {
      selectedMenuOption.action()
    }
  }

  useEffect(() => {
    if (!menuItems.length) return

    const defaultFrame = menuItems.find((item) => item.selected)
    if (defaultFrame) {
      setDefaultSelected(defaultFrame)
    } else {
      setDefaultSelected(menuItems[0])
    }
  }, [menuItems])

  return (
    <div className={style.frameSelector}>
      <CustomSelect
        defaultValue={defaultSelected}
        options={menuItems}
        variant="standard"
        type="singleSelect"
        onChange={handleFrameChange}
        startAdornment={<span className={style.inputAdornment}>Frame: </span>}
        withNoneOption={false}
        ariaLabel="frames"
      />
      {isPrefillConfigured && !linkIconDisabled && (
        <Link className={style.linkIcon} fontSize="medium" />
      )}
      {isPrefillConfigured && linkIconDisabled && (
        <LinkOff
          className={`${style.linkIcon} ${style.disabledIcon}`}
          fontSize="medium"
        />
      )}
    </div>
  )
}

export default FrameSelector
