import Dexie from 'dexie';

import { NumberValues, StringValues, OptionsValues } from 'constants/filters';

export class IDBFilters<K, C extends Dexie.Collection | Dexie.Table> {
  public [StringValues.START] = (metric: keyof K, value: string) => (
    collection: C
  ) => {
    const splittedValue = this.trimSpacesAndSplit(value);

    return collection.filter(keyword =>
      splittedValue.some(filter =>
        (keyword[metric] ? (keyword[metric] as string) : '')
          .toString()
          .toLowerCase()
          .startsWith(filter.toLowerCase())
      )
    );
  };

  public [StringValues.NSTART] = (metric: keyof K, value: string) => (
    collection: C
  ) => {
    const splittedValue = this.trimSpacesAndSplit(value);

    return collection.filter(
      keyword =>
        !splittedValue.some(filter =>
          (keyword[metric] ? (keyword[metric] as string) : '')
            .toString()
            .toLowerCase()
            .startsWith(filter.toLowerCase())
        )
    );
  };

  public [StringValues.END] = (metric: keyof K, value: string) => (
    collection: C
  ) => {
    const splittedValue = this.trimSpacesAndSplit(value);

    return collection.filter(keyword =>
      splittedValue.some(filter =>
        (keyword[metric] ? (keyword[metric] as string) : '')
          .toString()
          .toLowerCase()
          .endsWith(filter.toLowerCase())
      )
    );
  };

  public [StringValues.NEND] = (metric: keyof K, value: string) => (
    collection: C
  ) => {
    const splittedValue = this.trimSpacesAndSplit(value);

    return collection.filter(
      keyword =>
        !splittedValue.some(filter =>
          (keyword[metric] ? (keyword[metric] as string) : '')
            .toString()
            .toLowerCase()
            .endsWith(filter.toLowerCase())
        )
    );
  };

  public [StringValues.INC] = (metric: keyof K, value: string) => (
    collection: C
  ) => {
    const splittedValue = this.trimSpacesAndSplit(value);

    return collection.filter(keyword =>
      splittedValue.some(filter =>
        (keyword[metric] ? (keyword[metric] as string) : '')
          .toString()
          .toLowerCase()
          .includes(filter.toLowerCase())
      )
    );
  };

  public [StringValues.NINC] = (metric: keyof K, value: string) => (
    collection: C
  ) => {
    const splittedValue = this.trimSpacesAndSplit(value);

    return collection.filter(
      keyword =>
        !splittedValue.some(filter =>
          (keyword[metric] ? (keyword[metric] as string) : '')
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase())
        )
    );
  };

  public [StringValues.EQ] = (metric: keyof K, value: string) => (
    collection: C
  ) => {
    const splittedValue = this.trimSpacesAndSplit(value);

    return collection.filter(keyword =>
      splittedValue.some(
        filter =>
          (keyword[metric] ? (keyword[metric] as string) : '')
            .toString()
            .toLowerCase() === filter.toLowerCase()
      )
    );
  };

  // Number filters

  public [NumberValues.GT] = (metric: keyof K, value: string) => (
    collection: C
  ) =>
    collection.filter(keyword => (keyword[metric] as number) > Number(value));

  public [NumberValues.GTE] = (metric: keyof K, value: string) => (
    collection: C
  ) =>
    collection.filter(keyword => (keyword[metric] as number) >= Number(value));

  public [NumberValues.LT] = (metric: keyof K, value: string) => (
    collection: C
  ) =>
    collection.filter(keyword => (keyword[metric] as number) < Number(value));

  public [NumberValues.LTE] = (metric: keyof K, value: string) => (
    collection: C
  ) =>
    collection.filter(keyword => (keyword[metric] as number) <= Number(value));

  public [NumberValues.GTLT] = (metric: keyof K, value: string) => (
    collection: C
  ) => {
    const [min, max] = this.trimSpacesAndSplit(value);

    return collection
      .filter(keyword => (keyword[metric] as number) > Number(min))
      .filter(keyword => (keyword[metric] as number) < Number(max));
  };

  public [NumberValues.EQ] = (metric: keyof K, value: string) => (
    collection: C
  ) => {
    const splittedValue: Array<number> = this.trimSpacesAndSplit(
      value
    ).map(item => parseFloat(item));

    return collection.filter(keyword =>
      splittedValue.includes(keyword[metric] as number)
    );
  };

  public [NumberValues.NEQ] = (metric: keyof K, value: string) => (
    collection: C
  ) =>
    collection.filter(keyword => (keyword[metric] as number) !== Number(value));

  // Options filters

  public [OptionsValues.SOMEOF] = (metric: keyof K, value: string) => (
    collection: C
  ) => {
    const splittedValue = this.trimSpacesAndSplit(value);

    return collection.filter(keyword =>
      splittedValue.includes(keyword[metric] as string)
    );
  };

  private trimSpaces = (str: string): string => str.replace(/\s+/gm, ' ');

  private trimSpacesAndSplit = (str: string): string[] =>
    this.trimSpaces(str).split('|');
}
