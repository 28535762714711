import { stringify } from 'query-string';

export const createUrl = (
  baseUrl: string,
  url: string,
  query?: { [key: string]: string | number | boolean | any[] }
): string => {
  let requestUrl = `${baseUrl}${url}`;
  if (query) {
    requestUrl = `${requestUrl}?${stringify(query)}`;
  }
  return requestUrl;
};
