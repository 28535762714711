export enum UserStatus {
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
}

export interface IUserGroup {
  _id: string;
  name: string;
  description: string;
}

export enum UserRole {
  Customer = 'Customer team',
  Sales = 'Sales team',
  Admin = 'Admin',
  Client = 'Client',
}

export interface IUserRoleRes {
  _id: string;
  applicationId: string;
  description: string;
  name: UserRole;
}

export interface IUser<T> {
  sub: string;
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  locale: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  user_id: string;
  groups: IUserGroup[];
  roles: T[];
  status: UserStatus;
}

export interface IUserReducerState {
  currentUser: IUser<UserRole> | undefined;
  isAuthServiceReady?: boolean;
  isAuthenticated?: boolean;
  authToken?: string;
  accessToken?: string;
}
