import React, { useState } from 'react';
import { OrganisationsTableProps } from './types';
import { CompanyElement, DataTable } from '@dqna/seamless-suite-ui-kit';
import { IOrganisation } from 'types/admin.types';
import './OrganisationsTableNew.scss';
import { DeleteOrganisationModal } from '../DeleteOrganisationModal';
import { AddUserToOrganisationModalNew } from '../AddUserToOrganisationModalNew/AddUserToOrganisationModalNew.component';
import { useHistory } from 'react-router';
import { generateRouteWithIds } from 'utils/generateRouteWithIds';
import { Routes } from 'types/app.types';
import { useFlag } from 'utils/hooks/useFlag';

export const OrganisationsTableNew: React.FC<OrganisationsTableProps> = (props) => {
  const { organisations, isLoading, deleteOrganisation, addUserToOrganisation } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrganisation, setSelectedOrganisation] = useState<IOrganisation>();
  const [deleteModalOpenState, setDeleteModalOpenState] = useState(false);
  const [addUserModalOpenState, setAddUserModalOpenState] = useFlag(false);
  const { replace } = useHistory();

  const onDeleteOrganisationAction = (org: IOrganisation) => {
    setSelectedOrganisation(org);
    setDeleteModalOpenState(true);
  };

  const onViewOrganisationAction = (orgId: string) => {
    const pathname = generateRouteWithIds({
      route: Routes.ORGANISATION_DETAILS_ACCOUNTS,
      ids: { orgId },
    });

    replace({ pathname });
  };

  const onInviteUserAction = (org: IOrganisation) => {
    setSelectedOrganisation(org);
    setAddUserModalOpenState(true);
  };

  return (
    <>
      <DataTable
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: ({ row }: { row: IOrganisation }) => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onViewOrganisationAction(row.id);
                }}>
                <CompanyElement companyObject={{ name: row.name }} />
              </div>
            ),
          },
        ]}
        rows={organisations ?? []}
        getRowId={(row: IOrganisation) => row.id}
        loading={isLoading}
        rowMenuItems={(row: IOrganisation) => [
          {
            label: 'View',
            value: 'view',
            action: () => {
              onViewOrganisationAction(row.id);
            },
          },
          {
            label: 'Invite user',
            value: 'invite',
            action: () => {
              onInviteUserAction(row);
            },
          },
          {
            label: 'Delete',
            value: 'delete',
            action: () => {
              onDeleteOrganisationAction(row);
            },
          },
        ]}
        className="organisations-table"
        pagination
        onPageChange={setCurrentPage}
        page={currentPage}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 30]}
      />
      {selectedOrganisation && (
        <DeleteOrganisationModal
          modalOpenState={deleteModalOpenState}
          setModalOpenState={setDeleteModalOpenState}
          deleteOrganisation={deleteOrganisation}
          selectedOrganisation={selectedOrganisation}
        />
      )}
      {selectedOrganisation && (
        <AddUserToOrganisationModalNew
          modalOpenState={addUserModalOpenState}
          setModalOpenState={setAddUserModalOpenState}
          addUserToOrganisation={addUserToOrganisation}
          selectedOrganisation={selectedOrganisation}
        />
      )}
    </>
  );
};
