import { PopoverOrigin } from "@mui/material"

export const originTopRight: PopoverOrigin = {
  vertical: "top",
  horizontal: "right"
}

export const originBottomLeft: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left"
}

export const originBottomRight: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right"
}
