import React, { FC } from "react"
import { MenuItem, Menu, Button, Typography } from "@mui/material"
import { MoreVert, HelpOutlineOutlined } from "@mui/icons-material"
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import KebabMenuScss from "./KebabMenu.scss"
import { IKebabMenuProps, IKebabMenuItem } from "./IKebabMenuProps"
import { originBottomLeft, originTopRight } from "@src/utils/popupAnchors"
import { ArrowDropDownOutlined } from "@mui/icons-material"

function setCustomIcon(customIcon: string) {
  if (customIcon === "warning") {
    return (
      <WarningAmberOutlined
        className={KebabMenuScss.iconStyle}
        fontSize="large"
      />
    )
  } else if (customIcon === "info") {
    return (
      <InfoOutlinedIcon className={KebabMenuScss.iconStyle} fontSize="large" />
    )
  } else {
    return (
      <HelpOutlineOutlined
        className={KebabMenuScss.iconStyle}
        fontSize="large"
      />
    )
  }
}

type actionClick = (data: unknown) => void
const KebabMenu: FC<IKebabMenuProps> = ({
  menuItems,
  data,
  className,
  withArrowIcon,
  isKebabDisabled
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }
  const handleClose = (action: actionClick) => {
    setAnchorEl(null)
    if (action && typeof action === "function") {
      action(data)
    }
  }
  const menuItemsElements = menuItems.map(
    (item: IKebabMenuItem, itemIndex: number) => (
      <MenuItem
        aria-label={`action-${item.value}`}
        key={itemIndex}
        onClick={() => handleClose(item.action)}
        disabled={item.disabled}
      >
        <div className={KebabMenuScss.menuItemContainer}>
          <div className={KebabMenuScss.labelAndDescription}>
            <div className={KebabMenuScss.labelAndIcon}>
              <span>{item.label}</span>
              {item.endIcon && item.endIcon}
            </div>
            {item.component && item.component}
            {item.description && (
              <Typography
                className={KebabMenuScss.descriptionText}
                variant="caption"
              >
                {item.description}
              </Typography>
            )}
          </div>
          {item.customIcon && setCustomIcon(item.customIcon)}
        </div>
      </MenuItem>
    )
  )

  return (
    <div className={className}>
      <Button
        className={
          isKebabDisabled
            ? [KebabMenuScss.rowButton, KebabMenuScss.hideRowButton].join(" ")
            : KebabMenuScss.rowButton
        }
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="kebab-icon"
        onClick={handleClick}
        disableRipple
        disabled={isKebabDisabled}
      >
        {withArrowIcon ? <ArrowDropDownOutlined /> : <MoreVert />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: KebabMenuScss.menuActions }}
        aria-label="popup-menu"
        anchorOrigin={originBottomLeft}
        transformOrigin={originTopRight}
      >
        {menuItemsElements}
      </Menu>
    </div>
  )
}

export default KebabMenu
