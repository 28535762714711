import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { switchMap, catchError, mergeMap, take, tap, concatMap, filter } from 'rxjs/operators';
import { empty, from, of } from 'rxjs';
import { History } from 'history';
import { Action } from '@reduxjs/toolkit';
import { IDBConnection } from 'services/IDB.service';
import { ISubAccount } from 'types/subAccounts.types';
import { NotificationQueue, NotificationType } from 'services/NotificationQueue.service';
import { HTTPService } from 'services/HTTP.service';
import { subAccountsRoutes, userRoutes, keywordsGroupRoutes } from 'constants/routes';
import {
  fetchSubAccountError,
  updateSubAccountStatusError,
  deleteSubAccountError,
  deleteGroupError,
} from 'constants/errors/linkedAccount.errors';
import { KeywordGroupStatuses } from 'types/keywords.types';
import { IUser, UserRole } from 'types/user.type';
import { pauseGroupTestingPhaseError } from 'constants/errors/keywordsGroup.error';
import { NetworkError } from 'services/NetworkError.service';
import {
  changeSubAccountError,
  changeSubAccountFiltersError,
} from 'constants/errors/subAccounts.errors';
import { Routes } from 'types/app.types';
import { keywordActionFail } from 'store/reducers/keywords/keywordsManagment.reducer';
import {
  fetchSubAccounts,
  fetchSubAccountsSuccess,
  createSubAccount,
  deleteSubAccount,
  deleteSubAccountSuccess,
  changeSubAccountStatus,
  changeSubAccountSuccess,
  updateSubAccountDetails,
  updateSubAccountDetailsSuccess,
  subAccountsActionFail,
  deleteGroup,
  deleteGroupSuccess,
  userConfirmCloning,
  userConfirmCloningSuccess,
  updateSubAccountFilters,
  updateSubAccountFiltersSuccess,
} from 'store/reducers/subAccounts.reducer';

function mergeSubAccountUser(
  subAccount: ISubAccount,
  createUser?: IUser<UserRole>,
  updateUser?: IUser<UserRole>
): ISubAccount {
  return {
    ...subAccount,
    created: {
      ...subAccount.created,
      user_id: createUser?.name ?? null,
    },
    updated: {
      ...subAccount.updated,
      user_id: updateUser?.name ?? null,
    },
  };
}

export const getSubAccountsEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http }: { http: HTTPService }
) =>
  actions$.pipe(
    filter(fetchSubAccounts.match),
    mergeMap(({ payload }) => {
      return from(http.get<ISubAccount[]>(subAccountsRoutes.getSubAccountsList(payload))).pipe(
        mergeMap((data) => {
          const userIds = Array.from(
            new Set(
              data
                .map(({ created, updated }) => [created.user_id, updated.user_id])
                .flat()
                .filter((user) => user?.startsWith('google-oauth2'))
            )
          );

          return from(
            http.get<Array<IUser<UserRole>>>(userRoutes.getUsers(), {
              user_id: userIds,
            })
          ).pipe(
            switchMap((newUsers) => {
              const updatedData = data.map((subAcc) => {
                const newCreatedUser = newUsers.find(
                  (newUser) => newUser.user_id === subAcc.created.user_id
                );
                const newUpdatedUser = newUsers.find(
                  (newUser) => newUser.user_id === subAcc.updated.user_id
                );

                return mergeSubAccountUser(subAcc, newCreatedUser, newUpdatedUser);
              });
              return of(fetchSubAccountsSuccess({ accountId: payload, subAccounts: updatedData }));
            }),
            catchError((err) => {
              if (err instanceof NetworkError && err.code === 500) {
                const updatedData = data.map((subAcc) => mergeSubAccountUser(subAcc));
                return of(
                  fetchSubAccountsSuccess({ accountId: payload, subAccounts: updatedData })
                );
              }
              return of(subAccountsActionFail(fetchSubAccountError));
            })
          );
        }),
        catchError((_) => of(subAccountsActionFail(fetchSubAccountError)))
      );
    })
  );

export const createSubAccountEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http }: { http: HTTPService }
) =>
  actions$.pipe(
    filter(createSubAccount.match),
    concatMap(({ payload }) => {
      const {
        accountId,
        adwordsId,
        name,
        currency,
        subaccountUrls,
        country,
        countryAlpha2,
        managerId,
      } = payload;
      const requestBody = {
        account_id: accountId,
        adwords_account_id: String(adwordsId),
        currency_code: currency,
        name,
        site_urls: subaccountUrls,
        countryAlpha2,
        country,
        manager_id: managerId,
      };

      void http.post<ISubAccount>(subAccountsRoutes.createSubAccount(), requestBody);
      return empty();
    })
  );

export const archveSubAccountEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(deleteSubAccount.match),
    switchMap(({ payload }) => {
      const { accountId, subAccountId } = payload;

      return from(http.delete(subAccountsRoutes.deleteSubAccount(subAccountId))).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            `Subaccount is successfully deleted!`
          );
          return of(deleteSubAccountSuccess({ accountId, subAccountId }));
        }),
        catchError((_) => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            `Failure when deleting subaccount!`
          );
          return of(subAccountsActionFail(deleteSubAccountError));
        })
      );
    })
  );
export const updateSubAccountDetailsEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(updateSubAccountDetails.match),
    switchMap(({ payload }) => {
      const {
        accountId,
        subAccountId,
        maximumCpcBidInTestingPhase,
        maximumCpcBidInLivePhase,
        maximumCpcBidChangeInLivePhase,
        maximumIncrementRoas,
      } = payload;
      const requestBody = {
        account_id: accountId,
        id: subAccountId,
        maximum_cpc_bid_in_testing_phase: Number(maximumCpcBidInTestingPhase),
        maximum_cpc_bid_in_live_phase: Number(maximumCpcBidInLivePhase),
        maximum_cpc_bid_change_in_live_phase: Number(maximumCpcBidChangeInLivePhase),
        maximum_increment_roas: Number(maximumIncrementRoas),
      };

      return from(http.patch<ISubAccount>(subAccountsRoutes.changeDetails(), requestBody)).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            `Subaccount characteristics have successfully changed!`
          );
          return of(
            updateSubAccountDetailsSuccess({
              accountId,
              subAccountId,
              maximumCpcBidInTestingPhase,
              maximumCpcBidInLivePhase,
              maximumCpcBidChangeInLivePhase,
              maximumIncrementRoas,
            })
          );
        }),
        catchError((_) => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            `Failure when changing subaccount details!`
          );
          return of(subAccountsActionFail(changeSubAccountError));
        })
      );
    })
  );
export const changeSubAccountStatusEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(changeSubAccountStatus.match),
    switchMap(({ payload }) => {
      const { accountId, subAccountId, isActive } = payload;
      const requestBody = {
        id: subAccountId,
        isActive,
      };

      return from(http.patch(subAccountsRoutes.changeActivity(), requestBody)).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Subaccount activity changed successfully!'
          );

          return of(changeSubAccountSuccess({ accountId, subAccountId, isActive }));
        }),
        catchError((_) => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Error when changing subaccount activity!'
          );

          return of(subAccountsActionFail(updateSubAccountStatusError));
        })
      );
    })
  );

export const deleteGroupEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(deleteGroup.match),
    switchMap(({ payload }) => {
      const { accountId, subAccountId, groupId, date } = payload;

      return from(
        http.delete(subAccountsRoutes.keywordGroup(subAccountId), {
          keywords_group_id: groupId,
          account_id: accountId,
        })
      ).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            `Group is successfully deleted!`
          );
          return of(deleteGroupSuccess({ date, accountId, subAccountId, groupId }));
        }),
        catchError(() => {
          notificationQueue.showNotification(NotificationType.Toast, `Fail when deleting group!`);
          return of(subAccountsActionFail(deleteGroupError));
        })
      );
    })
  );

export const deleteGroupEpicSuccess = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { history, idb }: { history: History; idb: IDBConnection }
) =>
  actions$.pipe(
    filter(deleteGroupSuccess.match),
    take(1),
    tap(() => {
      const {
        location: { pathname },
        push,
      } = history;

      if (pathname !== Routes.LINKED_ACCOUNTS && !pathname.includes('keywordManagement')) {
        push(Routes.LINKED_ACCOUNTS);
      }
    }),
    tap(({ payload }) => {
      const { accountId, subAccountId, groupId, date } = payload;

      idb
        .updateEntities(
          [date, accountId, subAccountId, groupId],
          () => true,
          (keyword) => {
            keyword.groupId = 0;
            keyword.id = `${keyword.keyword}-${keyword.device}`;
          }
        )
        .catch((_) => {
          return of(subAccountsActionFail(deleteGroupError));
        });
    })
  );

export const userConfirmCloningEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http }: { http: HTTPService }
) =>
  actions$.pipe(
    filter(userConfirmCloning.match),
    switchMap(({ payload }) => {
      const { subAccountId, groupId, accountId } = payload;

      return from(
        http.patch(
          keywordsGroupRoutes.updateGroupData(subAccountId),
          {
            status: KeywordGroupStatuses.CAMPAIGN_ACTIVATE,
            id: groupId,
          },
          { account_id: accountId }
        )
      ).pipe(
        switchMap(() => of(userConfirmCloningSuccess({ accountId, subAccountId, groupId }))),
        catchError((_) => of(keywordActionFail(pauseGroupTestingPhaseError)))
      );
    })
  );

export const updateSubAccountFiltersEpic = (
  actions$: ActionsObservable<Action>,
  _state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(updateSubAccountFilters.match),
    switchMap(({ payload }) => {
      const { accountId, subAccountId, bgCampaignsFilters } = payload;
      const requestBody = {
        account_id: accountId,
        id: subAccountId,
        bgCampaignsFilters,
      };

      return from(http.patch(subAccountsRoutes.changeBgFilters(), requestBody)).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Subaccount filters have successfully changed!'
          );
          return of(
            updateSubAccountFiltersSuccess({ accountId, subAccountId, bgCampaignsFilters })
          );
        }),
        catchError((_) => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Failure when changing subaccount filters!'
          );
          return of(subAccountsActionFail(changeSubAccountFiltersError));
        })
      );
    })
  );
export default combineEpics(
  changeSubAccountStatusEpic,
  updateSubAccountDetailsEpic,
  getSubAccountsEpic,
  userConfirmCloningEpic,
  archveSubAccountEpic,
  deleteGroupEpic,
  deleteGroupEpicSuccess,
  createSubAccountEpic,
  updateSubAccountFiltersEpic
);
