import React, { FC } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from 'components/pages/App/PrivateRoute';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import configureStore from 'store';
import { incubetaLight } from '@dqna/seamless-suite-ui-kit';
import { ThemeProvider } from '@mui/material/styles';

import { AdminContainer } from 'components/containers/Admin';
import { PrivacyContainer } from 'components/containers/Privacy';
import { TermsAndConditionsContainer } from 'components/containers/TermsAndConditions';
import { HeaderContainer } from 'components/containers/Header';
import { HomeContainer } from 'components/containers/Home';
import { ExpiredInvitation } from '../ExpiredInvitation';
import { NoninvitedUser } from '../NoninvitedUser/NoninvitedUser';
import { AuthProvider } from './AuthProvider.component';
import { LicenseInfo } from '@mui/x-license-pro';
import { createBrowserHistory } from 'history';

import { UserRole } from 'types/user.type';

import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import { KeywordManagementContainer } from 'components/containers/KeywordManagement';
import { SubAccountsContainer } from 'components/containers/SubAccounts';
import { Routes } from 'types/app.types';
import { MainContainer } from 'components/containers/Main';
import { SomethingWentWrong } from '../SomethingWentWrong';

const history = createBrowserHistory();
const { store, authService } = configureStore(history);

export const App: FC = () => {
  LicenseInfo.setLicenseKey(process.env.MUI_LICENSE ?? '');

  return (
    <ThemeProvider theme={incubetaLight}>
      <Provider store={store}>
        <Router history={history}>
          <AuthProvider authService={authService} dispatch={store.dispatch}>
            <AppPagelayout>
              <Switch>
                <Route exact path={Routes.HOME}>
                  <HomeContainer />
                </Route>
                <Route exact path={Routes.INVITE_EXPIRED}>
                  <ExpiredInvitation />
                </Route>
                <Route exact path={Routes.NON_INIVTED_USER}>
                  <NoninvitedUser />
                </Route>
                <Route exact path={Routes.SOMETHING_WENT_WRONG}>
                  <SomethingWentWrong />
                </Route>

                <Route path={Routes.PRIVACY} redirectTo={Routes.HOME}>
                  <PrivacyContainer />
                </Route>
                <Route path={Routes.TERMS_AND_CONDITIONS} redirectTo={Routes.HOME}>
                  <TermsAndConditionsContainer />
                </Route>
                <PrivateRoute path={Routes.SIDEBAR} redirectTo={Routes.HOME}>
                  <MainContainer />
                  <PrivateRoute
                    path={Routes.ADMIN}
                    redirectTo={Routes.HOME}
                    roles={[UserRole.Admin, UserRole.Customer, UserRole.Sales]}>
                    <AdminContainer />
                  </PrivateRoute>
                  <PrivateRoute path={Routes.LINKED_ACCOUNTS} redirectTo={Routes.HOME}>
                    <SubAccountsContainer />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path={[
                      Routes.KEYWORD_MANAGEMENT_GROUPS,
                      Routes.KEYWORD_MANAGEMENT_GROUPS_NO_ID,
                    ]}
                    redirectTo={Routes.HOME}>
                    <KeywordManagementContainer />
                  </PrivateRoute>
                  <PrivateRoute
                    path={Routes.KEYWORD_MANAGEMENT_SUGGESTED_KEYWORDS}
                    redirectTo={Routes.HOME}>
                    <KeywordManagementContainer />
                  </PrivateRoute>
                  <PrivateRoute
                    path={Routes.KEYWORD_MANAGEMENT_SUB_ACCOUNT_DETAILS}
                    redirectTo={Routes.HOME}>
                    <KeywordManagementContainer />
                  </PrivateRoute>
                  <PrivateRoute
                    path={Routes.KEYWORD_MANAGEMENT_AB_TESTING}
                    redirectTo={Routes.HOME}>
                    <KeywordManagementContainer />
                  </PrivateRoute>
                </PrivateRoute>
              </Switch>
            </AppPagelayout>
            <ToastContainer />
          </AuthProvider>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

const AppPagelayout: FC = ({ children }) => {
  return (
    <>
      <HeaderContainer />
      <div className="app__page-layout">{children}</div>
    </>
  );
};
