import classNames from 'classnames';
import React, {
  FC,
  KeyboardEvent,
  MouseEvent,
  useMemo,
  forwardRef,
  useCallback,
} from 'react';

import { Text } from 'components/ui-kit/Text';

import { getId } from 'utils/getId';

import { InputTypes, ITextProps, inputDisplayNames } from './types';
import { KeyCodes } from '../../../constants';

import './Input.scss';

export const TextInput = forwardRef<HTMLInputElement, ITextProps>(
  (
    {
      type = InputTypes.Text,
      Icon,
      disabled,
      onChange,
      className,
      value,
      defaultValue,
      name,
      onEnter,
      outlined,
      label,
      validationError,
      tip,
      autoFocus,
      onFocus,
      readOnly,
    },
    ref
  ) => {
    const id = useMemo(() => getId(), []);

    const blockClasses = classNames('input-text', className, {
      'input-text--iconed': Icon,
      'input-text--error': !!validationError,
    });
    const onEnterPress = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        e.keyCode === KeyCodes.Enter && onEnter?.(e.currentTarget);
      },
      [onEnter]
    );

    const inputStyles = classNames('input-text__field', {
      'input-text__field--outlined': outlined,
    });

    const handleIconClick = useCallback(
      (e: MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onEnter?.();
      },
      [onEnter]
    );

    return (
      <div className={blockClasses}>
        <div className="input-text__wrapper">
          <input
            type={type}
            id={id}
            disabled={disabled}
            onChange={onChange}
            defaultValue={defaultValue}
            value={value}
            onKeyDown={onEnterPress}
            className={inputStyles}
            name={name}
            placeholder={label}
            title={label}
            autoFocus={autoFocus}
            onFocus={onFocus}
            readOnly={!!readOnly}
            ref={ref}
          />
          <label htmlFor={id} className="input-text__label">
            {label}
          </label>
          {Icon && (
            <Icon
              className="input-text__icon"
              width="2.4rem"
              height="2.4rem"
              onClick={handleIconClick}
              title={label}
            />
          )}
        </div>
        {tip && <div className="input-text__tip">{tip}</div>}
        {validationError && (
          <Text className="input-text__error-text">{validationError}</Text>
        )}
      </div>
    );
  }
);

TextInput.displayName = inputDisplayNames.textInput;
