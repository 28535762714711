import React, { FC, useEffect, useState } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router';
import { AdminContainerProps } from 'components/containers/Admin';

import './Admin.scss';
import { Routes } from 'types/app.types';
import { OrganisationsNew } from './OrganisationsNew';
import { OrganisationDetailsNew } from './OrganisationDetailsNew.component.tsx';

export const Admin: FC<AdminContainerProps> = ({
  currentOrganisation,
  getOrgansiation,
  createOrganisation,
  addUser,
  deleteUser,
  getOrganisations,
  organisations,
  isOrgsLoading,
  deleteOrganisation,
  reinviteUser,
  deleteReinvitedUser,
  revertBids,
  setPageTitle,
  getAccountsOfAllOrgs,
  accountsOfAllOrgs,
  deleteAccount,
  isGetAccountsOfAllOrgsLoading,
  newAccount,
  createAccount,
  linkDataSourcesStatuses,
  checkDataSourceIsLinked,
  accessToken,
  authToken,
  checkDataSourceIsVerifiedReset,
  accounts,
  editAccountName,
  getAccounts,
  newAccountStateReset,
  isDataSourceVerified,
  verifyAdverityAdWords,
  verifyAdveritySearchConsole,
  creationError,
  isOrgCreationLoading,
}) => {
  const [addAccountModalOpenState, setAddAccountModalOpenState] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/admin/organisations') {
      setPageTitle({
        title: 'Organisations',
        routes: [
          { breadcrumbCaption: 'Home', breadcrumbUrl: Routes.HOME },
          { breadcrumbCaption: 'Organisations', breadcrumbUrl: Routes.ADMIN },
        ],
      });
    }
  }, [pathname]);

  return (
    <div className="admin">
      <Switch>
        <Route path={Routes.ORGANISATIONS} exact>
          <OrganisationsNew
            organisations={organisations}
            getOrganisations={getOrganisations}
            isLoading={isOrgsLoading}
            deleteOrganisation={deleteOrganisation}
            createOrganisation={createOrganisation}
            addUserToOrganisation={addUser}
            isOrgCreationLoading={isOrgCreationLoading}
          />
        </Route>
        <Route path={Routes.ORGANISATION_DETAILS}>
          <OrganisationDetailsNew
            organisation={currentOrganisation}
            getOrganisation={getOrgansiation}
            setPageTitle={setPageTitle}
            getAccountsOfAllOrgs={getAccountsOfAllOrgs}
            accountsOfAllOrgs={accountsOfAllOrgs}
            deleteAccount={deleteAccount}
            isGetAccountsOfAllOrgsLoading={isGetAccountsOfAllOrgsLoading}
            users={currentOrganisation?.users}
            reinviteUser={reinviteUser}
            deleteReinvitedUser={deleteReinvitedUser}
            deleteUser={deleteUser}
            newAccount={newAccount}
            createAccount={createAccount}
            linkDataSourcesStatuses={linkDataSourcesStatuses}
            checkDataSourceIsLinked={checkDataSourceIsLinked}
            accessToken={accessToken ?? ''}
            authToken={authToken ?? ''}
            checkDataSourceIsVerifiedReset={checkDataSourceIsVerifiedReset}
            accounts={accounts ?? []}
            editAccountName={editAccountName}
            getAccounts={getAccounts}
            newAccountStateReset={newAccountStateReset}
            isDataSourceVerified={isDataSourceVerified}
            verifyAdverityAdWords={verifyAdverityAdWords}
            verifyAdveritySearchConsole={verifyAdveritySearchConsole}
            creationError={creationError}
            modalOpenState={addAccountModalOpenState}
            setModalOpenState={setAddAccountModalOpenState}
            addUserToOrganisation={addUser}
          />
        </Route>
        <Redirect from="/admin" to="/admin/organisations" exact />
      </Switch>
    </div>
  );
};

Admin.displayName = 'AdminPage';
