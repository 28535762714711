import React, { FC, useMemo } from 'react';
import formatDate from 'date-fns/format';
import VerticalStepper from '@dqna/seamless-suite-ui-kit/dist/components/VerticalStepper';
import { PauseCircleOutline } from '@mui/icons-material';
import { Avatar, Chip } from '@mui/material';
import {
  IKeywordGroupPhase,
  KeywordGroupStatuses,
  KeywordGroupStatusesSteppers,
} from 'types/keywords.types';
import { KeywordGroupVerticalStepperProps } from './type';

export const KeywordGroupVerticalStepper: FC<KeywordGroupVerticalStepperProps> = ({ group }) => {
  const sortedPhases = useMemo(() => {
    const phases = group?.phases?.map((item) => {
      return { ...item, index: KeywordGroupStatusesSteppers[item.phase] };
    });
    return phases?.sort((a: { index: number }, b: { index: number }) => a.index - b.index);
  }, [group]);

  const stepperDate = (data: IKeywordGroupPhase[], phase: string) => {
    const localPhase = data.find((item) => item.phase === phase);
    if (!localPhase?.startDate) {
      return '';
    }
    return formatDate(new Date(`${localPhase.startDate}z`), 'dd.MM.yyyy');
  };

  const chipItem = group.status === KeywordGroupStatuses.TESTING_PHASE_PAUSED && (
    <Chip
      label="Paused"
      color="info"
      variant="outlined"
      className="learning-chip"
      avatar={
        <Avatar sx={{ bgcolor: 'transparent' }}>
          <PauseCircleOutline color="info" />
        </Avatar>
      }
    />
  );

  const learningPhaseDate = `${formatDate(
    new Date(`${group.testPhase.startDate}z`),
    'dd.MM.yyyy'
  )} ${
    group.testPhase.daysLeft !== 0
      ? `(${group.testPhase.daysLeft} days left)`
      : stepperDate(sortedPhases, KeywordGroupStatuses.LIVE_PHASE)
  }`;

  return (
    <VerticalStepper
      activeStep={KeywordGroupStatusesSteppers[group.status]}
      steps={[
        {
          subTitle: stepperDate(sortedPhases, KeywordGroupStatuses.CREATED),
          title: 'Created',
        },
        {
          subTitle: (
            <span className="phase-learning">
              {learningPhaseDate}
              {chipItem}
            </span>
          ),
          title: 'Learning',
        },
        {
          subTitle: stepperDate(sortedPhases, KeywordGroupStatuses.LIVE_PHASE),
          title: 'Live',
        },
      ]}
    />
  );
};
