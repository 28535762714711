import { MouseEvent, FormEvent, ReactNode } from 'react';

export enum ButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Link = 'link',
}

export enum ActionTypes {
  Button = 'button',
  Submit = 'submit',
}

export interface IButtonProps {
  readonly type?: ButtonTypes;
  readonly onClick?: (
    e?:
      | MouseEvent<HTMLButtonElement | HTMLAnchorElement>
      | FormEvent<HTMLFormElement>
  ) => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly testId?: string;
  readonly name: string;
  readonly link?: string;
  readonly actionType?: ActionTypes;
  readonly icon?: ReactNode;
}
