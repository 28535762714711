import React from 'react';
import { SearchInput } from '@dqna/seamless-suite-ui-kit';
import useSearchInUrl from 'utils/hooks/useSearchInUrl';

export const OrganisationSearch = () => {
  const { handleSearch, search } = useSearchInUrl();

  return (
    <SearchInput
      placeholder="Search for organisation"
      classNameInputContainer="organisations-page__search"
      onChange={handleSearch}
      value={search}
    />
  );
};
