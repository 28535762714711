import { AppPageTitle, LayoutState } from 'types/layout.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: LayoutState = {
  pageTitle: { title: 'Seamless Search', routes: [] },
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setPageTitle: (state, action: PayloadAction<AppPageTitle>) => {
      state.pageTitle = action.payload;
    },
  },
});

export const { setPageTitle } = layoutSlice.actions;
export default layoutSlice.reducer;
