import classNames from 'classnames';
import React, { FC, KeyboardEvent, useCallback, useMemo, memo } from 'react';

import { KeyCodes } from '../../../../constants';
import { IDdItemTextProps } from './types';

export const DropdownTextItem: FC<IDdItemTextProps> = memo(
  ({ onChange, selected, value, name, className, label, disabled }) => {
    const itemClass = classNames(
      'dropdown__item dropdown__text-item',
      className,
      {
        'dropdown__text-item--checked': selected,
        'dropdown__text-item--disabled': disabled,
      }
    );

    const handleEnterPress = useCallback(
      ({ keyCode }: KeyboardEvent<HTMLLIElement>) => {
        if (keyCode === KeyCodes.Enter) {
          onChange();
        }
      },
      [onChange]
    );

    const listTitle = useMemo(() => label || value.toString(), [label, value]);

    return (
      <li
        title={listTitle}
        className={itemClass}
        role="menuitem"
        aria-labelledby={name}
        aria-describedby={`${value}`}
        aria-checked={selected}
        tabIndex={0}
        onClick={onChange}
        onKeyDown={handleEnterPress}>
        <span className="dropdown__item-data">{label || value}</span>
      </li>
    );
  }
);

DropdownTextItem.displayName = 'DropdownTextItem';
