import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IKeywordsGroupCreationState, IKeyword } from 'types/keywords.types';

export type { IKeywordsGroupCreationState } from 'types/keywords.types';

export const keywordsGroupCreationInitialState: IKeywordsGroupCreationState = {
  isLoading: false,
  keywords: null,
  error: null,
};

const keywordsGroupCreationSlice = createSlice({
  name: 'keywordsGroupCreation',
  initialState: keywordsGroupCreationInitialState,
  reducers: {
    updateKeywordsGroupCreation: (
      state,
      action: PayloadAction<{
        accountId: number;
        subAccountId: number;
        date: string;
        metric: keyof IKeyword;
        searchString: string;
        groupId?: number;
      }>
    ) => {
      state.isLoading = true;
    },
    updateKeywordsGroupCreationSuccess: (state, action: PayloadAction<IKeyword[] | null>) => {
      state.isLoading = false;
      state.keywords = action.payload;
    },
    updateKeywordsGroupCreationFail: (state, action: PayloadAction<Error>) => {
      state.isLoading = true;
      state.error = action.payload;
    },
  },
});

export const {
  updateKeywordsGroupCreation,
  updateKeywordsGroupCreationFail,
  updateKeywordsGroupCreationSuccess,
} = keywordsGroupCreationSlice.actions;

export default keywordsGroupCreationSlice.reducer;
