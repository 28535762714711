import React, { useState } from 'react';
import { DataTable } from '@dqna/seamless-suite-ui-kit';
import { Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import { formatCurrency } from 'country-currency-map';

import { IKeywordNewType } from 'types/keywords.types';
import { LOW_VOLUME_LIMIT } from 'constants/keywords';
import { ISubAccount } from 'types/subAccounts.types';
import { floatNumberFixer } from 'utils/floatNumberFixer';

interface RemoveKeywordsDataTableProps {
  keywordsOfSubaccount: IKeywordNewType[];
  keywordsOfSubaccountLoading: boolean;
  setRowSelectionModel: (model: number[]) => void;
  rowSelectionModel: number[];
  subAccount?: ISubAccount;
}

const RemoveKeywordsDataTable: React.FC<RemoveKeywordsDataTableProps> = (props) => {
  const {
    keywordsOfSubaccount,
    keywordsOfSubaccountLoading,
    rowSelectionModel,
    setRowSelectionModel,
    subAccount,
  } = props;
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const onSelectAll = () => {
    setRowSelectionModel(keywordsOfSubaccount.map((keyword) => keyword.id) ?? []);
  };

  const onSelectAllFromThisPage = () => {
    setRowSelectionModel(
      keywordsOfSubaccount
        .map((keyword) => keyword.id)
        .slice(currentPage * pageSize, pageSize * (currentPage + 1)) ?? []
    );
  };

  const onSelectAllLowVolume = () => {
    const lowVolumeKeywordIds = keywordsOfSubaccount.reduce((filteredIds: number[], keyword) => {
      if (keyword.sconClicksSum < LOW_VOLUME_LIMIT) {
        filteredIds.push(keyword.id);
      }

      return filteredIds;
    }, []);

    setRowSelectionModel(lowVolumeKeywordIds ?? []);
  };

  const onDeselectAll = () => {
    setRowSelectionModel([]);
  };

  const currencySymbol = formatCurrency('', subAccount?.currencyCode);

  const columns: GridColumns = [
    {
      field: 'keyword',
      headerName: 'Name',
      flex: 2,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography variant="h2">{row.keyword}</Typography>
          {row.sconClicksSum < LOW_VOLUME_LIMIT && <SyncDisabledIcon color="error" />}
        </Stack>
      ),
    },
    {
      field: 'keywordGroupName',
      headerName: 'Keyword group',
      flex: 1,
    },
    {
      field: 'sconClicksSum',
      headerName: 'Organic clicks',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.sconClicksSum)}</span>
      ),
    },

    {
      field: 'gadsTextClicksSum',
      headerName: 'Paid clicks',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.gadsTextClicksSum)}</span>
      ),
    },
    {
      field: 'totalCTR',
      headerName: 'Total CTR',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.totalCTR)}</span>
      ),
    },
    {
      field: 'gadsTextCPC',
      headerName: 'Paid CPC',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.gadsTextCPC)}</span>
      ),
    },
    {
      field: 'totalRevenueSum',
      headerName: `Ttl. Rev. (${currencySymbol})`,
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.totalRevenueSum)}</span>
      ),
    },
    {
      field: 'totalMargin',
      headerName: `Ttl. Mrg. (${currencySymbol})`,
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.totalMargin)}</span>
      ),
    },
  ];

  const itemsCountOnCurrentPage = () => {
    const remainingItems = keywordsOfSubaccount.length - pageSize * currentPage;

    return Math.min(remainingItems, pageSize);
  };

  return (
    <DataTable
      checkboxHeaderDropdownMenuItems={[
        {
          label: `All available (${keywordsOfSubaccount.length})`,
          value: 'all',
          action: onSelectAll,
        },
        {
          label: `All from this page (${itemsCountOnCurrentPage()})`,
          value: 'allFromThisPage',
          action: onSelectAllFromThisPage,
        },
        {
          label: 'Low volume',
          value: 'lowVolume',
          action: onSelectAllLowVolume,
        },
        {
          label: 'Deselect all',
          value: 'deselect',
          action: onDeselectAll,
          disabled: rowSelectionModel.length !== keywordsOfSubaccount.length,
        },
      ]}
      onSelectionModelChange={setRowSelectionModel}
      selectionModel={rowSelectionModel}
      checkboxHeaderWithDropdown
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      page={currentPage}
      onPageChange={setCurrentPage}
      pagination
      checkboxSelection
      columns={columns}
      rows={keywordsOfSubaccount}
      loading={keywordsOfSubaccountLoading}
      withRowBorder
      rowsPerPageOptions={[10, 20, 30]}
      withFilterPanel
    />
  );
};

export default RemoveKeywordsDataTable;
