import { MOCK_API_URL } from '__mocks__/apiUrlMock';

export enum Auth0Envs {
  Staging = 'staging',
  Development = 'development',
  Production = 'production',
  Demo = 'demo',
  Test = 'test',
}

interface Auth0Config {
  domain: string;
  clientId: string;
  redirectUri: string;
  logoutUri: string;
  nonInvitedUserUri: string;
}

interface OneSignalConfig {
  appId: string;
  safari_web_id?: string;
}

const oneSignalEnvs: { [key in Auth0Envs]: OneSignalConfig } = {
  [Auth0Envs.Development]: {
    appId: '28e456e1-d85c-41c7-92f4-edd31a439073',
    safari_web_id: 'web.onesignal.auto.560021c2-877c-448b-9811-f001e7b5ec58',
  },
  [Auth0Envs.Staging]: {
    appId: '8dd292d1-e01d-4da8-b5f0-b01b7a507df2',
    safari_web_id: 'web.onesignal.auto.081d2360-74df-41b0-afe2-959ef72fcc8c',
  },
  [Auth0Envs.Production]: {
    appId: '1472ab7f-f900-47fe-8bad-8708c1a9ce54',
    safari_web_id: 'web.onesignal.auto.69a0d04c-4cfa-4f80-8d34-652264ce8748',
  },
};

const auth0envs: { [key in Auth0Envs]: Auth0Config } = {
  [Auth0Envs.Staging]: {
    domain: 'incubeta-mario-staging.eu.auth0.com',
    clientId: 'ynT56FKvlk8SB35ixZcKNb6rE8tUuwS9',
    redirectUri: 'https://seamless.incubeta.tech',
    logoutUri: 'https://seamless.incubeta.tech',
    nonInvitedUserUri: 'https://seamless.incubeta.tech/noninvited-user',
  },
  [Auth0Envs.Demo]: {
    domain: 'incubeta-mario-staging.eu.auth0.com',
    clientId: 'ynT56FKvlk8SB35ixZcKNb6rE8tUuwS9',
    redirectUri: 'https://demo.search.seamlesspro.io/',
    logoutUri: 'https://demo.search.seamlesspro.io/',
    nonInvitedUserUri: 'https://demo.search.seamlesspro.io/noninvited-user',
  },
  [Auth0Envs.Development]: {
    domain: 'incubeta-mario-staging.eu.auth0.com',
    clientId: 'ynT56FKvlk8SB35ixZcKNb6rE8tUuwS9',
    // domain: 'incubeta.eu.auth0.com',
    // clientId: '5cErmd5cRncnwEqbCLZ6JyqctsWTPSbJ',
    redirectUri: 'http://localhost:8081',
    logoutUri: 'http://localhost:8081',
    nonInvitedUserUri: 'http://localhost:8081/noninvited-user',
  },
  [Auth0Envs.Production]: {
    domain: 'incubeta.eu.auth0.com',
    clientId: '5cErmd5cRncnwEqbCLZ6JyqctsWTPSbJ',
    redirectUri: 'https://search.seamlesspro.io',
    logoutUri: 'https://search.seamlesspro.io',
    nonInvitedUserUri: 'https://search.seamlesspro.io/noninvited-user',
  },
  [Auth0Envs.Test]: {
    domain: '',
    clientId: '',
    redirectUri: '',
    logoutUri: '',
    nonInvitedUserUri: '',
  },
};

export const API_URL = process.env.NODE_ENV === 'test' ? MOCK_API_URL : process.env.API_URL ?? '';

export const NODE_ENV = (process.env.NODE_ENV as Auth0Envs) || Auth0Envs.Development;

export const IDBName = 'seamless';

export const auth0config = auth0envs[NODE_ENV];

export const oneSignalConfig = oneSignalEnvs[NODE_ENV];
