import React, { useEffect, useState } from 'react';
import { OrganisationsTableNew } from '../OrganisationsTableNew';
import { OrganisationsProps } from './types';
import { CircularProgress, Stack } from '@mui/material';
import { Button } from '@dqna/seamless-suite-ui-kit';
import './OrganisationsNew.scss';
import { OrganisationSearch } from '../OrganisationsSearch';
import useSearchInUrl from 'utils/hooks/useSearchInUrl';
import { IOrganisation } from 'types/admin.types';
import { NoSearchResult } from 'components/ui-kit/NoSearchResult';
import { useFlag } from 'utils/hooks/useFlag';
import { CreateOrganisationModalNew } from '../CreateOrganisationModalNew';

export const OrganisationsNew: React.FC<OrganisationsProps> = (props) => {
  const {
    organisations,
    isLoading,
    getOrganisations,
    deleteOrganisation,
    createOrganisation,
    addUserToOrganisation,
    isOrgCreationLoading,
  } = props;
  const [isCreateOrgModalOpen, setIsCreateOrgModalOpen] = useFlag(false);
  const { search } = useSearchInUrl();
  const [filteredOrgs, setFilteredOrgs] = useState<IOrganisation[]>([]);

  useEffect(() => {
    getOrganisations();
  }, []);

  useEffect(() => {
    if (!organisations) {
      return;
    }

    if (search) {
      const searchResult = organisations.filter((org) => org.name.toLowerCase().includes(search));
      setFilteredOrgs(searchResult);
    } else {
      setFilteredOrgs(organisations);
    }
  }, [organisations, search]);

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between" mb="1.6rem">
        <OrganisationSearch />
        <Button variant="contained" color="secondary" onClick={setIsCreateOrgModalOpen}>
          Create Organisation
        </Button>
      </Stack>
      {isOrgCreationLoading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {isLoading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {!!filteredOrgs.length && (
        <OrganisationsTableNew
          organisations={filteredOrgs}
          isLoading={isLoading}
          deleteOrganisation={deleteOrganisation}
          addUserToOrganisation={addUserToOrganisation}
        />
      )}
      {search && !filteredOrgs.length && !isLoading && <NoSearchResult />}
      <CreateOrganisationModalNew
        modalOpenState={isCreateOrgModalOpen}
        setModalOpenState={setIsCreateOrgModalOpen}
        createOrganisation={createOrganisation}
      />
    </>
  );
};
