import React, { FC } from "react"
import { IChevronProps } from "./IChevronProps"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

const Chevron: FC<IChevronProps> = ({ extended }) =>
  extended ? (
    <ExpandLess aria-label="chevron-less" color="action" />
  ) : (
    <ExpandMore aria-label="chevron-more" color="action" />
  )

export default Chevron
