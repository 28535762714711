import { DataType } from 'components/ui-kit/Dropdown';

export type MatcherFunction<T> = (actual?: T, value?: string) => boolean;

export enum NumberValues {
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  EQ = 'eq',
  NEQ = 'neq',
  GTLT = 'gtlt',
}

export enum StringValues {
  START = 'start',
  NSTART = 'nstart',
  END = 'end',
  NEND = 'nend',
  INC = 'inc',
  NINC = 'ninc',
  EQ = 'seq',
}

export enum OptionsValues {
  SOMEOF = 'someof',
}

export interface FilterSchema {
  label: string;
  value: NumberValues | StringValues | OptionsValues;
  placeholder: string;
}

const valuePlaceholder = 'value';
const valueRangePlaceholder = 'value1 | value2 | ...';

export const numberPatterns: FilterSchema[] = [
  {
    label: 'Greater than',
    value: NumberValues.GT,
    placeholder: valuePlaceholder,
  },
  {
    label: 'Greater than or equal to',
    value: NumberValues.GTE,
    placeholder: valuePlaceholder,
  },
  {
    label: 'Less than',
    value: NumberValues.LT,
    placeholder: valuePlaceholder,
  },
  {
    label: 'Less than or equal to',
    value: NumberValues.LTE,
    placeholder: valuePlaceholder,
  },
  {
    label: 'Between',
    value: NumberValues.GTLT,
    placeholder: valueRangePlaceholder,
  },
  {
    label: 'Equals',
    value: NumberValues.EQ,
    placeholder: valuePlaceholder,
  },
  {
    label: "Doesn't equal",
    value: NumberValues.NEQ,
    placeholder: valuePlaceholder,
  },
];

export const stringPatterns: FilterSchema[] = [
  {
    label: 'Starts with',
    value: StringValues.START,
    placeholder: valueRangePlaceholder,
  },
  {
    label: "Doesn't start with",
    value: StringValues.NSTART,
    placeholder: valueRangePlaceholder,
  },
  {
    label: 'Ends with',
    value: StringValues.END,
    placeholder: valueRangePlaceholder,
  },
  {
    label: "Doesn't end with",
    value: StringValues.NEND,
    placeholder: valueRangePlaceholder,
  },
  {
    label: 'Contains',
    value: StringValues.INC,
    placeholder: valueRangePlaceholder,
  },
  {
    label: "Doesn't contain",
    value: StringValues.NINC,
    placeholder: valueRangePlaceholder,
  },
];

export const optionsPatterns: FilterSchema[] = [
  {
    label: 'Matches some of',
    value: OptionsValues.SOMEOF,
    placeholder: '',
  },
];

export const filterPatterns: {
  [key in DataType]: FilterSchema[];
} = {
  [DataType.String]: stringPatterns,
  [DataType.Number]: numberPatterns,
  [DataType.Options]: optionsPatterns,
};

export const KEYWORD_LIMIT = 10000;

export const GET_NOTIFICATION_INTERVAL = 60000;

export const CONFLICT_ERROR_CODE = 409;

export const TABLE_PAGE_KEYWORD_LIMIT = 30;
