import classNames from 'classnames';
import React, { FC, useRef, useCallback, useMemo } from 'react';

import { Button, ButtonTypes } from 'components/ui-kit/Button';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { DropdownCheckBoxItem } from '../ListItem';
import { RenderDDList, RenderDDInput } from './DropdownCommon.component';
import { IDropdownProps, TitlePositions, IMultiselectItemData } from './types';

import './Dropdown.scss';
import { useFlag } from 'utils/hooks/useFlag';

export const DropdownMultiselect: FC<IDropdownProps> = ({
  listClass,
  listItemClass,
  className,
  options,
  defaultOpened,
  disabled,
  name,
  onChange,
  titlePosition = TitlePositions.Left,
  value,
  onClearValue,
  children,
}) => {
  const [opened, toggleVisible] = useFlag(defaultOpened);

  const onItemClick = useCallback(
    (option: IMultiselectItemData) => {
      onChange(option);
    },
    [onChange]
  );
  const items = useMemo(
    () =>
      options.map((option: IMultiselectItemData) => {
        return (
          <DropdownCheckBoxItem
            key={`${option.value}`}
            className={classNames(listItemClass)}
            name={name}
            onChange={() => onItemClick(option)}
            value={option.value}
            label={option.label}
            selected={option.selected}
            disabled={option.disabled}
          />
        );
      }),
    [options]
  );

  const btnClass = classNames(
    'dropdown__btn',
    `dropdown__btn--${titlePosition}`,
    {
      'dropdown__btn--opened': opened,
    }
  );

  const wrapperClass = classNames('dropdown dropdown-text', className);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const onClickOutside = useCallback(() => {
    toggleVisible(false);
  }, []);
  useOnClickOutside(dropdownRef, onClickOutside);

  return (
    <div className={wrapperClass} ref={dropdownRef}>
      <Button
        type={ButtonTypes.Secondary}
        className={btnClass}
        onClick={toggleVisible}
        disabled={disabled}
        name={name}>
        <RenderDDInput value={value} name={name} onClearValue={onClearValue}>
          {children}
        </RenderDDInput>
      </Button>
      <RenderDDList opened={opened} listClass={listClass}>
        {items}
      </RenderDDList>
    </div>
  );
};

DropdownMultiselect.displayName = 'DropdownMultiselect';
