import { DataSourcesTypes } from 'types/accounts.type';

export const DEFAULT_MAXIMUM_CPC_BID_IN_TESTING_PHASE = 5.0;
export const DEFAULT_MAXIMUM_CPC_BID_IN_LIVE_PHASE = 40.0;
export const DEFAULT_MAXIMUM_CPC_BID_CHANGE_IN_LIVE_PHASE = 0.01;
export const DEFAULT_MAXIMUM_INCREMENT_ROAS = 1.0;
export const REQUIRED_ACCOUNTS_TYPES = [
  DataSourcesTypes.ADVERITY_ADWORDS,
  DataSourcesTypes.ADVERITY_SEARCH_CONSOLE,
  DataSourcesTypes.GOOGLE_ADWORDS,
];

export const REQUIRED_VERIFYED_ACCOUNT_TYPES = [
  DataSourcesTypes.ADVERITY_ADWORDS,
  DataSourcesTypes.ADVERITY_SEARCH_CONSOLE,
];

export const LINK_ACCOUNT_MODAL_NUMBER = 5; // number of steps for creating account modal
