import React, { FC, useMemo, ChangeEvent, useCallback } from 'react';

import { getId } from 'utils/getId';

import { InputTypes, IRadioProps, inputDisplayNames } from './types';

import './Input.scss';

export const Radio: FC<IRadioProps> = ({
  name,
  value,
  onChange,
  disabled,
  children,
  checked,
}) => {
  const id = useMemo(() => getId(), []);

  const handleRadioChange = useCallback(
    (e?: ChangeEvent<HTMLInputElement>) => onChange?.(e),
    [onChange]
  );

  return (
    <label
      className="input-radio"
      htmlFor={id}
      tabIndex={-1}
      title={`${name}: ${value}`}>
      <span className="input-radio__label">{children || value}</span>
      <input
        id={id}
        type={InputTypes.Radio}
        className="input-radio__box"
        onChange={handleRadioChange}
        name={name}
        value={value}
        disabled={disabled}
        tabIndex={0}
        checked={checked}
      />
      <span className="input-radio__checkmark" />
    </label>
  );
};

Radio.displayName = inputDisplayNames.radio;
