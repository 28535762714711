import React, { memo, useState, useEffect, ReactNode } from 'react';
import classNames from 'classnames';

import { TableElements, TableFooterProps, TableCellAlign } from './types';

export const TableFooter = memo(
  <T extends { id: string | number }>({
    data = [],
    className,
    selectable,
    expandable,
    cells = [],
  }: TableFooterProps<T>) => {
    const footerCells = cells.map((cell, index) => {
      const { colspan, rowspan, renderer, className, align } = cell;
      const currentCell = data.length
        ? typeof renderer === 'function'
          ? renderer(data)
          : renderer
        : null;

      return (
        <td
          key={index}
          colSpan={colspan}
          rowSpan={rowspan}
          className={classNames('table__footer-cell', className)}>
          <div
            className={classNames('table__content', {
              'table__content--left': align === TableCellAlign.Left,
              'table__content--right': align === TableCellAlign.Right,
              'table__content--center': align === TableCellAlign.Center,
            })}>
            {currentCell}
          </div>
        </td>
      );
    });

    const renderCellSelecatble = () =>
      selectable ? (
        <td className="table__footer-cell table__footer-cell--selectable" />
      ) : null;

    return (
      <tfoot>
        <tr className={classNames('table__footer', className)}>
          {renderCellSelecatble()}
          {footerCells}
          {expandable && <td className="table__cell--expand" />}
        </tr>
      </tfoot>
    );
  },
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data && prevProps.cells === nextProps.cells
);

TableFooter.displayName = TableElements.TableFooter;
