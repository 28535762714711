import { IUserReducerState, IUser, UserRole } from 'types/user.type';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type { IUserReducerState } from 'types/user.type';

const initialState: IUserReducerState = {
  isAuthServiceReady: false,
  isAuthenticated: false,
  currentUser: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{
        currentUser: IUser<UserRole>;
        authToken: string;
        accessToken: string;
      }>
    ) => {
      const { currentUser, authToken, accessToken } = action.payload;
      state.currentUser = currentUser;
      state.authToken = authToken;
      state.accessToken = accessToken;
    },
    clearUser: (state) => {
      state.currentUser = undefined;
    },
    setIsAuthServiceReady: (state, action: PayloadAction<boolean>) => {
      state.isAuthServiceReady = action.payload;
    },
    setIsUserAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const fetchUser = createAction('FETCH_USER');
export const { setUser, clearUser, setIsAuthServiceReady, setIsUserAuthenticated } =
  userSlice.actions;
export default userSlice.reducer;
