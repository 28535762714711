import classNames from 'classnames';
import React, { FC, Fragment } from 'react';

import { ISpinnerProps } from './types';

import './Spinner.scss';

export const Spinner: FC<ISpinnerProps> = ({ opened, children }) => (
  <Fragment>
    {opened ? (
      <div
        className={classNames('spinner-wrapper', {
          'spinner-wrapper--opened': opened,
        })}>
        <div className="spinner">
          <div />
          <div />
          <div />
        </div>
      </div>
    ) : null}

    {children}
  </Fragment>
);
