import React, { FC } from "react"
import styles from "./Counter.scss"

export interface CounterPropsType {
  counterArg: number
  textSingular: string
  textPlural: string
}

const Counter: FC<CounterPropsType> = ({
  counterArg,
  textSingular,
  textPlural
}) =>
  counterArg === 1 ? (
    <div className={styles.counter}>
      <span>{counterArg}</span>&nbsp;{textSingular}
    </div>
  ) : (
    <div className={styles.counter}>
      <span>{counterArg}</span>&nbsp;{textPlural}
    </div>
  )

export default Counter
