import React, { FC, useState, useEffect } from "react"
import { CompanyLogo, CompanyLabel } from "@src/components/CompanyElement"
import Chevron from "@src/components/Chevron"
import DropdownHeader from "@src/components/DropdownHeader"
import { IUserDropdownProps, IManagedCompany, IOrg } from "./IUserDropdownProps"
import {
  Button,
  Menu,
  MenuItem,
  Box,
  Divider,
  IconButton,
  Stack
} from "@mui/material"
import { Logout, SupportAgent, Add } from "@mui/icons-material"
import { originTopRight, originBottomRight } from "@src/utils/popupAnchors"
import styles from "./UserDropdown.scss"
import UserInitialsAvatar from "../UserInitialsAvatar"

const menuClassOverride = {
  paper: styles.dropdownPaper
}

const backdropProps = {
  "aria-label": "menu-backdrop",
  classes: {
    root: styles.cleanBackdrop
  }
}

const CompaniesLabel: FC<unknown> = () => (
  <Box className={styles.managedCompanyLabel} component="li">
    Managed clients
  </Box>
)

const SelectedLabel: FC<unknown> = () => (
  <Box
    className={styles.selectedLabel}
    component="span"
    aria-label="selected-company-label"
  >
    Selected
  </Box>
)

const createCompaniesMenuItems = (
  managedCompanies: IManagedCompany[],
  handleChange: (id?: number) => void,
  selectedCompany: number
) =>
  managedCompanies?.map((item: IManagedCompany) => {
    const isSelected = item.id === selectedCompany

    return (
      <MenuItem
        key={item.id}
        value={item.id}
        onClick={() => handleChange(item.id)}
        aria-label={isSelected ? "selected-company" : "managed-company"}
        className={styles.companyItem}
      >
        <CompanyLogo companyObject={item} />
        <CompanyLabel companyObject={item} />

        {isSelected ? <SelectedLabel /> : null}
      </MenuItem>
    )
  })

const createOrgMenuItems = (
  org: IOrg,
  handleChange: (id?: number) => void,
  selectedCompany: number,
  isLast: boolean,
  onAddButtonClick?: (orgName: string) => void,
  onOrgNameClick?: (orgName: string) => void
) => {
  return (
    <>
      <Stack className={styles.managedOrgLabel} component="li">
        {onOrgNameClick ? (
          <span onClick={() => onOrgNameClick(org.orgName)} aria-label="org-name" className={styles.managedOrgName}>
            {org.orgName}
          </span>
        ) : (
          org.orgName
        )}
        {onAddButtonClick && (
          <IconButton
            onClick={() => onAddButtonClick(org.orgName)}
            title="Add Account"
          >
            <Add fontSize="large" />
          </IconButton>
        )}
      </Stack>
      {createCompaniesMenuItems(
        org.managedCompanies,
        handleChange,
        selectedCompany
      )}
      <Divider className={isLast ? styles.actionsDivider : styles.orgDivider} />
    </>
  )
}

const UserDropdown: FC<IUserDropdownProps> = ({
  managedCompanies,
  selectedCompanyDefault,
  onChange,
  onLogout,
  onProfile,
  userImage,
  userFullName,
  userEmail,
  isUsingAdminService,
  managedCompaniesByOrg,
  onSupport,
  onAddButtonClick,
  onOrgNameClick
}) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(
    selectedCompanyDefault
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  useEffect(() => {
    setSelectedCompanyId(selectedCompanyDefault || 0)
  }, [selectedCompanyDefault])

  const isMenuOpen = anchorEl !== null
  const menuActiveClass = isMenuOpen ? styles.active : ""
  const userButtonClasses = [styles.dropdownButton, menuActiveClass].join(" ")

  const menuProps = {
    BackdropProps: backdropProps,
    "aria-label": "user-menu",
    anchorEl,
    open: isMenuOpen,
    onClose: () => setAnchorEl(null),
    classes: menuClassOverride,
    anchorOrigin: originTopRight,
    transformOrigin: originBottomRight
  }

  const currentCompany = !isUsingAdminService
    ? managedCompanies?.find(
        (item: IManagedCompany) => item.id === selectedCompanyId
      )
    : null

  const shouldShowManagedClients =
    !isUsingAdminService && managedCompanies?.length > 1

  const handleChange = (clientId: number) => {
    setSelectedCompanyId(clientId)
    if (onChange) {
      onChange(clientId.toString())
    }
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <Button
        aria-label="user-button"
        onClick={handleClick}
        className={userButtonClasses}
      >
        {!isUsingAdminService ? (
          <span className={styles.companyName}>
            {currentCompany?.name ?? ""}
          </span>
        ) : null}

        {userImage ? (
          <img
            aria-label="user-image"
            className={styles.userIcon}
            src={userImage}
          />
        ) : (
          <UserInitialsAvatar name={userFullName} />
        )}
        <span>{userFullName?.split(" ")[0]}</span>

        <Chevron extended={isMenuOpen} />
      </Button>
      <Menu {...menuProps}>
        <DropdownHeader title={userFullName} subtext={userEmail} />

        <div className={styles.body}>
          {shouldShowManagedClients ? (
            <>
              <CompaniesLabel />

              {createCompaniesMenuItems(
                managedCompanies,
                handleChange,
                selectedCompanyId
              )}

              <hr aria-label="divider" />
            </>
          ) : null}
          {managedCompaniesByOrg &&
            managedCompaniesByOrg.map((org, index) =>
              <React.Fragment key={org.orgName}>
                {createOrgMenuItems(
                  org,
                  handleChange,
                  selectedCompanyId,
                  index === managedCompaniesByOrg.length - 1,
                  onAddButtonClick,
                  onOrgNameClick
                )}
              </React.Fragment>
            )}
          {onProfile ? (
            <MenuItem className={styles.userAction} onClick={onProfile}>
              Profile settings
            </MenuItem>
          ) : null}
          {onSupport && (
            <MenuItem className={styles.userAction} onClick={onSupport}>
              <SupportAgent className={styles.bottomActionIcon} />
              <span>New support request</span>
            </MenuItem>
          )}
          <MenuItem className={styles.userAction} onClick={onLogout}>
            <Logout className={styles.bottomActionIcon} />
            <span>Log out</span>
          </MenuItem>
        </div>
      </Menu>
    </div>
  )
}

export default UserDropdown
