import React, { FC } from "react"
import style from "./Flag.scss"

export interface IFlagProps {
  color?: "primary" | "secondary" | "success" | "info" | "success-dark"
  label: string
  className?: string
}

const Flag: FC<IFlagProps> = ({ color, label, className }: IFlagProps) => {
  return (
    <div
      aria-label="flag"
      className={`${style.flag} ${style[`${color}-flag`]} ${className}`}
    >
      <span>{label}</span>
    </div>
  )
}

export default Flag
