import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { parse } from 'query-string';

import {
  ILinkAccountResponse,
  LinkAccountResponseStatus,
  LinkAccountResponseAccount,
} from '../../components/pages/Home/types';
import { notificationQueue, NotificationType } from 'services/NotificationQueue.service';

export const useSourceRedirect = (path: string = '/') => {
  const accountName: { [key in LinkAccountResponseAccount]: string } = {
    [LinkAccountResponseAccount.Adwords]: 'Google Ads',
    [LinkAccountResponseAccount.SearchConsole]: 'Google Search Console',
  };

  const [queryParams, setQueryParams] = useState<ILinkAccountResponse | null>(null);

  const { search, pathname } = useLocation();

  useEffect(() => {
    if (search && pathname === path) {
      const parsedQuery = parse(search, {
        decode: true,
      }) as unknown as ILinkAccountResponse;
      setQueryParams(parsedQuery);
    }
  }, [search, pathname]);

  useEffect(() => {
    if (queryParams) {
      const { status, message, error } = queryParams;
      if ([LinkAccountResponseStatus.Ok, LinkAccountResponseStatus.Error].includes(status)) {
        const toastMessage =
          error && error === 'unauthorized'
            ? `You are not authorised to access Seamless Search. Please either sign-up or get approved by your company admin.`
            : `${accountName[queryParams['account-type']]}: ${message}`;

        // onClose function added because refreshing the link when it has query params will show the success toaster every time.
        // OnClose sets the query params in the link back to 0
        notificationQueue.showNotification(NotificationType.Toast, toastMessage, {
          autoClose: 50000,
          onClose: () => {
            window.history.replaceState({}, document.title, pathname);
          },
        });
      }
    }
  }, [queryParams]);
};
