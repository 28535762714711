import React, { FC, MouseEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { IDdActionItemProps } from './types';

export const ActionItem: FC<IDdActionItemProps> = ({
  label,
  link,
  Icon,
  onClick,
}) => {
  const handleItemClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        e.preventDefault();
        e.stopPropagation();
        onClick?.();
      }
    },
    [onClick]
  );

  return (
    <li
      title={label}
      className="dropdown__item dropdown__action-item"
      role="menuitem"
      aria-labelledby={name}
      tabIndex={0}>
      <Link to={link} className="dropdown__item-link" onClick={handleItemClick}>
        {Icon && <Icon className="dropdown__item-icon" />}
        <span className="dropdown__item-data">{label}</span>
      </Link>
    </li>
  );
};

ActionItem.displayName = 'ActionItem';
