/* eslint-disable */
import { createUrl } from 'utils/createUrl';
import { waitForFlag } from 'utils/waitForFlag';
import { getOrg } from 'utils/manageOrganisations';
import { AuthService } from './Auth.service';
import { NetworkError } from './NetworkError.service';
import { CONFLICT_ERROR_CODE } from 'constants/filters';

export class HTTPService {
  private readonly authService: AuthService;
  constructor(
    private readonly baseUrl: string,
    authService: AuthService
  ) {
    this.authService = authService;
  }

  private async exec(
    requestUrl: string,
    options: RequestInit,
    formData?: boolean,
    headers?: Record<string, string>
  ) {
    await waitForFlag(() => !this.authService.getIsAuthServiceReady, 250);
    const { __raw } = (await this.authService.getIdTokenClaims()) as {
      __raw: string;
    };
    const accessToken = await this.authService.getTokenSilently();
    options.headers = new Headers({
      Authorization: `Bearer ${__raw}`,
      'X-Access-Token': accessToken,
      'X-Organization': getOrg(),
      ...(!formData ? { 'Content-Type': 'application/json' } : undefined),
      ...headers,
    });
    options.mode = 'cors';
    options.credentials = 'same-origin';
    try {
      const response = await fetch(requestUrl, options);
      if (response.status === 403) {
        throw new NetworkError('', 403);
      }

      const json = await response?.json();

      if (json?.code > 200 && json?.code !== CONFLICT_ERROR_CODE) {
        throw new NetworkError(json?.err || '', json.code);
      }

      if (json?.code === 200 && json?.status === 'ERROR') {
        throw new NetworkError('Call succesful but status error');
      }

      if (options.method === 'PATCH') {
        return response;
      }

      return json;
    } catch (e) {
      if (e instanceof NetworkError && e.code === 403) {
        this.authService.logoutUnAuthorizedUser();
      }
    }
  }

  public get = async <T extends object>(
    url: string,
    query?: Record<string, string | number | boolean | any[]>,
    headers?: Record<string, string>
  ): Promise<T> => {
    try {
      const requestUrl = createUrl(this.baseUrl, url, query);

      const json = await this.exec(requestUrl, { method: 'GET' }, undefined, headers);

      if (!json) {
        throw new NetworkError('Cannot load data');
      }

      return json.data!;
    } catch (error) {
      throw error;
    }
  };

  public post = async <T extends object>(
    url: string,
    body?: Record<string, any> | FormData,
    query?: Record<string, string | number | boolean>,
    headers?: Record<string, string>
  ): Promise<T> => {
    try {
      const requestUrl = createUrl(this.baseUrl, url, query);
      const isFormData = body instanceof FormData;
      const json = await this.exec(
        requestUrl,
        {
          method: 'POST',
          body: isFormData ? (body as FormData) : JSON.stringify(body),
        },
        isFormData,
        headers
      );

      if (json?.code == CONFLICT_ERROR_CODE) {
        return json;
      } else {
        return json.data!;
      }
    } catch (error) {
      throw error;
    }
  };

  public patch = async <T extends object>(
    url: string,
    body?: Record<string, any>,
    query?: Record<string, string | number | boolean>,
    headers?: Record<string, string>
  ): Promise<T> => {
    try {
      const requestUrl = createUrl(this.baseUrl, url, query);
      const res = await this.exec(
        requestUrl,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        },
        undefined,
        headers
      );
      return res.data!;
    } catch (error) {
      throw error;
    }
  };

  public delete = async <T extends object>(
    url: string,
    query?: Record<string, string | number | boolean | any[]>,
    body?: Record<string, any>,
    headers?: Record<string, string>
  ): Promise<T> => {
    try {
      const requestUrl = createUrl(this.baseUrl, url, query);
      const json = await this.exec(
        requestUrl,
        {
          method: 'DELETE',
          body: JSON.stringify(body),
        },
        undefined,
        headers
      );
      return json.data!;
    } catch (error) {
      throw error;
    }
  };

  public put = async <T extends object>(
    url: string,
    body?: Record<string, any> | FormData,
    query?: Record<string, string | number | boolean>,
    headers?: Record<string, string>
  ): Promise<T> => {
    try {
      const requestUrl = createUrl(this.baseUrl, url, query);
      const isFormData = body instanceof FormData;
      const json = await this.exec(
        requestUrl,
        {
          method: 'PUT',
          body: isFormData ? (body as FormData) : JSON.stringify(body),
        },
        isFormData,
        headers
      );

      if (json?.code == CONFLICT_ERROR_CODE) {
        return json;
      } else {
        return json.data!;
      }
    } catch (error) {
      throw error;
    }
  };
}
