import React, { FC, useState, useMemo, useEffect } from 'react';
import { INotificationsProps } from './types';
import { Typography, Divider } from '@mui/material';
import { NewNotificationsSearch } from './NewNotificationsSearch.component';
import NotificationsActiveIconNew from 'jsx:assets/icons/notification-active.svg';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import './NewNotifications.scss';
import { GET_NOTIFICATION_INTERVAL } from 'constants/filters';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Flag } from '@dqna/seamless-suite-ui-kit';

export const NewNotifications: FC<INotificationsProps> = ({
  currentUser,
  notifications,
  getNotifications,
}) => {
  const [filteredNotifs, setFilteredNotifs] = useState<INotificationsProps['notifications']>(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newNotification, setNewNotification] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);

    if (
      !localStorage.notification_seen_by ||
      localStorage.notification_seen_by !== currentUser?.email
    ) {
      localStorage.setItem('notification_seen_by', currentUser?.email ?? '');
      localStorage.removeItem('notification_date_seen');
      setNewNotification(true);
    } else {
      setNewNotification(false);
    }
  };

  const handleClose = () => {
    const currentDate = new Date();
    setAnchorEl(null);
    setMenuOpen(false);
    localStorage.setItem('notification_date_seen', currentDate.toUTCString());
    setNewNotification(false);
  };

  useEffect(() => {
    window.OneSignal.isPushNotificationsEnabled().then((isEnabled: boolean) => {
      if (!isEnabled) {
        setInterval(() => getNotifications(), GET_NOTIFICATION_INTERVAL);
      } else {
        window.OneSignal.on('notificationDisplay', function (event: any) {
          getNotifications();
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!notifications) {
      getNotifications();
    }
  }, [currentUser]);

  useEffect(() => {
    const date = notifications?.[0]?.date;
    if (!date) {
      return;
    }
    const lastNotificationDate = new Date(date);
    lastNotificationDate > (localStorage.notification_date_seen || '')
      ? setNewNotification(true)
      : setNewNotification(false);
  }, [notifications, localStorage.notification_date_seen]);

  const timeSinceNotification = (dateOfNotification: string): string => {
    const notificationDate = new Date(dateOfNotification);
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - notificationDate.getTime();
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    if (hoursDifference < 24) {
      return `${hoursDifference}hr`;
    }

    const daysDifference = Math.floor(hoursDifference / 24);
    return `${daysDifference}d`;
  };

  const newNotificationsChip = (dateOfNotification: string) => {
    if (!dateOfNotification) {
      return;
    }
    const notificationDate = new Date(dateOfNotification);
    if (!localStorage.notification_date_seen) {
      return <Flag color="info" label="New" />;
    }
    return notificationDate > localStorage.notification_date_seen ? (
      <Flag color="info" label="New" />
    ) : (
      <></>
    );
  };

  const handleSearch = (filteredData: INotificationsProps['notifications']) => {
    setFilteredNotifs(filteredData);
  };

  const renderNotificationItems = useMemo(() => {
    const notificationsToRender = filteredNotifs ?? notifications;

    return notificationsToRender ? (
      <ul className="notification-list">
        {notificationsToRender.map((notif, key) => (
          <React.Fragment key={key}>
            <li className="notification">
              <div className="notification__content">
                <div className="notification__left">
                  <Typography
                    variant="body2"
                    className="notification__title"
                    fontWeight={600}
                    color={'#333333'}>
                    {notif.subject}
                    {newNotificationsChip(notif.date)}
                  </Typography>
                  <Typography variant="body2" className="notification__description">
                    {notif.body}
                  </Typography>
                </div>
                <div className="notification__right">
                  <Typography
                    variant="body2"
                    className="notification__date"
                    fontSize={'1.2rem'}
                    lineHeight={'3.5rem'}>
                    {notif.date ? timeSinceNotification(notif.date) : ''}
                  </Typography>
                </div>
              </div>
              <Divider style={{ marginLeft: '-1.6rem', marginRight: '-2rem' }} />
            </li>
          </React.Fragment>
        ))}
      </ul>
    ) : (
      <Typography variant="body2" className="notification__description">
        No Notifications
      </Typography>
    );
  }, [filteredNotifs, notifications, timeSinceNotification]);

  return (
    <>
      <div onClick={handleClick} className="notification-encasing">
        {newNotification ? (
          <NotificationsActiveIconNew className="notification__action-icon" />
        ) : (
          <NotificationsNoneIcon
            className="notification__action-icon--inactive"
            style={{ height: '2rem', width: '2rem', color: '#666666' }}
          />
        )}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        style={{ width: '48rem', height: '37.3rem', borderRadius: '1.2rem' }}
        classes={{ paper: 'custom-menu-paper' }}>
        <MenuItem
          className="notification"
          sx={{ '&:hover': { backgroundColor: '#ffffff', cursor: 'default' } }}>
          <div className="top__left">
            <Typography variant="body1" fontWeight={600}>
              Notifications
            </Typography>
          </div>
        </MenuItem>
        <MenuItem className="notification" sx={{ '&:hover': { backgroundColor: '#ffffff' } }}>
          <NewNotificationsSearch notifications={notifications} onSearch={handleSearch} />
        </MenuItem>
        <Divider />
        <MenuItem sx={{ '&:hover': { backgroundColor: '#ffffff', cursor: 'default' } }}>
          {renderNotificationItems}
        </MenuItem>
        <Divider />
      </Menu>
    </>
  );
};

NewNotifications.displayName = 'Notifications';
