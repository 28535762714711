import React, { FC, useEffect, useState } from 'react';
import {
  Modal,
  DetailInputPanel,
  ModalActions,
  Button,
  CustomSelectFormElement,
  UserInteractionDialog,
  FormContainer,
  useFormContainer,
} from '@dqna/seamless-suite-ui-kit';
import countries from '../../../../assets/configs/countries.json';
import {
  AddSubAccountModalProps,
  CountriesOptionType,
  DetailInputPanelFieldsType,
  FormType,
} from './types';
import { useFlag } from 'utils/hooks/useFlag';
import './AddSubAccountModal.scss';
import { OptionType } from 'types/app.types';
import { CreationStatus } from '.';

export const AddSubAccountModal: FC<AddSubAccountModalProps> = ({
  accounts,
  subAccounts,
  modalOpenState,
  setModalOpenState,
  createSubAccount,
  adwords,
  currentUser,
  subAccountsCreationResults,
  emptyCreationStatus,
  fetchSubAccountUrls,
  urlsByAccount,
  fetchSubAccounts,
  subAccountCreationTimedOut,
}) => {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useFlag();
  const [creationStatusModalOpenState, setCreationStatusModalOpenState] = useState(false);
  const [singleAccountSubAccount, setSingleAccountSubAccount] = useState<
    Array<{
      label: string;
      value: number;
    }>
  >([]);
  const formMethods = useFormContainer<FormType>({
    defaultValues: { subAccounts: [{ account: null, subAccount: null, url: null, country: null }] },
  });
  const formData = formMethods.watch();
  useEffect(() => {
    if (subAccountsCreationResults) {
      const isLoadingDone = Object.values(subAccountsCreationResults).every(
        (subAccount) => !subAccount.isLoading
      );
      const uniqueAccounts = Object.values(subAccountsCreationResults).reduce(
        (accumulator, creationResult) => {
          accumulator.add(creationResult.accountId);
          return accumulator;
        },
        new Set<number>([])
      );
      let fetchSubAccountCreationInterval: ReturnType<typeof setTimeout> | undefined;
      let subAccountCreationTimeout: ReturnType<typeof setTimeout> | undefined;
      if (uniqueAccounts && !isLoadingDone) {
        fetchSubAccountCreationInterval = setInterval(() => {
          uniqueAccounts.forEach((accountId) => {
            fetchSubAccounts(accountId);
          });
        }, 7000);
        subAccountCreationTimeout = setTimeout(() => {
          clearInterval(fetchSubAccountCreationInterval);
          subAccountCreationTimedOut();
        }, 90000);
        return () => {
          clearInterval(fetchSubAccountCreationInterval);
          clearTimeout(subAccountCreationTimeout);
        };
      }
      if (subAccountCreationTimeout && uniqueAccounts && isLoadingDone) {
        clearInterval(fetchSubAccountCreationInterval);
        clearTimeout(subAccountCreationTimeout);
      }
    }
  }, [subAccountsCreationResults]);

  useEffect(() => {
    formMethods.reset();
  }, [modalOpenState]);

  useEffect(() => {
    fetchSubAccountUrls();
  }, [accounts]);

  useEffect(() => {
    setDefaultAccountOnFormData();
  }, [accounts, formData.subAccounts.length, modalOpenState]);

  const countriesList: CountriesOptionType[] = countries.map(
    ({ fullCountryName, alpha3Code, alpha2Code }) => ({
      value: alpha3Code,
      label: fullCountryName,
      alpha2Code,
    })
  );

  const setDefaultAccountOnFormData = () => {
    if (accounts?.length === 1) {
      const selectedAccount: OptionType = {
        value: accounts[0].id.toString(),
        label: accounts[0].name,
      };
      formData.subAccounts.forEach((subAccount, rowIndex) => {
        formMethods.setValue(`subAccounts.${rowIndex}.account`, selectedAccount);
      });
      const accountIdToPass = parseInt(formData.subAccounts[0]?.account?.value ?? '');
      const subAccountOptions = mapSubAccountsBasedOnAccountId(accountIdToPass);
      setSingleAccountSubAccount(subAccountOptions);
    }
  };

  const mapSubAccountsBasedOnAccountId = (accId: number) => {
    const mappedAdwords = adwords[accId]
      ? adwords[accId].map((adword) => ({
          label: adword.name,
          value: adword.id,
        }))
      : [];

    const filteredSubAccounts = mappedAdwords.filter((item) => {
      if (!subAccounts) return [];
      return !subAccounts[accId]?.map((subs) => subs.name)?.includes(item.label);
    });

    return filteredSubAccounts;
  };

  const subAccountUrlsList = (rowIndex: number) => {
    const accountId = formData.subAccounts[rowIndex]?.account?.value;

    if (!accountId) return [];

    const fetchedSubAccountUrls = urlsByAccount[parseInt(accountId)] || [];

    return fetchedSubAccountUrls.map((subAccountUrl: string) => ({
      value: subAccountUrl,
      label: subAccountUrl,
    }));
  };

  const onCloseModal = (action: string) => {
    if (action === 'confirm') {
      setModalOpenState(false);
      formMethods.reset();
    } else if (action === 'cancel') {
      setIsCancelDialogOpen(false);
    }
  };

  const getSubAccountOptions = (rowIndex: number) => {
    const selectedAccountId = parseInt(formData.subAccounts[rowIndex]?.account?.value ?? '');
    if (!selectedAccountId || !subAccounts) return [];

    return mapSubAccountsBasedOnAccountId(selectedAccountId);
  };

  const onSubmit = (formData: FormType) => {
    setCreationStatusModalOpenState(true);
    setModalOpenState(false);
    emptyCreationStatus();
    formData.subAccounts.forEach((subAccount) => {
      const selectedAccountId =
        accounts?.length === 1 ? accounts[0].id : parseInt(subAccount?.account?.value ?? '');
      if (selectedAccountId && adwords[selectedAccountId]) {
        const selectedAWAccount = adwords[selectedAccountId].find(
          (adword) => adword.name === subAccount?.subAccount?.label
        );
        const currency = selectedAWAccount?.currencyCode.length
          ? selectedAWAccount?.currencyCode
          : 'GBP';
        const managerId = selectedAWAccount?.managerId;
        const alpha2Code = countriesList.find(
          (countryDetails) => countryDetails.label === subAccount.country?.label
        )?.alpha2Code;
        createSubAccount({
          accountId: selectedAccountId,
          adwordsId: selectedAWAccount?.id ?? 0,
          name: subAccount?.subAccount?.label ?? '',
          currency,
          subaccountUrls: subAccount.url?.map((url) => url?.label ?? '') ?? [],
          country: subAccount.country?.value ?? '',
          countryAlpha2: alpha2Code ?? '',
          currentUser,
          managerId,
        });
      }
    });
  };

  const onAccountFieldChange = (selectedAccount: OptionType, rowIndex: number) => {
    const currentSelectedAccount: string | null | undefined =
      formData.subAccounts[rowIndex]?.account?.value;

    const resetSubAccountAndUrl = () => {
      formMethods.setValue(`subAccounts.${rowIndex}.subAccount`, null);
      formMethods.setValue(`subAccounts.${rowIndex}.url`, []);
    };

    if (selectedAccount === null || selectedAccount.value === null) {
      formMethods.setValue(`subAccounts.${rowIndex}.account`, null);
      resetSubAccountAndUrl();
    } else if (selectedAccount.value !== currentSelectedAccount) {
      resetSubAccountAndUrl();
    }

    formMethods.setValue(`subAccounts.${rowIndex}.account`, selectedAccount);
  };

  const onSubAccountFieldChange = (selectedSubAccount: OptionType, rowIndex: number) => {
    const currentSelectedSubAccount = formData.subAccounts[rowIndex]?.subAccount?.value;

    if (selectedSubAccount === null || selectedSubAccount.value === null) {
      formMethods.setValue(`subAccounts.${rowIndex}.subAccount`, null);
      formMethods.setValue(`subAccounts.${rowIndex}.url`, []);
    } else if (selectedSubAccount.value !== currentSelectedSubAccount) {
      formMethods.setValue(`subAccounts.${rowIndex}.url`, []);
    }

    formMethods.setValue(`subAccounts.${rowIndex}.subAccount`, selectedSubAccount);
  };

  const rowCount = formData.subAccounts.length;

  return (
    <>
      <Modal open={modalOpenState} onClose={setIsCancelDialogOpen} className="add-subaccount">
        <FormContainer<FormType> formMethods={formMethods} onSubmit={onSubmit}>
          <DetailInputPanel<DetailInputPanelFieldsType>
            sx={{
              td: {
                width: '20rem',
                maxWidth: '20rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            name="subAccounts"
            title="Add Sub-account(s)"
            firstRowDisabled={false}
            allowAdd={false}
            allowRemove={true}
            addingCaption={rowCount > 1 ? `Add More (${rowCount})` : 'Add More'}
            columns={[
              {
                name: 'account',
                header: 'Account',
                render: (props, rowIndex) => (
                  <>
                    <CustomSelectFormElement
                      {...props}
                      optionData={(accounts ?? []).map((account) => ({
                        value: account.id,
                        label: account.name,
                      }))}
                      labelKey="label"
                      valueKey="value"
                      type="singleSelect"
                      disabled={accounts?.length === 1}
                      variant="standard"
                      defaultValue={
                        accounts?.length === 1
                          ? {
                              value: accounts[0].name,
                              label: accounts[0].name,
                            }
                          : undefined
                      }
                      placeholder="Please select"
                      rules={{
                        required: 'Account must be selected.',
                      }}
                      onChange={(selectedAccount: OptionType) => {
                        onAccountFieldChange(selectedAccount, rowIndex);
                      }}
                      ariaLabel="account"
                    />
                  </>
                ),
              },
              {
                name: 'subAccount',
                header: 'Sub-Account',
                render: (props, rowIndex) => (
                  <>
                    <CustomSelectFormElement
                      {...props}
                      optionData={
                        accounts?.length === 1
                          ? singleAccountSubAccount
                          : getSubAccountOptions(rowIndex)
                      }
                      labelKey="label"
                      valueKey="value"
                      type="singleSelect"
                      variant="standard"
                      placeholder="Please select"
                      rules={{ required: 'Sub-account must be selected.' }}
                      disabled={
                        !formData.subAccounts[rowIndex]?.account && !(accounts?.length === 1)
                      }
                      onChange={(selectedSubAccount: OptionType) => {
                        onSubAccountFieldChange(selectedSubAccount, rowIndex);
                      }}
                      ariaLabel="subaccount"
                    />
                    {formData.subAccounts[rowIndex]?.account ? null : (
                      <span title="Please select an account first" style={{ color: 'grey' }}>
                        Please select an account first
                      </span>
                    )}
                  </>
                ),
              },
              {
                name: 'url',
                header: 'URL',
                render: (props, rowIndex) => (
                  <>
                    <CustomSelectFormElement
                      {...props}
                      optionData={subAccountUrlsList(rowIndex)}
                      labelKey="label"
                      valueKey="value"
                      type="multiselect"
                      variant="standard"
                      placeholder="Please select"
                      rules={{
                        required: 'URL must be selected.',
                      }}
                      disabled={!formData.subAccounts[rowIndex]?.subAccount}
                      ariaLabel="url"
                    />
                    {formData.subAccounts[rowIndex]?.subAccount ? null : (
                      <span title="Please select an account first" style={{ color: 'grey' }}>
                        Please select a subaccount first
                      </span>
                    )}
                  </>
                ),
              },
              {
                name: 'country',
                header: 'Country',
                render: (props) => (
                  <CustomSelectFormElement
                    {...props}
                    optionData={countriesList}
                    labelKey="label"
                    valueKey="value"
                    type="singleSelect"
                    variant="standard"
                    placeholder="Please select"
                    rules={{
                      required: 'Country must be selected.',
                    }}
                    ariaLabel="country"
                  />
                ),
              },
            ]}
          />
          <ModalActions>
            <Button onClick={setIsCancelDialogOpen} variant="outlined">
              Cancel
            </Button>
            <Button variant="contained" type="submit" aria-label="Add Subaccount">
              Add
            </Button>
          </ModalActions>
        </FormContainer>
      </Modal>
      <UserInteractionDialog
        uiDialogState={isCancelDialogOpen}
        setUIDialogState={setIsCancelDialogOpen}
        title="Confirmation?"
        message="Do you want to cancel this process?"
        buttonCaptions={{
          cancel: 'Go back',
          confirm: 'Yes, cancel',
        }}
        onClose={(action) => {
          onCloseModal(action);
        }}
      />
      <CreationStatus
        subAccountsCreationResults={subAccountsCreationResults}
        modalOpenState={creationStatusModalOpenState}
        setModalOpenState={setCreationStatusModalOpenState}
      />
    </>
  );
};
