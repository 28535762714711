import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuthProvider } from 'utils/hooks/useAuthProvider';
import { IPrivateRouteProps } from './types';

export const PrivateRoute: FC<IPrivateRouteProps> = ({
  path,
  redirectTo,
  children,
  exact,
  roles,
}) => {
  const { isAuthenticated, isAuthServiceReady, role } = useAuthProvider();

  if (roles?.length && !role && isAuthenticated) return null;

  let permitAccess = isAuthenticated ?? !isAuthServiceReady;

  if (roles && role) {
    permitAccess = permitAccess && roles.some((userRole) => role?.includes(userRole));
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={() => (permitAccess ? children : <Redirect to={redirectTo} />)}></Route>
  );
};
