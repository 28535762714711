import React, { useCallback, FC, useMemo } from 'react';

import { IKeyword } from 'types/keywords.types';
import { filterByIdsMetric } from './externalFilterCreators';
import { Button, ButtonTypes } from 'components/ui-kit/Button';
import { ICheckboxFilterButtonProps } from './types';
import { useFlag } from 'utils/hooks/useFlag';

import './Filters.scss';

export const CheckboxFilterButton: FC<ICheckboxFilterButtonProps<IKeyword>> = ({
  selectedRows,
  onChange,
  disabled,
  label,
}) => {
  const [filterApplied, toggleFilterApplied] = useFlag();

  const keywordsSet = useMemo(
    () =>
      Object.keys(
        selectedRows.reduce(
          (gen: { [key: string]: boolean }, curr: IKeyword) => ({
            ...gen,
            [curr.id]: true,
          }),
          {}
        )
      ),
    [selectedRows]
  );

  const onClick = useCallback(() => {
    const filters = !filterApplied
      ? filterByIdsMetric(keywordsSet.join('|'))
      : null;
    onChange(filters);
    toggleFilterApplied();
  }, [filterApplied, keywordsSet, onChange]);

  return (
    <Button
      className="checkbox-filter__wrapper"
      name="checkbox-filter"
      type={ButtonTypes.Primary}
      onClick={onClick}
      disabled={disabled}>
      <div className="checkbox-filter__label">
        {label || 'Send ' + keywordsSet.length + ' Keywords to Learning Phase'}
      </div>
    </Button>
  );
};

CheckboxFilterButton.displayName = 'CheckboxFilterButton';
