import React, { FC, memo, useMemo, useCallback } from 'react';
import classNames from 'classnames';

import { Button, ButtonTypes } from 'components/ui-kit/Button';
import { Dropdown } from 'components/ui-kit/Dropdown';
import { limits } from './pagination.constants';

import LeftArrow from 'jsx:assets/icons/leftArrow.svg';
import RightArrow from 'jsx:assets/icons/rightArrow.svg';

import { PaginationProps } from './types';

import './Pagination.scss';

export const Pagination: FC<PaginationProps> = memo(
  ({
    totalItems,
    totalPages,
    currentPage,
    limit,
    onPageChange,
    onLimitChange,
    containerClass,
    buttonClass,
  }) => {
    if (!totalItems) {
      return null;
    }

    const onNextPageClick = useCallback(() => {
      const next = currentPage + 1;
      onPageChange(next);
    }, [onPageChange, currentPage]);

    const onPrevPageClick = useCallback(() => {
      const next = currentPage - 1;
      onPageChange(next);
    }, [onPageChange, currentPage]);

    const prevButton = useMemo(
      () => (
        <Button
          type={ButtonTypes.Secondary}
          name="Previous page"
          disabled={currentPage === 1}
          onClick={onPrevPageClick}
          className={classNames('pagination__btn', buttonClass)}>
          <LeftArrow className="pagination__arrow" />
        </Button>
      ),
      [onPrevPageClick, currentPage]
    );

    const nextButton = useMemo(
      () => (
        <Button
          type={ButtonTypes.Secondary}
          name="Next page"
          disabled={currentPage === totalPages}
          onClick={onNextPageClick}
          className={classNames('pagination__btn', buttonClass)}>
          <RightArrow className="pagination__arrow" />
        </Button>
      ),
      [onNextPageClick, currentPage, totalPages]
    );

    const limitDropdown = useMemo(
      () => (
        <Dropdown.Text
          className="pagination__limit"
          options={limits}
          name="pagination limit"
          value={limit}
          onChange={(data) => {
            onLimitChange(data.value as number);
          }}>
          {limit || 'Choose limit'}
        </Dropdown.Text>
      ),
      [onLimitChange, limit]
    );

    const fromItem = useMemo(() => (currentPage - 1) * limit + 1, [currentPage, limit]);
    const toItem = useMemo(
      () => (currentPage * limit < totalItems ? currentPage * limit : totalItems),
      [currentPage, limit, totalItems]
    );

    return (
      <div className={classNames('pagination', containerClass)}>
        <span className="pagination__text">Rows per page:</span>
        {limitDropdown}
        <span className="pagination__numbers">
          {fromItem} - {toItem} of {totalItems}
        </span>
        <div className="pagination__buttons">
          {prevButton}
          {nextButton}
        </div>
      </div>
    );
  }
);

Pagination.displayName = 'Pagination';
