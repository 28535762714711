import {
  Button,
  Modal,
  ModalActions,
  CustomAlert,
  ModalContainer,
} from '@dqna/seamless-suite-ui-kit';
import { ErrorOutline, CheckCircleOutline } from '@mui/icons-material';
import React from 'react';
import { CreationStatusProps } from './types';
import { CircularProgress, Stack } from '@mui/material';
import './AddSubAccountModal.scss';

export const CreationStatus: React.FC<CreationStatusProps> = (props) => {
  const { modalOpenState, setModalOpenState, subAccountsCreationResults } = props;
  const onCloseModal = () => {
    setModalOpenState(false);
  };

  const isLoadingDone = Object.values(subAccountsCreationResults).every(
    (subAccount) => !subAccount.isLoading
  );
  const isEveryCreationSuccessfull = Object.values(subAccountsCreationResults).every(
    (subAccount) => subAccount.isSuccess
  );

  return (
    <Modal open={modalOpenState} onClose={onCloseModal}>
      <ModalContainer title="Adding sub-account(s)">
        <Stack margin="2rem" gap="2rem">
          {Object.keys(subAccountsCreationResults).map((subAccount, key) => {
            if (subAccountsCreationResults[subAccount].isLoading) {
              return <LoadingAlert name={subAccount} key={key} />;
            }

            if (subAccountsCreationResults[subAccount].isSuccess) {
              return <SuccessAlert name={subAccount} key={key} />;
            }

            return <ErrorAlert name={subAccount} key={key} />;
          })}
        </Stack>
      </ModalContainer>
      <ModalActions>
        {isLoadingDone && isEveryCreationSuccessfull && (
          <Button variant="contained" onClick={onCloseModal}>
            Done
          </Button>
        )}
        {isLoadingDone && !isEveryCreationSuccessfull && (
          <Button variant="contained" onClick={onCloseModal}>
            Okay, I understand
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};

const SuccessAlert: React.FC<{ name: string }> = ({ name }) => {
  return (
    <CustomAlert icon={<CheckCircleOutline />} severity="success">
      <strong>{name}</strong> has been created successfully.
    </CustomAlert>
  );
};

const LoadingAlert: React.FC<{ name: string }> = ({ name }) => {
  return (
    <CustomAlert icon={<CircularProgress color="info" size="2rem" />} severity="info">
      <strong>{name}</strong> creation in progress
    </CustomAlert>
  );
};

const ErrorAlert: React.FC<{ name: string }> = ({ name }) => {
  return (
    <CustomAlert icon={<ErrorOutline fontSize="large" />} severity="error">
      <strong>{name}</strong> creation failed due to a technical error on our end. Please try
      connecting again. If the issue keeps happening contact our{' '}
      <a href="mailto:support@seamlesspro.io" className="creation-status__support_link">
        support team
      </a>
    </CustomAlert>
  );
};
