import React from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import style from "../DetailInputPanel.scss"
import { TableCell } from "@src/components/Table"
import { TableFormRowProps } from "../DetailInputPanelProps"
import { DeleteOutline, AddOutlined, DragIndicator } from "@mui/icons-material"
import { TableRow } from "@src/components/Table"
import { Button } from "@mui/base"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

const TableFormRow = <T,>({
  fieldName,
  rowIndex,
  columns,
  onRemove,
  allowRemove,
  numbered,
  disabled,
  disableRemove,
  allowSublines,
  dragId,
  draggable,
  ...props
}: TableFormRowProps<T> & {
  allowRemove: boolean
  disableRemove: boolean
  allowSublines?: boolean
  dragId?: string
  dragabble?: boolean
}) => {
  const { register, getValues, control, formState } = useFormContext()

  const { remove, append, fields } = allowSublines
    ? useFieldArray({
        control,
        name: `${fieldName}.${rowIndex}.subValues`
      })
    : { remove: null, append: null, fields: null }

  const { listeners, setNodeRef, attributes, transform, transition } =
    useSortable({
      id: dragId
    })

  const cells = columns.map(({ render, name }) => {
    const cellName = `${fieldName}.${rowIndex}.${name.toString()}`

    type CellErrorType = {
      [key: string]: { message: string; type: string; ref: { name: string } }
    }

    const cellErrors: CellErrorType[] =
      (formState?.errors[fieldName] as unknown as CellErrorType[]) ?? []

    let isCellError = false
    if (cellErrors?.length) {
      cellErrors.forEach((cellError) => {
        Object.entries(cellError).some(([_key, value]) => {
          if (value.ref.name === cellName) {
            isCellError = true
          }
        })
      })
    }

    const { pattern, required } = register(cellName)

    const cellProps = {
      name: cellName,
      pattern,
      required,
      disabled,
      defaultValue: getValues(cellName)
    }

    return (
      <TableCell
        valign="top"
        key={cellName}
        className={isCellError ? style.cellWithError : ""}
      >
        {render(cellProps, rowIndex)}
      </TableCell>
    )
  })

  const subCells = (subRowIndex: number, fieldId: string) =>
    columns.map(({ render, name }, subCellIndex) => {
      const cellName = `${fieldName}.${rowIndex}.subValues.${subRowIndex}.${fieldId}.${name.toString()}`
      const easyCellName = `${fieldName}.${rowIndex}.subValues.${subRowIndex}.${name.toString()}`

      if (subCellIndex === 0) {
        return <TableCell valign="top" key={cellName}></TableCell>
      }

      const { pattern, required } = register(easyCellName)

      const cellProps = {
        name: easyCellName,
        pattern,
        required,
        disabled,
        defaultValue: getValues(easyCellName)
      }

      return (
        <TableCell valign="top" key={cellName}>
          {render(cellProps, rowIndex)}
        </TableCell>
      )
    })

  const dragStyle = {
    transform: CSS.Translate.toString(transform),
    transition
  }

  const addSublines = () => {
    append({})
  }

  return (
    <>
      <TableRow
        {...props}
        insideRef={setNodeRef}
        style={draggable ? dragStyle : null}
        {...attributes}
      >
        {numbered ? (
          <TableCell valign="top" className={style.numberRow}>
            <span className={style.title}>{rowIndex + 1}.</span>
            <input
              type="hidden"
              {...register(`${fieldName}.${rowIndex}.rowNumber`)}
              value={rowIndex + 1}
            />
          </TableCell>
        ) : null}
        {cells}
        {allowRemove ? (
          <TableCell className={style.removeContainer} valign="top">
            <Button
              onClick={() => onRemove(rowIndex)}
              className={style.removeButton}
              disabled={disableRemove ?? disabled}
            >
              <DeleteOutline className={style.icon} />
            </Button>
          </TableCell>
        ) : (
          <TableCell className={style.removeContainer}></TableCell>
        )}
        {allowSublines && (
          <TableCell className={style.removeContainer} valign="top">
            <Button
              onClick={() => addSublines()}
              className={style.removeButton}
            >
              <AddOutlined className={style.icon} />
            </Button>
          </TableCell>
        )}
        {draggable ? (
          <TableCell className={style.removeContainer} valign="top">
            <Button className={style.removeButton} {...listeners}>
              <DragIndicator className={style.icon} />
            </Button>
          </TableCell>
        ) : (
          <TableCell className={style.removeContainer}></TableCell>
        )}
      </TableRow>
      {allowSublines &&
        fields.map((field, index) => (
          <TableRow {...props} key={index}>
            {numbered ? (
              <TableCell valign="top" className={style.numberRow}></TableCell>
            ) : null}
            {subCells(index, field.id)}
            <TableCell className={style.removeContainer} valign="top">
              <Button
                onClick={() => {
                  remove(index)
                }}
                className={style.removeButton}
              >
                <DeleteOutline className={style.icon} />
              </Button>
            </TableCell>
            <TableCell className={style.removeContainer}></TableCell>
          </TableRow>
        ))}
    </>
  )
}

export default TableFormRow
