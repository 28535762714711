import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IRootState } from 'store/reducers';
import { Privacy } from 'components/pages/Privacy';

const mapStateToProps = (state: IRootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export type PrivacyContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const PrivacyContainer = connect(mapStateToProps, mapDispatchToProps)(Privacy);
