import React from 'react';
import { Button } from '@dqna/seamless-suite-ui-kit';
import { useAuthProvider } from 'utils/hooks/useAuthProvider';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import './NoninvitedUser.scss';
import { Routes } from 'types/app.types';

export const NoninvitedUser = () => {
  const { login, isAuthServiceReady } = useAuthProvider();
  return (
    <>
      <div className="noninvited">
        <Typography className="noninvited__h1" variant="h5">
          Ooops, it appears your user does not exist in the platform
        </Typography>
        <Typography className="noninvited__text" variant="body2">
          Please send a request to our client administrator using the following email address:
          <a
            className="support__link"
            href="mailto:support@seamlesspro.io?subject=Access%20Request&body=Hi%20Seamless%20Support">
            &nbsp;support@seamlesspro.io
          </a>
          . Once we receive your message, we will contact you to invite you to our platform.
        </Typography>
        <Typography className="login__message" variant="body1" fontWeight={600}>
          Log in with another account
        </Typography>
        <Button
          size="large"
          className="login__button"
          color="primary"
          variant="contained"
          disabled={!isAuthServiceReady}
          // eslint-disable-next-line
          onClick={() => login()}>
          Log in
        </Button>
      </div>
      <div className="bottom">
        <Link className="bottom__link" to={Routes.PRIVACY}>
          Privacy
        </Link>
        <Link className="bottom__link" to={Routes.TERMS_AND_CONDITIONS}>
          Terms And Conditions
        </Link>
      </div>
    </>
  );
};
