export enum Routes {
  HOME = '/',
  INVITE_EXPIRED = '/invite-expired',
  NON_INIVTED_USER = '/noninvited-user',
  ADMIN = '/admin',
  ORGANISATIONS = '/admin/organisations',
  ORGANISATIONS_OLD = '/admin/organisations-old',
  ORGANISATION_DETAILS = '/admin/organisations/:orgId',
  ORGANISATION_DETAILS_OLD = '/admin/organisations-old/:orgId',
  ORGANISATION_DETAILS_ACCOUNTS = '/admin/organisations/:orgId/accounts',
  ORGANISATION_DETAILS_USERS = '/admin/organisations/:orgId/users',
  PRIVACY = '/privacy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  LINKED_ACCOUNTS = '/linkedAccounts',
  SUGGESTED_KEYWORDS = '/suggested-keywords',
  SUB_ACCOUNT_DETAILS = '/details',
  AB_TESTING = '/ab-test',
  KEYWORD_MANAGEMENT_GROUPS = '/keywordManagement/:accountId/subAccounts/:subAccountId/group/:groupId',
  KEYWORD_MANAGEMENT_GROUPS_NO_ID = '/keywordManagement/:accountId/subAccounts/:subAccountId/group',
  KEYWORD_MANAGEMENT = '/keywordManagement',
  KEYWORD_MANAGEMENT_SUGGESTED_KEYWORDS = '/keywordManagement/:accountId/subAccounts/:subAccountId/suggested-keywords',
  KEYWORD_MANAGEMENT_SUB_ACCOUNT_DETAILS = '/keywordManagement/:accountId/subAccounts/:subAccountId/details',
  KEYWORD_MANAGEMENT_AB_TESTING = '/keywordManagement/:accountId/subAccounts/:subAccountId/ab-test',
  AB_TEST = '/abTest',
  REPORTS = '/reports',
  SIDEBAR = '/:tab',
  SOMETHING_WENT_WRONG = '/error-page',
}

// routes with double slash that result in broken redirect urls
export const blockedRoutes = ['//admin', '//linkedAccounts'];

export interface OptionType {
  label: string;
  value: string | null;
}
