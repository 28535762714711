import React, { useState, useEffect, ChangeEvent, ReactNode, memo } from 'react';
import classNames from 'classnames';

import { TableBodyProps, TableElements, TableBodyCell, TableCellAlign } from './types';

import { Checkbox } from 'components/ui-kit/Input';

export const TableBody = memo(
  <T extends { id: string | number }>({
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    data = {} as T,
    selectable,
    selectionDisabled,
    selected,
    onSelectRow,
    expandable,
    expandedRow,
    cells = [],
  }: TableBodyProps<T>) => {
    if (!cells.length) return null;
    const [selectedRow, setSelectedRow] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const [expanded, setExpanded] = useState(true);

    useEffect(() => {
      setSelectedRow(selected ?? false);
    }, [selected]);

    const renderBodyCells = (cellsData: Array<TableBodyCell<T>>) =>
      cellsData.map((cell, index) => {
        const { colspan, rowspan, className, renderer, field, align } = cell;

        let value: T[keyof T] | string | ReactNode | null = (field && data[field]) ?? null;

        if (renderer) {
          value = typeof renderer === 'function' ? renderer(data) : renderer;
          if (!value && value !== 0) {
            value = '-';
          }
        }

        return (
          <td key={index} colSpan={colspan} rowSpan={rowspan} className="table__cell">
            <div
              className={classNames('table__content', className, {
                'table__content--left': align === TableCellAlign.Left,
                'table__content--right': align === TableCellAlign.Right,
                'table__content--center': align === TableCellAlign.Center,
              })}>
              {value}
            </div>
          </td>
        );
      });

    const renderInput = () =>
      selectable ? (
        <td className="table__cell table__cell--selectable">
          <Checkbox
            name="table row"
            value={selected ? 'selected' : 'not selected'}
            className="table__cell-checkbox"
            checked={selected}
            disabled={selectionDisabled}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target;
              setSelectedRow(checked);
              if (onSelectRow) {
                onSelectRow(data, checked);
              }
            }}
          />
        </td>
      ) : null;

    const isExpandableRow = typeof expandable === 'function' ? expandable(data) : expandable;

    const renderExpandIcon = () => {
      return expandable ? (
        <td className="table__cell table__cell--expand">
          {isExpandableRow ? (
            /*
            function to add button to manually extend rows
            <div className="table__expand">
              <Arrow
                className={classNames('table__expand-icon', {
                  'table__expand-icon--expanded': expanded,
                })}
                onClick={() => setExpanded(!expanded)}
              />
              <span className="table__expand-text">
                {expanded ? 'Collapse' : 'Expand'}
              </span>
            </div>
          */
            <div />
          ) : null}
        </td>
      ) : null;
    };

    const renderExpandedRow = () => {
      return isExpandableRow && expandedRow ? (
        <tr
          className={classNames('table__row', {
            'table__row--expanded': expanded,
            'table__row--collapsed': !expanded,
          })}>
          {renderBodyCells(expandedRow)}
        </tr>
      ) : null;
    };

    return (
      <>
        <tr
          className={classNames('table__row', {
            'table__row--selected': selectedRow,
          })}>
          {renderInput()}
          {renderBodyCells(cells)}
          {renderExpandIcon()}
        </tr>
        {renderExpandedRow()}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.selected === nextProps.selected &&
    prevProps.data === nextProps.data &&
    prevProps.cells === nextProps.cells
);

TableBody.displayName = TableElements.TableBody;
