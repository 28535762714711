import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { switchMap, catchError, map, filter } from 'rxjs/operators';
import { from, of, forkJoin } from 'rxjs';
import { setUser, clearUser, fetchUser } from 'store/reducers/user.reducer';
import { HTTPService } from 'services/HTTP.service';
import { AuthService } from 'services/Auth.service';
import { IUser, IUserRoleRes } from 'types/user.type';
import { userRoutes } from 'constants/routes';
import { getOrg, setOrg } from 'utils/manageOrganisations';
import { Action } from '@reduxjs/toolkit';

const fetchUserInfoEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, auth }: { http: HTTPService; auth: AuthService }
) =>
  action$.pipe(
    filter(fetchUser.match),
    switchMap(() => forkJoin([from(auth.getIdTokenClaims()), from(auth.getTokenSilently())])),
    switchMap(([{ __raw: authToken }, accessToken]: [{ __raw: string }, string]) => {
      return from(http.get<IUser<IUserRoleRes>>(userRoutes.getUserInfo())).pipe(
        map((user) => {
          if (!Object.keys(user).length) {
            return clearUser();
          }

          const currentOrg = getOrg();
          if (!currentOrg) {
            setOrg(user.groups[0].name);
          }
          window.OneSignal.push(() => {
            window.OneSignal.isPushNotificationsEnabled((isEnabled: boolean) => {
              if (isEnabled) {
                window.OneSignal.setExternalUserId(user.user_id);
              }
            });
          });
          return setUser({
            currentUser: {
              ...user,
              roles: user.roles.map(({ name }) => name),
            },
            authToken,
            accessToken,
          });
        }),
        catchError((_) => of(clearUser()))
      );
    })
  );

export default combineEpics(fetchUserInfoEpic);
