import React, { FC } from "react"
import { Box, Stack, Typography } from "@mui/material"
import { IFormGroupProps } from "./IFormGroupProps"
import FormGroupScss from "./FormGroup.scss"
import { InfoOutlined, WarningAmberOutlined } from "@mui/icons-material"

const FormGroup: FC<IFormGroupProps> = ({
  title,
  extraText,
  children,
  className,
  withWarningIcon,
  withInfoIcon,
  error,
  disabled
}) => {
  return (
    <Box className={[FormGroupScss.container, className].join(" ")}>
      {title && (
        <Typography
          variant="caption"
          className={`${FormGroupScss.title} ${
            error && FormGroupScss.titleError
          } ${disabled && FormGroupScss.titleDisabled}`}
        >
          <Stack flexDirection="row" alignItems="end">
            {title}
            {withWarningIcon && (
              <WarningAmberOutlined
                fontSize="large"
                sx={{ ml: "0.4rem", color: "#ffb547" }}
              />
            )}
            {withInfoIcon && (
              <InfoOutlined
                fontSize="medium"
                color="inherit"
                sx={{ ml: "0.4rem", mb: "0.2rem" }}
              />
            )}
            {extraText && (
              <Typography variant="caption" className={FormGroupScss.caption}>
                {extraText}
              </Typography>
            )}
          </Stack>
        </Typography>
      )}
      {children}
    </Box>
  )
}

export default FormGroup
