import React from 'react';

import { TableBase } from './TableBase.component';
import { TableProps, TableType, TableElements } from './types';

export const Table = <T extends { id: string | number }>({
  dataSource,
  fullSelectionMetric,
  children,
  className,
  selectable,
  selectionDisabled,
  isLoading,
  scrollable,
  bodyHeight,
  pagination,
  downloadCsvData,
  extras,
  columnFilter,
  excludedColumns,
  selectionCommonField,
  errorTableElement,
}: TableProps<T>) => (
  <TableBase
    type={TableType.Table}
    dataSource={dataSource}
    fullSelectionMetric={fullSelectionMetric}
    className={className}
    selectable={selectable}
    selectionDisabled={selectionDisabled}
    isLoading={isLoading}
    scrollable={scrollable}
    bodyHeight={bodyHeight}
    pagination={pagination}
    downloadCsvData={downloadCsvData}
    extras={extras}
    columnFilter={columnFilter}
    excludedColumns={excludedColumns}
    selectionCommonField={selectionCommonField}
    errorTableElement={errorTableElement}>
    {children}
  </TableBase>
);

Table.displayName = TableElements.Table;
