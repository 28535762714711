import React, { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { Button } from '@dqna/seamless-suite-ui-kit';
import { OpenInNew } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GoogleAds from 'jsx:assets/gaicon.svg';
import { ConsoleStepProps } from './types';
import { AccountTypes } from 'components/pages/Home/types';
import { DataSourcesStatus, DataSourcesTypes } from 'types/accounts.type';
import { LINK_ACCOUNT_MODAL_NUMBER } from 'constants/subAccount';
import { MAX_NUMBER_OF_TRIES } from 'constants/account';
import { LinkingDataSourceStatus } from './LinkingDataSourceStatus.component';

export const GoogleAdsStep: React.FC<ConsoleStepProps> = (props) => {
  const {
    onCloseModal,
    onSubmit,
    onLinkAccount,
    linkDataSourceStatus,
    checkDataSourceIsLinked,
    onStepBack,
    setCurrentTimer,
  } = props;

  const handleOnbuttonClick = () => {
    onLinkAccount(AccountTypes.AdWords);
    checkDataSourceIsLinked(DataSourcesTypes.GOOGLE_ADWORDS);
  };

  useEffect(() => {
    if (
      linkDataSourceStatus &&
      linkDataSourceStatus.currentTry <= MAX_NUMBER_OF_TRIES &&
      linkDataSourceStatus.status === DataSourcesStatus.LOADING
    ) {
      const googleAdstimer = setTimeout(() => {
        checkDataSourceIsLinked(DataSourcesTypes.GOOGLE_ADWORDS);
      }, 30000);
      setCurrentTimer(googleAdstimer);
    }
  }, [linkDataSourceStatus]);

  return (
    <div>
      <Typography variant="body2" marginBottom={3}>
        4/{LINK_ACCOUNT_MODAL_NUMBER} - Connect Google Ads
      </Typography>
      <div className="modal-details">
        <Stack direction="row" className="modal-details__title">
          <GoogleAds />
          <Typography variant="body2" marginLeft={1} fontWeight={600}>
            Connect Google Ads
          </Typography>
        </Stack>
        <Stack direction="column" className="modal-details__body">
          <Typography variant="body2">
            Please connect to your Google Ads account in order for Seamless to access your paid
            search keywords.
          </Typography>
          {!linkDataSourceStatus && (
            <Button
              variant="text"
              color="primary"
              size="small"
              className="modal-details__body--account-btn"
              onClick={handleOnbuttonClick}
              endIcon={<OpenInNew />}>
              Select Account
            </Button>
          )}
          <LinkingDataSourceStatus
            linkDataSourceStatus={linkDataSourceStatus}
            tryAgainCallback={() => {
              checkDataSourceIsLinked(DataSourcesTypes.GOOGLE_ADWORDS, 0);
            }}
            accessText="View google Ads data for your verified sites"
            adverity={false}
          />
        </Stack>
      </div>

      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={onStepBack} startIcon={<ArrowBackIcon />}>
          Back
        </Button>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={linkDataSourceStatus?.status !== DataSourcesStatus.LINKED}>
            Next
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

GoogleAdsStep.displayName = 'GoogleAdsStep';
