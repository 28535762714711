import { Paper, PaperProps } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import MultiSelectScss from "./CustomSelect.scss"

interface AutoHeightPaperProps extends PaperProps {
  parentRef: HTMLDivElement
}

const PaperComponent: FC<AutoHeightPaperProps> = (props) => {
  const { parentRef, ...remainingProps } = props
  const [heightToBottomOfScreen, setHeightToBottomOfScreen] = useState(0)

  useEffect(() => {
    const selfBottom = parentRef?.getBoundingClientRect()?.bottom
    const remainingHeight = window.innerHeight - (selfBottom ?? 0)
    const remainingHeightMinusOffset = remainingHeight - 20
    setHeightToBottomOfScreen(remainingHeightMinusOffset)
  }, [remainingProps])

  return (
    <Paper
      {...remainingProps}
      className={MultiSelectScss.paper}
      style={{
        maxHeight: heightToBottomOfScreen
      }}
    />
  )
}

export default PaperComponent
