import React, { PropsWithChildren } from "react"
import { FormHelperText } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import FormGroup from "@src/components/FormGroup"
import { FormElementPropsType, useDefaultValue } from "../FormContainer"
import CustomSelect, {
  CustomSelectPropsType
} from "@src/components/CustomSelect"
import { ErrorMessage } from "@hookform/error-message"

export interface CustomSelectFormElementPropsType<ObjectType> {
  optionData: ObjectType[]
  labelKey: keyof ObjectType
  valueKey: keyof ObjectType
  imageKey?: keyof ObjectType
  disabledKey?: keyof ObjectType
  useNullForNone?: boolean // useNullForNone is required to be true for the single select component to be able to handle a possible empty string element in its options list.
}

export const CustomSelectFormElement = <T, K>(
  props: PropsWithChildren<
    FormElementPropsType<T> &
      CustomSelectFormElementPropsType<K> &
      CustomSelectPropsType
  >
) => {
  const {
    name,
    rules,
    title,
    placeholder,
    optionData,
    labelKey,
    valueKey,
    imageKey,
    disabledKey,
    disabled,
    withWarningIcon,
    withInfoIcon,
    titleExtra
  } = props

  const formContext = useFormContext()
  const {
    control,
    formState: { errors }
  } = formContext

  const defaultFormValue = useDefaultValue(formContext, name as string)

  return (
    <FormGroup
      title={title}
      extraText={titleExtra}
      withWarningIcon={withWarningIcon}
      withInfoIcon={withInfoIcon}
      error={!!errors[name?.toString()]}
      disabled={disabled}
    >
      <Controller
        name={name?.toString()}
        control={control}
        rules={rules}
        defaultValue={defaultFormValue}
        render={({ field: { onChange, value } }) => (
          <CustomSelect
            placeholder={placeholder}
            options={optionData.map((optionSource) => ({
              label:
                optionSource[valueKey]?.toString() === ""
                  ? "Empty value"
                  : optionSource[labelKey]?.toString(),
              value: optionSource[valueKey]?.toString(),
              image: imageKey ? optionSource[imageKey]?.toString() : null,
              disabled: disabledKey ? !!optionSource[disabledKey] : false
            }))}
            onChange={onChange}
            disabled={disabled}
            defaultValue={value}
            error={!!errors[name?.toString()]}
            {...props}
          />
        )}
      />

      <ErrorMessage
        errors={errors}
        name={name.toString()}
        render={({ message }) => (
          <FormHelperText error={true}>{message}</FormHelperText>
        )}
      />
    </FormGroup>
  )
}

export default CustomSelectFormElement
