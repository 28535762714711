import { FilterState } from './types';
import { DataType } from 'components/ui-kit/Dropdown';
import { StringValues, NumberValues } from 'constants/filters';

export const filterByStringMetric = (
  metric: string,
  value: string
): FilterState => ({
  value,
  metric,
  dataType: DataType.String,
  matcher: StringValues.START,
});

export const filterByIdsMetric = (value: string): FilterState => ({
  value,
  metric: 'id',
  dataType: DataType.String,
  matcher: StringValues.START,
});
