import React, { FC, PropsWithChildren } from "react"
import { Dialog, StyledEngineProvider, Typography } from "@mui/material"
import { Close } from "@mui/icons-material"
import Button from "@src/components/Button"
import styles from "./UserInteractionDialog.scss"

enum CLOSE_ACTIONS {
  CONFIRM = "confirm",
  CANCEL = "cancel"
}

export type CloseActionType = CLOSE_ACTIONS.CONFIRM | CLOSE_ACTIONS.CANCEL

export interface UserInteractionDialogPropsType {
  uiDialogState: boolean
  setUIDialogState: (state: boolean) => void
  title: string
  message?: string
  buttonCaptions?: {
    confirm?: string
    cancel?: string
  }
  onClose?: (closeAction: CloseActionType) => void
}

const UserInteractionDialog: FC<
  PropsWithChildren<UserInteractionDialogPropsType>
> = (props) => {
  const {
    uiDialogState,
    setUIDialogState,
    title,
    message,
    buttonCaptions,
    onClose,
    children
  } = props

  const onButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setUIDialogState(false)
    if (onClose) {
      onClose(event.currentTarget.id as CloseActionType)
    }
  }

  const handleClose = (_event: Event, reason: string) => {
    if (reason && ["backdropClick", "escapeKeyDown"].includes(reason)) {
      setUIDialogState(false)
      if (onClose) {
        onClose(null)
      }
    }
  }

  const buttons = Object.keys(buttonCaptions ?? []).map((buttonKey) => {
    const buttonId = buttonKey as CloseActionType
    const buttonCaption = buttonCaptions[buttonId]
    const buttonVariant =
      buttonId === CLOSE_ACTIONS.CANCEL ? "outlined" : "contained"

    if (!buttonCaption) {
      return null
    }

    return (
      <Button
        key={buttonId}
        id={buttonId}
        color="primary"
        variant={buttonVariant}
        onClick={onButtonClick}
      >
        {buttonCaption}
      </Button>
    )
  })

  return (
    <Dialog
      open={uiDialogState}
      onClose={handleClose}
      classes={{
        paper: styles.modal
      }}
    >
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <span
        className={styles.closeIcon}
        onClick={() => setUIDialogState(false)}
        aria-label="close-button"
      >
        <Close />
      </span>
      <StyledEngineProvider injectFirst>
        <Typography
          component={"span"}
          variant="body2"
          align="center"
          gutterBottom={true}
          className={styles.message}
        >
          {message}
        </Typography>
        {children}
      </StyledEngineProvider>
      <div className={styles.buttonContainer}>{buttons}</div>
    </Dialog>
  )
}

export default UserInteractionDialog
