import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISubAccountsState, ISubAccount, CreateSubAccountParams } from 'types/subAccounts.types';
import { IKeywordGroupInfo, KeywordGroupStatuses } from 'types/keywords.types';
import { formatSubAccountName } from 'utils/formatSubAccountName';

export type { ISubAccountsState } from 'types/subAccounts.types';

const getSubaccountIndex = (accountId: number, subAccountId: number, state: ISubAccountsState) => {
  return state.subAccounts[accountId].findIndex((subAccount) => subAccount.id === subAccountId);
};

const getKeywordGroups = (accountId: number, subAccountId: number, state: ISubAccountsState) => {
  const subAccountsIndex = getSubaccountIndex(accountId, subAccountId, state);
  return state.subAccounts[accountId][subAccountsIndex].keywordGroups;
};

const initialState: ISubAccountsState = {
  isLoading: false,
  error: null,
  subAccounts: {},
  currentPage: 1,
  limit: 25,
  subAccountsCreationResults: {},
  subAccountDetailsUpdateLoading: false,
};

const subAccountSlice = createSlice({
  name: 'subAccount',
  initialState,
  reducers: {
    changeSubAccountStatus: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number; isActive: boolean }>
    ) => {
      state.isLoading = true;
    },
    changeSubAccountSuccess: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number; isActive: boolean }>
    ) => {
      const { accountId, subAccountId, isActive } = action.payload;
      state.isLoading = false;
      state.error = null;
      const subAccountsIndex = getSubaccountIndex(accountId, subAccountId, state);
      if (subAccountsIndex !== -1) {
        state.subAccounts[accountId][subAccountsIndex].isActive = isActive;
      }
    },
    fetchSubAccounts: (state, action: PayloadAction<number>) => {
      state.isLoading = true;
    },
    fetchSubAccountsSuccess: (
      state,
      action: PayloadAction<{ accountId: number; subAccounts: ISubAccount[] }>
    ) => {
      state.isLoading = false;
      state.error = null;
      state.subAccounts[action.payload.accountId] = action.payload.subAccounts;
      const subaccountNames = action.payload.subAccounts.map((subAccount) => subAccount.name);
      Object.entries(state.subAccountsCreationResults).forEach(
        ([subAccountName, { isLoading }]) => {
          if (isLoading && subaccountNames.includes(subAccountName)) {
            state.subAccountsCreationResults[subAccountName].isLoading = false;
            state.subAccountsCreationResults[subAccountName].isSuccess = true;
          }
        }
      );
    },
    deleteSubAccount: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number }>
    ) => {
      state.isLoading = true;
    },
    deleteSubAccountSuccess: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number }>
    ) => {
      const { accountId, subAccountId } = action.payload;
      state.isLoading = false;
      const currentAccount = state.subAccounts[accountId];
      state.subAccounts[accountId] = currentAccount.filter(
        (subAccount) => subAccount.id !== subAccountId
      );
    },
    changeSubAccountGroup: (
      state,
      action: PayloadAction<{
        accountId: number;
        subAccountId: number;
        groupId: number;
        group: IKeywordGroupInfo | null;
      }>
    ) => {
      const { groupId, subAccountId, accountId, group } = action.payload;
      const subAccountsIndex = getSubaccountIndex(accountId, subAccountId, state);

      if (group && subAccountsIndex !== -1) {
        const keywordGroups = getKeywordGroups(accountId, subAccountId, state);
        if (keywordGroups) {
          const groupIndex = keywordGroups.findIndex((group) => group.id === groupId);
          if (groupIndex !== -1) {
            keywordGroups[groupIndex] = group;
            state.subAccounts[accountId][subAccountsIndex].keywordGroups = keywordGroups;
          }
        }
      }
      state.isLoading = false;
    },
    deleteGroup: (
      state,
      action: PayloadAction<{
        date: string;
        accountId: number;
        subAccountId: number;
        groupId: number;
      }>
    ) => {
      state.isLoading = true;
    },
    deleteGroupSuccess: (
      state,
      action: PayloadAction<{
        date: string;
        accountId: number;
        subAccountId: number;
        groupId: number;
      }>
    ) => {
      const { accountId, subAccountId, groupId } = action.payload;
      const subAccountsIndex = getSubaccountIndex(accountId, subAccountId, state);
      const keywordGroups = getKeywordGroups(accountId, subAccountId, state);
      const groups = keywordGroups?.filter((groups) => groups.id !== groupId);

      if (subAccountsIndex !== -1 && groups) {
        state.subAccounts[accountId][subAccountsIndex].keywordGroups =
          groups.length > 0 ? groups : [];
      }

      state.isLoading = false;
    },
    subAccountsActionFail: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.subAccountDetailsUpdateLoading = false;
    },
    addGroup: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number; group: IKeywordGroupInfo }>
    ) => {
      const { accountId, subAccountId, group } = action.payload;
      const subAccountsIndex = getSubaccountIndex(accountId, subAccountId, state);
      const keywordGroups = getKeywordGroups(accountId, subAccountId, state);
      if (keywordGroups) {
        keywordGroups.push(group);
        state.subAccounts[accountId][subAccountsIndex].keywordGroups = keywordGroups;
      } else {
        state.subAccounts[accountId][subAccountsIndex].keywordGroups = [group];
      }
    },
    userConfirmCloning: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number; groupId: number }>
    ) => {
      state.isLoading = true;
      state.error = null;
    },
    userConfirmCloningSuccess: (
      state,
      action: PayloadAction<{ accountId: number; subAccountId: number; groupId: number }>
    ) => {
      const { accountId, subAccountId, groupId } = action.payload;
      const subAccountsIndex = getSubaccountIndex(accountId, subAccountId, state);
      const keywordGroups = getKeywordGroups(accountId, subAccountId, state);
      if (keywordGroups) {
        const groupIndex = keywordGroups.findIndex((group) => group.id === groupId);
        if (groupIndex !== -1) {
          keywordGroups[groupIndex].status = KeywordGroupStatuses.TESTING_PHASE;
          state.subAccounts[accountId][subAccountsIndex].keywordGroups = keywordGroups;
        }
      }
    },
    createSubAccount: (state, action: PayloadAction<CreateSubAccountParams>) => {
      const nameWithoutSpecialCharacters = formatSubAccountName(action.payload.name);
      state.subAccountsCreationResults[nameWithoutSpecialCharacters] = {
        accountId: action.payload.accountId,
        isSuccess: false,
        isLoading: true,
      };
    },
    subAccountCreationTimedOut: (state) => {
      Object.entries(state.subAccountsCreationResults).forEach(([subAccountName]) => {
        state.subAccountsCreationResults[subAccountName].isLoading = false;
        state.subAccountsCreationResults[subAccountName].isSuccess = false;
      });
    },
    emptyCreationStatus: (state) => {
      state.subAccountsCreationResults = {};
    },
    updateSubAccountDetails: (
      state,
      action: PayloadAction<{
        accountId: number;
        subAccountId: number;
        maximumCpcBidInTestingPhase: number;
        maximumCpcBidInLivePhase: number;
        maximumCpcBidChangeInLivePhase: number;
        maximumIncrementRoas: number;
      }>
    ) => {
      state.subAccountDetailsUpdateLoading = true;
    },
    updateSubAccountDetailsSuccess: (
      state,
      action: PayloadAction<{
        accountId: number;
        subAccountId: number;
        maximumCpcBidInTestingPhase: number;
        maximumCpcBidInLivePhase: number;
        maximumCpcBidChangeInLivePhase: number;
        maximumIncrementRoas: number;
      }>
    ) => {
      const {
        accountId,
        subAccountId,
        maximumCpcBidInTestingPhase,
        maximumCpcBidInLivePhase,
        maximumCpcBidChangeInLivePhase,
        maximumIncrementRoas,
      } = action.payload;
      const subAccountsIndex = getSubaccountIndex(accountId, subAccountId, state);
      state.subAccounts[accountId][subAccountsIndex] = {
        ...state.subAccounts[accountId][subAccountsIndex],
        maximumCpcBidChangeInLivePhase,
        maximumCpcBidInLivePhase,
        maximumCpcBidInTestingPhase,
        maximumIncrementRoas,
      };
      state.subAccountDetailsUpdateLoading = false;
    },
    updateSubAccountFilters: (
      state,
      action: PayloadAction<{
        accountId: number;
        subAccountId: number;
        bgCampaignsFilters: string[];
      }>
    ) => {
      state.isLoading = true;
    },
    updateSubAccountFiltersSuccess: (
      state,
      action: PayloadAction<{
        accountId: number;
        subAccountId: number;
        bgCampaignsFilters: string[];
      }>
    ) => {
      const { accountId, subAccountId, bgCampaignsFilters } = action.payload;
      const subAccountsIndex = getSubaccountIndex(accountId, subAccountId, state);
      state.isLoading = false;
      state.subAccounts[accountId][subAccountsIndex].bgCampaignsFilters = bgCampaignsFilters;
    },
  },
});

export const {
  fetchSubAccounts,
  fetchSubAccountsSuccess,
  createSubAccount,
  emptyCreationStatus,
  deleteSubAccountSuccess,
  changeSubAccountStatus,
  changeSubAccountSuccess,
  updateSubAccountDetails,
  updateSubAccountDetailsSuccess,
  subAccountsActionFail,
  changeSubAccountGroup,
  deleteGroup,
  deleteGroupSuccess,
  addGroup,
  userConfirmCloning,
  userConfirmCloningSuccess,
  updateSubAccountFilters,
  updateSubAccountFiltersSuccess,
  deleteSubAccount,
  subAccountCreationTimedOut,
} = subAccountSlice.actions;
export default subAccountSlice.reducer;
