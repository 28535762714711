import React, { FC } from "react"
import { Divider, StyledEngineProvider, Typography, Box } from "@mui/material"
import styles from "./DetailSection.scss"

export interface DetailSectionPropsType {
  className?: string

  /**
   * The sectionCaption is a `subtitle2` textfield that is displayed above the component.
   */
  sectionCaption?: string

  /**
   * If `true` a divider will be shown on the bottom of this whole component.
   */
  withDivider?: boolean

  /**
   * Number of columns in the detail section. The child components will be wrapped in a new row based on this amount.
   */
  numberOfColumns?: number

  /**
   * The width of each column
   */
  colWidth?: string
}

const DetailSection: FC<DetailSectionPropsType> = ({
  className,
  sectionCaption,
  withDivider,
  numberOfColumns,
  colWidth,
  children
}) => {
  const childrenWithMargin = (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${numberOfColumns}, ${colWidth ?? "1fr"})`}
      columnGap={2}
      rowGap={0}
    >
      {React.Children.map(children, (child) => {
        // Child can be null if something was rendered then removed,
        // e.g. a loader. This prevents an empty box with margins to appear.
        if (!child) return null
        return (
          <Box gridColumn="span 1">
            <div className={styles.childrenMargin}>{child}</div>
          </Box>
        )
      })}
    </Box>
  )

  return (
    <div className={`${styles.detailSection} ${className}`}>
      {sectionCaption ? (
        <StyledEngineProvider injectFirst>
          <Typography
            component={"div"}
            variant="subtitle2"
            className={styles.sectionCaption}
          >
            {sectionCaption}
          </Typography>
        </StyledEngineProvider>
      ) : null}
      {childrenWithMargin}
      {withDivider ? (
        <StyledEngineProvider injectFirst>
          <Divider className={styles.divider} />
        </StyledEngineProvider>
      ) : null}
    </div>
  )
}

DetailSection.defaultProps = {
  withDivider: true
}

export default DetailSection
