import { useHistory } from 'react-router-dom';

import { setOrg, getOrg } from 'utils/manageOrganisations';

export const useOrganisation = () => {
  const { push, block } = useHistory();
  const changeOrganisation = (name: string) => {
    setOrg(name);
    push('/');
    block();
    window.location.reload();
  };

  const currentOrg = getOrg();

  return { currentOrg, changeOrganisation };
};
