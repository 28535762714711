/* eslint-disable */
import React, { FC, useState, useCallback, useEffect, useMemo, ChangeEvent } from 'react';
import { useHistory, useParams } from 'react-router';
import { useFlag } from 'utils/hooks/useFlag';
import { TextInput } from 'components/ui-kit/Input';
import { IncrementalIntegerInput } from 'components/ui-kit/Input/IncrementalInteger.component';
import { Dropdown, IItemData } from 'components/ui-kit/Dropdown';
import { Modal } from 'components/ui-kit/Modal';
import { Button, ButtonTypes } from 'components/ui-kit/Button';
import { AbGroupStatuses, AbTypes } from 'types/abtesting.types';
import { IAbTestingPanelProps } from './types';
import { abGroupTypes, EMPTY_VALIDATION_ERROR } from 'constants/abTesting';
import './AbTestingPanel.scss';
import { Routes } from 'types/app.types';
import { generateRouteWithIds } from 'utils/generateRouteWithIds';
import { Box } from '@mui/material';
import { CustomAlert } from '@dqna/seamless-suite-ui-kit';
import { AbGroupTables } from './AbGroupTables.component';

export const AbTestingPanel: FC<IAbTestingPanelProps> = ({
  newAbTestId,
  abGroupSubAccountId,
  abGroup,
  isAbGroupLoading,
  getAbTest,
  changeSubAccountBgFilters,
  previewAbTest,
  deleteAbGroup,
  activateAbTest,
  resetAbTestState,
  subAccount,
}) => {
  const { push, replace } = useHistory();
  const { accountId, subAccountId } = useParams<{
    accountId: string;
    subAccountId: string;
  }>();
  const [testName, setTestName] = useState<string>('');
  const [keywordPercentage, setKeywordPercentage] = useState<number>(100);
  const [selectedGroupOption, setSelectedGroupOption] = useState(AbTypes.NON_SEAMLESS);
  const [isReviewOpen, setIsReviewOpen] = useFlag();
  const [isPreviewOpen, setIsPreviewOpen] = useFlag();
  const [isLiveOpen, setIsLiveOpen] = useFlag();
  const [bgFilters, setBgFilters] = useState<string[]>([]);

  const abGroupOptions: IItemData[] = useMemo(
    () =>
      abGroupTypes.map((option) => ({
        ...option,
        selected: selectedGroupOption === option.value,
      })),
    [selectedGroupOption]
  );

  useEffect(() => {
    const routeWithIds = generateRouteWithIds({
      route: Routes.KEYWORD_MANAGEMENT_AB_TESTING,
      ids: { accountId, subAccountId },
    });
    replace({
      pathname: routeWithIds,
    });
  }, []);

  useEffect(() => {
    setBgFilters(subAccount?.bgCampaignsFilters ?? []);
  }, [subAccount]);

  useEffect(() => {
    resetAbTestState();
  }, []);

  useEffect(() => {
    if (subAccount?.abGroup?.status && !abGroup) {
      getAbTest(subAccount.account.id, subAccount.id, subAccount.abGroup.id);
    }
  }, [subAccount, abGroup]);

  useEffect(() => {
    newAbTestId && getAbTest(subAccount!.account.id, subAccount!.id, newAbTestId);
  }, [subAccount, newAbTestId]);

  useEffect(() => {
    abGroup?.group.status === AbGroupStatuses.PENDING &&
      setTimeout(() => {
        getAbTest(subAccount!.account.id, subAccount!.id, abGroup.group.id);
      }, 30000);
  }, [subAccount, abGroup]);

  const handleFilterEdit = useCallback(
    (e: ChangeEvent<HTMLInputElement>, array: string[], index: number) => {
      const {
        target: { value },
      } = e;

      array[index] = value;
      setBgFilters([...array]);
    },
    []
  );

  const handleDeleteBg = useCallback((item: string, array: string[]) => {
    array = array.filter((currentItem) => currentItem !== item);
    setBgFilters([...array]);
  }, []);

  const handleGroupNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    setTestName(value);
  }, []);

  const handleCloseModal = useCallback(() => {
    setBgFilters(subAccount!.bgCampaignsFilters);
    setIsReviewOpen();
  }, [subAccount]);

  const handlePreviewAbTest = useCallback(() => {
    previewAbTest(subAccount!.id, testName, keywordPercentage, selectedGroupOption);
    setIsPreviewOpen();
  }, [subAccount, testName, keywordPercentage, selectedGroupOption]);

  const handleSendToLive = useCallback(() => {
    activateAbTest(subAccount!.id, newAbTestId ?? subAccount!.abGroup!.id);
    push({
      pathname: Routes.LINKED_ACCOUNTS,
      search: undefined,
    });
  }, [newAbTestId, subAccount]);

  const handleChangeSubAccountDetails = useCallback(
    (accountId: number, subAccountId: number, bgCampaignsFilters: string[]) => {
      changeSubAccountBgFilters(accountId, subAccountId, bgCampaignsFilters);
    },
    []
  );

  const bgFiltersReview = useMemo(() => {
    return bgFilters.map((item, index) => {
      return (
        <div className="filter__item" key={index}>
          <TextInput
            value={item}
            outlined={true}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleFilterEdit(e, bgFilters, index);
            }}
            name="Bg Filter"
            label="Bg Filter"
            className="filter__item--input"
          />
          <Button
            className="filter__item--deletebg"
            name="Delete BG filter"
            onClick={() => {
              handleDeleteBg(item, bgFilters);
            }}>
            X
          </Button>
        </div>
      );
    });
  }, [bgFilters]);

  return (
    <div className="abtesting-panel">
      <div className="abtesting-panel__main">
        <div className="abtesting-panel__main ab-banner">
          {!abGroup?.group.id && (
            <div className="ab-banner__controls">
              <TextInput
                value={testName}
                onChange={handleGroupNameChange}
                name="Test name"
                outlined={true}
                validationError={testName ? '' : EMPTY_VALIDATION_ERROR}
                label="Test name"
                className="ab-banner__input"
              />
              <div className="ab-banner__incrementality">
                <IncrementalIntegerInput
                  name="% of keywords"
                  outlined={true}
                  setValue={setKeywordPercentage}
                  incrementValue={1}
                  incrementMaxValue={100}
                  incrementMinValue={0}
                  decimalInteger={0}
                  label="% of keywords"
                  value={keywordPercentage}
                  className="ab-banner__incrementality--btn"
                />
              </div>

              <Dropdown.Text
                options={abGroupOptions}
                value={selectedGroupOption}
                name="AB split"
                onChange={(option: IItemData) => {
                  setSelectedGroupOption(option.label as AbTypes);
                }}>
                {selectedGroupOption || 'Select AB split type'}
              </Dropdown.Text>
            </div>
          )}
          <Button
            className="ab-banner__button ab-banner__button--review-bg"
            name="review BG filters"
            onClick={() => {
              setIsReviewOpen();
            }}>
            Please review BG filters here
          </Button>
          <Button
            className="ab-banner__button ab-banner__button--preview-ab"
            name="Preview ab test"
            disabled={!!abGroup?.group.id}
            onClick={setIsPreviewOpen}>
            Preview AB Test
          </Button>
          <Button
            className="ab-banner__button ab-banner__button--reset"
            name="Reset"
            disabled={!abGroup?.group.id}
            onClick={() => {
              deleteAbGroup(subAccount!.account.id, abGroup?.group?.id, subAccount!.id);
            }}>
            Reset
          </Button>
          <Button
            className="ab-banner__button ab-banner__button--live"
            name="Live"
            disabled={abGroup?.group.status !== AbGroupStatuses.READY}
            onClick={setIsLiveOpen}>
            Go Live
          </Button>
        </div>

        {!abGroup?.group.id ? (
          <>
            <Box
              sx={{
                textAlign: 'center',
                margin: '1.3rem',
              }}>
              <CustomAlert severity="info">Create new ab test</CustomAlert>
            </Box>
          </>
        ) : (
          <AbGroupTables
            subAccount={subAccount}
            abGroup={abGroup}
            isAbGroupLoading={isAbGroupLoading}
            abGroupSubAccountId={abGroupSubAccountId}
          />
        )}
      </div>
      <Modal
        title="Confirmation ?"
        opened={isReviewOpen}
        onClose={handleCloseModal}
        acceptText="Save BG filters"
        onAccept={() => {
          handleChangeSubAccountDetails(subAccount!.account.id, subAccount!.id, bgFilters);
        }}
        declineText="Cancel">
        {bgFiltersReview}
        <Button
          className="add__filter"
          type={ButtonTypes.Secondary}
          name="Add filter"
          onClick={() => {
            setBgFilters([...bgFilters, '']);
          }}>
          + filter
        </Button>
      </Modal>
      <Modal
        title="Confirmation ?"
        opened={isPreviewOpen}
        onClose={setIsPreviewOpen}
        acceptText="Preview AB test"
        onAccept={handlePreviewAbTest}
        declineText="Cancel">
        <h3>This will create an AB test for subaccount : &quot;{subAccount?.name}&quot; !</h3>
      </Modal>
      <Modal
        title="Confirmation ?"
        opened={isLiveOpen}
        onClose={setIsLiveOpen}
        acceptText="Send to live"
        onAccept={handleSendToLive}
        declineText="Cancel">
        <h3>
          Do you really want to create an AB test for this sub-account: &quot;{subAccount?.name}
          &quot;?
        </h3>
        <div>This action is not reversible. </div>
      </Modal>
    </div>
  );
};

AbTestingPanel.displayName = 'AbTestingPanel';
