import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchNotifs } from 'store/actions/notifications.actions';
import { IRootState } from 'store/reducers';

import { NewNotifications } from 'components/ui-kit/Header/NewNotifications.component';

const mapStateToProps = (state: IRootState) => ({
  notifications: state.notifications.data,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getNotifications: () => dispatch(fetchNotifs()),
});

export type NotificationsContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const NotificationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewNotifications);
