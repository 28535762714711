import React, { FC, useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SubAccountsContainerProps } from 'components/containers/SubAccounts';
import { Button, ButtonWithDropdown } from '@dqna/seamless-suite-ui-kit';
import './SubAccounts.scss';
import { Stack, Typography } from '@mui/material';
import { SubAccountsDataTable } from '../SubAccountsDataTable';
import { IAccount } from 'types/accounts.type';
import { DeleteAccountModal } from '../DeleteAccountModal';
import { AddSubAccountModal } from '../AddSubAccountModal';
import { LinkAccountsNewModal } from '../LinkAccountsModal/LinkAccountsModal.component';
import { NoDataPlaceholder } from 'components/ui-kit/NoDataPlaceholder';
import { SubAccountSearch } from '../SubAccountSearch';
import useSearchInUrl from 'utils/hooks/useSearchInUrl';
import { NoSearchResult } from 'components/ui-kit/NoSearchResult';

export const SubAccountsPage: FC<SubAccountsContainerProps> = (props) => {
  const {
    accounts,
    subAccounts,
    deleteAccount,
    adwords,
    currentUser,
    subAccountsCreationResults,
    emptyCreationStatus,
    createSubAccount,
    createAccount,
    authToken,
    accessToken,
    checkDataSourceIsVerifiedReset,
    newAccount,
    linkDataSourcesStatuses,
    checkDataSourceIsLinked,
    editAccountName,
    accountsLoading,
    setPageTitle,
    urlsByAccount,
    fetchSubAccountUrls,
    getAccounts,
    newAccountStateReset,
    isDataSourceVerified,
    verifyAdveritySearchConsole,
    verifyAdverityAdWords,
    creationError,
    fetchSubAccounts,
    subAccountCreationTimedOut,
  } = props;
  const [selectedAccount, setSelectedAccount] = useState<IAccount>();
  const [deleteAccountModalOpenState, setDeleteAccountModalOpenState] = useState(false);
  const [addSubAccountModalOpenState, setAddSubAccountModalOpenState] = useState(false);
  const [addAccountModalOpenState, setAddAccountModalOpenState] = useState(false);
  const { search } = useSearchInUrl();
  const [filteredAccounts, setFilteredAccounts] = useState<IAccount[]>([]);

  useEffect(() => {
    setPageTitle({ title: 'Keyword Management', routes: [] });
  }, []);

  useEffect(() => {
    if (!accounts) {
      getAccounts();
    }
  }, [accounts]);

  useEffect(() => {
    if (search && accounts) {
      const accountsWithSerachedSubAccounts = accounts?.filter((account) =>
        subAccounts[account.id]?.find((subAccount) =>
          subAccount.name.toLowerCase().includes(search)
        )
      );
      setFilteredAccounts(accountsWithSerachedSubAccounts ?? []);
    } else {
      setFilteredAccounts(accounts ?? []);
    }
  }, [search, accounts, subAccounts]);

  const handleDeleteAccount = (account: IAccount) => {
    setSelectedAccount(account);
    setDeleteAccountModalOpenState(true);
  };

  return (
    <div className="sub-accounts-page__wrapper">
      {!accounts?.length && !accountsLoading ? (
        <NoDataPlaceholder
          buttonCaption="Add account"
          message=""
          onButtonClick={() => {
            setAddAccountModalOpenState(true);
          }}
          title="Time to add Accounts!"
          className="sub-accounts-page__no-data"
        />
      ) : (
        <>
          <Stack flexDirection="row" justifyContent="space-between" my="2.4rem">
            <SubAccountSearch />
            <ButtonWithDropdown
              menuItems={[
                {
                  action: () => {
                    setAddAccountModalOpenState(true);
                  },
                  label: 'Add Account',
                  value: 'account',
                },
                {
                  action: () => {
                    setAddSubAccountModalOpenState(true);
                  },
                  label: 'Add Sub-account',
                  value: 'subaccount',
                },
              ]}>
              Add
            </ButtonWithDropdown>
          </Stack>
          {!filteredAccounts.length && search && <NoSearchResult />}
          {filteredAccounts.map((account) => (
            <div className="sub-accounts-page__account-container" key={account.id}>
              <Stack justifyContent="space-between" flexDirection="row" alignItems="baseline">
                <Typography
                  variant="body2"
                  color="#333"
                  fontWeight={600}
                  marginBottom="2.4rem"
                  id={account.id.toString()}>
                  {account.name}
                </Typography>
                <Button
                  variant="text"
                  startIcon={<DeleteOutlineIcon />}
                  size="small"
                  onClick={() => {
                    handleDeleteAccount(account);
                  }}>
                  Remove account
                </Button>
              </Stack>
              <SubAccountsDataTable
                isLoading={false}
                account={account}
                subAccountList={subAccounts[account.id] || []}
                {...props}
              />
            </div>
          ))}
          {selectedAccount && (
            <DeleteAccountModal
              deleteAccount={deleteAccount}
              deleteAccountModalOpenState={deleteAccountModalOpenState}
              setDeleteAccountModalOpenState={setDeleteAccountModalOpenState}
              selectedAccount={selectedAccount}
            />
          )}
        </>
      )}
      <AddSubAccountModal
        modalOpenState={addSubAccountModalOpenState}
        setModalOpenState={setAddSubAccountModalOpenState}
        accounts={accounts}
        subAccounts={subAccounts}
        createSubAccount={createSubAccount}
        adwords={adwords}
        currentUser={currentUser}
        subAccountsCreationResults={subAccountsCreationResults}
        emptyCreationStatus={emptyCreationStatus}
        urlsByAccount={urlsByAccount}
        fetchSubAccountUrls={fetchSubAccountUrls}
        fetchSubAccounts={fetchSubAccounts}
        subAccountCreationTimedOut={subAccountCreationTimedOut}
      />
      <LinkAccountsNewModal
        modalOpenState={addAccountModalOpenState}
        setModalOpenState={setAddAccountModalOpenState}
        newAccount={newAccount}
        createAccount={createAccount}
        linkDataSourcesStatuses={linkDataSourcesStatuses}
        checkDataSourceIsLinked={checkDataSourceIsLinked}
        accessToken={accessToken ?? ''}
        authToken={authToken ?? ''}
        deleteAccount={deleteAccount}
        checkDataSourceIsVerifiedReset={checkDataSourceIsVerifiedReset}
        accounts={accounts ?? []}
        editAccountName={editAccountName}
        getAccounts={getAccounts}
        newAccountStateReset={newAccountStateReset}
        isDataSourceVerified={isDataSourceVerified}
        verifyAdveritySearchConsole={verifyAdveritySearchConsole}
        verifyAdverityAdWords={verifyAdverityAdWords}
        creationError={creationError}
      />
    </div>
  );
};
