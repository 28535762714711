/* eslint-disable */
import { Subject } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { ToastOptions, toast } from 'react-toastify';

import { notify } from './ServiceWorker.service';

export enum NotificationType {
  Toast = 'toast',
  Browser = 'browser',
}

interface Notification {
  type: NotificationType;
  title: string;
  options?: ToastOptions | NotificationOptions;
}

export class NotificationQueue {
  private readonly subject$ = new Subject<Notification>();

  constructor() {
    this.subject$
      .pipe(
        delay(500),
        tap(({ type, title, options }) => {
          this[type](title, options);
        })
      )
      .subscribe();
  }

  public requestPermissions = () => {
    if ('Notification' in window && window.Notification.permission !== 'granted') {
      window.Notification.requestPermission();
    }
  };

  public showNotification = (
    type: NotificationType,
    title: string,
    options?: NotificationOptions | ToastOptions
  ) => {
    this.subject$.next({
      type,
      title,
      options,
    });
  };

  public showAlert = (title: string) => {
    alert(title);
  };

  private [NotificationType.Toast] = (title: string, options?: ToastOptions) => {
    toast(title, {
      type: 'info',
      position: 'bottom-left',
      autoClose: 10000,
      hideProgressBar: true,
      draggable: false,
      closeButton: false,
      className: 'home__toast toast--general',
      ...options,
    });
  };

  private [NotificationType.Browser] = (title: string, options?: NotificationOptions) => {
    notify(title, options);
  };
}

export const notificationQueue = new NotificationQueue();
