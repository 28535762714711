type RoutesType = Record<string, (...args: any) => string>;

export const subAccountsRoutes: RoutesType = {
  changeActivity: () => '/sub-account',
  createSubAccount: () => '/sub-account',
  changeBgFilters: () => '/sub-account',
  changeDetails: () => '/sub-account',
  groupKeywords: (subAccountId: number, kwGroupId: number) =>
    `/sub-account/${subAccountId}/keywords-groups/${kwGroupId}/keywords`,
  subAccountKeywords: (subAccountId: string) =>
    `/sub-account/${subAccountId}/keywords-groups/keywords`,
  subAccountKeywordsInKeywordGroups: (subAccountId: string) =>
    `/sub-account/${subAccountId}/keywords`,
  suggestedKeywords: (subAccountId: string) => `/sub-account/${subAccountId}/suggested-keywords`,
  deleteSubAccount: (id: number) => `/sub-account/${id.toString(10)}`,
  getSubAccountsList: (accountId: number) => `/sub-account/${accountId}/list`,
  keywordGroup: (subAccountId: number) =>
    `/sub-account/${subAccountId.toString(10)}/keywords-groups`,
  deleteGroup: (subAccountId: number, groupId: number) =>
    `/sub-account/${subAccountId.toString(10)}/keywords-groups/${groupId.toString(10)}/keywords`,
  revertBids: (subAccountId: number) => `/sub-account/${subAccountId}/bids`,
};

export const accountsRoutes: RoutesType = {
  accounts: () => '/accounts',
  getAccountsList: () => '/accounts/list',
  manageAccount: (id: number) => `/accounts/${id}`,
  getAccountAdwords: (id: number) => `/accounts/${id.toString()}/adwords`,
  linkAdwords: (id: number) => `/link-accounts/${id}/adwords`,
  linkSearchConsole: (id: number) => `/link-accounts/${id}/search-console`,
  linkAdveritySearchConsole: (id: number) => `/link-accounts/${id}/adverity-search-console`,
  linkAdverityAdWords: (id: number) => `/link-accounts/${id}/adverity-adwords`,
  verifyAdveritySearchConsole: (id: number) =>
    `/link-accounts/${id}/verify-adverity-search-console`,
  verifyAdverityAdWords: (id: number) => `/link-accounts/${id}/verify-adverity-adwords`,
  getSubAccountUrls: (id: number) => `accounts/${id}/sites`,
};

export const keywordsGroupRoutes: RoutesType = {
  updateGroupData: (subAccountId: number) => `/sub-account/${subAccountId}/keywords-groups`,
};

export const userRoutes: RoutesType = {
  getUserInfo: () => '/user',
  getUsers: () => '/users',
};

export const adminRoutes: RoutesType = {
  manageOrganisation: () => '/organizations',
  getOrganisation: (orgId: string) => `/organizations/${orgId}`,
  manageUser: (orgId: string) => `/organizations/${orgId}/users`,
  manageReinvitedUser: (orgId: string) => `/organizations/${orgId}/invitations`,
};

export const notificationsRoutes: RoutesType = {
  fetchNotifications: () => '/notifications',
};

export const abTestRoutes: RoutesType = {
  previewAbTest: (subAccountId: number) => `/ab_testing/${subAccountId}`,
  manageAbGroup: (abGroupId: number, subAccountId: number) =>
    `/ab_testing/${subAccountId}/ab_test/${abGroupId}`,
};
