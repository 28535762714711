import { IItemData, DataType } from '../Dropdown';
import { NumberValues, StringValues, OptionsValues } from 'constants/filters';
import { ReactNode } from 'react';

export interface ICheckboxFilterButtonProps<T> {
  selectedRows: Array<T>;
  onChange: (filter: FilterState | null) => void;
  disabled?: boolean;
  label?: string;
}

export type ChangeFilterHandler = (
  key: string,
  metricName: string,
  value: string,
  dataType: DataType,
  matcher: NumberValues | StringValues | OptionsValues
) => void;
export type DeleteFilterHandler = (key: string, isTile?: boolean) => void;

export interface FilterItemProps {
  metrics: IItemData[];
  selectOptions?: { [key: string]: IItemData[] };
  onChangeFilter: ChangeFilterHandler;
  onRemoveFilter: DeleteFilterHandler;
  editMode?: boolean;
  index: string;
  defaultValues?: FilterState;
  onError: (metric: string, error: string) => void;
}

export interface FilterState {
  metric: string;
  matcher: NumberValues | StringValues | OptionsValues;
  value: string;
  dataType: DataType;
}

export interface ExtendedFilters extends FilterState {
  key: string;
}

export interface FilterProps {
  metrics: IItemData[];
  selectOptions?: { [key: string]: IItemData[] };
  onSubmitFilter: (filters: FilterState[]) => void;
  defaultValues?: FilterState[];
  customActions: ReactNode;
}
