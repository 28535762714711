import React, { FC } from "react"
import { StyledEngineProvider, Typography } from "@mui/material"
import styles from "./FieldTypography.scss"

const FieldLabelTypography: FC = (props) => {
  return (
    <StyledEngineProvider injectFirst>
      <Typography
        component={"span"}
        variant="caption"
        className={styles.caption}
      >
        {props.children}
      </Typography>
    </StyledEngineProvider>
  )
}

export default FieldLabelTypography
