export enum ChainLinkStatus {
  Done = 'done',
  Active = 'active',
  Empty = 'empty',
  Error = 'error',
  Middone = 'middone',
}

interface Index {
  indexKey: number;
  link?: string;
}

export interface ChainLinkProps {
  index: number;
  link?: string;
  showIndex?: boolean;
  className?: string;
}

export interface ProgressChainProps {
  className?: string;
  status?: ChainLinkStatus;
  indexes?: Index[];
}

export const ChainLinkName = 'ChainLink';
