import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { switchMap, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { forkJoin, from, of } from 'rxjs';
import {
  fetchAccountsSuccess,
  fetchAccountsFail,
  createAccountSuccess,
  deleteAccountSuccess,
  deleteAccountFail,
  checkDataSourceIsLinkedSuccess,
  checkDataSourceIsLinkedFail,
  editAccountNameFail,
  editAccountNameSuccess,
  getAccountsOfAllOrgsFail,
  getAccountsOfAllOrgsSuccess,
  fetchSubAccountUrlsSuccess,
  fetchSubAccountUrlsFail,
  createAccountFail,
  fetchAccounts,
  deleteAccount,
  checkDataSourceIsLinked,
  editAccountName,
  getAccountsOfAllOrgs,
  fetchSubAccountUrls,
  createAccount,
} from 'store/reducers/accounts.reducer';
import { IAccount, DataSourcesStatus } from 'types/accounts.type';
import { HTTPService } from 'services/HTTP.service';
import { accountsRoutes } from 'constants/routes';
import { NotificationQueue, NotificationType } from 'services/NotificationQueue.service';
import { deleteAccountError, editAccountNameError } from 'constants/errors/accounts.errors';
import { MAX_NUMBER_OF_TRIES } from 'constants/account';
import { IRootState } from 'store/reducers';
import { Action } from '@reduxjs/toolkit';
import {
  fetchAccountError,
  fetchSubAccountUrlsError,
  createAccountError,
} from 'constants/errors/linkedAccount.errors';
import { getOrg } from 'utils/manageOrganisations';
import { fetchSubAccounts } from 'store/reducers/subAccounts.reducer';
import { fetchAdwords } from 'store/reducers/adwords.reducer';

export const getAccountsEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http }: { http: HTTPService }
) =>
  actions$.pipe(
    filter(fetchAccounts.match),
    switchMap(() => {
      return from(http.get<IAccount[]>(accountsRoutes.getAccountsList())).pipe(
        mergeMap((data) => {
          const subAccountActions = data.map((acc) => fetchSubAccounts(acc.id));
          const adwordsActions = data.map((acc) => fetchAdwords(acc.id));
          return from([fetchAccountsSuccess(data), ...subAccountActions, ...adwordsActions]);
        }),
        catchError((_) => of(fetchAccountsFail(fetchAccountError)))
      );
    })
  );

export const createAccountEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http }: { http: HTTPService }
) =>
  actions$.pipe(
    filter(createAccount.match),
    switchMap(({ payload: { name, orgName } }) => {
      const organizationName = orgName ?? getOrg();
      return from(
        http.post<{ id: number; name: string }>(
          accountsRoutes.accounts(),
          {
            name,
          },
          undefined,
          {
            'X-Organization': organizationName,
          }
        )
      ).pipe(
        switchMap(({ id, name }) =>
          of(createAccountSuccess({ id, name, orgName: organizationName }))
        ),
        catchError((_) => of(createAccountFail(createAccountError)))
      );
    })
  );

export const deleteAccountEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(deleteAccount.match),
    switchMap(({ payload: { id, orgName } }) => {
      const organizationName = orgName ?? getOrg();
      return from(
        http.delete(accountsRoutes.manageAccount(id), undefined, undefined, {
          'X-Organization': organizationName,
        })
      ).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            `Account is successfully deleted!`
          );
          return of(deleteAccountSuccess({ id, orgName: organizationName }));
        }),
        catchError((_) => of(deleteAccountFail(deleteAccountError)))
      );
    })
  );

export const checkDataSourceIsLinkedEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(checkDataSourceIsLinked.match),
    switchMap(({ payload: { id, dataSourceType, currentTry, orgName } }) => {
      const organizationName = orgName ?? getOrg();
      return from(
        http.get<IAccount[]>(accountsRoutes.getAccountsList(), undefined, {
          'X-Organization': organizationName,
        })
      ).pipe(
        switchMap((data) => {
          const linked = data.filter(
            (account) =>
              account.id === id &&
              account.data_sources.find((source) => source.type === dataSourceType)
          );
          if (currentTry === MAX_NUMBER_OF_TRIES) {
            return of(
              checkDataSourceIsLinkedFail({
                dataSourceType,
                dataSourceStatus: DataSourcesStatus.ERROR,
                currentTry,
                error: new Error('Exceeded maximum number of tries'),
              })
            );
          }
          if (linked.length) {
            return of(
              checkDataSourceIsLinkedSuccess({
                dataSourceType,
                dataSourceStatus: DataSourcesStatus.LINKED,
                currentTry,
              })
            );
          }
          return of(
            checkDataSourceIsLinkedSuccess({
              dataSourceType,
              dataSourceStatus: DataSourcesStatus.LOADING,
              currentTry,
            })
          );
        }),
        catchError((_) => of(fetchAccountsFail(fetchAccountError)))
      );
    })
  );

export const editAccountNameEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(editAccountName.match),
    switchMap(({ payload: { id, name, orgName } }) => {
      const organizationName = orgName ?? getOrg();
      return from(
        http.patch(accountsRoutes.manageAccount(id), { name }, undefined, {
          'X-Organization': organizationName,
        })
      ).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            `Account name is successfully updated!`
          );
          return of(editAccountNameSuccess({ id, name, orgName: organizationName }));
        }),
        catchError((_) => of(editAccountNameFail(editAccountNameError)))
      );
    })
  );

export const getAccountsOfAllOrgsEpics = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<IRootState>,
  { http }: { http: HTTPService }
) => {
  return actions$.pipe(
    filter(getAccountsOfAllOrgs.match),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const observables = state.user.currentUser?.groups.map((group) => {
        return from(
          http.get<IAccount[]>(accountsRoutes.getAccountsList(), undefined, {
            'X-Organization': group.name,
          })
        ).pipe(switchMap((data) => of({ group: group.name, accounts: data })));
      });

      return forkJoin(observables).pipe(
        switchMap((data) => {
          const accountsByOrg: Record<string, IAccount[]> = {};

          data?.forEach((byOrg) => {
            accountsByOrg[byOrg.group] = byOrg.accounts;
          });
          return of(getAccountsOfAllOrgsSuccess(accountsByOrg));
        }),
        catchError(() => of(getAccountsOfAllOrgsFail()))
      );
    })
  );
};

export const fetchSubAccountUrlsEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<IRootState>,
  { http }: { http: HTTPService }
) =>
  actions$.pipe(
    filter(fetchSubAccountUrls.match),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const observables = state.accounts.accounts?.map((account) =>
        from(http.get<string[]>(`/${accountsRoutes.getSubAccountUrls(account.id)}`)).pipe(
          switchMap((data) => of({ account: account.id, urls: data }))
        )
      );
      return forkJoin(observables).pipe(
        switchMap((data) => {
          const urlsByAccount: Record<number, string[]> = {};
          data?.forEach((byAccount) => {
            urlsByAccount[byAccount.account] = byAccount.urls;
          });
          return of(fetchSubAccountUrlsSuccess(urlsByAccount));
        }),
        catchError(() => of(fetchSubAccountUrlsFail(fetchSubAccountUrlsError)))
      );
    })
  );

export default combineEpics(
  getAccountsEpic,
  createAccountEpic,
  deleteAccountEpic,
  checkDataSourceIsLinkedEpic,
  editAccountNameEpic,
  getAccountsOfAllOrgsEpics,
  fetchSubAccountUrlsEpic
);
