import React from 'react';


import './Privacy.scss';

export const Privacy = ({

}) => {

  return (
    <div className='privacy'>
      <h1>Privacy Policy</h1>
      <h3>This Privacy Policy describes how <a href='https://search.seamlesspro.io/' target='_blank'>https://search.seamlesspro.io/</a> (the “Site”, “service” or “we”) collects, uses,
      and discloses your Personal Information when you visit or make a purchase from the Site.
      </h3>
      <h1>Collecting Personal Information</h1>
      <p>When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary for the Site to function. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.
      </p>
      <h2>Device information</h2>
      <ol>
        <li><strong>Examples of Personal Information collected:</strong> version of web browser, IP address, time zone, cookie information, and how you interact with the Site information.</li>
        <li><b>Purpose of collection:</b> to load the Site accurately and securely for you, for behavioral analytics, and for functionality purposes.</li>
        <li><b>Source of collection:</b> Collected automatically when you access our Site using cookies, log files, web beacons, behavioural analytics, tags, or pixels.</li>
        <li><b>Disclosure for business purposes:</b> shared with web and behavioral analytics, security and identification vendors, and business data extraction services. We only disclose data for necessary business purposes.This allows us to provide the Site to you.</li>
      </ol>
      <h2>Customer support information</h2>
      <ol>
        <li><b>Examples of Personal Information collected:</b> Google user ID, email address, full name, employer name.</li>
        <li><b>Purpose of collection:</b> to provide customer support and for performance.</li>
        <li><b>Source of collection:</b> collected from you and authentication partners. For example, we may have access to certain information from a third party authentication service if you log into our Site through such a service or otherwise provide us with access to information from the service. Any access that we may have to such information from a third party social media or authentication service is in accordance with the authorization procedures determined by that service. This applies when you log in to the Site using single sign-in services such as Google.</li>
        <li><b>Disclosure for a business purpose:</b> ticketing partners, identity management solutions and cloud providers.</li>
      </ol>
      <h1>Sharing Personal Information</h1>
      <p>We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:</p>
      <ol>
        <li>We may share your cellsPersonal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</li>
        <li>Business partners, suppliers, and sub-contractors for the performance of any contract we enter into with you.</li>
        <li>Analytics and search engine providers that assist us in the improvement and optimization of our Site.</li>
        <li>Ticketing partners that assist us with support requests.</li>
        <li>Identity management solutions.</li>
        <li>Cloud providers.</li>
        <li>Google, for login and identification purposes. You can read more about how Google uses your Personal Information here: <a href='https://policies.google.com/privacy?hl=en' target='_blank'>https://policies.google.com/privacy?hl=en</a>.</li>
      </ol>

      <h2>Analytics</h2>
      <p>We use Google Analytics to collect metrics and information regarding your use of the Site, including evaluating how our customers use the Service, to develop new features, and improve existing features. You can read more about how Google uses your Personal Information here: <a href='https://policies.google.com/privacy?hl=en' target='_blank'>https://policies.google.com/privacy?hl=en</a>.You can also opt-out of Google Analytics here: <a href='https://tools.google.com/dlpage/gaoptout' target='_blank'>https://tools.google.com/dlpage/gaoptout</a>.</p>

      <h1>Using Personal Information</h1>
      <p>We use your personal Information to provide our services to you, which includes: making the Site available to you, to provide you with the necessary functionality during your use of our service and keeping you up to date on new products and services.</p>

      <h2>Lawful basis</h2>
      <p>Pursuant to the General Data Protection Regulation (“GDPR”), if you are a resident of the European Economic Area (“EEA”), we process your personal information under the following lawful bases:</p>
      <ol>
        <li>Your consent;</li>
        <li>The performance of the contract between you and the Site;</li>
        <li>Compliance with our legal obligations;</li>
        <li>To protect your vital interests;</li>
        <li>To perform a task carried out in the public interest;</li>
        <li>For our legitimate interests, which do not override your fundamental rights and freedoms.</li>
      </ol>

      <h2>Retention</h2>
      <p>When you register your account with the Site, we will retain your Personal Information for our records unless and until you ask us to erase this information. For more information on your right of erasure, please see the ‘Your rights’ section below.</p>

      <h2>Automatic decision-making</h2>
      <p>If you are a resident of the EEA, you have the right to object to processing based solely on automated decision-making (which includes profiling), when that decision-making has a legal effect on you or otherwise significantly affects you.
      We do not engage in fully automated decision-making that has a legal or otherwise significant effect using customer data.
      </p>

      <h1>Your rights</h1>
      <h2>GDPR</h2>
      <p>If you are a resident of the EEA, you have the right to access the Personal Information we hold about you, to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights, please contact us through the contact information below.
      We do not share your personal data with third parties, unless it is necessary to carry out the service or your request, for our professional or legitimate business needs, or as required or permitted by law. Where we do transfer your personal data to third parties or service providers, appropriate arrangements will be made in order to ensure correct and secure data processing in compliance with applicable data protection law. Your Personal Information will be initially processed in the UK and then will be transferred outside of Europe for storage and further processing, including to Canada and the United States.
      If you would like to designate an authorized agent to submit these requests on your behalf, please contact us at the address below.
      </p>

      <h1>Cookies</h1>
      <p>A cookie is a small amount of information that’s downloaded to your computer or device when you visit our Site. We use a number of different cookies, including functional, performance, advertising, and social media or content cookies. Cookies make your browsing experience better by allowing the website to remember your actions and preferences (such as login and region selection). This means you don’t have to re-enter this information each time you return to the site or browse from one page to another. Cookies also provide information on how people use the website, for instance whether it’s their first time visiting or if they are a frequent visitor.
      We use the following cookies to optimize your experience on our Site and to provide our services.
      </p>

      <h2>Cookies Necessary for the Functioning of the Website</h2>
      <table className="table">
        <thead>
          <tr>
            <th className="table-header1">Name</th>
            <th className="table-cells"><span>Function</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-cells"><span>auth0.is.authenticated</span></td>
            <td className="table-cells"><span>Used to determine if a request to Auth0 for silent authentication 2 should take place</span></td>
          </tr>
          <tr>
            <td className="table-cells"><span>_legacy_auth0.is.authenticated</span></td>
            <td className="table-cells"><span>Legacy version of the above cookie</span></td>
          </tr>
        </tbody>
      </table>

      <h2>Reporting and Analytics</h2>
      <table className="table">
        <thead>
          <tr>
            <th className="table-header1">Name</th>
            <th className="table-cells"><span>Function</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-cells"><span>_ga</span>_utmaGoogle Analytics</td>
            <td className="table-cells"><span>General user analysis. These cookies are used to collect information about how visitors use our website. We use the information to compile reports and to help us improve the website. The cookies collect information in an anonymous form, including the number of visitors to the website, where visitors have come to the site from and the pages they visited</span></td>
          </tr>
          <tr>
            <td className="table-cells"><span>_hjClosedSurveyInvites</span></td>
            <td className="table-cells"><span>Hotjar cookie that is set once a user interacts with an External Link Survey invitation modal. It is used to ensure that the same invite does not reappear if it has already been shown.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjDonePolls</td>
            <td className="table-cells-highlighted"><span>Hotjar cookie that is set once a user completes a survey using the On-site Survey widget. It is used to ensure that the same survey does not reappear if it has already been filled in.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjMinimizedPolls</td>
            <td className="table-cells-highlighted"><span>Hotjar cookie that is set once a user minimizes an On-site Survey widget. It is used to ensure that the widget stays minimized when the user navigates through your site.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjShownFeedbackMessage</td>
            <td className="table-cells-highlighted"><span>Hotjar cookie that is set when a user minimizes or completes Incoming Feedback. This is done so that the Incoming Feedback will load as minimized immediately if the user navigates to another page where it is set to show.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjSessionTooLarge</td>
            <td className="table-cells-highlighted"><span>Causes Hotjar to stop collecting data if a session becomes too large. This is determined automatically by a signal from the WebSocket server if the session size exceeds the limit.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjSessionRejected</td>
            <td className="table-cells-highlighted"><span>If present, this cookie will be set to '1' for the duration of a user's session, if Hotjar rejected the session from connecting to our WebSocket due to server overload. This cookie is only applied in extremely rare situations to prevent severe performance issues.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjSessionResumed</td>
            <td className="table-cells-highlighted"><span>A cookie that is set when a session/recording is reconnected to Hotjar servers after a break in connection.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjid</td>
            <td className="table-cells">Hotjar cookie that is set when the customer first lands on a page with the Hotjar script. It is used to persist the Hotjar User ID, unique to that site on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.</td>
          </tr>
          <tr>
            <td className="table-cells">_hjRecordingLastActivity</td>
            <td className="table-cells-highlighted"><span>This should be found in Session storage (as opposed to cookies). This gets updated when a user recording starts and when data is sent through the WebSocket (the user performs an action that Hotjar records).</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjTLDTest</td>
            <td className="table-cells-highlighted"><span>When the Hotjar script executes we try to determine the most generic cookie path we should use, instead of the page hostname. This is done so that cookies can be shared across subdomains (where applicable). To determine this, we try to store the _hjTLDTest cookie for different URL substring alternatives until it fails. After this check, the cookie is removed.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjUserAttributesHash</td>
            <td className="table-cells-highlighted"><span>User Attributes sent through the Hotjar Identify API are cached for the duration of the session in order to know when an attribute has changed and needs to be updated.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjCachedUserAttributes</td>
            <td className="table-cells-highlighted"><span>This cookie stores User Attributes which are sent through the Hotjar Identify API, whenever the user is not in the sample. Collected attributes will only be saved to Hotjar servers if the user interacts with a Hotjar Feedback tool, but the cookie will be used regardless of whether a Feedback tool is present.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjLocalStorageTest</td>
            <td className="table-cells-highlighted"><span>This cookie is used to check if the Hotjar Tracking Script can use local storage. If it can, a value of 1 is set in this cookie. The data stored in_hjLocalStorageTest has no expiration time, but it is deleted almost immediately after it is created.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjIncludedInPageviewSample</td>
            <td className="table-cells-highlighted"><span>This cookie is set to let Hotjar know whether that user is included in the data sampling defined by your site's pageview limit.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjIncludedInSessionSample</td>
            <td className="table-cells-highlighted"><span>This cookie is set to let Hotjar know whether that user is included in the data sampling defined by your site's daily session limit.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjAbsoluteSessionInProgress</td>
            <td className="table-cells-highlighted"><span>This cookie is used to detect the first pageview session of a user. This is a True/False flag set by the cookie.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjFirstSeen</td>
            <td className="table-cells">This is set to identify a new user’s first session. It stores a true/false value, indicating whether this was the first time Hotjar saw this user. It is used by Recording filters to identify new user sessions.</td>
          </tr>
          <tr>
            <td className="table-cells">_hjViewportId</td>
            <td className="table-cells-highlighted"><span>This stores information about the user viewport such as size and dimensions.</span></td>
          </tr>
          <tr>
            <td className="table-cells">_hjRecordingEnabled</td>
            <td className="table-cells-highlighted"><span>This is added when a Recording starts and is read when the recording module is initialized to see if the user is already in a recording in a particular session.</span></td>
          </tr>
        </tbody>
      </table>
      <p>The length of time that a cookie remains on your computer or mobile device depends on whether it is a “persistent” or “session” cookie. Session cookies last until you stop browsing and persistent cookies last until they expire or are deleted. Most of the cookies we use are persistent and will expire between 30 minutes and two years from the date they are downloaded to your device.</p>
      <p>You can control and manage cookies in various ways. Please keep in mind that removing or blocking cookies will negatively impact your user experience and the majority of the Site will no longer be accessible.</p>
      <p>Most browsers automatically accept cookies, but you can choose whether or not to accept cookies through your browser controls, often found in your browser’s “Tools” or “Preferences” menu. For more information on how to modify your browser settings or how to block, manage or filter cookies can be found in your browser’s help file or through such sites as <a href='www.allaboutcookies.org.' target='_blank'>www.allaboutcookies.org.</a></p>
      <p>Additionally, please note that blocking cookies may not completely prevent how we share information with third parties such as our advertising partners. To exercise your rights or opt-out of certain uses of your information by these parties, please follow the instructions in the “Behavioural Advertising” section above.</p>
      <h1>Changes</h1>
      <p>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</p>

      <h1>Contact</h1>
      <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at UKDPO@incubeta.com or by mail using the details provided below:</p>

      <p>Incubeta, The Bower, Old Street, London, EC1V 9NR, United Kingdom</p>
      <p>Last updated: 13th June 2021</p>
      <p>If you are not satisfied with our response to your complaint, you have the right to lodge your complaint with the relevant data protection authority.</p>
    </div>
  );
};

Privacy.displayName = 'PrivacyPage';
