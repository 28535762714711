import React, { FC } from 'react';
import { PauseActivateSubAccountModalProps } from './types';
import { Modal } from 'components/ui-kit/Modal';

export const PauseActivateSubAccountModal: FC<PauseActivateSubAccountModalProps> = (props) => {
  const {
    pauseActivateModalOpenState,
    setPauseActivateModalOpenState,
    selectedSubAccount,
    changeSubAccountStatus,
  } = props;

  const handleStatusToggle = () => {
    changeSubAccountStatus(
      selectedSubAccount.account.id,
      selectedSubAccount.id,
      !selectedSubAccount.isActive
    );
  };

  return (
    <Modal
      title="Confirmation ?"
      opened={pauseActivateModalOpenState}
      onClose={() => {
        setPauseActivateModalOpenState(false);
      }}
      acceptText={`${selectedSubAccount.isActive ? 'Pause' : 'Run'} activity`}
      onAccept={handleStatusToggle}
      declineText="Cancel">
      <div>
        This will {selectedSubAccount.isActive ? 'pause' : 'run'} all Seamless Search keyword
        activity on subaccount &quot;
        {selectedSubAccount.name}&quot;
      </div>
    </Modal>
  );
};
