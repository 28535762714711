import { IKeywordNewType } from 'types/keywords.types';
import { ISubAccount, KeywordBodyType } from 'types/subAccounts.types';

export interface CreateKeywordGroupModalProps {
  modalOpenState: boolean;
  setModalOpenState: (stat: boolean) => void;
  keywords: IKeywordNewType[];
  subAccount: ISubAccount;
  sendKeywordsToTest: (
    date: string,
    accountId: number,
    subAccountId: number,
    body: KeywordBodyType
  ) => void;
  keywordGroupCreationStatus: {
    isLoading: boolean;
    error: Error | null;
    numberOfAddedKeywords: number;
  };
  getSuggestedKeywords: (params: { subAccountId: number }) => void;
  keywordListLoadingState: boolean;
}

export interface KeywordsModalTableProps {
  setRowSelectionModel: (model: string[]) => void;
  rowSelectionModel: string[];
  keywords: IKeywordNewType[];
  subAccount: ISubAccount;
  onSwitchStep: () => void;
  onCloseModal: () => void;
  keywordListLoadingState: boolean;
}

export interface GroupNameFormProps {
  groupName: string;
  setGroupName: (name: string) => void;
  onSwitchStep: () => void;
  onCloseModal: () => void;
  onSubmit: (formData: CreateGroupFormData) => void;
  subAccount: ISubAccount;
}

export interface CreationStatusProps {
  keywordGroupCreationStatus: {
    isLoading: boolean;
    error: Error | null;
    numberOfAddedKeywords: number;
  };
  groupName: string;
  numberOfSelectedKeywords: number;
  onCloseModal: () => void;
}

export interface CreateGroupFormData {
  groupName: string;
}

export enum CreationFlowSteps {
  KEYWORDS_TABLE,
  GROUP_NAME,
  CREATION_STATUS,
}
