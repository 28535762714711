import React, {
  FC,
  useState,
  useMemo,
  useEffect,
  useCallback,
  MouseEvent,
} from 'react';
import classNames from 'classnames';

import { Button, ButtonTypes } from 'components/ui-kit/Button';
import { FilterItem } from './FilterItem.component';

import {
  FilterProps,
  ExtendedFilters,
  ChangeFilterHandler,
  DeleteFilterHandler,
} from './types';

import { getId } from 'utils/getId';

import './Filters.scss';

export const Filters: FC<FilterProps> = ({
  onSubmitFilter,
  metrics,
  selectOptions,
  defaultValues,
  customActions,
}) => {
  const [filters, setFilters] = useState<ExtendedFilters[]>([]);
  const [editMode, setEditMode] = useState(true);
  const [errors, setErrors] = useState<{ [key: string]: string | null }>({});

  useEffect(() => {
    if (defaultValues) {
      const allUnkeyedDefaults = defaultValues.map(defaultFilter => {
        const filterExist = filters.find(
          filter =>
            filter.metric === defaultFilter.metric &&
            filter.matcher === defaultFilter.matcher &&
            filter.value === defaultFilter.value
        );
        return filterExist || { ...defaultFilter, key: getId() };
      });
      setFilters([...allUnkeyedDefaults]);
      setEditMode(false);
    }

    if (!filters.length && !defaultValues) {
      setEditMode(true);
    }
  }, [defaultValues]);

  const handleFilterItemChange: ChangeFilterHandler = useCallback(
    (key, metric, value, dataType, matcher) => {
      setFilters(currFilters =>
        currFilters.map(filter => {
          if (filter.key === key) {
            return { ...filter, metric, value, dataType, matcher };
          }
          return filter;
        })
      );
    },
    []
  );

  const handleDeleteFilter: DeleteFilterHandler = useCallback(
    (key, isTile = false) => {
      const updatedFilters = filters.filter(
        ({ key: filterKey }) => filterKey !== key
      );
      setFilters(updatedFilters);

      if (isTile) {
        onSubmitFilter(updatedFilters);
      }
    },
    [filters, onSubmitFilter]
  );

  const handleAddFilterItems = useCallback(() => {
    if (!editMode) {
      setEditMode(true);
    }
    setFilters(prevFilters => [
      ...prevFilters,
      {
        key: getId(),
      } as ExtendedFilters,
    ]);
  }, [editMode, getId]);

  const handleApplyFilters = useCallback(() => {
    const isErrorsExist = Object.values(errors).filter(Boolean);

    if (!isErrorsExist.length) {
      const nonEmptyFilters = filters.filter(
        ({ value }) => value != null && value.replace(/\s/, '') !== ''
      );
      const filtersWithoutKey = nonEmptyFilters.map(({ key, ...rest }) => ({
        ...rest,
      }));
      onSubmitFilter(filtersWithoutKey);
      setEditMode(false);
      setFilters(nonEmptyFilters);
    }
  }, [errors, filters, onSubmitFilter]);

  const handleManageError = useCallback((metric: string, error: string) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [metric]: error,
    }));
  }, []);

  const handleClickClosedFilters = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setEditMode(true);
    },
    []
  );

  const isExistingFilters = useMemo(() => Object.entries(filters).length, [
    filters,
  ]);

  return (
    <div className="filter">
      <div
        className={classNames('filter__opened', {
          filter__hidden: !editMode,
        })}>
        <div
          className={classNames('filter__items', {
            filter__tiles: !editMode,
          })}
          onClick={!editMode ? handleClickClosedFilters : () => {}}>
          {filters.map(filter => (
            <FilterItem
              key={filter.key}
              onChangeFilter={handleFilterItemChange}
              metrics={metrics}
              selectOptions={selectOptions}
              onRemoveFilter={handleDeleteFilter}
              editMode={editMode}
              index={filter.key}
              onError={handleManageError}
              defaultValues={filter}
            />
          ))}
        </div>
        <div className="filter__buttons">
          <div className="filter__buttons--right">
            {!isExistingFilters || editMode ? (
              <Button
                name="Add filter"
                onClick={handleAddFilterItems}
                type={ButtonTypes.Secondary}>
                Add filter
              </Button>
            ) : null}
            {customActions}
          </div>
          {isExistingFilters && editMode ? (
            <Button
              name="Apply filters"
              type={ButtonTypes.Primary}
              onClick={handleApplyFilters}>
              Apply filters
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

Filters.displayName = 'Filters';
