import React, { FC, MouseEvent, useState } from "react"
import { IChipProps } from "./IChipProps"
import { Chip as MUIChip } from "@mui/material"
import { Check } from "@mui/icons-material"
import ChipScss from "./Chip.scss"

const Chip: FC<IChipProps> = (
  {
    label,
    value,
    onDelete,
    onSelect,
    defaultSelected,
    avatar,
    preventDeselect,
    variant,
    isSmall,
    multiline
  },
  props
) => {
  const [selected, setSelected] = useState(defaultSelected)

  const handleDelete = (event: Event) => {
    event.stopPropagation()
    if (onDelete) {
      onDelete(value)
    }
  }

  const handleSelect = (event: MouseEvent) => {
    event.stopPropagation()
    if (preventDeselect && selected) {
      return
    }
    if (onSelect) {
      onSelect(value)
    }
    setSelected(!selected)
  }

  const methods = {
    onDelete: onDelete ? handleDelete : undefined,
    onClick: onSelect ? handleSelect : undefined
  }

  return (
    <MUIChip
      classes={{
        root: [
          ChipScss.root,
          onSelect ? ChipScss.selectElement : "",
          selected ? ChipScss.selected : "",
          isSmall ? ChipScss.small : "",
          multiline ? ChipScss.multiline : "",
          variant === "outlined" ? ChipScss.outlined : ""
        ].join(" "),
        label: [ChipScss.label, multiline ? ChipScss.labelMultiline : ""].join(
          " "
        ),
        avatar: ChipScss.avatar
      }}
      label={label}
      {...methods}
      avatar={avatar}
      icon={onSelect && selected ? <Check /> : null}
      variant={variant}
      {...props}
    ></MUIChip>
  )
}

export default Chip
