import React, { FC, useMemo, useState } from 'react';
import formatDate from 'date-fns/format';
import { useFlag } from 'utils/hooks/useFlag';
import { DetailPanel, DetailSection, TextWithTitle } from '@dqna/seamless-suite-ui-kit';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { KeywordGroupKeywordDisplayWithModal } from './KeywordGroupKeywordDisplayWithModal.component';
import { KeywordGroupVerticalStepper } from './KeywordGroupVerticalStepper.component';
import { ChangeLearningPhaseStatusModal } from './ChangeLearningPhaseStatusModal.component';
import { RemoveGroupModal } from '.';
import { KeywordGroupRightSideContainerProps } from './type';
import {
  IKeywordNewType,
  KeywordGroupStatuses,
  TestingPhaseStatusesActions,
} from 'types/keywords.types';
import { downloadCSV } from 'utils/downloadCSV';
import './KeywordGroupDetails.scss';
import { RemoveKeywordsModal } from '../RemoveKeywordsModal';
import { LOW_VOLUME_LIMIT } from 'constants/keywords';

export const KeywordGroupRightSideContainer: FC<KeywordGroupRightSideContainerProps> = (props) => {
  const {
    subAccount,
    group,
    keywords,
    deleteGroup,
    pauseKeywordsGroupTestingPhase,
    dataTableLoading,
    removeKeywords,
  } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isChangeGroupTestPhaseStatusModalOpen, setChangeGroupTestPhaseStatusModalOpen] =
    useFlag(false);
  const [isRemoveKeywordsModalOpen, setRemoveKeywordsModalOpen] = useState(false);

  const menuItemsWithDependencies = [
    {
      buttonItem: {
        label: 'Delete',
        value: 'delete',
        action: () => {
          setDeleteModalOpen(true);
        },
      },
      dependands: [],
    },
    {
      buttonItem: {
        label: 'Export',
        value: 'export',
        action: () => {
          downloadCSV(keywords, 'seamless_search.csv');
        },
      },
      dependands: [],
    },
    {
      buttonItem: {
        action: setChangeGroupTestPhaseStatusModalOpen,
        label: TestingPhaseStatusesActions[group.status],
        value: 'Edit',
      },
      dependands: [KeywordGroupStatuses.TESTING_PHASE_PAUSED, KeywordGroupStatuses.TESTING_PHASE],
    },
    {
      buttonItem: {
        action: () => {
          setRemoveKeywordsModalOpen(true);
        },
        label: 'Remove keywords',
        value: 'remove keywords',
      },
      dependands: [],
    },
  ];

  const optionalMenuItems = useMemo(() => {
    const buttonActions = menuItemsWithDependencies.filter(
      (item) => item.dependands.includes(group.status) || !item.dependands.length
    );
    return buttonActions.map((item) => item.buttonItem);
  }, [group.status, keywords]);

  const filteredKeywords = useMemo(() => {
    return keywords.filter((keyword: IKeywordNewType) => keyword.keywordGroupId === group.id);
  }, [keywords, group]);

  const nomberOfLowVolumeKeywords = useMemo(() => {
    if (!filteredKeywords || !group) return 0;

    return filteredKeywords.filter((keyword) => keyword.sconClicksSum < LOW_VOLUME_LIMIT).length;
  }, [filteredKeywords, group]);

  return (
    <>
      <div className="group-panel panel-sections">
        <DetailPanel menuItems={optionalMenuItems} title={group?.name || ''}>
          <DetailSection
            withDivider={false}
            numberOfColumns={2}
            sectionCaption="General info"
            className="section">
            <TextWithTitle
              text={String(group?.numberOfKeywords) || ''}
              title="Number of keywords"
            />
            <TextWithTitle text={String(nomberOfLowVolumeKeywords)} title="Low volume" />
          </DetailSection>
          <DetailSection numberOfColumns={1}>
            <Typography variant="caption">Status</Typography>
            <div className="vertical-stepper">
              <KeywordGroupVerticalStepper group={group} />
            </div>
          </DetailSection>
          <DetailSection withDivider={true}>
            {dataTableLoading ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{ margin: 'auto' }} />
              </Box>
            ) : (
              <KeywordGroupKeywordDisplayWithModal
                keywords={keywords}
                groupId={group.id}
                subAccount={subAccount}
              />
            )}
          </DetailSection>
          <DetailSection
            numberOfColumns={2}
            sectionCaption="History"
            className="section"
            withDivider={false}>
            <TextWithTitle
              text={formatDate(new Date(`${subAccount?.created?.date ?? ''}z`), 'dd.MM.yyyy')}
              title="Creation Date"
            />
          </DetailSection>
        </DetailPanel>
      </div>
      <RemoveGroupModal
        isOpen={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
        group={group}
        deleteGroup={deleteGroup}
      />
      <ChangeLearningPhaseStatusModal
        isOpen={isChangeGroupTestPhaseStatusModalOpen}
        setOpen={setChangeGroupTestPhaseStatusModalOpen}
        group={group}
        pauseKeywordsGroupTestingPhase={pauseKeywordsGroupTestingPhase}
      />
      <RemoveKeywordsModal
        modalOpenState={isRemoveKeywordsModalOpen}
        setModalOpenState={setRemoveKeywordsModalOpen}
        keywords={filteredKeywords}
        keywordsLoading={dataTableLoading}
        removeKeywords={removeKeywords}
        subAccount={subAccount}
      />
    </>
  );
};
