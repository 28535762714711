import { Popper, PopperProps } from "@mui/material"
import React, { FC } from "react"
import MultiSelectScss from "./CustomSelect.scss"

// The popper uses an any that we cannot avoid in this line
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const popperOptions: any = {
  modifiers: [
    {
      name: "flip",
      options: {
        rootBoundary: "clippingParents",
        fallbackPlacements: ["bottom"]
      }
    },
    {
      name: "preventOverflow",
      options: {
        enabled: false,
        escapeWithReference: true,
        boundariesElement: "document"
      }
    }
  ]
}
const PopperComponent: FC<PopperProps> = (props) => (
  <Popper
    {...props}
    placement="bottom"
    className={[MultiSelectScss.popper, props.className].join(" ")}
    popperOptions={popperOptions}
  />
)

export default PopperComponent
