import { IItemData } from 'components/ui-kit/Dropdown';
import { AbTypes } from 'types/abtesting.types';

export const abGroupTypes: IItemData[] = [
  { value: AbTypes.NON_SEAMLESS, label: 'Non-Seamlsess Keywords' },
  { value: AbTypes.ONLY_SEAMLESS, label: 'Only Seamless Keywords' },
  { value: AbTypes.ALL_KEYWORDS, label: 'All Account Keywords' },
];

export const EMPTY_VALIDATION_ERROR: string = "Group name can't be empty!";

export const GET_AB_GROUP_INTERVAL: number = 7000;
