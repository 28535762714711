import React, { FC, useEffect, useState } from "react"
import FieldLabelTypography from "@src/components/FieldTypography"
import styles from "./ChipGroup.scss"
import { Avatar, Tooltip, Typography } from "@mui/material"
import Chip from "@src/components/Chip"

export interface ChipGroupPropsType {
  maxChipsToDisplay?: number
  label?: string
  fixedContainerWidth?: number // Container width in px
  chipDataWithImage: {
    imageUrl?: string
    textValue: string
  }[]
  multiline?: boolean
  isSmall?: boolean
  className?: string
  chipVariant?: "filled" | "outlined"
  truncateLongChips?: boolean
}

const ChipGroup: FC<ChipGroupPropsType> = ({
  label,
  chipDataWithImage,
  maxChipsToDisplay,
  fixedContainerWidth,
  multiline,
  isSmall,
  className,
  chipVariant,
  truncateLongChips = false
}) => {
  const [calculatedMaxChipsToDisplay, setCalculatedMaxChipsToDisplay] =
    useState(null)
  const [tooLongChipsLabels, setTooLongChipsLabels] = useState<string[]>([])

  useEffect(() => {
    setTooLongChipsLabels([])
    if (!fixedContainerWidth) {
      setCalculatedMaxChipsToDisplay(maxChipsToDisplay)
      return
    }
    if (!chipDataWithImage?.length) return

    const letterLengthInPx = 10
    const marginsAndPaddings = 14
    const roughChipLengths = chipDataWithImage.map((chip) => {
      if (
        truncateLongChips &&
        chip.textValue?.length * letterLengthInPx + marginsAndPaddings >=
          fixedContainerWidth
      ) {
        setTooLongChipsLabels((prev) => [...prev, chip.textValue])
      }
      return chip.textValue?.length * letterLengthInPx + marginsAndPaddings
    })

    let chipCounter = 0
    let wholeLength = 0
    let widthToFitIn = fixedContainerWidth
    roughChipLengths.forEach((chipLength, index) => {
      // If there are more chips, we need some space for the "+7" indicator
      if (roughChipLengths[index + 1]) {
        widthToFitIn = fixedContainerWidth - 20
      }
      if (wholeLength + chipLength > widthToFitIn) {
        return
      } else {
        wholeLength += chipLength
        chipCounter++
      }
    })
    setCalculatedMaxChipsToDisplay(chipCounter)
  }, [chipDataWithImage])

  const nonDisplayedChipLabels =
    calculatedMaxChipsToDisplay &&
    chipDataWithImage
      .map((chip) => chip.textValue)
      .slice(truncateLongChips ? 0 : calculatedMaxChipsToDisplay)
      .join(", ")

  const provideChipLabel = (label: string) => {
    if (!truncateLongChips) return label
    return tooLongChipsLabels.includes(label)
      ? `${label.slice(0, 12)}...`
      : label
  }

  return (
    <div
      className={`${styles.chipGroup} ${
        fixedContainerWidth ? styles.fixedWidthGroup : ""
      } ${className || ""}`}
    >
      {label ? <FieldLabelTypography>{label}</FieldLabelTypography> : null}
      <ul className={fixedContainerWidth ? styles.fixedWidthUl : ""}>
        {chipDataWithImage?.map((data, index) => {
          if (
            calculatedMaxChipsToDisplay &&
            calculatedMaxChipsToDisplay <= index
          ) {
            return
          }

          return (
            <li key={index}>
              <Chip
                variant={chipVariant ?? "filled"}
                isSmall={isSmall}
                avatar={
                  data.imageUrl ? (
                    <Avatar alt={data.textValue} src={data.imageUrl} />
                  ) : null
                }
                label={provideChipLabel(data.textValue)}
                multiline={multiline}
              />
            </li>
          )
        })}
        {calculatedMaxChipsToDisplay &&
          calculatedMaxChipsToDisplay < chipDataWithImage.length && (
            <Tooltip title={nonDisplayedChipLabels} arrow>
              <Typography sx={{ cursor: "pointer" }}>
                +{chipDataWithImage.length - calculatedMaxChipsToDisplay}
              </Typography>
            </Tooltip>
          )}
      </ul>
    </div>
  )
}

export default ChipGroup
