import {
  DataTable,
  ModalActions,
  Button,
  CheckboxKebabMenuItem,
} from '@dqna/seamless-suite-ui-kit';
import React, { useState } from 'react';
import { IKeywordNewType } from 'types/keywords.types';
import { KeywordsModalTableProps } from './types';
import { formatCurrency } from 'country-currency-map';
import { floatNumberFixer } from 'utils/floatNumberFixer';

export const KeywordsModalTable: React.FC<KeywordsModalTableProps> = (props) => {
  const {
    rowSelectionModel,
    setRowSelectionModel,
    keywords,
    subAccount,
    onCloseModal,
    onSwitchStep,
    keywordListLoadingState,
  } = props;
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const onSelectAll = () => {
    setRowSelectionModel(keywords.map((item) => item.keyword) ?? []);
  };

  const onSelectAllFromThisPage = () => {
    setRowSelectionModel(
      keywords
        .map((item) => item.keyword)
        .slice(currentPage * pageSize, pageSize * (currentPage + 1)) ?? []
    );
  };

  const itemsCountOnCurrentPage = () => {
    const remainingItems = keywords.length - pageSize * currentPage;

    return Math.min(remainingItems, pageSize);
  };

  const currencySymbol = formatCurrency('', subAccount?.currencyCode);

  const columns = [
    {
      field: 'keyword',
      headerName: 'Keyword',
      flex: 1,
    },
    {
      field: 'sconImprSum',
      headerName: 'Organic Impr.',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.sconImprSum)}</span>
      ),
    },
    {
      field: 'sconClicksSum',
      headerName: 'Organic clicks',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.sconClicksSum)}</span>
      ),
    },
    {
      field: 'sconCTR',
      headerName: 'Org. CTR',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.sconCTR)}</span>
      ),
    },
    {
      field: 'sconPosAvg',
      headerName: 'Org. Pos.',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.sconPosAvg)}</span>
      ),
    },
    {
      field: 'gadsTextImprSum',
      headerName: 'Paid Impr.',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.gadsTextImprSum)}</span>
      ),
    },
    {
      field: 'gadsTextCTR',
      headerName: 'Paid CTR',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.gadsTextCTR)}</span>
      ),
    },
    {
      field: 'gadsTextCPC',
      headerName: `Paid CPC (${currencySymbol})`,
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.gadsTextCPC)}</span>
      ),
    },
  ];

  const checkboxMenuItems = () => {
    const items = [
      {
        label: '',
        value: 'all',
        action: onSelectAll,
        component: <CheckboxKebabMenuItem label="All available" count={keywords.length} />,
      },
      {
        label: '',
        value: 'allFromThisPage',
        action: onSelectAllFromThisPage,
        component: (
          <CheckboxKebabMenuItem label="All from this page" count={itemsCountOnCurrentPage()} />
        ),
      },
    ];

    if (rowSelectionModel.length) {
      items.push({
        label: '',
        value: 'deselect',
        action: () => {
          setRowSelectionModel([]);
        },
        component: <CheckboxKebabMenuItem label="Deselect all" />,
      });
    }

    return items;
  };

  return (
    <>
      <DataTable
        getRowId={(row: IKeywordNewType) => row.keyword}
        checkboxHeaderDropdownMenuItems={checkboxMenuItems()}
        loading={keywordListLoadingState}
        onSelectionModelChange={setRowSelectionModel}
        selectionModel={rowSelectionModel}
        checkboxHeaderWithDropdown
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        page={currentPage}
        onPageChange={setCurrentPage}
        pagination
        checkboxSelection
        columns={columns}
        rows={keywords}
        withRowBorder
        rowsPerPageOptions={[10, 20, 30]}
        withFilterPanel
        hideFooterSelectedRowCount={false}
        className="create-group-modal__keywords-table"
      />
      <ModalActions>
        <Button variant="outlined" onClick={onCloseModal} size="small">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!rowSelectionModel.length}
          onClick={onSwitchStep}
          size="small">
          Next
        </Button>
      </ModalActions>
    </>
  );
};
