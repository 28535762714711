import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IRootState } from 'store/reducers';
import { TermsAndConditions } from 'components/pages/TermsAndConditions';

const mapStateToProps = (state: IRootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export type TermsAndConditionsContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const TermsAndConditionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditions);
