import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Modal, useFormContainer } from '@dqna/seamless-suite-ui-kit';
import { Typography } from '@mui/material';
import { AccountsNameStep } from './AccountsNameStep.component';
import { SearchConsoleStep } from './SearchConsoleStep.component';
import { AdveritySearchConsoleStep } from './AdveritySearchConsoleStep.component';
import { GoogleAdsStep } from './GoogleAdsStep.component';
import { AdverityGoogleAdsStep } from './AdverityGoogleAdsStep.component';
import { createUrl } from 'utils/createUrl';
import { getOrg } from 'utils/manageOrganisations';
import { NotificationType, notificationQueue } from 'services/NotificationQueue.service';
import { API_URL } from '../../../../config';
import { accountsRoutes } from 'constants/routes';
import { AccountNameFormData, LinkAccountsFormStep, LinkAccountsModalProps } from './types';
import { AccountTypes } from 'components/pages/Home/types';
import './LinkAccountsModal.scss';
import { DataSourcesStatus, DataSourcesTypes } from 'types/accounts.type';

const apiLinks: {
  [key in AccountTypes]?: (id: number) => string;
} = {
  [AccountTypes.GoogleSearch]: (id: number) => accountsRoutes.linkSearchConsole(id),
  [AccountTypes.AdWords]: (id: number) => accountsRoutes.linkAdwords(id),
  [AccountTypes.AdverityGoogleSearch]: (id: number) => accountsRoutes.linkAdveritySearchConsole(id),
  [AccountTypes.AdverityAdWords]: (id: number) => accountsRoutes.linkAdverityAdWords(id),
};

const FormStepsToDataSource = {
  [LinkAccountsFormStep.GOOGLE_SEARCH_CONSOLE_STEP]: DataSourcesTypes.GOOGLE_SEARCH_CONSOLE,
  [LinkAccountsFormStep.ADVERITY_SEARCH_CONSOLE_STEP]: DataSourcesTypes.ADVERITY_SEARCH_CONSOLE,
  [LinkAccountsFormStep.GOOGLE_ADS_STEP]: DataSourcesTypes.GOOGLE_ADWORDS,
  [LinkAccountsFormStep.ADVERITY_GOOGLE_ADS_STEP]: DataSourcesTypes.ADVERITY_ADWORDS,
};

export const LinkAccountsNewModal: React.FC<LinkAccountsModalProps> = (props) => {
  const {
    modalOpenState,
    setModalOpenState,
    createAccount,
    accessToken,
    authToken,
    newAccount,
    checkDataSourceIsLinked,
    deleteAccount,
    checkDataSourceIsVerifiedReset,
    accounts,
    editAccountName,
    isDataSourceVerified,
    verifyAdverityAdWords,
    verifyAdveritySearchConsole,
    linkDataSourcesStatuses,
    creationError,
    newAccountStateReset,
    getAccounts,
    orgName,
  } = props;
  const [currentStep, setCurrentStep] = useState(LinkAccountsFormStep.ACCOUNT_NAME_STEP);
  const [currentTimer, setCurrentTimer] = useState<ReturnType<typeof setTimeout>>();
  const formMethods = useFormContainer<AccountNameFormData>();
  const { pathname } = useLocation();
  const currentDataSourceStatus = useMemo(() => {
    if (currentStep !== LinkAccountsFormStep.ACCOUNT_NAME_STEP && linkDataSourcesStatuses) {
      return linkDataSourcesStatuses[FormStepsToDataSource[currentStep]];
    }
    return null;
  }, [currentStep, linkDataSourcesStatuses]);

  const accountLinkedNotVerified = useMemo(() => {
    return !isDataSourceVerified && currentDataSourceStatus?.status === DataSourcesStatus.LINKED;
  }, [isDataSourceVerified, currentDataSourceStatus]);

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (newAccount) {
        deleteAccount(newAccount.id, orgName);
      }
    },
    [newAccount]
  );

  useEffect(() => {
    const isLastStepSuccessfull =
      currentStep === LinkAccountsFormStep.ADVERITY_GOOGLE_ADS_STEP &&
      currentDataSourceStatus?.status !== DataSourcesStatus.LINKED;
    if (isLastStepSuccessfull) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    } else if (
      currentStep === LinkAccountsFormStep.GOOGLE_SEARCH_CONSOLE_STEP &&
      !currentDataSourceStatus
    ) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentStep, currentDataSourceStatus]);

  const onCloseModal = () => {
    const isLastStepsuccessfull =
      currentStep === LinkAccountsFormStep.ADVERITY_GOOGLE_ADS_STEP &&
      currentDataSourceStatus?.status !== DataSourcesStatus.LINKED;
    setModalOpenState(false);
    setCurrentStep(LinkAccountsFormStep.ACCOUNT_NAME_STEP);
    formMethods.resetField('accountName');
    if (newAccount?.id && !isLastStepsuccessfull) {
      deleteAccount(newAccount.id, orgName);
    }
    checkDataSourceIsVerifiedReset();
    newAccountStateReset();
    if (currentTimer) {
      clearTimeout(currentTimer);
    }
  };

  const onNextStep = () => {
    setCurrentStep(currentStep + 1);
    checkDataSourceIsVerifiedReset();
  };

  const onStepBack = () => {
    setCurrentStep(currentStep - 1);
    checkDataSourceIsVerifiedReset();
  };

  const onCreateAccount = () => {
    const accountName = formMethods.getValues('accountName');
    if (!newAccount?.id) {
      createAccount(accountName, orgName);
    } else {
      accountName !== newAccount.name && editAccountName(newAccount?.id, accountName, orgName);
    }
    onNextStep();
  };

  const onFinishSettingAccount = () => {
    getAccounts();
    setModalOpenState(false);
    setCurrentStep(LinkAccountsFormStep.ACCOUNT_NAME_STEP);
    formMethods.resetField('accountName');
    checkDataSourceIsVerifiedReset();
    newAccountStateReset();
    notificationQueue.showNotification(NotificationType.Toast, 'Account created succesfully!');
  };

  const handleCheckDataSourceIsLinked = (status: DataSourcesTypes, setTryNumber?: number) => {
    if (newAccount?.id) {
      checkDataSourceIsLinked(
        status,
        newAccount.id,
        setTryNumber ?? currentDataSourceStatus?.currentTry ?? 0,
        orgName
      );
    }
  };

  const handleLinkStep = useCallback(
    (source: AccountTypes) => {
      if (!apiLinks[source] || !newAccount) {
        return;
      }
      const route = createUrl(API_URL, apiLinks[source](newAccount.id), {
        access_token: authToken ?? '',
        user_access_token: accessToken ?? '',
        organization: orgName ?? getOrg(),
        path: pathname,
      });

      window.open(route, '_blank');
    },
    [newAccount, accessToken, authToken]
  );

  return (
    <Modal onClose={onCloseModal} open={modalOpenState}>
      <Typography variant="h6">Create account</Typography>
      {currentStep === LinkAccountsFormStep.ACCOUNT_NAME_STEP && (
        <AccountsNameStep
          onCloseModal={onCloseModal}
          onSubmit={onCreateAccount}
          formMethods={formMethods}
          accounts={accounts}
          creationError={creationError}
          newAccount={newAccount}
        />
      )}
      {currentStep === LinkAccountsFormStep.GOOGLE_SEARCH_CONSOLE_STEP && (
        <SearchConsoleStep
          onCloseModal={onCloseModal}
          onSubmit={onNextStep}
          onLinkAccount={handleLinkStep}
          linkDataSourceStatus={currentDataSourceStatus}
          checkDataSourceIsLinked={handleCheckDataSourceIsLinked}
          onStepBack={onStepBack}
          setCurrentTimer={setCurrentTimer}
        />
      )}
      {currentStep === LinkAccountsFormStep.ADVERITY_SEARCH_CONSOLE_STEP && (
        <AdveritySearchConsoleStep
          onCloseModal={onCloseModal}
          onSubmit={onNextStep}
          onLinkAccount={handleLinkStep}
          linkDataSourceStatus={currentDataSourceStatus}
          checkDataSourceIsLinked={handleCheckDataSourceIsLinked}
          onStepBack={onStepBack}
          accountLinkedNotVerified={accountLinkedNotVerified}
          verifyAdveritySearchConsole={() => {
            newAccount?.id && verifyAdveritySearchConsole(newAccount?.id, orgName);
          }}
          setCurrentTimer={setCurrentTimer}
        />
      )}
      {currentStep === LinkAccountsFormStep.GOOGLE_ADS_STEP && (
        <GoogleAdsStep
          onCloseModal={onCloseModal}
          onSubmit={onNextStep}
          onLinkAccount={handleLinkStep}
          linkDataSourceStatus={currentDataSourceStatus}
          checkDataSourceIsLinked={handleCheckDataSourceIsLinked}
          onStepBack={onStepBack}
          setCurrentTimer={setCurrentTimer}
        />
      )}
      {currentStep === LinkAccountsFormStep.ADVERITY_GOOGLE_ADS_STEP && (
        <AdverityGoogleAdsStep
          onCloseModal={onCloseModal}
          onSubmit={onFinishSettingAccount}
          onLinkAccount={handleLinkStep}
          linkDataSourceStatus={currentDataSourceStatus}
          checkDataSourceIsLinked={handleCheckDataSourceIsLinked}
          onStepBack={onStepBack}
          accountLinkedNotVerified={accountLinkedNotVerified}
          verifyAdverityAdWords={() => {
            newAccount?.id && verifyAdverityAdWords(newAccount?.id, orgName);
          }}
          setCurrentTimer={setCurrentTimer}
        />
      )}
    </Modal>
  );
};

LinkAccountsNewModal.displayName = 'LinkAccountsNewModal';
