export function convertToCSV(data: Array<Record<string, any>>): string {
  if (!data.length) return '';
  const csvRows: string[] = [];
  const headers: string[] = Object.keys(data[0]);
  csvRows.push(headers.join(','));

  for (const row of data) {
    const values = headers.map((header) => row[header]);
    csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
}

export function downloadCSV(data: Array<Record<string, any>>, filename: string): void {
  const csvContent: string = convertToCSV(data);
  const blob: Blob = new Blob([csvContent], { type: 'text/csv' });
  const link: HTMLAnchorElement = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}
