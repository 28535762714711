import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchAccounts,
  createAccount,
  verifyAdveritySearchConsole,
  verifyAdverityAdWords,
  checkDataSourceIsLinked,
  deleteAccount,
  checkDataSourceIsVerifiedReset,
  editAccountName,
  newAccountStateReset,
} from 'store/reducers/accounts.reducer';
import { IRootState } from 'store/reducers';

import { DataSourcesTypes } from 'types/accounts.type';

import { Home } from 'components/pages/Home';

const mapStateToProps = (state: IRootState) => {
  const { accounts, newAccount, linkDataSourcesStatuses, isDataSourceVerified, creationError } =
    state.accounts;
  const { accessToken, authToken } = state.user;

  const isRequiredAccountsLinked = accounts?.length
    ? // eslint-disable-next-line @typescript-eslint/naming-convention
      accounts.some(({ data_sources }) =>
        [DataSourcesTypes.GOOGLE_ADWORDS, DataSourcesTypes.GOOGLE_SEARCH_CONSOLE].every(
          (dataSourceType) => data_sources.map((item) => item.type).includes(dataSourceType)
        )
      )
    : false;

  return {
    isRequiredAccountsLinked,
    accounts,
    creationError,
    newAccount,
    accessToken,
    authToken,
    linkDataSourcesStatuses,
    isDataSourceVerified,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAccounts: () => dispatch(fetchAccounts()),
  createAccountModalActions: {
    checkDataSourceIsLinked: (dataSourceType: DataSourcesTypes, id: number, currentTry: number) =>
      dispatch(checkDataSourceIsLinked({ dataSourceType, id, currentTry })),
    createAccount: (name: string) => dispatch(createAccount({ name })),
    deleteAccount: (id: number) => dispatch(deleteAccount({ id })),
    checkDataSourceIsVerifiedReset: () => dispatch(checkDataSourceIsVerifiedReset()),
    editAccountName: (id: number, name: string) => dispatch(editAccountName({ id, name })),
    verifyAdveritySearchConsole: (accountId: number) =>
      dispatch(verifyAdveritySearchConsole({ accountId })),
    verifyAdverityAdWords: (accountId: number) => dispatch(verifyAdverityAdWords({ accountId })),
    getAccounts: () => dispatch(fetchAccounts()),
    newAccountStateReset: () => dispatch(newAccountStateReset()),
  },
});

export type HomeContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);
