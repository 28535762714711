import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Stack,
  Typography
} from "@mui/material"
import {
  GridFilterInputMultipleValue,
  GridFilterInputMultipleValueProps
} from "@mui/x-data-grid-pro"
import React, { useEffect, useState } from "react"
import style from "./FilterInputWithSeparator.scss"

import CustomSelect, { Option } from "../CustomSelect"

const SEPARATORS = [";", "|", "?", "\\", "%"]

const FilterInputWithSeparator = (props: GridFilterInputMultipleValueProps) => {
  const { item, applyValue } = props
  const [filterValue, setFilterValue] = useState<string[]>(item.value || [])
  const [separatorValue, setSeparatorValue] = useState<Option>({
    label: ";",
    value: ";"
  })

  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<string>
  ) => {
    if (reason === "createOption") {
      const newValuesBySeparator = details.option.split(separatorValue.value)

      setFilterValue((prevValues) => [...prevValues, ...newValuesBySeparator])
    } else {
      setFilterValue(value)
    }
  }

  useEffect(() => {
    applyValue({ ...item, value: filterValue })
  }, [filterValue])

  return (
    <Stack flexDirection="row" gap="1.6rem" alignItems="center">
      <Stack mt={"-1.9rem"}>
        <Typography variant="caption">Delimiter</Typography>
        <CustomSelect
          variant={"standard"}
          type={"singleSelect"}
          onChange={setSeparatorValue}
          options={SEPARATORS.map((item) => ({ label: item, value: item }))}
          className={style.filterSeparatorSelector}
          withNoneOption={false}
          defaultValue={separatorValue}
        />
      </Stack>
      <GridFilterInputMultipleValue
        {...props}
        onChange={handleChange}
        value={filterValue}
        className={style.filterInputWithSeparator}
      />
    </Stack>
  )
}

export default FilterInputWithSeparator
