import Dexie, { Collection } from 'dexie';

import { getOrg } from 'utils/manageOrganisations';

import { IDBName } from '../config';
import { IDBFilters } from './IDBFilters.service';
import { IKeyword } from 'types/keywords.types';

export interface IExtendedKeyword extends IKeyword {
  orgName: string;
  subAccountId: number;
  accountId: number;
  date: string;
}

type KwCollection =
  | Dexie.Collection<IExtendedKeyword, string>
  | Dexie.Table<IExtendedKeyword, string>;

export class IDBConnection extends Dexie {
  public keywords: Dexie.Table<IExtendedKeyword, string>;
  public idbFilters = new IDBFilters<IExtendedKeyword, KwCollection>();

  constructor() {
    super(IDBName);
  }

  public init = async () => {
    this.version(3).stores({
      keywords: '++, keyword, date, [orgName+date+accountId+subAccountId+groupId]',
    });
    this.on('versionchange', async () => {
      await this.removeDBData();
    });

    this.keywords = this.table('keywords');
  };

  public filterItems =
    (
      date: string,
      accountId: number,
      subAccountId: number,
      groupId: number,
      offset: number = 0,
      limit: number = 10000,
      sortBy?: keyof IKeyword,
      sortOrder?: boolean
    ) =>
    async (
      ...filters: Array<(collection: KwCollection) => Dexie.Collection<IExtendedKeyword, string>>
    ): Promise<{
      totalItems: number;
      data: IExtendedKeyword[];
      keywordIds: Array<string | number>;
    }> => {
      const preparedData = this.keywords
        .where('[orgName+date+accountId+subAccountId+groupId]')
        .equals([getOrg(), date, accountId, subAccountId, groupId]);

      const keywordValues = (await preparedData.toArray()).map((keyword) => keyword.keyword);

      const keywordIds = Array.from(new Set(keywordValues));

      const sortedData = async (
        data: Collection<IExtendedKeyword, string>,
        offset: number,
        limit: number,
        sortBy?: keyof IKeyword,
        sortOrder?: boolean
      ): Promise<IExtendedKeyword[]> => {
        if (!sortBy || !sortOrder) {
          return await data.toArray();
        }
        const sortedData = sortBy
          ? sortOrder
            ? await data.sortBy(sortBy)
            : await data.reverse().sortBy(sortBy)
          : await data.toArray();
        const dataSpliced = sortedData.splice(offset, limit);
        return dataSpliced;
      };

      if (!filters.length) {
        const totalItems = await preparedData.count();
        const data = await sortedData(preparedData, offset, limit, sortBy, sortOrder);

        return {
          keywordIds,
          totalItems,
          data,
        };
      }

      const [firstFilter, ...otherFilters] = filters;

      let filteredData: Dexie.Collection<IExtendedKeyword, string> = firstFilter(preparedData);

      if (otherFilters.length) {
        for (const filterFunction of otherFilters) {
          filteredData = filterFunction(filteredData);
        }
      }
      const totalItems = await filteredData.count();
      const data = await sortedData(filteredData, offset, limit, sortBy, sortOrder);

      return {
        keywordIds,
        totalItems,
        data,
      };
    };

  public keywordsLength = async () => {
    return await this.keywords.count();
  };

  public checkDataExistenceForDate = async (date: string) => {
    const numberOfEntities = await this.keywords.where('date').equals(date).count();
    return Boolean(numberOfEntities);
  };

  public checkDataExistence = async (
    date: string,
    accountId: number,
    subAccountId: number,
    groupId?: number
  ) => {
    const numberOfEntities = await this.keywords
      .where('[orgName+date+accountId+subAccountId+groupId]')
      .equals([getOrg(), date, accountId, subAccountId, groupId ?? 0])
      .count();
    return Boolean(numberOfEntities);
  };

  public removeDBData = async () => {
    await this.keywords.clear();
  };

  public updateEntities = async (
    compoundKey: [string, number, number, number],
    andClause: (kw: IExtendedKeyword) => boolean,
    changes: Record<string, any> | ((keyword: IExtendedKeyword) => IExtendedKeyword)
  ) => {
    return await this.keywords
      .where('[orgName+date+accountId+subAccountId+groupId]')
      .equals([getOrg(), ...compoundKey])
      .and(andClause)
      .modify(changes);
  };
}

export const idb = new IDBConnection();
