import Button from "@src/components/Button"
import React, { PropsWithChildren, useEffect, useState } from "react"
import {
  FormProvider,
  RegisterOptions,
  useForm,
  UseFormProps,
  UseFormReturn
} from "react-hook-form"

import styles from "./FormContainer.scss"

declare type FieldValues = Record<string, any>
declare type TContext = any
declare type TFieldValues = FieldValues & TContext

export interface FormContainerPropsType<FormDataType> {
  defaultValues?: FormDataType
  builtInButtons?: {
    alignment?: "left" | "center" | "right"
    submitCaption?: string
    cancelCaption?: string
    disableSubmitWhenFormInvalid?: boolean
    submitDisabled?: boolean
  }
  formMethods?: UseFormReturn<TFieldValues, TContext>
  onSubmit: (data: any) => void
  onCancel?: () => void
  avoidResetOnCancel?: boolean
  disableSubmitOnEnter?: boolean
}

export const useFormContainer = <FormDataType,>(
  props?: UseFormProps<FormDataType, TContext>
) => {
  return useForm<FormDataType>({
    ...props
  })
}

export const useDefaultValue = (formContext: UseFormReturn, name: string) => {
  const [defaultValue, setDefaultValue] = useState(null)

  useEffect(() => {
    setDefaultValue(formContext.getValues(name))
  }, [])

  return defaultValue
}

export const FormContainer = <FormDataType,>(
  props: PropsWithChildren<FormContainerPropsType<FormDataType>>
) => {
  const {
    builtInButtons,
    defaultValues,
    children,
    onSubmit,
    onCancel,
    avoidResetOnCancel,
    disableSubmitOnEnter
  } = props

  const formMethods = props.formMethods
    ? props.formMethods
    : useForm<FormDataType>({
        defaultValues: defaultValues as any
      })
  const disableSubmit = () => {
    return (
      (builtInButtons.disableSubmitWhenFormInvalid === true &&
        !formMethods.formState.isValid) ||
      builtInButtons.submitDisabled
    )
  }

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onSubmit)}
        onKeyDown={(event) => {
          if (event.key === "Enter" && disableSubmitOnEnter) {
            event.preventDefault()
          }
        }}
      >
        {children}
        {builtInButtons ? (
          <div
            className={`
            ${styles.formButtonContainer}
            ${
              builtInButtons.alignment === "left"
                ? styles.formButtonContainerLeft
                : builtInButtons.alignment === "right"
                ? styles.formButtonContainerRight
                : styles.formButtonContainerCenter
            }
            `}
          >
            <Button
              type={avoidResetOnCancel ? "button" : "reset"}
              color={"primary"}
              variant={"outlined"}
              onClick={() => (onCancel ? onCancel() : null)}
            >
              {builtInButtons.cancelCaption
                ? builtInButtons.cancelCaption
                : "Cancel"}
            </Button>
            <Button
              type={"submit"}
              color={"primary"}
              variant={"contained"}
              disabled={disableSubmit()}
            >
              {builtInButtons.submitCaption
                ? builtInButtons.submitCaption
                : "Save"}
            </Button>
          </div>
        ) : null}
      </form>
    </FormProvider>
  )
}

export interface FormElementPropsType<FormDataType> {
  name: keyof FormDataType | string
  placeholder?: string
  rules?: RegisterOptions
  title?: string
  titleExtra?: string
  withWarningIcon?: boolean
  withInfoIcon?: boolean
}

export default FormContainer
