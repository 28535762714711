import React, { FC, useEffect, useState } from 'react';
import { AddUserToOrganisationModalNewProps, AddUserToOrganisationFormDetails } from './types';
import {
  FormContainer,
  Modal,
  useFormContainer,
  ChipInputFormElement,
  DetailSection,
  ModalActions,
  Button,
} from '@dqna/seamless-suite-ui-kit';
import { Typography, Box } from '@mui/material';
import { emailRegex } from 'constants/regex';

export const AddUserToOrganisationModalNew: FC<AddUserToOrganisationModalNewProps> = ({
  modalOpenState,
  setModalOpenState,
  addUserToOrganisation,
  selectedOrganisation,
}) => {
  const [emailInput, setEmailInput] = useState<string>('');

  const formMethods = useFormContainer<AddUserToOrganisationFormDetails>({
    defaultValues: {},
  });

  const emailInputValue = formMethods.watch('email');

  useEffect(() => {
    setEmailInput(emailInputValue);
  }, [emailInputValue]);

  const validateEmail = (emails: Array<{ label: string; value: string }>) => {
    const invalidEmail = emails.find((email) => !email.label.match(emailRegex));

    if (invalidEmail) {
      return 'Please enter valid emails';
    }

    const organisationUsersEmails = selectedOrganisation.users?.map((user) => user.email);
    const repeatedEmails = emails
      .filter((email) => organisationUsersEmails?.includes(email.label))
      .map((email) => email.label);

    const repeatedEmailsShowcased = repeatedEmails.toString().replace(',', ', ');

    return repeatedEmails.length > 0
      ? `${repeatedEmailsShowcased} ${
          repeatedEmails.length === 1 ? 'is' : 'are'
        } already part of the organization. Please remove them from the list.`
      : true;
  };

  const handleClose = () => {
    formMethods.reset();
    setModalOpenState();
  };

  const handleSubmit = () => {
    addUserToOrganisation(selectedOrganisation.id, emailInput);
    handleClose();
  };

  return (
    <>
      <Modal onClose={handleClose} open={modalOpenState} className="invite-user-modal">
        <Typography variant="h6" marginBottom={2}>
          Invite User
        </Typography>
        <FormContainer<AddUserToOrganisationFormDetails>
          onSubmit={handleSubmit}
          formMethods={formMethods}>
          <Box sx={{ textAlign: 'center', width: '100%' }}>
            <DetailSection>
              <ChipInputFormElement
                name="email"
                rules={{
                  required: 'This field is mandatory',
                  validate: validateEmail,
                }}
                title="Enter emails to invite and press enter after each email"
              />
            </DetailSection>
            <ModalActions>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Send Invite
              </Button>
            </ModalActions>
          </Box>
        </FormContainer>
      </Modal>
    </>
  );
};
