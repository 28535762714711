import {
  combineEpics,
  ActionsObservable,
  StateObservable,
  ofType,
} from 'redux-observable';
import { switchMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { HTTPService } from 'services/HTTP.service';

import { Action } from 'store/types';

import {
  fetchNotifsSuccess,
  fetchNotifsFail,
  INotification,
  NotifsActions,
  NotificationStatus,
} from '../actions/notifications.actions';
import { notificationsRoutes } from 'constants/routes';

export const fetchNotificationsEpic = (
  actions$: ActionsObservable<Action<NotifsActions.FetchNotifs>>,
  state$: StateObservable<null>,
  { http }: { http: HTTPService }
) =>
  actions$.pipe(
    ofType(NotifsActions.FetchNotifs),
    switchMap(() => {
      return from(http.get<INotification[]>(notificationsRoutes.fetchNotifications())).pipe(
        map(res => fetchNotifsSuccess(res)),
        catchError(err => of(fetchNotifsFail(err)))
      );
    })
  );

export default combineEpics(fetchNotificationsEpic);
