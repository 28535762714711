import {
  TableHead as MUITableHead,
  TableCell as MUITableCell,
  TableRow as MUITableRow,
  Table as MUITable,
  TableCellProps as MUITableCellProps,
  TableRowProps,
  TableHeadProps
} from "@mui/material"
import React, { FC } from "react"
import style from "./Table.scss"

export const TableHead: FC<TableHeadProps> = ({ className, ...props }) => (
  <MUITableHead {...props} className={[style.tableHead, className].join(" ")} />
)

type TableCellProps = MUITableCellProps & { isHeader?: boolean }

export const TableCell: FC<TableCellProps> = ({
  className,
  valign,
  isHeader,
  ...props
}) => {
  const { children, ...remainingProps } = props
  return (
    <MUITableCell
      {...remainingProps}
      sx={{ verticalAlign: `${valign} !important` }}
      className={[style.tableCell, className].join(" ")}
    >
      {isHeader ? (
        children
      ) : (
        <div className={style.tableCellContainer}>{children}</div>
      )}
    </MUITableCell>
  )
}

export const Table: FC = (props) => <MUITable {...props} />

export const TableRow: FC<
  TableRowProps & { insideRef?: (node: HTMLElement) => void }
> = ({ className, insideRef, ...props }) => (
  <MUITableRow
    {...props}
    className={[style.tableRow, className].join(" ")}
    ref={insideRef}
  />
)
