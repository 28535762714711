import React, { PropsWithChildren } from "react"
import { FormHelperText, TextField, TextFieldProps } from "@mui/material"
import { useFormContext } from "react-hook-form"
import FormGroup from "@src/components/FormGroup"
import {
  FormElementPropsType,
  useDefaultValue
} from "@src/components/FormControls/FormContainer"
import { ErrorMessage } from "@hookform/error-message"

export const TextFieldFormElement = <T,>(
  props: PropsWithChildren<FormElementPropsType<T> & TextFieldProps>
) => {
  const { name, rules, title, titleExtra, withWarningIcon, ...restOfProps } =
    props
  const formContext = useFormContext()
  const {
    register,
    formState: { errors }
  } = formContext

  const defaultValue = useDefaultValue(formContext, name)

  return (
    <FormGroup
      title={title}
      extraText={titleExtra}
      withWarningIcon={withWarningIcon}
      error={!!errors[name]}
    >
      <TextField
        variant="outlined"
        {...register(name, rules)}
        defaultValue={defaultValue}
        {...restOfProps}
        error={!!errors[name]}
      />

      <ErrorMessage
        errors={errors}
        name={name.toString()}
        render={({ message }) => (
          <FormHelperText error={true}>{message}</FormHelperText>
        )}
      />
    </FormGroup>
  )
}

export default TextFieldFormElement
