import React, { useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  DetailPanel,
  DetailSection,
  TextWithTitle,
  DetailDisplayPanel,
  Button,
} from '@dqna/seamless-suite-ui-kit';
import { OpenInFull, CloseFullscreen } from '@mui/icons-material';
import {
  AGGREGATED_PERIOD_IN_DAYS,
  statusDescriptions,
  statusLabels,
  SuggestedKeywordsDetilsProps,
} from './types';
import { format } from 'date-fns';
import { IKeywordNewType, SuggestedKeywordStatus } from 'types/keywords.types';
import { formatCurrency } from 'country-currency-map';
import './SuggestedKeywords.scss';
import { CreateKeywordGroupModal } from '../CreateKeywordGroupModal';
import { downloadCSV } from 'utils/downloadCSV';
import { floatNumberFixer } from 'utils/floatNumberFixer';
import { SuggestedKeywordsExpandedTable } from './SuggestedKeywordsExpandedTable.component';

export const SuggestedKeywordsDetails: React.FC<SuggestedKeywordsDetilsProps> = (props) => {
  const {
    selectedStatus,
    keywordsOfCurrentStatus,
    lastIngested,
    subAccount,
    sendKeywordsToTest,
    keywordGroupCreationStatus,
    getSuggestedKeywords,
    keywordListLoadingState,
  } = props;
  const [displayedRows, setDisplayedRows] = useState<IKeywordNewType[]>([]);
  const [isTableExpanded, setTableExpanded] = useState(false);
  const [createGroupModalOpenState, setCreateGroupModalOpenState] = useState(false);

  useEffect(() => {
    const sortedByOrgClicks = keywordsOfCurrentStatus.sort((currentKeyword, nextKeyword) => {
      if (currentKeyword.sconClicksSum < nextKeyword.sconClicksSum) return 1;
      if (currentKeyword.sconClicksSum > nextKeyword.sconClicksSum) return -1;
      return 0;
    });
    setDisplayedRows(sortedByOrgClicks);
  }, [keywordsOfCurrentStatus]);

  const kebabMenuItems = () => {
    const menuItems = [
      {
        label: 'Export',
        value: 'export',
        action: () => {
          downloadCSV(keywordsOfCurrentStatus, 'seamless_search.csv');
        },
      },
    ];
    if (selectedStatus === SuggestedKeywordStatus.ACTIVE) {
      menuItems.unshift({
        label: 'Create keyword group',
        value: 'create',
        action: () => {
          setCreateGroupModalOpenState(true);
        },
      });
    }
    return menuItems;
  };

  const formatedIngestionDate = () => {
    if (!lastIngested) return '';

    return format(new Date(lastIngested), 'MMM dd., EEE H:mm');
  };

  const formatAggregatedPeriod = () => {
    if (!lastIngested) return '';

    const ingestionDate = new Date(lastIngested);
    const startDate = new Date(
      ingestionDate.setDate(ingestionDate.getDate() - AGGREGATED_PERIOD_IN_DAYS)
    );

    const aggregatedPeriod = [`${format(startDate, 'MMM dd.')} - `];

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const lastIngestionIsYesterday = yesterday.toDateString() === ingestionDate.toDateString();

    if (lastIngestionIsYesterday) {
      aggregatedPeriod.push('Yesterday');
    } else {
      aggregatedPeriod.push(format(new Date(lastIngested), 'MMM dd.'));
    }

    aggregatedPeriod.push(` (${AGGREGATED_PERIOD_IN_DAYS} days)`);

    return aggregatedPeriod.join('');
  };

  const currencySymbol = formatCurrency('', subAccount.currencyCode);

  const onCloseModal = () => {
    setTableExpanded(false);
  };

  return (
    <>
      <CreateKeywordGroupModal
        modalOpenState={createGroupModalOpenState}
        setModalOpenState={setCreateGroupModalOpenState}
        keywords={keywordsOfCurrentStatus}
        subAccount={subAccount}
        sendKeywordsToTest={sendKeywordsToTest}
        keywordGroupCreationStatus={keywordGroupCreationStatus}
        getSuggestedKeywords={getSuggestedKeywords}
        keywordListLoadingState={keywordListLoadingState}
      />
      <div className="suggested-keywords-details">
        <DetailPanel menuItems={kebabMenuItems()} title={statusLabels[selectedStatus]}>
          <DetailSection withDivider colWidth="95%" numberOfColumns={1}>
            <Typography variant="body2" lineHeight="2rem">
              {statusDescriptions[selectedStatus]}
            </Typography>
          </DetailSection>
          <DetailSection
            numberOfColumns={3}
            colWidth="30%"
            sectionCaption="General info"
            withDivider={false}>
            <TextWithTitle
              text={keywordsOfCurrentStatus.length.toString()}
              title="Number of keywords"
            />
          </DetailSection>
          <DetailSection numberOfColumns={3} colWidth="30%" withDivider>
            <TextWithTitle text="Daily, from 7:00" title="Ingestion schedule" />
            <TextWithTitle text={formatedIngestionDate()} title="Last ingestion" />
            <TextWithTitle text={formatAggregatedPeriod()} title="Aggregated period" />
          </DetailSection>
          <DetailSection withDivider={false}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" width="95%">
              <Typography variant="subtitle2">List of keywords</Typography>
              <Button
                variant="text"
                endIcon={isTableExpanded ? <CloseFullscreen /> : <OpenInFull />}
                onClick={() => {
                  setTableExpanded((expanded) => !expanded);
                }}>
                Expand view
              </Button>
            </Stack>
            <div className="suggested-keywords-details__expandable-table">
              <DetailDisplayPanel<IKeywordNewType>
                name="keywordsList"
                rows={displayedRows}
                columns={[
                  {
                    name: 'keyword',
                    header: 'Keyword',
                    render: (row) => <Typography variant="subtitle2">{row}</Typography>,
                  },
                  {
                    name: 'sconImprSum',
                    header: 'Org. Imp.',
                    render: (row) => (
                      <Typography variant="body2">{floatNumberFixer(row)}</Typography>
                    ),
                  },
                  {
                    name: 'sconClicksSum',
                    header: 'Org. Clicks',
                    render: (row) => (
                      <Typography variant="body2">{floatNumberFixer(row)}</Typography>
                    ),
                  },
                  {
                    name: 'sconPosAvg',
                    header: 'Org. Pos.',
                    render: (row) => (
                      <Typography variant="body2">{floatNumberFixer(row)}</Typography>
                    ),
                  },
                  {
                    name: 'gadsTextClicksSum',
                    header: 'Paid clicks',
                    render: (row) => (
                      <Typography variant="body2">{floatNumberFixer(row)}</Typography>
                    ),
                  },
                  {
                    name: 'gadsTextCPC',
                    header: `Paid CPC (${currencySymbol})`,
                    render: (row) => (
                      <Typography variant="body2">{floatNumberFixer(row)}</Typography>
                    ),
                  },
                ]}
              />
            </div>
          </DetailSection>
        </DetailPanel>
        <SuggestedKeywordsExpandedTable
          subAccount={subAccount}
          keywords={keywordsOfCurrentStatus}
          displayedRows={displayedRows}
          setDisplayedRows={setDisplayedRows}
          isTableExpanded={isTableExpanded}
          onCloseModal={onCloseModal}
        />
      </div>
    </>
  );
};
