import React from 'react';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import { CloseActionType } from '@dqna/seamless-suite-ui-kit/dist/components/UserInteractionDialog';
import { UserInteractionDialog } from '@dqna/seamless-suite-ui-kit';
import { DeleteUserModalProps } from './types';
import { UserStatus } from 'types/user.type';

export const DeleteUserModal: React.FC<DeleteUserModalProps> = (props) => {
  const { modalOpenState, setModalOpenState, selectedUser, deleteReinvitedUser, deleteUser } =
    props;
  const { orgId } = useParams<{ orgId: string }>();

  const onClose = (closeAction: CloseActionType) => {
    if (closeAction === 'confirm') {
      if (selectedUser.status === UserStatus.INVITED) {
        deleteReinvitedUser(orgId, selectedUser.user_id);
      } else {
        deleteUser(orgId, selectedUser.user_id);
      }
    }
  };

  return (
    <UserInteractionDialog
      uiDialogState={modalOpenState}
      setUIDialogState={setModalOpenState}
      title="Removing User's Access"
      message={undefined}
      buttonCaptions={{
        cancel: 'Cancel',
        confirm: 'Yes, remove access',
      }}
      onClose={onClose}>
      <Typography variant="body2" textAlign="center">
        You are about to remove access for
        <strong> {selectedUser.name ?? selectedUser.email}</strong>. This action cannot be undone.
        Are you sure about proceeding?
      </Typography>
    </UserInteractionDialog>
  );
};
