import { useState, useRef, useCallback } from 'react';

export const useFlag = (
  defaultBoolValue: boolean = false
): [boolean, (val?: any) => void] => {
  const [value, setter] = useState(defaultBoolValue);

  const toggle = useCallback((val?: any) => {
    setter(oldValue => (typeof val === 'boolean' ? val : !oldValue));
  }, []);

  return [value, toggle];
};
