import { DataTable, Modal } from '@dqna/seamless-suite-ui-kit';
import React, { useState } from 'react';
import { IKeywordNewType } from 'types/keywords.types';
import { SuggestedKeywordsTableProps } from './types';
import { formatCurrency } from 'country-currency-map';
import { floatNumberFixer } from 'utils/floatNumberFixer';
import { Typography } from '@mui/material';
import './SuggestedKeywordsExpandedTable.scss';

export const SuggestedKeywordsExpandedTable: React.FC<SuggestedKeywordsTableProps> = (props) => {
  const { subAccount, displayedRows, isTableExpanded, onCloseModal } = props;
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const currencySymbol = formatCurrency('', subAccount.currencyCode);

  const columns = [
    {
      field: 'keyword',
      headerName: 'Keyword',
      flex: 1,
    },
    {
      field: 'sconImprSum',
      headerName: 'Org. Imp.',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.sconImprSum)}</span>
      ),
    },
    {
      field: 'sconClicksSum',
      headerName: 'Org. Clicks',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.sconClicksSum)}</span>
      ),
    },
    {
      field: 'sconPosAvg',
      headerName: 'Org. Pos.',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.sconPosAvg)}</span>
      ),
    },
    {
      field: 'gadsTextClicksSum',
      headerName: 'Paid clicks',
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.gadsTextClicksSum)}</span>
      ),
    },
    {
      field: 'gadsTextCPC',
      headerName: `Paid CPC (${currencySymbol})`,
      flex: 1,
      renderCell: ({ row }: { row: IKeywordNewType }) => (
        <span>{floatNumberFixer(row.gadsTextCPC)}</span>
      ),
    },
  ];

  return (
    <>
      <Modal
        open={isTableExpanded}
        className="expanded-suggested-table"
        onClose={onCloseModal}
        fullScreen>
        <Typography variant="h6" className="title">
          View keyword(s)
        </Typography>
        <DataTable
          getRowId={(row: IKeywordNewType) => row.keyword}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          onPageChange={setCurrentPage}
          page={currentPage}
          columns={columns}
          rows={displayedRows}
          rowsPerPageOptions={[10, 20, 30]}
          withRowBorder
          pagination
          withFilterPanel
        />
      </Modal>
    </>
  );
};
