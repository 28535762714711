import { AccountTypes } from 'components/pages/Home/types';
import { UseFormReturn } from 'react-hook-form';
import { DataSourcesTypes, IAccount, LinkDataSourceStatus } from 'types/accounts.type';

export interface LinkAccountsModalProps {
  modalOpenState: boolean;
  accounts: IAccount[];
  setModalOpenState: (state: boolean) => void;
  readonly createAccount: (name: string, orgName?: string) => void;
  readonly editAccountName: (id: number, name: string, orgName?: string) => void;
  readonly accessToken: string;
  readonly authToken: string;
  readonly newAccount: { id: number; name: string } | null;
  readonly deleteAccount: (id: number, orgName?: string) => void;
  readonly getAccounts: () => void;
  readonly orgName?: string;
  checkDataSourceIsLinked: (
    dataSourceType: DataSourcesTypes,
    id: number,
    currentTry: number,
    orgName?: string
  ) => void;
  checkDataSourceIsVerifiedReset: () => void;
  newAccountStateReset: () => void;
  isDataSourceVerified: boolean;
  verifyAdveritySearchConsole: (accountId: number, orgName?: string) => void;
  verifyAdverityAdWords: (accountId: number, orgName?: string) => void;
  linkDataSourcesStatuses: Record<DataSourcesTypes, LinkDataSourceStatus | null>;
  creationError: Error | null;
}

export interface AccountNameStepProps {
  onCloseModal: () => void;
  onSubmit: () => void;
  formMethods: UseFormReturn<AccountNameFormData, any>;
  accounts: IAccount[];
  creationError: Error | null;
  readonly newAccount: { id: number; name: string } | null;
}

export interface ConsoleStepProps {
  onCloseModal: () => void;
  onSubmit: () => void;
  onLinkAccount: (source: AccountTypes) => void;
  linkDataSourceStatus: LinkDataSourceStatus | null;
  checkDataSourceIsLinked: (dataSourceType: DataSourcesTypes, setTryNumber?: number) => void;
  onStepBack: () => void;
  accountLinkedNotVerified?: boolean;
  verifyAdveritySearchConsole?: () => void;
  verifyAdverityAdWords?: () => void;
  setCurrentTimer: (timer: ReturnType<typeof setTimeout>) => void;
}

export enum LinkAccountsFormStep {
  ACCOUNT_NAME_STEP,
  GOOGLE_SEARCH_CONSOLE_STEP,
  ADVERITY_SEARCH_CONSOLE_STEP,
  GOOGLE_ADS_STEP,
  ADVERITY_GOOGLE_ADS_STEP,
}

export interface AccountNameFormData {
  accountName: string;
}

export interface LinkingDataSourceStatusProps {
  linkDataSourceStatus: LinkDataSourceStatus | null;
  tryAgainCallback: () => void;
  accessText: string;
  adverity: boolean;
}
