import React from 'react';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { LinkingDataSourceStatusProps } from './types';
import { DataSourcesStatus } from 'types/accounts.type';

export const LinkingDataSourceStatus: React.FC<LinkingDataSourceStatusProps> = (props) => {
  const { linkDataSourceStatus, tryAgainCallback, accessText, adverity } = props;

  return (
    <div className="link-data-source">
      {linkDataSourceStatus?.status === DataSourcesStatus.LINKED && (
        <Stack direction="column" className="link-data-source__linked">
          <Stack direction="row" gap="1rem">
            <Typography variant="body2">Account:</Typography>
            <Typography variant="body2">email</Typography>
          </Stack>
          <Stack direction="row" gap="2rem">
            <Typography variant="body2">Access:</Typography>
            <Typography variant="body2">{accessText}</Typography>
          </Stack>
          {adverity && (
            <Stack direction="row" gap="2.5rem">
              <Typography variant="body2">URL(s):</Typography>
              <Typography variant="body2">All site URLs</Typography>
            </Stack>
          )}
        </Stack>
      )}
      {linkDataSourceStatus?.status === DataSourcesStatus.LOADING && (
        <Stack direction="row" className="link-data-source__loading">
          <CircularProgress color="info" size="1.5rem" />
          <Typography variant="body2">Checking connection</Typography>
        </Stack>
      )}
      {linkDataSourceStatus?.status === DataSourcesStatus.ERROR && (
        <>
          <Stack direction="row" justifyContent="space-between" className="link-data-source__error">
            <Stack
              direction="column"
              className="link-data-source__error link-data-source__error--typography-main">
              <Typography variant="body2">Accounts does not match</Typography>
              <Typography variant="body2">
                We could not connect Adverity’s server due to a technical issue. Please try
                connecting again. If the issue keeps happening contact our support team.
              </Typography>
            </Stack>
            <Button
              variant="text"
              size="small"
              className="link-data-source__error--btn"
              onClick={tryAgainCallback}
              startIcon={<Refresh />}>
              Try again
            </Button>
          </Stack>
        </>
      )}
    </div>
  );
};

LinkingDataSourceStatus.displayName = 'LinkingDataSourceStatus';
