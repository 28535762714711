export const fetchAccountError = {
  name: 'FETCH_ERROR',
  message: "Couldn't fetch accounts",
};

export const fetchSubAccountError = {
  name: 'FETCH_ERROR',
  message: "Couldn't fetch sub-accounts",
};

export const updateSubAccountStatusError = {
  name: 'STATUS_UPDATE_ERROR',
  message: "Couldn't update sub-account status",
};

export const deleteSubAccountError = {
  name: 'ARCHIVE_ERROR',
  message: "Couldn't delete sub-account",
};

export const fetchAccountAdwordsError = {
  name: 'ADWORDS_FETCH_ERROR',
  message: "Couldn't fetch adwards",
};

export const sendKeywordsToTestError = {
  name: 'SEND_TO_TEST_FAILED',
  message: 'Some of the keywords were not sent to test',
};

export const deleteKeywordsError = {
  name: 'DELETE_FAILED',
  message: 'Keywords were not deleted',
};

export const createAccountError = {
  name: 'CREATE_ACCOUNT_ERROR',
  message: "There's already an account with this name, please select another name",
};

export const deleteGroupError = {
  name: 'DELETE_GROUP_ERROR',
  message: 'An error occured when deleting the group',
};

export const fetchSubAccountUrlsError = {
  name: 'FETCH_SUB_ACCOUNT_URLS_ERROR',
  message: 'An error occured when fetching subaccount urls',
};
