import { combineEpics } from 'redux-observable';

import userEpics from './user.epics';
import notificationsEpics from './notifications.epics';
import subAccountsEpics from './subAccounts.epics';
import keywordsEpics from './keywords.epics';
import accountsEpics from './accounts.epics';
import accountsVerificationEpics from './accountsVerification.epics';
import adwordsEpics from './adwords.epics';
import adminEpics from './admin.epics';
import abTestingEpics from './abTesting.epics';

export default combineEpics(
  notificationsEpics,
  subAccountsEpics,
  keywordsEpics,
  accountsEpics,
  adwordsEpics,
  userEpics,
  adminEpics,
  abTestingEpics,
  accountsVerificationEpics
);
