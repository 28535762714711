import { ReactChild, ReactFragment, ReactNode } from 'react';
import { Value, IDdActionItemProps } from '../ListItem';

export enum ListPositions {
  Top = 'top',
  Bottom = 'bottom',
  Right = 'right',
}

export type childrenType =
  | ReactChild
  | Array<ReactChild>
  | ReactFragment
  | null
  | undefined;

export type ListPosition =
  | ListPositions.Top
  | ListPositions.Bottom
  | ListPositions.Right;

export enum TitlePositions {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum DataType {
  String = 'string',
  Number = 'number',
  Options = 'options',
}

export interface IItemData {
  value: Value;
  label?: string;
  disabled?: boolean;
  dataType?: DataType;
  sublist?: IItemData[];
}

export interface ITextItemData extends IItemData {
  disabled?: boolean;
}

export interface ICountryCode extends IItemData {
  countryCode: string;
}

export interface IMultiselectItemData extends IItemData {
  selected?: boolean;
}

export type ChangeDropdownHandler = (
  selectedItem: IItemData,
  selectedParent?: IItemData
) => void;

export interface IDropdownProps {
  listClass?: string;
  listItemClass?: string;
  className?: string;
  options: Array<ITextItemData | IMultiselectItemData>;
  defaultOpened?: boolean;
  disabled?: boolean;
  name: string;
  titlePosition?: TitlePositions;
  onClearValue?: (name: string) => void;
  value: Value | Array<any> | null;
  error?: string | null;
  searchable?: boolean;
  onChange: ChangeDropdownHandler;
}

export interface IDropdownTextProps extends IDropdownProps {
  position?: ListPositions;
}

export enum DropdownActionTypes {
  Custom = 'custom',
  List = 'list',
}
export interface IDropdownActionProps {
  className?: string;
  options: IDdActionItemProps[];
  type?: DropdownActionTypes;
  buttonContent: ReactNode;
  name: string;
  search?: ReactNode;
}

export interface IDDListProps {
  position?: ListPosition;
  listClass?: string;
  opened: boolean;
  children: childrenType;
  stickyItem?: childrenType;
}

export interface IDDInputProps {
  children: childrenType;
  name: string;
  value: Value | Value[] | null;
  onClearValue?: (name: string) => void;
  iconSize?: string;
}
