import { Action } from 'store/types';

export function createAction<
  A extends string,
  P extends object = { [key: string]: any }
>(action: A, payload: P): Action<A, P>;

export function createAction<A extends string>(action: A): Action<A, {}>;

export function createAction<
  A extends string,
  P extends object = { [key: string]: any }
>(action: A, payload?: P): Action<A, P> {
  return payload
    ? {
        type: action,
        payload,
      }
    : {
        type: action,
      };
}
