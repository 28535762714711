import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IRootState } from 'store/reducers';

import { FilterState } from 'components/ui-kit/Filters';
import { IKeyword, RemoveKeywordsParams } from 'types/keywords.types';
import { KeywordManagementPage } from 'components/pages/KeywordManagement/KeywordManangement.component';
import { KeywordBodyType } from 'types/subAccounts.types';

import {
  activateAbTest,
  deleteAbGroup,
  getAbTest,
  previewAbTest,
  resetAbTestState,
} from 'store/reducers/abTesting.reducer';
import { AppPageTitle } from 'types/layout.types';
import { fetchAccounts } from 'store/reducers/accounts.reducer';
import {
  deleteGroup,
  updateSubAccountDetails,
  updateSubAccountFilters,
  userConfirmCloning,
} from 'store/reducers/subAccounts.reducer';
import { setPageTitle } from 'store/reducers/layout.reducer';
import {
  getKeywords,
  pauseGroupKeywordsTestingPhase,
  sendGroupKeywordsToTest,
} from 'store/reducers/keywords/keywordsManagment.reducer';
import {
  getKeywordsOfKeywordGroup,
  getKeywordsOfSubaccount,
  getKeywordsOfSubaccountInKeywordGroups,
  getSuggestedKeywords,
  removeKeywords,
} from 'store/reducers/keywords/keywordsDetailsPage.reducer';

const mapStateToProps = (state: IRootState) => {
  const {
    data: keywords,
    isLoading,
    page,
    limit,
    totalItems,
    keywordIds,
    filters,
    subAccountId,
    groupId,
    accountId,
  } = state.keywords.management;
  const { keywords: groupKeywords } = state.keywords.groupCreation;
  const {
    subAccounts,
    isLoading: subAccountsLoadingState,
    subAccountDetailsUpdateLoading,
  } = state.subAccounts;
  const suggestedKeywordsState = state.keywords.detailsPage.suggestedKeywords;
  const { newAbTestId, abGroupSubAccountId, abGroup, isAbGroupLoading } = state.abGroups;

  const selectionFilter = filters.find(({ metric }) => metric === 'id');

  return {
    keywords: selectionFilter ? groupKeywords : keywords,
    isLoading,
    kwPagination: { page, limit, totalItems },
    keywordIds,
    subAccounts,
    kwFilters: filters,
    subAccountId,
    groupId,
    accountId,
    keywordsOfSubaccountState: state.keywords.detailsPage.keywordsPerSubaccount,
    keywordsPerKeywordGroup: state.keywords.detailsPage.keywordsPerKeywordGroup,
    keywordsOfSubaccountInKeywordGroupsState:
      state.keywords.detailsPage.keywordsOfSubaccountInKeywordGroups,
    subAccountsLoadingState,
    suggestedKeywordsState,
    keywordGroupCreationStatus: state.keywords.detailsPage.keywordGroupCreationStatus,
    newAbTestId,
    abGroupSubAccountId,
    abGroup,
    isAbGroupLoading,
    subAccountDetailsUpdateLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getKeywords: (
    accountId: number,
    subAccountId: number,
    date: string,
    groupId?: number,
    pagination?: { page: number; limit: number },
    filters?: FilterState[],
    sorting?: { sortBy?: keyof IKeyword; sortOrder?: boolean }
  ) =>
    dispatch(getKeywords({ accountId, subAccountId, date, groupId, pagination, filters, sorting })),
  pauseKeywordsGroupTestingPhase: (accountId: number, subAccountId: number, groupId: number) =>
    dispatch(pauseGroupKeywordsTestingPhase({ accountId, subAccountId, groupId })),
  getAccounts: () => dispatch(fetchAccounts()),
  userConfirmCloning: (accountId: number, subAccountId: number, groupId: number) =>
    dispatch(userConfirmCloning({ accountId, subAccountId, groupId })),
  deleteGroup: (date: string, accountId: number, subAccountId: number, groupId: number) =>
    dispatch(deleteGroup({ date, accountId, subAccountId, groupId })),
  sendKeywordsToTest: (
    date: string,
    accountId: number,
    subAccountId: number,
    body: KeywordBodyType
  ) => dispatch(sendGroupKeywordsToTest({ date, accountId, subAccountId, body })),
  getKeywordsOfSubaccount: (subAccountId: number, date: string) =>
    dispatch(getKeywordsOfSubaccount({ subAccountId, date })),
  getKeywordsOfKeywordGroup: (subAccountId: number, keywordGroupId: number, date: string) =>
    dispatch(getKeywordsOfKeywordGroup({ subAccountId, groupId: keywordGroupId, date })),
  removeKeywords: (props: RemoveKeywordsParams) => dispatch(removeKeywords(props)),
  getSuggestedKeywords: ({ subAccountId }: { subAccountId: number }) =>
    dispatch(getSuggestedKeywords(subAccountId)),
  getKeywordsOfSubaccountInKeywordGroups: (subAccountId: number) =>
    dispatch(getKeywordsOfSubaccountInKeywordGroups(subAccountId)),
  changeSubAccountBgFilters: (
    accountId: number,
    subAccountId: number,
    bgCampaignsFilters: string[]
  ) => dispatch(updateSubAccountFilters({ accountId, subAccountId, bgCampaignsFilters })),
  previewAbTest: (
    subAccountId: number,
    testName: string,
    keywordsPercent: number,
    splitType: string
  ) =>
    dispatch(
      previewAbTest({ subAccountId, testName, keywordsPercent, splitKeywordsType: splitType })
    ),
  getAbTest: (accountId: number, subAccountId: number, abTestId: number) =>
    dispatch(getAbTest({ accountId, subAccountId, abTestId })),
  activateAbTest: (subAccountId: number, abTestId: number) =>
    dispatch(activateAbTest({ subAccountId, abTestId })),
  deleteAbGroup: (accountId: number, abGroupId: number, subAccountId: number) =>
    dispatch(deleteAbGroup({ accountId, abGroupId, subAccountId })),
  resetAbTestState: () => dispatch(resetAbTestState()),
  changeSubAccountDetails: (
    accountId: number,
    subAccountId: number,
    maximumCpcBidInTestingPhase: number,
    maximumCpcBidInLivePhase: number,
    maximumCpcBidChangeInLivePhase: number,
    maximumIncrementRoas: number
  ) =>
    dispatch(
      updateSubAccountDetails({
        accountId,
        subAccountId,
        maximumCpcBidInTestingPhase,
        maximumCpcBidInLivePhase,
        maximumCpcBidChangeInLivePhase,
        maximumIncrementRoas,
      })
    ),
  setPageTitle: (pageTitle: AppPageTitle) => dispatch(setPageTitle(pageTitle)),
});

export type KeywordManagementContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const KeywordManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KeywordManagementPage);
