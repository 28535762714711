import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { MasterDetailPanelContainer } from '@dqna/seamless-suite-ui-kit';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { KeywordGroupLeftSideContainer } from './KeywordGroupLeftSideContainer.component';
import { KeywordGroupRightSideContainer } from './KeywordGroupRightSideContainer.component';
import { KeywordGroupMainContainerProps } from './type';
import { generateRouteWithIds } from 'utils/generateRouteWithIds';
import { Routes } from 'types/app.types';
import { NoDataPlaceholder } from 'components/ui-kit/NoDataPlaceholder';

export const KeywordGroupMainContainer: FC<KeywordGroupMainContainerProps> = (props) => {
  const {
    subAccount,
    groupId,
    group,
    keywords,
    createKwGroupModal,
    deleteGroup,
    pauseKeywordsGroupTestingPhase,
    dataTableLoading,
    removeKeywords,
  } = props;
  const { replace } = useHistory();

  useEffect(() => {
    if (!subAccount) return;

    if (!subAccount?.keywordGroups?.length) {
      const routeWithIds = generateRouteWithIds({
        route: Routes.KEYWORD_MANAGEMENT_GROUPS_NO_ID,
        ids: {
          accountId: subAccount.account.id,
          subAccountId: subAccount.id,
        },
      });
      replace({
        pathname: routeWithIds,
      });
      return;
    }
    let selectedGroupId = groupId;

    if (!groupId || !subAccount.keywordGroups.find(({ id }) => id === Number(groupId))) {
      selectedGroupId = subAccount.keywordGroups[0].id;
    }
    const routeWithIds = generateRouteWithIds({
      route: Routes.KEYWORD_MANAGEMENT_GROUPS,
      ids: {
        accountId: subAccount?.account.id,
        subAccountId: subAccount.id,
        groupId: selectedGroupId,
      },
    });
    replace({
      pathname: routeWithIds,
    });
  }, [subAccount]);

  if (!group && subAccount) {
    return (
      <NoDataPlaceholder
        buttonCaption="Create keyword group"
        message=""
        onButtonClick={() => {
          createKwGroupModal(true);
        }}
        title="Time to create a Keyword group!"
      />
    );
  }

  return group ? (
    <MasterDetailPanelContainer leftPanelSize={25} className="keywords group-panel">
      <KeywordGroupLeftSideContainer subAccount={subAccount} groupId={groupId} />
      <KeywordGroupRightSideContainer
        subAccount={subAccount}
        group={group}
        keywords={keywords}
        pauseKeywordsGroupTestingPhase={pauseKeywordsGroupTestingPhase}
        deleteGroup={deleteGroup}
        dataTableLoading={dataTableLoading}
        removeKeywords={removeKeywords}
      />
    </MasterDetailPanelContainer>
  ) : (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress sx={{ margin: 'auto' }} />
    </Box>
  );
};
