import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import { useHistory } from 'react-router';
import { Routes } from 'types/app.types';
import AdminLogo from 'jsx:assets/icons/adminLogo.svg';
import SearchLogo from 'jsx:assets/icons/searchLogoSmall.svg';

export const AdminDropDown = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isFabOpen = anchorEl !== null;
  const fabAriaControls = isFabOpen ? 'basic-menu' : undefined;
  const fabExtended = isFabOpen ? true : undefined;
  const { replace } = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigateToAdmin = () => {
    replace({ pathname: Routes.ADMIN });
    handleClose();
  };

  const handleNavigateToSearch = () => {
    replace({ pathname: Routes.LINKED_ACCOUNTS });
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label="admin-button"
        aria-controls={fabAriaControls}
        aria-haspopup="true"
        aria-expanded={fabExtended}
        onClick={handleClick}
        size="large"
        sx={{ width: '5.2rem', '&:hover': { bgcolor: '#ffffff' } }}>
        <AppsIcon fontSize="inherit" />
      </IconButton>
      <Menu aria-label="admin-menu" anchorEl={anchorEl} open={isFabOpen} onClose={handleClose}>
        <MenuItem value="admin" onClick={handleNavigateToAdmin} aria-label="app-clickable">
          <ListItemIcon sx={{ minWidth: '2.5rem !important' }}>
            <AdminLogo />
          </ListItemIcon>
          <ListItemText>Seamless Admin</ListItemText>
        </MenuItem>
        <MenuItem value="search" onClick={handleNavigateToSearch} aria-label="app-clickable">
          <ListItemIcon sx={{ minWidth: '2.5rem !important' }}>
            <SearchLogo />
          </ListItemIcon>
          <ListItemText>Seamless Search</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
