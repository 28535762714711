import { IKeywordGroupInfo } from './keywords.types';

export interface AbTestingQueryParams {
  subAccountId: number;
  accountId: number;
}

export enum AbGroupStatuses {
  PENDING = 'PENDING',
  READY = 'READY',
  LIVE = 'LIVE',
  ERROR = 'ERROR',
}
export enum AbTypes {
  ALL_KEYWORDS = 'ALL_KEYWORDS',
  NON_SEAMLESS = 'NON_SEAMLESS',
  ONLY_SEAMLESS = 'ONLY_SEAMLESS',
}

export interface IAbTestJob {
  abGroupId: number;
}

export interface IABSplitSummary {
  keywordsSplit: IABSplitKeywordSummary;
  groupsMetrics: IABSplitGroupsMetrics[];
}

export interface IABSplitKeywordSummary {
  totalKeywordsCount: number;
  totalActiveKeywordsCount: number;
  nonSeamlessActiveKeywordsCount: number;
  seamlessKeywordsCount: number;
  abKeywordsCount: number;
}

export interface IABSplitGroupsMetrics {
  metric: string;
  aValue: number;
  bValue: number;
  aRatio: number;
  bRatio: number;
  abRatioDiff: number;
}

export interface IAbKeyword {
  abSplitLabel: string;
  name: string;
  cpc: number;
  ctr: number;
  roas: number;
  cost: number;
  impr: number;
  revenue: number;
  isSeamlessKeyword: boolean;
}

export interface IAbGroup {
  group: IAbGroupInfo;
  keywords: IAbKeyword[];
  summary: IABSplitSummary;
}

export interface IActivateAbGroup {
  abGroup: IAbGroupInfo;
  keywordGroup: IKeywordGroupInfo;
}

export interface IAbGroupInfo {
  id: number;
  name: string;
  status: AbGroupStatuses;
  splitKeywordsType: AbTypes;
  subAccountId: number;
}

export interface IAbGroupState {
  isAbGroupLoading: boolean;
  error: Error | null;
  newAbTestId: number | null;
  abGroup: IAbGroup | null;
  abGroupSubAccountId: number | null;
}
