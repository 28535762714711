import React, { FC, MouseEvent, FormEvent, useCallback, memo } from 'react';
import classNames from 'classnames';

import { ButtonTypes, IButtonProps, ActionTypes } from './types';

import './Button.scss';

export const Button: FC<IButtonProps> = memo(
  ({
    type = ButtonTypes.Primary,
    disabled = false,
    onClick,
    children,
    className,
    testId,
    name,
    link,
    actionType = ActionTypes.Button,
    icon,
  }) => {
    const btnClasses = classNames('button', className, {
      'button--link': !!link && type === ButtonTypes.Link,
      'button--disabled': disabled,
      'button--primary': type === ButtonTypes.Primary,
      'button--secondary': type === ButtonTypes.Secondary,
    });

    const handleClick = useCallback(
      (
        e:
          | MouseEvent<HTMLButtonElement | HTMLAnchorElement>
          | FormEvent<HTMLFormElement>
      ) => {
        onClick?.(e);
        e.currentTarget.blur();
      },
      [onClick]
    );

    return link ? (
      <a
        title={name}
        href={link}
        onClick={handleClick}
        className={btnClasses}
        data-testid={testId}>
        {icon}
        {children || link}
      </a>
    ) : (
        <button
          title={name}
          type={actionType}
          disabled={disabled}
          className={btnClasses}
          onClick={handleClick}
          aria-label={name}>
          {icon}
          {children}
        </button>
      );
  }
);

Button.displayName = 'Button';