import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { GridInputSelectionModel } from '@mui/x-data-grid-pro';
import { DataTable, Flag } from '@dqna/seamless-suite-ui-kit';
import { KeywordGroupLeftSideContainerProps } from './type';
import { generateRouteWithIds } from 'utils/generateRouteWithIds';
import { Routes } from 'types/app.types';
import { KeywordGroupStatuses } from 'types/keywords.types';

export const KeywordGroupLeftSideContainer: FC<KeywordGroupLeftSideContainerProps> = ({
  subAccount,
  groupId,
}) => {
  const { replace } = useHistory();
  const [selectionSet, setSelectionSet] = useState<GridInputSelectionModel>(groupId);
  return (
    <DataTable
      onRowClick={(params) => {
        const routeWithIds = generateRouteWithIds({
          route: Routes.KEYWORD_MANAGEMENT_GROUPS,
          ids: {
            accountId: subAccount.account.id,
            subAccountId: subAccount.id,
            groupId: params.row.id,
          },
        });
        replace(routeWithIds);
        setSelectionSet(params.row.id);
      }}
      columns={[
        {
          field: 'name',
          flex: 1,
          headerName: 'Name',
        },
        {
          field: 'status',
          headerName: 'STATUS',
          renderCell: (row) =>
            row.value === KeywordGroupStatuses.LIVE_PHASE ? (
              <Flag color="info" label="Live" />
            ) : (
              <Flag color="secondary" label="Learning" />
            ),
        },
      ]}
      rows={subAccount.keywordGroups ?? []}
      selectionModel={selectionSet}
      pagination
      pageSize={10}
      rowsPerPageOptions={[10, 20, 30]}
    />
  );
};
