import React, { FC, useState, ChangeEvent, useEffect, useCallback, useMemo } from 'react';

import { Dropdown, DropdownActionTypes } from 'components/ui-kit/Dropdown';
import { TextInput } from 'components/ui-kit/Input';
import { Button, ButtonTypes } from 'components/ui-kit/Button';

import NotificationsIcon from 'jsx:assets/icons/notifications.svg';
import NotificationsActiveIcon from 'jsx:assets/icons/notificationsActive.svg';
import Search from 'jsx:assets/icons/search.svg';

import { INotificationsProps } from './types';

import './Notifications.scss';
import { GET_NOTIFICATION_INTERVAL } from 'constants/filters';

export const Notifications: FC<INotificationsProps> = ({
  currentUser,
  notifications,
  getNotifications,
  intro,
}) => {
  const [searchNotifs, setSearchNotifs] = useState('');
  const [showIntro, setShowIntro] = useState(false);
  const [filteredNotifs, setFilteredNotifs] = useState<typeof notifications>(null);

  useEffect(() => {
    window.OneSignal.isPushNotificationsEnabled().then((isEnabled: boolean) => {
      if (!isEnabled) {
        setInterval(() => getNotifications(), GET_NOTIFICATION_INTERVAL);
      } else {
        window.OneSignal.on('notificationDisplay', function (event: any) {
          getNotifications();
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!notifications) {
      getNotifications();
    }

    if (localStorage.notification_seen_by !== currentUser?.email && !showIntro) {
      setShowIntro(true);
    }
  }, [currentUser, localStorage.notification_seen_by]);

  const handleSearchNotifs = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;
      setSearchNotifs(value);

      const valueRegex = new RegExp(value, 'ig');
      const filteredData = notifications?.filter((option) => valueRegex.test(option.subject));

      setFilteredNotifs(filteredData);
    },
    [notifications]
  );

  const handleNotificationIntro = useCallback(() => {
    if (currentUser) {
      localStorage.setItem('notification_seen_by', currentUser.email);
      setShowIntro(false);
    }
  }, [currentUser, setShowIntro]);

  const notificationsSearch = useMemo(
    () => (
      <TextInput
        key="search_input"
        name="Search"
        Icon={Search}
        value={searchNotifs}
        label=""
        onChange={handleSearchNotifs}
        autoFocus={true}
      />
    ),
    [searchNotifs, handleSearchNotifs]
  );

  const renderItems = useCallback(
    (items: typeof notifications) =>
      items ? (
        items.map((notif, key) => (
          <li key={key} className="notification" title={`Notification Type : ${notif.severity}`}>
            <div className="notification__left">
              <div
                className={`notification__status notification__status--${notif.severity.toLowerCase()}`}
              />
            </div>
            <div className="notification__right">
              <span className="notification__title">{notif.subject}</span>
              <span className="notification__description">{notif.body}</span>
              <span className="notification__date">{notif.date}</span>
            </div>
          </li>
        ))
      ) : (
        <li>No notifications</li>
      ),
    []
  );

  return (
    <Dropdown.Action
      options={[]}
      name="notifications"
      className="notifications"
      type={DropdownActionTypes.Custom}
      search={notificationsSearch}
      buttonContent={
        <>
          {notifications?.length ? (
            <NotificationsActiveIcon className="header__action-icon" />
          ) : (
            <NotificationsIcon className="header__action-icon" />
          )}
          Notifications
        </>
      }>
      {renderItems(filteredNotifs ?? notifications)}
      {showIntro && (
        <div className="notifications__intro">
          <p className="intro__title">Welcome to Seamless Search notifications</p>
          <ul className="intro__list">
            <li className="intro__list-item">
              <div className={`notification__status notification__status--success intro__status`} />
              <span className="intro__text">Success... Everything is good</span>
            </li>
            <li className="intro__list-item">
              <div className={`notification__status notification__status--pending intro__status`} />
              <span className="intro__text">Warning... Attention/action is needed</span>
            </li>
            <li className="intro__list-item">
              <div className={`notification__status notification__status--error intro__status`} />
              <span className="intro__text">Error... Something has gone wrong</span>
            </li>
            <li className="intro__list-item">
              <div className={`notification__status notification__status--regular intro__status`} />
              <span className="intro__text">Regular... Regular information</span>
            </li>
          </ul>
          <Button
            name="See notifications"
            className="intro__close-btn"
            onClick={handleNotificationIntro}
            type={ButtonTypes.Secondary}>
            OK
          </Button>
        </div>
      )}
    </Dropdown.Action>
  );
};

Notifications.displayName = 'Notifications';
