import React from "react"
import CustomSelect from "../CustomSelect"
import { PrefillSelectorProps } from "./PrefillSelectorProps"
import style from "./PrefillSelector.scss"

const PrefillSelector = (props: PrefillSelectorProps) => {
  const {
    creativeId,
    feedOptions,
    handleChange,
    defaultValue,
    disabled,
    isSearchInProgress,
    onKeyUp,
    onScrollEnd
  } = props

  return (
    <div
      className={`${style.prefillSelector} ${disabled ? style.disabled : ""}`}
    >
      <CustomSelect
        key={`prefill-${creativeId}`}
        type="searchSelect"
        variant="standard"
        options={feedOptions}
        onKeyUp={onKeyUp}
        onChange={handleChange}
        onScrollEnd={onScrollEnd}
        defaultValue={defaultValue}
        disabled={disabled}
        isSearchInProgress={isSearchInProgress}
        startAdornment={<span className={style.inputAdornment}>Prefill: </span>}
        withControlledDefaultValue={true}
        ariaLabel="prefill"
      />
    </div>
  )
}

export default PrefillSelector
