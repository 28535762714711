import { Avatar } from "@mui/material"
import React, { FC } from "react"
import QuestionMarkImage from "@src/static/svg/no-image.svg"
import MultiSelect from "./MultiSelect"
import SingleSelect from "./SingleSelect"
import styles from "./CustomSelect.scss"
import SearchSelect from "./SearchSelect"

export interface Option {
  label: string
  value: string
  image?: string | null
  disabled?: boolean
}

export interface SearchSelectType {
  variant: "standard"
  type: "searchSelect"
  defaultValue?: Option
  isMentionSearch?: boolean
  isSearchInProgress?: boolean
  onChange?: (value: Option) => void
  onKeyUp?: (value: string) => void
  onScrollEnd?: () => Promise<void>
  withControlledDefaultValue?: boolean
}

export interface SingleSelectType {
  variant: "standard"
  type: "singleSelect"
  withKeysAsLabels?: false
  defaultValue?: Option
  withCheckBox?: false
  limitTags?: -1
  onChange?: (value: Option) => void
}

export interface MultiSelectType {
  variant: "standard" | "creatable"
  type: "multiselect"
  withKeysAsLabels?: true
  defaultValue?: Option[]
  withCheckBox?: boolean
  limitTags?: number
  onChange?: (value: Option[]) => void
  includeSelectAllOption?: boolean
  selectAllOptionLabel?: string
}

export type defaultImageType = "Avatar" | "QuestionMark" | "None"

export interface CustomSelectType {
  options?: Option[]
  defaultImage?: defaultImageType
  placeholder?: string
  renderImage?: (option: Option, defaultImage: defaultImageType) => JSX.Element
  disabled?: boolean
  className?: string
  startAdornment?: JSX.Element
  searchInKeys?: boolean
  withNoneOption?: boolean
  ariaLabel?: string
  error?: boolean
  useNullForNone?: boolean
}

export type CustomSelectPropsType = (
  | SingleSelectType
  | MultiSelectType
  | SearchSelectType
) &
  CustomSelectType

const CustomSelect: FC<CustomSelectPropsType> = (props) => {
  const renderImage = (
    option: Option,
    defaultImage: defaultImageType
  ): JSX.Element => {
    if (option.image)
      return (
        <img
          className={styles.renderImage}
          loading="lazy"
          width="20"
          src={option.image}
          alt=""
        />
      )

    if (defaultImage === "QuestionMark")
      return (
        <Avatar
          className={styles.renderImage}
          alt={QuestionMarkImage}
          src={QuestionMarkImage}
        />
      )

    if (defaultImage === "Avatar")
      return <Avatar className={styles.renderImage} alt={""} src={""} />

    return null
  }

  if (props.type === "singleSelect") {
    return <SingleSelect renderImage={renderImage} {...props} />
  }

  if (props.type === "searchSelect") {
    return <SearchSelect renderImage={renderImage} {...props} />
  }

  return <MultiSelect renderImage={renderImage} {...props} />
}

CustomSelect.defaultProps = {
  defaultImage: "None",
  withKeysAsLabels: false,
  withCheckBox: false,
  limitTags: -1
}

export default CustomSelect
