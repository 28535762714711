import React, { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { Button } from '@dqna/seamless-suite-ui-kit';
import { OpenInNew } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Google from 'jsx:assets/google_search_console.svg';
import Adverity from 'jsx:assets/adverity.svg';
import { ConsoleStepProps } from './types';
import { AccountTypes } from 'components/pages/Home/types';
import { DataSourcesStatus, DataSourcesTypes } from 'types/accounts.type';
import { LINK_ACCOUNT_MODAL_NUMBER } from 'constants/subAccount';
import { MAX_NUMBER_OF_TRIES } from 'constants/account';
import { LinkingDataSourceStatus } from './LinkingDataSourceStatus.component';

export const AdveritySearchConsoleStep: React.FC<ConsoleStepProps> = (props) => {
  const {
    onCloseModal,
    onSubmit,
    onLinkAccount,
    linkDataSourceStatus,
    checkDataSourceIsLinked,
    onStepBack,
    accountLinkedNotVerified,
    verifyAdveritySearchConsole,
    setCurrentTimer,
  } = props;

  const handleOnbuttonClick = () => {
    onLinkAccount(AccountTypes.AdverityGoogleSearch);
    checkDataSourceIsLinked(DataSourcesTypes.ADVERITY_SEARCH_CONSOLE);
  };

  useEffect(() => {
    if (
      linkDataSourceStatus &&
      linkDataSourceStatus.currentTry <= MAX_NUMBER_OF_TRIES &&
      linkDataSourceStatus.status === DataSourcesStatus.LOADING
    ) {
      const adveritySearchConsoleTimer = setTimeout(() => {
        checkDataSourceIsLinked(DataSourcesTypes.ADVERITY_SEARCH_CONSOLE);
      }, 30000);
      setCurrentTimer(adveritySearchConsoleTimer);
    }
  }, [linkDataSourceStatus]);

  return (
    <div>
      <Typography variant="body2" marginBottom={3}>
        3/{LINK_ACCOUNT_MODAL_NUMBER} - Set up Adverity for Search Console
      </Typography>
      <div className="modal-details">
        <Stack direction="row" className="modal-details__title">
          <Adverity />
          <Google />
          <Typography variant="body2" marginLeft={1} fontWeight={600}>
            Adverity for Search Console
          </Typography>
        </Stack>
        <Stack direction="column" className="modal-details__body">
          <Typography variant="body2">
            Please connect to Adverity in order for Seamless to access Search Console data for
            reporting for your campaigns.
          </Typography>
          {!linkDataSourceStatus && (
            <Button
              variant="text"
              color="primary"
              size="small"
              className="modal-details__body--account-btn"
              onClick={handleOnbuttonClick}
              endIcon={<OpenInNew />}>
              Select Account
            </Button>
          )}
          <LinkingDataSourceStatus
            linkDataSourceStatus={linkDataSourceStatus}
            tryAgainCallback={() => {
              checkDataSourceIsLinked(DataSourcesTypes.ADVERITY_SEARCH_CONSOLE, 0);
            }}
            accessText="See,edit,create and delete your Google Search accounts and data"
            adverity={true}
          />
        </Stack>
      </div>

      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={onStepBack} startIcon={<ArrowBackIcon />}>
          Back
        </Button>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={linkDataSourceStatus?.status !== DataSourcesStatus.LINKED}
            onClick={accountLinkedNotVerified ? verifyAdveritySearchConsole : onSubmit}>
            {accountLinkedNotVerified ? 'Verify' : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

AdveritySearchConsoleStep.displayName = 'AdveritySearchConsoleStep';
