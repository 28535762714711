import { parse } from 'query-string';

export const serializeData = <T>(data?: T): string => {
  if (!data) return '';

  const jsonString = JSON.stringify(data);
  return `q=${btoa(jsonString)}`;
};

export const deserializeData = <T>(data: string): T | null => {
  if (!data) return null;
  const { q = '' } = parse(data);
  const jsonString = atob(q as string);
  return JSON.parse(jsonString);
};
