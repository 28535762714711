import React, { FC, useMemo } from 'react';
import { AbKeywordTypes, IAbGroupTablesProps } from './types';
import { AbGroupStatuses, IAbKeyword } from 'types/abtesting.types';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { CustomAlert } from '@dqna/seamless-suite-ui-kit';
import { Table, TableBody, TableHeader } from 'components/ui-kit/Table';
import { abKwColumns, abKwRows, abMetricsColumns, abMetricsRows } from './AbTestingPanelConstants';

export const AbGroupTables: FC<IAbGroupTablesProps> = (props) => {
  const { abGroup, isAbGroupLoading, abGroupSubAccountId, subAccount } = props;

  const { keywordsAGroup, keywordsBGroup, groupsMetrics } = useMemo(() => {
    const keywords = subAccount?.id === abGroupSubAccountId ? abGroup?.keywords : [];

    const keywordsAGroup: IAbKeyword[] = [];
    const keywordsBGroup: IAbKeyword[] = [];

    keywords?.forEach((keyword, index) => {
      keyword.abSplitLabel === AbKeywordTypes.AKwGroup
        ? keywordsAGroup.push(keyword)
        : keywordsBGroup.push(keyword);
    });
    const groupsMetrics =
      subAccount?.id === abGroupSubAccountId ? abGroup?.summary.groupsMetrics : [];

    return { keywordsAGroup, keywordsBGroup, groupsMetrics };
  }, [abGroup, subAccount]);

  if (abGroup?.group.status === AbGroupStatuses.ERROR) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          margin: '1.3rem',
        }}>
        <CustomAlert severity="error">
          There was error while creating your group, please reset!
        </CustomAlert>
      </Box>
    );
  }
  return (
    <>
      <Backdrop open={isAbGroupLoading} sx={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      <div className="abtesting__main ab-tables">
        <div className="ab-tables ab-groups">
          <div className="ab-groups__title">
            <h1 className="ab-groups__title--main-text">{keywordsAGroup.length}</h1>
          </div>
          <div className="ab-groups__subtitle">
            <h2>Search KW&apos;s (A Group)</h2>
          </div>
          <div className="ab-groups__keywords-a">
            <Table dataSource={keywordsAGroup}>
              <TableHeader cells={abKwColumns} />
              <TableBody cells={abKwRows} />
            </Table>
          </div>
        </div>
        <div className="ab-tables ab-groups">
          <div className="ab-groups__title">
            <h1 className="ab-groups__title--main-text">{keywordsBGroup.length}</h1>
          </div>
          <div className="ab-groups__subtitle">
            <h2 className="ab-groups__subtitle--secondary-text">Search KW&apos;s (B Group)</h2>
          </div>
          <div className="ab-groups__keywords-b">
            <Table dataSource={keywordsBGroup}>
              <TableHeader cells={abKwColumns} />
              <TableBody cells={abKwRows} />
            </Table>
          </div>
        </div>
      </div>
      <div className="abtesting__main ab-tables ab-statistics">
        <div className="ab-groups__subtitle">
          <h2 className="ab-groups__subtitle--secondary-text">Ab Group Metrics</h2>
        </div>
        <div className="ab-statistics__keywords-stats">
          <Table dataSource={groupsMetrics}>
            <TableHeader cells={abMetricsColumns} />
            <TableBody cells={abMetricsRows} />
          </Table>
        </div>
      </div>
    </>
  );
};

AbGroupTables.displayName = 'AbGroupTables';
