import React, { FC } from "react"
import FieldLabelTypography from "@src/components/FieldTypography"
import Counter, { CounterPropsType } from "../Counter"
import styles from "./CounterGroup.scss"

export interface CounterGroupPropsType {
  label?: string
  counterData: CounterPropsType[]
}

const CounterGroup: FC<CounterGroupPropsType> = ({ label, counterData }) => {
  return (
    <div className={styles.counterGroup}>
      {label ? <FieldLabelTypography>{label}</FieldLabelTypography> : null}
      <ul>
        {counterData?.map((oneCounter, index) => (
          <li key={index}>
            <Counter {...oneCounter} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CounterGroup
