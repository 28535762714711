import {
  Autocomplete,
  Typography,
  Box,
  TextField,
  PaperProps,
  InputAdornment
} from "@mui/material"
import React, {
  FC,
  ReactNode,
  useMemo,
  useState,
  useEffect,
  useCallback
} from "react"
import { CustomSelectType, SearchSelectType, Option } from ".."
import styles from "./SearchSelect.scss"
import PopperComponent from "../PopperComponent"
import PaperComponent from "../PaperComponent"

type SearchSelectPropsType = SearchSelectType & CustomSelectType

const SearchSelect: FC<SearchSelectPropsType> = ({
  options,
  placeholder,
  defaultValue,
  disabled,
  onChange,
  onKeyUp,
  onScrollEnd,
  startAdornment,
  renderImage,
  isMentionSearch,
  isSearchInProgress,
  withControlledDefaultValue,
  ariaLabel,
  error
}) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleUserKeyPress = useCallback(
    (event) => {
      // If user hits Esc, close the dropdown
      if (isOpen && event.keyCode === 27) {
        setOpen(false)
      }
    },
    [isOpen]
  )

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  useEffect(() => {
    if (isMentionSearch) {
      setOpen(true)
    }
  }, [isMentionSearch])

  const StyledPopper = React.useCallback((props) => {
    const anchorEl = document.getElementsByClassName("dropdownAnchor")[0]
    return (
      <PopperComponent
        {...props}
        anchorEl={anchorEl}
        className={styles.mentionPopper}
      />
    )
  }, [])

  const onRenderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Option
  ): ReactNode => {
    return (
      <li {...props} key={`${option.value}${option.label}`}>
        <Box
          className={`${styles.optionText} ${styles.optionTextOneLiner} ${
            isMentionSearch ? styles.mentionDropdownItem : ""
          }`}
        >
          {isMentionSearch ? (
            <div className={styles.mentionDropdownItemInner}>
              {renderImage(option, "QuestionMark")}
              <div className={styles.textContent}>
                <Typography component="span" variant="body1">
                  {option.label}
                </Typography>
                <Typography component="span" variant="body1">
                  {option.value}
                </Typography>
              </div>
            </div>
          ) : (
            <Typography component="span" variant="body1">
              {option.label}
            </Typography>
          )}
        </Box>
      </li>
    )
  }

  const handleOnChange = (
    _event: React.SyntheticEvent,
    value: string | Option
  ) => {
    setOpen(false)
    onChange?.(value as Option)
  }

  const renderMemoizedPaper = useMemo(() => {
    // eslint-disable-next-line react/display-name
    return (props: PaperProps) => (
      <PaperComponent parentRef={containerRef} {...props} />
    )
  }, [containerRef])

  const onScroll = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget
    // Make copy of scroll top instead of assigning reference
    const heightFromTop = parseInt(`${listboxNode.scrollTop}`)
    if (
      onScrollEnd &&
      listboxNode.scrollTop + listboxNode.clientHeight ===
        listboxNode.scrollHeight
    ) {
      setLoading(true)
      onScrollEnd()
        .then(() => {
          listboxNode.scrollTo({
            top: heightFromTop
          })
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
  }

  if (!options) return null

  return (
    <div ref={setContainerRef}>
      <Autocomplete
        className={`${styles.autoComplete} ${styles.searchSelect} ${
          isMentionSearch ? styles.isMentionSearch : ""
        }`}
        forcePopupIcon={true}
        isOptionEqualToValue={(option, value) => {
          return option?.value === value?.value
        }}
        defaultValue={defaultValue}
        value={withControlledDefaultValue ? defaultValue : undefined}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            error={error}
            onChange={(event) => {
              if (!isOpen) setOpen(true)
              onKeyUp ? onKeyUp((event.currentTarget as any).value) : null
            }}
            InputProps={{
              ...params.InputProps,
              "aria-label": `search-select-${ariaLabel}`,
              startAdornment: startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : null,
              onClickCapture: () => {
                if (!disabled) setOpen(true)
              }
            }}
          />
        )}
        loading={isSearchInProgress || loading}
        renderOption={onRenderOption}
        disabled={disabled}
        open={isOpen}
        onChange={handleOnChange}
        PopperComponent={isMentionSearch ? StyledPopper : PopperComponent}
        PaperComponent={renderMemoizedPaper}
        ListboxProps={{ onScroll }}
      />
    </div>
  )
}

export default SearchSelect
