enum CookieName {
  Org = 'organization',
}

export const setOrg = (name: string) => {
  localStorage.setItem(CookieName.Org, name);
};

export const getOrg = () => {
  return localStorage.getItem(CookieName.Org) || '';
};

export const clearOrg = () => {
  localStorage.removeItem(CookieName.Org);
};
