import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { map, catchError, switchMap, withLatestFrom, filter } from 'rxjs/operators';
import { from, of, concat } from 'rxjs';
import { History } from 'history';
import { Action } from '@reduxjs/toolkit';
import { IRootState } from 'store/reducers';
import { IOrganisation } from 'types/admin.types';

import {
  createOrganisation,
  createOrganisationSuccess,
  createOrganisationFail,
  getOrganisationDetails,
  getOrganisationDetailsSuccess,
  getOrganisationDetailsFail,
  addUserToOrganisation,
  addUserToOrganisationSuccess,
  addUserToOrganisationFail,
  deleteUserFromOrganisation,
  deleteUserFromOrganisationFail,
  deleteUserFromOrganisationSuccess,
  getOrganisations,
  getOrganisationsSuccess,
  getOrganisationsFail,
  deleteOrganisation,
  deleteOrganisationFail,
  deleteOrganisationSuccess,
  reinviteUserToOrganisation,
  reinviteUserToOrganisationSuccess,
  reinviteUserToOrganisationFail,
  deleteReinvitedUserFromOrganisation,
  deleteReinvitedUserFromOrganisationSuccess,
  deleteReinvitedUserFromOrganisationFail,
} from 'store/reducers/admin.reducer';
import { HTTPService } from 'services/HTTP.service';
import { NotificationQueue, NotificationType } from 'services/NotificationQueue.service';
import { adminRoutes } from 'constants/routes';
import {
  orgCreationError,
  existingOrgError,
  userCreationError,
  existingUserError,
  userReinviteError,
  fetchOrganisationError,
} from 'constants/errors/admin.error';
import { CONFLICT_ERROR_CODE } from 'constants/filters';
import { IUserRoleRes, IUser } from 'types/user.type';

export const getOrganisationsEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http }: { http: HTTPService }
) =>
  actions$.pipe(
    filter(getOrganisations.match),
    switchMap(() => {
      return from(http.get<IOrganisation[]>(adminRoutes.manageOrganisation())).pipe(
        map((data) => getOrganisationsSuccess(data)),
        catchError((_) => of(getOrganisationsFail(fetchOrganisationError)))
      );
    })
  );

export const createOrganisationEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(createOrganisation.match),
    switchMap(({ payload: { orgEmail, orgName } }) => {
      return from(
        http.post<
          | {
              code: number;
              org: IOrganisation;
              error: Error;
            }
          | IOrganisation
        >(adminRoutes.manageOrganisation(), {
          email: orgEmail,
          name: orgName,
        })
      ).pipe(
        switchMap((org) => {
          if ('code' in org && org.code === CONFLICT_ERROR_CODE) {
            const response = org as {
              code: number;
              org: IOrganisation;
              error: Error;
            };
            notificationQueue.showNotification(NotificationType.Toast, existingOrgError.message);
            return of(createOrganisationFail(response.error));
          } else {
            org = org as IOrganisation;
            notificationQueue.showNotification(
              NotificationType.Toast,
              `Organisation "${orgName}" is successfully created! Please check your email`
            );
            return of(createOrganisationSuccess(org));
          }
        }),
        catchError((err) => {
          notificationQueue.showNotification(NotificationType.Toast, orgCreationError.message);
          return of(createOrganisationFail(err));
        })
      );
    })
  );

export const getOrganisationEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<IRootState>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(getOrganisationDetails.match),
    withLatestFrom(state$),
    switchMap(
      ([
        { payload },
        {
          admin: { organisations },
        },
      ]) => {
        const currentOrg = organisations?.find(({ id }) => id === payload.orgId);

        if (currentOrg) {
          return of(getOrganisationDetailsSuccess(currentOrg));
        }

        return from(http.get<IOrganisation>(adminRoutes.getOrganisation(payload.orgId))).pipe(
          switchMap((org) => of(getOrganisationDetailsSuccess(org))),
          catchError((err) => of(getOrganisationDetailsFail(err)))
        );
      }
    )
  );

export const deleteOrganisationEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<null>,
  {
    http,
    history,
    notificationQueue,
  }: {
    http: HTTPService;
    notificationQueue: NotificationQueue;
    history: History;
  }
) =>
  actions$.pipe(
    filter(deleteOrganisation.match),
    switchMap(({ payload }) => {
      const { orgId } = payload;

      return from(http.delete(adminRoutes.getOrganisation(orgId))).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Organisation is successfully deleted!'
          );
          history.push('/admin/organisations');
          return of(deleteOrganisationSuccess({ orgId }));
        }),
        catchError((err) => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'Error when deleting organisation!'
          );
          return of(deleteOrganisationFail(err));
        })
      );
    })
  );

export const addUserEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<IRootState>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(addUserToOrganisation.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { orgId, email } = action.payload;
      const observables = email.map((emailObj: { label: string; value: string }) =>
        from(
          http.post<
            | {
                code: number;
                data: Array<IUser<IUserRoleRes>>;
                error: Error | null;
              }
            | Array<IUser<IUserRoleRes>>
          >(adminRoutes.manageUser(orgId), { email: emailObj.label })
        ).pipe(
          switchMap((data) => {
            if ('code' in data && data.code === CONFLICT_ERROR_CODE) {
              const response = data as {
                code: number;
                data: Array<IUser<IUserRoleRes>>;
                error: Error;
              };
              notificationQueue.showNotification(NotificationType.Toast, existingUserError.message);
              return of(addUserToOrganisationFail(response.error));
            } else {
              data = data as Array<IUser<IUserRoleRes>>;
              const currentOrganisationUsers = state.admin.currentOrganisation?.users ?? [];
              const newUserList = [...data, ...currentOrganisationUsers];
              notificationQueue.showNotification(
                NotificationType.Toast,
                `User ${emailObj.label} is added to organisation! User is sent an invitation email.`
              );
              return of(addUserToOrganisationSuccess({ newUserList }));
            }
          }),
          catchError((err) => {
            notificationQueue.showNotification(NotificationType.Toast, userCreationError.message);
            return of(addUserToOrganisationFail(err));
          })
        )
      );
      return concat(...observables);
    })
  );

export const reinviteUserEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<IRootState>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(reinviteUserToOrganisation.match),
    switchMap(({ payload }) => {
      const { orgId, userEmail } = payload;

      return from(
        http.post<
          | {
              code: number;
              data: Array<IUser<IUserRoleRes>>;
              error: Error | null;
            }
          | Array<IUser<IUserRoleRes>>
        >(adminRoutes.manageUser(orgId), { email: userEmail })
      ).pipe(
        switchMap((data) => {
          if ('code' in data && data?.code === CONFLICT_ERROR_CODE) {
            const response = data as {
              code: number;
              data: Array<IUser<IUserRoleRes>>;
              error: Error;
            };
            notificationQueue.showNotification(NotificationType.Toast, userReinviteError.message, {
              className: 'home__toast home__toast--error',
            });
            return of(reinviteUserToOrganisationFail(response.error));
          } else {
            notificationQueue.showNotification(
              NotificationType.Toast,
              `User ${userEmail} has been resent an invitation.`
            );

            return of(reinviteUserToOrganisationSuccess({ userEmail }));
          }
        }),
        catchError((err): any => {
          notificationQueue.showNotification(NotificationType.Toast, userReinviteError.message, {
            className: 'home__toast home__toast--error',
          });
          return of(reinviteUserToOrganisationFail(err));
        })
      );
    })
  );

export const deleteReinvitedUserEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<IRootState>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(deleteReinvitedUserFromOrganisation.match),
    switchMap(({ payload }) => {
      const { userId, orgId } = payload;

      return from(
        http.delete(adminRoutes.manageReinvitedUser(orgId), undefined, {
          user_id: userId,
        })
      ).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'User is successfully deleted!'
          );
          return of(deleteReinvitedUserFromOrganisationSuccess({ orgId, userId }));
        }),
        catchError((err) => {
          notificationQueue.showNotification(NotificationType.Toast, 'Error when deleting user!');
          return of(deleteReinvitedUserFromOrganisationFail(err));
        })
      );
    })
  );

export const deleteUserEpic = (
  actions$: ActionsObservable<Action>,
  state$: StateObservable<IRootState>,
  { http, notificationQueue }: { http: HTTPService; notificationQueue: NotificationQueue }
) =>
  actions$.pipe(
    filter(deleteUserFromOrganisation.match),
    switchMap(({ payload }) => {
      const { userId, orgId } = payload;

      return from(
        http.delete(adminRoutes.manageUser(orgId), undefined, {
          user_id: userId,
        })
      ).pipe(
        switchMap(() => {
          notificationQueue.showNotification(
            NotificationType.Toast,
            'User is successfully deleted!'
          );
          return of(deleteUserFromOrganisationSuccess({ orgId, userId }));
        }),
        catchError((err) => {
          notificationQueue.showNotification(NotificationType.Toast, 'Error when deleting user!');
          return of(deleteUserFromOrganisationFail(err));
        })
      );
    })
  );

export default combineEpics(
  createOrganisationEpic,
  getOrganisationEpic,
  addUserEpic,
  deleteUserEpic,
  getOrganisationsEpic,
  deleteOrganisationEpic,
  reinviteUserEpic,
  deleteReinvitedUserEpic
);
