import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react"
import { Tabs, TabsList, TabPanel, Tab } from "@mui/base"
import { ITabSelectorProps, ITabSelectorState } from "./ITabSelectorProps"
import styles from "./TabSelector.scss"
import { Button } from "@mui/material"
import { Close } from "@mui/icons-material"

const TabSelector: FC<ITabSelectorProps> = (props) => {
  const {
    tabItems,
    searchInput,
    defaultValue,
    localInputDisabledOnIndex,
    buttons,
    className,
    defaultSearchOpen,
    controlledValue
  } = props

  if (!tabItems) return null

  const [tabSelectorState, setTabSelectorState] = useState<ITabSelectorState>({
    localInput: null,
    tabListElements: [],
    tabContentElements: []
  })
  const [isSearchInputOpen, setIsSearchInputOpen] = useState<boolean>(false)
  const [tabsListWidth, setTabsListWidth] = useState<number>(null)
  const [currentTab, setCurrentTab] = useState<number | string>(
    defaultValue ?? 0
  )
  const tabsListRef = useRef(null)
  const isSearchDisabled = localInputDisabledOnIndex
    ? localInputDisabledOnIndex.findIndex(
        (element) => element === currentTab
      ) !== -1
    : false

  useLayoutEffect(() => {
    let tmpSearchInput = null
    if (searchInput) {
      tmpSearchInput = { ...searchInput }
      tmpSearchInput.props = {
        ...tmpSearchInput.props,
        onMagnifierClick: (event) => {
          onSearchInputMagnifierClick()
          searchInput.props.onMagnifierClick &&
            searchInput.props.onMagnifierClick(event)
        }
      }
    }

    const tabsList: JSX.Element[] = []
    const tabsContent: JSX.Element[] = []

    tabItems.forEach((tabItem, index) => {
      const { tabIcon: TabIcon } = tabItem
      tabsList.push(
        <Tab className={styles.tab} key={index} value={index}>
          <div className={styles.tabContent}>
            <TabIcon />
            <span>{tabItem.tabCaption}</span>
          </div>
        </Tab>
      )
      tabsContent.push(
        <TabPanel value={index} key={index} className={styles.panel}>
          {tabItem.tabContent}
        </TabPanel>
      )
    })

    setTabSelectorState({
      localInput: tmpSearchInput,
      tabListElements: tabsList,
      tabContentElements: tabsContent
    })

    const timer = setTimeout(() => {
      if (
        tabsListRef.current &&
        tabsListRef.current.offsetWidth &&
        !tabsListWidth
      ) {
        setTabsListWidth(tabsListRef.current.offsetWidth)
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [tabItems])

  useEffect(() => {
    setIsSearchInputOpen(defaultSearchOpen)
  }, [defaultSearchOpen])

  useEffect(() => {
    if (controlledValue != null) {
      setCurrentTab(controlledValue)
    }
  }, [controlledValue])

  const onSearchInputMagnifierClick = () => {
    setIsSearchInputOpen(true)
  }

  const onSearchCloseButtonClick = () => {
    setIsSearchInputOpen(false)
  }

  if (tabSelectorState.tabListElements.length !== tabItems.length) return null

  return (
    <>
      <Tabs
        className={[styles.selector].concat(className).join(" ")}
        value={currentTab}
        onChange={(_event, value) => setCurrentTab(value)}
      >
        <div className={styles.tabListContainer}>
          <TabsList
            className={styles.tabsList}
            ref={tabsListRef}
            style={
              tabSelectorState.localInput && tabsListWidth
                ? !isSearchInputOpen
                  ? { width: tabsListWidth / 10 + "rem" }
                  : {
                      width: 0,
                      opacity: 0,
                      padding: 0,
                      marginRight: 0
                    }
                : null
            }
          >
            {tabSelectorState.tabListElements}
          </TabsList>
          {!isSearchDisabled && tabSelectorState.localInput && tabsListWidth ? (
            <>
              <div
                className={styles.searchInput}
                style={
                  !isSearchInputOpen
                    ? { width: "4.2rem", borderRadius: "2.4rem" }
                    : {
                        width: tabsListWidth / 10 + 7.2 + "rem",
                        borderRadius: "0"
                      }
                }
              >
                {tabSelectorState.localInput}
              </div>
              <Button
                variant="text"
                endIcon={<Close />}
                className={styles.closeButton}
                onClick={onSearchCloseButtonClick}
                style={!isSearchInputOpen ? { opacity: 0 } : { opacity: 1 }}
              >
                Close
              </Button>
            </>
          ) : null}
        </div>
        {buttons}
        {tabSelectorState.tabContentElements}
      </Tabs>
    </>
  )
}

export default TabSelector
