import { Alert, AlertProps } from "@mui/material"
import React, { FC } from "react"

import styles from "./CustomAlert.scss"

const CustomAlert: FC<AlertProps> = (props) => {
  return (
    <Alert {...props} className={styles.customAlertContent}>
      {props.children}
    </Alert>
  )
}

export default CustomAlert
