import React, { FC, ReactNode } from "react"
import Button from "@src/components/Button"
import { Typography } from "@mui/material"
import typingSaly from "@src/static/image/saly-is-typing.png"

import styles from "./NoDataPlaceholderPanel.scss"

export interface NoDataPlaceholderPanelPropsType {
  title: string
  message: string | JSX.Element
  buttonCaption?: string
  imageUrl?: string
  className?: string
  onButtonClick?: () => void
  customElement?: ReactNode
}

export type NoDataPlaceholderPanelType = NoDataPlaceholderPanelPropsType

const NoDataPlaceholderPanel: FC<NoDataPlaceholderPanelType> = ({
  title,
  message,
  buttonCaption,
  imageUrl,
  className,
  onButtonClick,
  customElement
}) => {
  return (
    <div className={`${styles.noDataPlaceholderPanel} ${className}`}>
      <Typography component={"span"} variant="h5">
        {title}
      </Typography>
      <Typography component={"span"} variant="body2">
        {message}
      </Typography>
      {buttonCaption && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => (onButtonClick ? onButtonClick() : null)}
        >
          {buttonCaption}
        </Button>
      )}
      {customElement}
      <img
        className={styles.overlayImage}
        src={imageUrl ? imageUrl : typingSaly.toString()}
        alt="overlay_image"
      />
    </div>
  )
}

export default NoDataPlaceholderPanel
