import React, { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router';
import formatDate from 'date-fns/format';
import getBackDays from 'date-fns/subDays';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  GroupWorkOutlined,
  NewReleasesOutlined,
  AssignmentOutlined,
  BusinessOutlined,
} from '@mui/icons-material';
import { Backdrop, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { ButtonWithDropdown, TabSelector } from '@dqna/seamless-suite-ui-kit';
import { ITabs } from '@dqna/seamless-suite-ui-kit/dist/components/TabSelector/ITabSelectorProps';
import { SuggestedKeywordsPanel } from './SuggestedKeywordsPanel';
import { AbTestingPanel } from './AbTestingPanel';
import { KeywordGroupMainContainer } from './KeywordGroupPanelContainers';
import { RemoveKeywordsModal } from './RemoveKeywordsModal';
import { CreateKeywordGroupModal } from './CreateKeywordGroupModal';
import { KeywordManagementContainerProps } from 'components/containers/KeywordManagement';
import { ISubAccount } from 'types/subAccounts.types';
import { IKeywordGroupInfo, KEYWORDS_DATA_AVAILABLE_DAYS_BACK } from 'types/keywords.types';
import { Routes } from 'types/app.types';
import './KeywordManagement.scss';
import { SubAccountDetailsPanel } from './SubAccountDetailsPanel';

export const KeywordManagementPage: FC<KeywordManagementContainerProps> = ({
  getAccounts,
  subAccounts,
  removeKeywords,
  deleteGroup,
  subAccountsLoadingState,
  getSuggestedKeywords,
  suggestedKeywordsState,
  sendKeywordsToTest,
  getKeywordsOfSubaccountInKeywordGroups,
  keywordsOfSubaccountInKeywordGroupsState,
  pauseKeywordsGroupTestingPhase,
  keywordGroupCreationStatus,
  keywordsOfSubaccountState,
  newAbTestId,
  abGroupSubAccountId,
  abGroup,
  isAbGroupLoading,
  getAbTest,
  changeSubAccountBgFilters,
  previewAbTest,
  deleteAbGroup,
  activateAbTest,
  resetAbTestState,
  changeSubAccountDetails,
  subAccountDetailsUpdateLoading,
  setPageTitle,
}) => {
  const { pathname } = useLocation();
  const [isKeywordGroupModalOpen, setKeywordGroupModalOpen] = useState<boolean>(false);
  const [isRemoveKeywordsModalOpen, setRemoveKeywordsModalOpen] = useState<boolean>(false);
  const [subAccount, setSubAccount] = useState<ISubAccount>();
  const [group, setGroup] = useState<IKeywordGroupInfo>();
  const [selectedTab, setSelectedTab] = useState(0);
  const { showKeywordManagementPageAbTestTab } = useFlags();

  const date = useMemo(() => {
    const today = new Date();
    const dateKeywordsAvailable = getBackDays(today, KEYWORDS_DATA_AVAILABLE_DAYS_BACK);
    return formatDate(dateKeywordsAvailable, 'yyyy-MM-dd');
  }, []);

  const { accountId, subAccountId, groupId } = useParams<{
    accountId: string;
    subAccountId: string;
    groupId: string;
  }>();

  const getDefaultTab = () => {
    if (pathname.includes(Routes.SUGGESTED_KEYWORDS)) {
      return 1;
    }
    if (pathname.includes(Routes.SUB_ACCOUNT_DETAILS)) {
      return 2;
    }

    if (pathname.includes(Routes.AB_TESTING)) {
      return 3;
    }
    return 0;
  };

  useEffect(() => {
    if (!Object.keys(subAccounts).length) {
      getAccounts();
    }
  }, [subAccounts]);

  useEffect(() => {
    setSelectedTab(getDefaultTab());
  }, []);

  useEffect(() => {
    setSubAccount(subAccounts[Number(accountId)]?.find(({ id }) => id === Number(subAccountId)));
  }, [subAccounts, accountId, subAccountId]);

  useEffect(() => {
    if (!subAccount?.keywordGroups?.length) {
      setGroup(undefined);
      return;
    }

    const currentGroup =
      subAccount.keywordGroups.find(({ id }) => id === Number(groupId)) ??
      subAccount.keywordGroups[0];
    setGroup(currentGroup);
  }, [subAccount, groupId]);

  useEffect(() => {
    getSuggestedKeywords({ subAccountId: parseInt(subAccountId) });
  }, [subAccountId]);

  useEffect(() => {
    if (group && subAccount) {
      getKeywordsOfSubaccountInKeywordGroups(parseInt(subAccountId));
    }
  }, [group, subAccount, subAccountId]);

  useEffect(() => {
    setPageTitle({
      title: subAccount?.name ?? '',
      routes: [
        {
          breadcrumbCaption: subAccount?.account.name ?? '',
          breadcrumbUrl: Routes.LINKED_ACCOUNTS,
        },
        {
          breadcrumbCaption: `${subAccount?.name ?? ''}`,
          breadcrumbUrl: Routes.LINKED_ACCOUNTS,
        },
      ],
    });
  }, [subAccount]);

  const tabItems = () => {
    const mainItems = [
      {
        tabCaption: 'Keyword groups',
        tabContent: (
          <KeywordGroupMainContainer
            subAccount={subAccount}
            groupId={Number(groupId)}
            group={group ?? null}
            keywords={keywordsOfSubaccountInKeywordGroupsState.data ?? []}
            dataTableLoading={keywordsOfSubaccountInKeywordGroupsState.isLoading}
            createKwGroupModal={setKeywordGroupModalOpen}
            pauseKeywordsGroupTestingPhase={(groupId: number) =>
              pauseKeywordsGroupTestingPhase(parseInt(accountId), parseInt(subAccountId), groupId)
            }
            deleteGroup={(groupIdToDelete: number) =>
              deleteGroup(date, parseInt(accountId), parseInt(subAccountId), groupIdToDelete)
            }
            removeKeywords={removeKeywords}
          />
        ),
        tabIcon: GroupWorkOutlined as ITabs['tabIcon'],
      },
      {
        tabCaption: 'Suggested keywords',
        tabContent: (
          <SuggestedKeywordsPanel
            subAccount={subAccount}
            getSuggestedKeywords={getSuggestedKeywords}
            suggestedKeywordsState={suggestedKeywordsState}
            date={date}
            sendKeywordsToTest={sendKeywordsToTest}
            keywordGroupCreationStatus={keywordGroupCreationStatus}
          />
        ),
        tabIcon: NewReleasesOutlined as ITabs['tabIcon'],
      },
      {
        tabCaption: 'Details',
        tabContent: (
          <SubAccountDetailsPanel
            subAccount={subAccount}
            subAccountDetailsUpdateLoading={subAccountDetailsUpdateLoading}
            changeSubAccountDetails={changeSubAccountDetails}
          />
        ),
        tabIcon: AssignmentOutlined as ITabs['tabIcon'],
      },
    ];
    if (showKeywordManagementPageAbTestTab) {
      mainItems.push({
        tabCaption: 'A/B test',
        tabContent: (
          <AbTestingPanel
            subAccount={subAccount}
            newAbTestId={newAbTestId}
            abGroupSubAccountId={abGroupSubAccountId}
            abGroup={abGroup}
            isAbGroupLoading={isAbGroupLoading}
            getAbTest={getAbTest}
            changeSubAccountBgFilters={changeSubAccountBgFilters}
            previewAbTest={previewAbTest}
            deleteAbGroup={deleteAbGroup}
            activateAbTest={activateAbTest}
            resetAbTestState={resetAbTestState}
          />
        ),
        tabIcon: BusinessOutlined as ITabs['tabIcon'],
      });
    }
    return mainItems;
  };

  return (
    <>
      <Backdrop
        open={subAccountsLoadingState || keywordsOfSubaccountState.isLoading}
        sx={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      <div className="keywords__page_wrapper--new">
        <Box
          sx={{
            height: '100%',
            textAlign: 'center',
            width: '100%',
          }}>
          <TabSelector
            controlledValue={selectedTab}
            defaultValue={getDefaultTab()}
            buttons={
              group ? (
                <ButtonWithDropdown
                  menuItems={[
                    {
                      action: () => {
                        setKeywordGroupModalOpen(true);
                      },
                      label: 'Add new group',
                      value: 'add',
                    },
                    {
                      action: () => {
                        setRemoveKeywordsModalOpen(true);
                      },
                      label: 'Remove keywords',
                      value: 'remove',
                    },
                  ]}>
                  Manage groups
                </ButtonWithDropdown>
              ) : undefined
            }
            tabItems={tabItems()}
          />
        </Box>
      </div>
      <CreateKeywordGroupModal
        modalOpenState={isKeywordGroupModalOpen}
        setModalOpenState={setKeywordGroupModalOpen}
        keywords={suggestedKeywordsState.data}
        subAccount={subAccount}
        sendKeywordsToTest={sendKeywordsToTest}
        keywordGroupCreationStatus={keywordGroupCreationStatus}
        getSuggestedKeywords={getSuggestedKeywords}
        keywordListLoadingState={suggestedKeywordsState.isLoading}
      />
      <RemoveKeywordsModal
        modalOpenState={isRemoveKeywordsModalOpen}
        setModalOpenState={setRemoveKeywordsModalOpen}
        keywords={keywordsOfSubaccountInKeywordGroupsState.data}
        keywordsLoading={keywordsOfSubaccountInKeywordGroupsState.isLoading}
        getKeywordsOfSubaccount={getKeywordsOfSubaccountInKeywordGroups}
        removeKeywords={removeKeywords}
        subAccount={subAccount}
      />
    </>
  );
};

KeywordManagementPage.displayName = 'KeywordManagementPage';
