import { createTheme } from "@mui/material"
import variables from "./incubetaLightTheme.scss"
import type {} from "@mui/x-data-grid/themeAugmentation"

const incubetaLight = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: "auto",
          input: {
            boxSizing: "border-box",
            height: "3rem"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: variables.body2FontSize,
          minHeight: variables.textFieldHeight
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: "white !important",
          backgroundColor: variables.secondaryMain,
          fontSize: `${variables.body2FontSize} !important`
        }
      }
    },
    MuiStep: {
      styleOverrides: {
        root: {
          fontSize: variables.stepperFontSize
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: variables.stepperIconFontSize,
          "&.Mui-completed": {
            color: variables.successMain
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: variables.primaryMain,
          color: variables.buttonText,
          "&:hover": {
            backgroundColor: variables.primaryLight
          }
        },
        containedSecondary: {
          backgroundColor: variables.buttonBackground,
          color: variables.buttonText,
          "&:hover": {
            backgroundColor: variables.buttonHover
          }
        },
        textPrimary: {
          ".MuiTouchRipple-root": {
            display: "none"
          },
          "&:hover": {
            backgroundColor: "transparent"
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          backgroundColor: variables.tableBackground
        },
        columnHeaders: {
          color: variables.textDisabled,
          backgroundColor: variables.tableBackground
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: variables.captionFontSize,
          fontWeight: variables.captionFontWeight
        }
      }
    }
  },
  typography: {
    body1: {
      fontSize: variables.body1FontSize,
      fontWeight: variables.body1FontWeight,
      lineHeight: variables.body1LineHeight
    },
    body2: {
      fontSize: variables.body2FontSize,
      lineHeight: variables.body2LineHeight,
      color: variables.textSecondary
    },
    subtitle2: {
      margin: ".2rem 0",
      fontSize: variables.subtitle2FontSize
    },
    caption: {
      fontSize: variables.captionFontSize,
      fontWeight: variables.captionFontWeight
    },
    fontFamily: "Poppins",
    h2: {
      color: variables.textPrimary,
      fontWeight: variables.h2FontWeight,
      fontSize: variables.h2FontSize,
      lineHeight: variables.h2LineHeight
    },
    h5: {
      fontSize: variables.h5FontSize,
      fontWeight: variables.h5FontWeight
    },
    h6: {
      fontSize: variables.h6FontSize,
      fontWeight: variables.h6FontWeight
    }
  },
  palette: {
    primary: {
      main: variables.primaryMain as string,
      light: variables.primaryLight as string,
      dark: variables.primaryDark as string,
      contrastText: variables.primaryContrastText as string
    },
    secondary: {
      main: variables.secondaryMain as string,
      light: variables.secondaryLight as string,
      dark: variables.secondaryDark as string
    },
    error: {
      main: variables.errorMain as string,
      dark: variables.errorDark as string,
      light: variables.errorLight as string
    },
    info: {
      main: variables.infoMain as string,
      dark: variables.infoDark as string,
      light: variables.infoLight as string
    },
    warning: {
      main: variables.warningMain as string,
      dark: variables.warningDark as string,
      light: variables.warningLight as string
    },
    success: {
      main: variables.successMain as string,
      dark: variables.successDark as string,
      light: variables.successLight as string
    },
    text: {
      primary: variables.textPrimary as string,
      secondary: variables.textSecondary as string,
      disabled: variables.textDisabled as string
    },
    action: {
      active: variables.actionActive as string,
      hover: variables.actionHover as string,
      selected: variables.actionSelected as string,
      disabled: variables.actionDisabled as string,
      disabledBackground: variables.actionDisabledBackground as string,
      focus: variables.actionFocus as string
    }
  }
})

export default incubetaLight
