import React from 'react';

import './TermsAndConditions.scss';

export const TermsAndConditions = ({}) => {
  return (
    <div className="terms">
      <h1>Seamless Search Subscription Terms of Service</h1>
      <p>
        These Client Terms of Service (“Terms”) establish the terms and
        conditions when purchasing a subscription for the use of Incubeta’s
        Proprietary Technology Seamless Search (“the Services”) as described in
        an Order Form signed by you. These Terms and any Order Form(s) together
        form a binding (“Agreement”) between you (“Client”) and us. We, our,
        Incubeta, “us” refers to the applicable Incubeta entity in the section
        entitled “Incubeta Entity and Law and Jurisdiction” below, unless
        otherwise agreed. If you are entering into this Agreement on behalf of a
        Company, or another legal entity (“Entity”), you are agreeing to this
        Agreement on behalf of such Entity and representing to us that you have
        authority to bind such Entity.
      </p>
      <ol>
        <li>
          <h2>Definitions</h2>
          <p>
            “Account” means any Seamless Search account created by or on behalf
            of the Client.
          </p>
          <p>
            “Affiliates” means with respect to a party, any entity that directly
            or indirectly controls, is controlled by, or is under common control
            with such party.
          </p>
          <p>
            “Agreement” means the Seamless Search Subscription Terms of Service
            with any and all applicable Order Form(s), and other supplemental
            terms such as a Statement of Work, Data Processing Agreement (DPA)
            and Service Level Agreement (SLA).
          </p>
          <p>
            “Applicable Data Protection Law” means the General Data Protection
            Regulation (“GDPR”), the California Consumer Privacy Act (“CPPA”)
            and any other applicable statute, treaty, regulation, directive, act
            or code in the Parties jurisdiction (each a “Law”).
          </p>
          <p>
            “Business Hours” means the operating hours of the Incubeta Support
            team, 9AM-5:30PM (BST).
          </p>
          <p>
            “Confidential Information” means information provided by one Party
            to the other in written, graphic, recorded, machine readable or
            other form concerning the business, clients, suppliers, finances and
            other areas of the other party’s business or products, including,
            without limitation, the Client Data, Model Data Order Forms, these
            Terms, but does not include information in the public domain other
            than through the default of the party disclosing the information,
            information required to be disclosed by any court or regulatory
            authority, or any information already in the possession or control
            of the disclosing party.
          </p>
          <p>
            “Client Data” means the data pulled from the Client’s own data
            sources (e.g. Google Ads data, Search Console, Analytics) or other
            Client Data made available by the Client in connection with the
            Services.
          </p>
          <p>
            “Fees” means the fees paid by Client to Incubeta for the Services.
          </p>
          <p>
            “Intellectual Property Rights” means copyright, rights in or
            relating to databases, patent rights, designs and registered
            designs, trademarks, rights in or relating to Confidential
            Information and other intellectual property rights (registered or
            unregistered) throughout the world.
          </p>
          <p>
            “Login Data” means the data required to activate an Account,
            including but not limited to, username, email, first name and last
            name.
          </p>
          <p>
            “Model Data” means all modelling data created from the machine
            learning algorithm.
          </p>
          <p>
            “Order Form” means an order form that sets out the specific
            conditions of a type of product or service provided by Incubeta,
            such as a Seamless Technology Order Form.
          </p>
          <p>
            “Personal Data”, “process/processing”, “controller”, “processor” and
            “data subject” all have the meanings given to such terms by the
            GDPR.
          </p>
          <p>
            “Proprietary Technology” means any part of a platform and/or
            software developed and owned by Incubeta and/or its affiliates or
            suppliers, including where applicable, related software, platform,
            data files, equipment, training material, testing material, designs,
            analyses, documentation and preparatory materials.
          </p>
          <p>“Platform” means the Seamless Search Platform.</p>
          <p>
            “Services” means the provision of Incubeta’s Proprietary Technology
            Seamless Search.
          </p>
          <p>
            “Service Data” means all data generated by the Platform, excluding
            Model Data and Client Data, through the use of the Services under
            these Terms.
          </p>
          <p>
            “Service Level Agreement” or “SLA” means the agreement which
            documents the services standards regarding the Seamless Search
            Platform.
          </p>
          <p>
            “Subscription Term” means the term as defined in the applicable
            Order Form.
          </p>
          <p>
            “You” and “Client” mean the Entity you represent in agreeing to the
            Agreement.
          </p>
          <p>
            “The Parties” means refers to the applicable Incubeta entity in the
            section entitled “Incubeta Entity and Law and Jurisdiction” below
            and Client.
          </p>{' '}
        </li>
        <li>
          <h2>Access to the Services</h2>
          <ol>
            <li>
              We will make the Services available to You pursuant to this
              Agreement and the applicable Order Form in accordance with the
              Order Form specifications. We will use reasonable efforts to make
              the services available to You in accordance with the SLA
              guaranteed times, except (a) during a planned downtime, software
              modifications, updates and/or patches, of which we would provide
              notice in advance; and (b) Force Majeure Events.
            </li>
            <li>
              Your Account is for your sole use and benefit unless otherwise
              agreed.
            </li>
            <li>
              Included in the Fees we will provide applicable standard support
              for the Services as detailed in the SLA.
            </li>
            <li>
              Incubeta might modify the functionality of the Services during the
              Subscription Term and will provide the Client with reasonable
              notice of any Downtime required for the modification.
            </li>
            <li>
              If you are approved by us to extend the use of the Services to
              your affiliates, Clients or other third parties, You will be
              acting on their behalf and You remain liable for your and their
              compliance hereunder.
            </li>
            <li>
              If we determine that any Client has violated the policies set
              forth here or any applicable laws or regulations, we reserve the
              right, in its sole discretion, to deny further access to the
              Client’s Account, without limiting any other rights or remedies it
              may have.
            </li>
          </ol>
        </li>
        <li>
          <h2>Use of the Services</h2>
          <ol>
            <li>
              The Platform has specific system requirements which the Client has
              to comply with to guarantee the proper functioning of the
              Platform, including but not limited to: a high Internet speed (UK
              broadband minimum - 10Mbps or equivalent; US broadband
              minimum25mbps); a modern desktop browser (no more than 1 year
              old); and a recommended hardware with 8GB RAM, 50GB spare hard
              drive space, latest operating system with full security patches
              and anti-virus and anti-malware software installed. You are
              responsible for procuring and maintaining the previous
              requirements to connect your network with the Services. The
              Platform does not support mobile or tablet browsers.
            </li>
            <li>
              Unless otherwise authorised by Incubeta in this Agreement or
              expressly agreed, You may not use the service in any manner where
              you act as a service provider where you outsource the Services to
              third parties. You agree not to license, sublicense, sell,
              outsource, assign, distribute or otherwise commercially exploit or
              resell the Services to any third party without approval by
              Incubeta.
            </li>
            <li>
              You may not access the Services if You are a direct competitor of
              Incubeta, except with Incubeta’s written prior approval and You
              may not use the Services for direct competitive purposes specific
              to Incubeta.
            </li>
            <li>
              The Parties hereby acknowledge and accept that Incubeta does not
              guarantee the Services will necessarily result in any expected
              outcome, forecast, projection, or result or in the Client
              attaining specific campaign performance objectives.
            </li>
          </ol>
        </li>
        <li>
          <h2>Additional Services</h2>
          <ol>
            <li>
              Through the Services, Client may have the ability to purchase a)
              training sessions; b) customised development; and additional
              services. The delivery of and fees for the Additional Services
              will be as set forth in the applicable Order(s). Client must have
              an active subscription to the Services in order to access the
              Additional Services.
            </li>
          </ol>
        </li>
        <li>
          <h2>Consultancy Services</h2>
          <ol>
            <li>
              Incubeta provides consultancy services. If Client requires this
              service, Incubeta will provide a Statement of Work (each a “SOW”),
              which will be incorporated into the Agreement. Each SOW will
              include (i) a description of the Consultancy Services; (ii) the
              then estimated completion dates; (iii) the fees, costs, and
              expenses payable to Incubeta; (iv) the payment schedule; and (v) a
              signature by Client and Incubeta’s respective authorized
              representatives.
            </li>
          </ol>
        </li>
        <li>
          <h2>Non-Incubeta Services</h2>
          <ol>
            <li>
              Our Services require the connection of Client Data pulled from
              third party platforms, including but not limited to Google Ads
              data, Search Console, Analytics platforms (each, a “Non-Incubeta
              Service”). These are not our Services and we do not warrant or
              support Non-Incubeta Services and ultimately Client will decide
              which of the supported third party platforms to connect with our
              Platform. Any use of a Non-Incubeta Service is solely between the
              Client and the applicable third-party provider. We will not be
              responsible for any use, disclosure, modification or deletion of
              Client Data which originates from a third party service while it
              is not in our Platforms. In addition, we will not be responsible
              for outage or losses of API connection caused from or related to
              Non-Incubeta Services.
            </li>
          </ol>
        </li>
        <li>
          <h2>Fees and Payments</h2>
          <ol>
            <li>
              Subscription Fees and any applicable additional fees will be
              stipulated in the Order Form.
            </li>
            <li>
              All Subscription Fees are due in full upon commencement of your
              Subscription Term, or within 30 days from receipt of invoice if
              applicable to the Client, unless otherwise expressly set forth in
              this Agreement, an Order Form, or an Statement of Work.
            </li>
            <li>
              If you fail to pay your subscription Fees or any other charges
              indicated in the applicable Order Form within ten (10) business
              days of our notice to you that payment is due, we might suspend or
              terminate access to use our Services in addition to any other
              remedies we might have.
            </li>
          </ol>
        </li>
        <li>
          <h2>Term</h2>
          <ol>
            <li>
              The term of your Subscription shall be the term stipulated in the
              applicable Order Form. Unless an Order Form says something
              different, all subscriptions automatically renew for additional
              periods equal to one (1) year or the preceding term, whichever is
              shorter.
            </li>
            <li>
              Either party can give the other notice of non-renewal at least
              thirty (30) days before the end of a Subscription Term to stop the
              subscriptions from automatically renewing.
            </li>
            <li>
              This Agreement can be terminated by either party if the other
              party commits a material breach if such breach remains uncured 15
              days from the date of the breach notice. Failure to pay Fees shall
              be considered a material breach.
            </li>
            <li>
              If the Agreement is terminated by You in accordance with this
              Clause 8.3, Incubeta will refund any prepaid fees covering the
              remaining term of the Subscription after the termination. If the
              Agreement is terminated by Incubeta in accordance with Clause 8.3,
              You will pay the remaining fees for the Subscription Term pursuant
              to the applicable Order Form. For clarity, in no event will
              termination by the parties under Clause 8.3 will relieve You to
              pay any fees payable to us for the period prior to the
              termination.
            </li>
            <li>
              30 days after the termination of this Agreement, we will have no
              obligation to retain Service Data and we will delete it.
            </li>
          </ol>
        </li>
        <li>
          <h2>Confidentiality</h2>
          <ol>
            <li>
              During the use of the Services Confidential Information might be
              disclosed. Recipient agrees to hold the Confidential Information
              disclosed by Disclosing Party in confidence and not to, directly
              or indirectly, copy, reproduce, distribute, manufacture,
              duplicate, reveal, report, publish, disclose, cause to be
              disclosed, or otherwise transfer the Confidential Information
              disclosed by Disclosing Party to any third party. This clause
              shall continue notwithstanding termination of these terms and
              conditions. Nothing above will prevent either party from sharing
              Confidential Information with Incubeta’s Affiliates, and financial
              and legal advisors; provided, however, that the third parties and
              advisors are bound to confidentiality obligations at least as
              restrictive as those in the Agreement. The “Disclosing Party”
              refers to the party disclosing Proprietary Information hereunder,
              whether such disclosure is directly from Disclosing Party or
              through Disclosing Party’s employees or agents; and “Recipient”
              refers to the party receiving any Proprietary Information
              hereunder, whether such disclosure is received directly or through
              Recipient’s employees or agents.
            </li>
            <li>
              The foregoing obligations shall not apply if and to the extent
              that: (i) Recipient establishes that the information communicated
              was publicly known at the time of Recipient's receipt or has
              become publicly known other than by a breach of this Agreement;
              (ii) prior to disclosure hereunder was already in the Recipient’s
              possession without restriction as evidenced by appropriate
              documentation; (iii) subsequent to disclosure hereunder is
              obtained by the Recipient on a non-confidential basis from a third
              party who has the right to disclose such information; or (iv) was
              developed by the Recipient without any use of any of the
              Confidential Information as evidenced by appropriate
              documentation. Notwithstanding anything to the contrary herein, if
              Recipient is ordered by an administrative agency or other
              governmental body of competent jurisdiction to disclose the
              Proprietary Information, then Recipient may disclose the requested
              Proprietary Information; provided however, that, Recipient shall
              first notify Disclosing Party prior to disclosure, if allowed by
              law, in order to give Disclosing Party a reasonable opportunity to
              seek an appropriate protective order or waive compliance with the
              terms of this Agreement and shall disclose only that part of the
              Proprietary Information which Recipient is required to disclose.
            </li>
            <li>
              Upon the request of the Disclosing Party, Recipient shall promptly
              destroy or deliver to the Disclosing Party its Confidential
              Information and any notes, extracts or other reproductions in
              whole or in part relating thereto, without retaining any copy
              thereof. Notwithstanding the foregoing, Recipient shall be
              permitted to retain such copies of Proprietary Information as
              necessary for legal or recordkeeping purposes, including such
              copies as are embedded in the automated backup of electronic data
              processing systems
            </li>
          </ol>
        </li>
        <li>
          <h2>Intellectual Property and Data Ownership</h2>
          <ol>
            <li>
              Client shall retain ownership to all Client Data and Service Data.
              Client grants Incubeta and its affiliates a worldwide,
              non-exclusive, royalty free, limited term license to access, use,
              process and incorporate to the Platform Client Data in order to
              provide the Services, to address Service maintenance issues, for
              billing purposes, to answer a data request, and for record
              purposes. Client represents and warrants that it has secured all
              rights in Client Data and in obtaining or collecting Client Data,
              it did not violate the law, any applicable regulations, or the
              rights of any third party.
            </li>
            <li>
              Incubeta owns all rights and interests in the Services, Model
              Data, and all hardware, Seamless software source code, customised
              development and other components of or used to provide the
              Services, including all related Intellectual Property rights and
              grants Client a non-sublicensable, non-transferrable,
              non-exclusive, license to Client and any authorised users to
              access and use the Platform during the Term only. We reserve the
              right to seek Intellectual Property Protection for any additional
              features, functionality or components that were initiated by your
              suggestions or feedback. Client acknowledges that the source code
              is not licensed to Client by this Agreement or any Order Form and
              will not be provided by Incubeta.
            </li>
          </ol>
        </li>
        <li>
          <h2>Data Protection and Security</h2>
          <ol>
            <li>
              Incubeta endeavours to protect the Personal Data that may be
              shared with us in order to provide the Services. Client shall not
              share Personal Data with Incubeta and shall not allow its
              authorised representatives to send Personal Data to Incubeta,
              except for Login Data required to activate the Account.
            </li>
            <li>
              The Client is the sole Data Controller of the Client Personal
              Data. The Client is responsible for obtaining all the necessary
              authorizations and approvals to enter, use, provide, store and
              process Client Personal Data to enable Incubeta to provide the
              Services.
            </li>
            <li>
              The Parties will comply with their respective obligations under
              Data Protection Regulation and each party will provide reasonable
              cooperation, if requested, to enable the other Party with data
              protection compliance.
            </li>
            <li>
              Incubeta’s obligation in this section shall apply only to the
              authorised processing of Personal Data (Login Data). Incubeta
              shall, with regards to Login data it processes on behalf of the
              Client:
              <ol>
                <li>
                  process the Client Login Data only in accordance with this
                  Agreement and as necessary to provide the Services to You;
                </li>
                <li>
                  never sell, rent, or lease your Login Data, to any third
                  party, except as permitted by this Agreement and in order to
                  provide, secure and support the Services;
                </li>
                <li>
                  maintain reasonable administrative, physical and technical
                  safeguards for the protection of the security, confidentiality
                  and integrity of Service Data and Login Data;
                </li>
                <li>
                  ensure that persons authorised to process the personal data
                  have committed themselves to confidentiality or are under an
                  appropriate statutory obligation of confidentiality;
                </li>
                <li>
                  as required by the Client, delete all Personal Data upon the
                  end of the Agreement.
                </li>
                <li>
                  in the event of any unauthorised or accidental access to or
                  use or disclosure of any Personal Data it shall take all
                  reasonable steps necessary to correct the causes of the access
                  and shall keep the Client informed.
                </li>
              </ol>
            </li>
            <li>
              Client acknowledges and agrees that (a) Incubeta Affiliates and
              Incubeta’s suppliers and partners may be retained as
              Sub-processors; and (b) Incubeta Affiliates and Incubeta’s
              suppliers and Partners, may engage third-party Sub-processors in
              connection with the provision of the Services. Incubeta has
              entered into agreements with each Sub-processor containing
              applicable data protection obligations. Information about
              Sub-processors can be found on Appendix B and may be updated from
              time to time by Incubeta.
            </li>
            <li>
              Client acknowledges and agrees that Personal Data will be stored
              in Google data centers. Client agrees that Google may store and
              process Client Personal Data in any country in which Google or any
              of its Subprocessors maintains facilities. Information about the
              locations of Google data centres is available at
              www.google.com/about/datacenters/locations/. If the storage and/or
              processing of Client Personal Data involves transfers of Client
              Personal Data from the EEA, Switzerland or the UK to any third
              country that is not subject to an adequacy decision under the
              European Data Protection Legislation, such transfer will be
              subject to the Model Contract Clauses of the European Commission.
            </li>
            <li>
              Client undertakes to Incubeta that it shall, in relation to the
              personal data that it shares with Incubeta:
              <ol>
                <li>
                  ensure it has an appropriate legal basis to allow the
                  processing and disclosure of Personal Data (including having a
                  legal basis for collecting personal data and providing any
                  notices and obtaining necessary consents);
                </li>
                <li>
                  will not knowingly or negligently do or omit to do anything
                  which places Incubeta in breach of its obligations under the
                  Data Protection Legislation, and indemnifies and hold harmless
                  Incubeta against any loss, actions, costs, expenses, claims,
                  proceedings and demands arising as a result of such breach or
                  otherwise under the Data Protection Legislation so far as it
                  is caused by any action or omission of the Client.
                </li>
                <li>
                  The Client will notify Incubeta immediately if it becomes
                  aware of or reasonably suspects a breach of clause 11.6.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h2>Client Obligations</h2>
          <ol>
            <li>
              Client is responsible for all use of the Proprietary Technology.
            </li>
            <li>
              Client shall not on its own nor allow any third party to: a)
              misappropriate any part of the Proprietary Technology, for
              instance by using the technology for malvertising; b) disassemble,
              decompile, reverse engineer, adapt, vary, change, adapt, modify,
              create derivative works or translate the Proprietary Technology;
              c) damage or tamper (any part of) the Proprietary Technology;
              d)breach any security measure; or e) remove, change or obscure any
              copyright or other such notices on or in the Proprietary
              Technology.
            </li>
            <li>
              If required by Incubeta, Client shall provide Incubeta access to
              any third party platforms necessary to complete the delivery of
              the Proprietary Technology and associated services and Client
              warrants that it has all the necessary legal rights and
              permissions to perform its obligations under this Agreement.
            </li>
            <li>
              Client shall provide any assistance or information that Incubeta
              may request. Client is responsible for providing accurate and
              adequate information, data, and assistance, in a timely manner
            </li>
            <li>
              Incubeta shall rely on any information provided by Client, data
              and assistance provided by or on behalf of Client.
            </li>
            <li>
              Client agrees to indemnify and hold harmless Incubeta , if any
              data and assistance provided by or on behalf of Client is
              inaccurate, incomplete, not timely, clear and or not communicated
              to the correct Incubeta person, etc. and as an effect of that
              Incubeta would not be able to provide the technology, services,
              billing, aggregate statistics or is not able to enforce its rights
              under the Agreement.
            </li>
            <li>
              Client shall ensure that it allocates sufficient personnel or
              subcontractors with the knowledge and experience required and
              sufficient time for providing such input to Incubeta.
            </li>
            <li>
              Client shall be and remain fully liable for any Personal Data
              breach on their side.
            </li>
            <li>
              Client will insure, indemnify, and hold harmless Incubeta for any
              violations, real or alleged, of any laws or regulations, relating
              to the Client use of the Proprietary Technology with the Client’s
              consumers and Clients.
            </li>
          </ol>
        </li>
        <li>
          <h2>Incubeta Obligations and Standard of Care</h2>
          <ol>
            <li>
              We will make the Services available to the Client as described in
              the Order Form. We will make available and/or maintain the
              Proprietary Technology in accordance with that degree of skill,
              diligence, prudence and foresight which would reasonably and
              ordinarily be expected from a service provider and shall use
              reasonable endeavors to do so in accordance with expressly agreed
              specifications and SLAs outlined in Appendix A.
            </li>
            <li>
              We will not use Client Data and Service Data for any purpose
              outside of providing the Services without the Client approval.
            </li>
            <li>
              We cannot guarantee that the Proprietary Technology is free of
              errors and functions without interruption, nor that the
              Proprietary Technology cannot unintendedly cause damage to data or
              other applications stored on the same cloud environment.
            </li>
          </ol>
        </li>
        <li>
          <h2>Disclaimer and Liability</h2>
          <ol>
            <li>
              EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, THE SERVICES AND ALL
              RELATED COMPONENTS AND INFORMATION ARE PROVIDED ON AN “AS IS” AND
              “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND WE
              EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR
              IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
              TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
              CLIENT ACKNOWLEDGES THAT WE DO NOT WARRANT THAT THE SERVICES WILL
              BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.
            </li>
            <li>
              The loading time and quality of the Platform depend on the
              bandwidth of your Internet connection. While the content is
              accessible through a PC, Incubeta is not responsible for any
              Internet connection issues, viruses, nor for the content's
              compatibility with mobile or tablets devices as the Platform does
              not support them.
            </li>
            <li>
              Client is responsible for Login credentials, usernames and
              passwords, and for the administration of the accounts.
            </li>
            <li>
              ALTHOUGH INCUBETA AIMS TO PROVIDE THE SERVICES TO THE HIGHEST
              STANDARDS OF THE INDUSTRY, NEITHER IT, NOR ITS EMPLOYEES ACCEPT
              ANY LIABILITY FOR (I) ANY INACCURACY OR MISLEADING INFORMATION
              PROVIDED BY THE SERVICE DATA AND ANY RELIANCE BY CLIENT ON ANY
              SUCH INFORMATION, (II) ANY LOSS OR CORRUPTION OF DATA, (III) ANY
              LOSS OF PROFIT, REVENUE OR GOODWILL, OR (IV) ANY INDIRECT, SPECIAL
              OR CONSEQUENTIAL LOSS ARISING FROM ANY BREACH OF THE TERMS AND
              CONDITIONS.
            </li>
            <li>
              INCUBETA’S AND ITS AFFILIATES TOTAL AGGREGATE LIABILITY ARISING
              FROM OR IN CONNECTION WITH THE AGREEMENT (WHETHER IN CONTRACT OR
              TORT OR UNDER ANY OTHER THEORY OF LIABILITY) SHALL BE LIMITED TO
              THE MINOR OF:(I) THE FEES RECEIVED BY US IN THE 12 MONTHS
              PRECEDING THE CLAIM IN CONNECTION WITH THE RELEVANT ORDER FORM IN
              RELATION TO WHICH A DISPUTE HAS ARISEN, OR (II) £150,000 IF THE
              CONTRACTING PARTY IS INCUBETA UK LIMITED OR $150,000 IF THE
              CONTRACTING PARTY IS INCUBETA US CORP. PLEASE SEE SECTION 25 FOR
              ENTITIES INFORMATION.
            </li>
            <li>
              The limitations under this section apply with respect to all legal
              theories, whether in contract, tort or otherwise, and to the
              extent permitted by law. The provisions of this section allocate
              the risks under this Agreement between the parties, and the
              parties have relied on these limitations in determining whether to
              enter into this Agreement and the fees for the Services
            </li>
          </ol>
        </li>
        <li>
          <h2>Indemnification</h2>
          <ol>
            <li>
              The Client will indemnify, defend and hold Incubeta harmless
              against any claim (a) arising from or related to the use of the
              Services by Client, End-Users, or Agents; or (b) alleging that the
              Client’s use of the Service or Client Data infringes or
              misappropriates third party rights.
            </li>
          </ol>
        </li>
        <li>
          <h2>Publicity</h2>
          <ol>
            <li>
              The Parties acknowledge and agree that Incubeta shall be entitled
              to refer to the subject matter of this Agreement and relative
              performance indicators relating to the Services for the purposes
              of marketing in press releases, public announcements, proposal
              documents, case studies and other marketing materials.
            </li>
          </ol>
        </li>
        <li>
          <h2>Force Majeure</h2>
          <ol>
            <li>
              If a Party performing an obligation under this Agreement is
              affected by Force Majeure it shall immediately notify the other
              Party in writing of the matters constituting the Force Majeure and
              shall keep the Non-Affected Party fully informed of their
              continuance and of any relevant change of circumstances whilst
              such Force Majeure continues (“Force Majeure” means any event
              outside the reasonable control of either party affecting its
              ability to perform its obligations under this Agreement, including
              Act of God, Pandemic, fire and flood). Force Majeure shall not
              exempt any payment obligations under the Agreement.
            </li>
            <li>
              The Affected Party by Force Majeure shall take all reasonable
              steps available to it to minimise the effects of Force Majeure on
              the performance of its obligations under this Agreement.
            </li>
            <li>
              The Affected Party shall not be in breach of this Agreement or
              otherwise liable to the Non-Affected Party, by reason of any delay
              in performance, or non-performance of any of its obligations due
              to the Force Majeure provided that: (a) it has notified the
              Non-Affected Party in accordance with Clause 15.1;and (b) it has
              complied with its obligations in Clause 15.2.
            </li>
            <li>
              The Non-Affected Party is entitled without liability to the
              Affected Party to suspend its own performance of this Agreement
              while the Force Majeure continues.
            </li>
            <li>
              If the Affected Party by Force Majeure fails to comply with its
              obligations under this Clause 12 then no relief for Force Majeure,
              including the provisions of Clause 15.3, shall be available to it
              and the obligations of each party shall continue in force.
            </li>
            <li>
              If the Force Majeure continues for longer than 30 (thirty) days,
              the Non-Affected Party may, at any time whilst such Force Majeure
              continues, by notice in writing to the Affected Party, terminate
              (without liability) this Agreement.
            </li>
          </ol>
        </li>
        <li>
          <h2>Assignment</h2>
          <ol>
            <li>
              The Parties may not assign this Agreement or any rights or
              obligations hereunder, by operation of law or otherwise without
              prior written consent of the Party, except that Incubeta may
              assign the Agreement to a subsidiary of the Incubeta Holdings
              Proprietary Limited group of companies
            </li>
          </ol>
        </li>
        <li>
          <h2>Survival</h2>
          <ol>
            <li>
              Upon the expiration or termination of this Agreement for any
              reason, the provisions of Clauses 5, 6, 7,8, 10, 11, 12, 13, 18
              and 25 and any other clauses which expressly or by implication are
              intended to come into or remain in force on or after termination
              will survive indefinitely subject to any rights of termination or
              revocation set out in those provisions.
            </li>
          </ol>
        </li>
        <li>
          <h2>Third Party Rights</h2>
          <ol>
            <li>
              The parties do not intend any third party to have the right to
              enforce any provision of the Agreement under the Contracts (Rights
              of Third Parties) Act 1999 or otherwise
            </li>
          </ol>
        </li>
        <li>
          <h2>Waiver</h2>
          <ol>
            <li>
              No failure or delay by either party in exercising any right under
              the Agreement will constitute a waiver of that right. No waiver
              under the Agreement will be effective unless made in writing and
              signed by an authorized representative of the party being deemed
              to have granted the waiver.
            </li>
          </ol>
        </li>
        <li>
          <h2>Severability</h2>
          <ol>
            <li>
              If at any time any part of this Agreement (including any one or
              more of the clauses of this Agreement or any sub-clause or
              paragraph or any part of one or more of these clauses) is held to
              be or becomes void or otherwise unenforceable for any reason under
              Applicable Laws, the validity, legality and/or enforceability of
              the remaining provisions of this Agreement shall not in any way be
              affected or impaired as a result of that omission and the
              provision in question shall apply with the minimum modifications
              necessary to make it valid and enforceable
            </li>
          </ol>
        </li>
        <li>
          <h2>Modifications</h2>
          <ol>
            <li>
              We may change the terms of this Agreement on 14 days’ notice to
              the Client (except any Order Forms). We will provide notice by
              emailing the email address associated with the Account and the
              changes are only binding on the end of the notice period. The
              Client can always review the latest terms by visiting this page.
            </li>
          </ol>
        </li>
        <li>
          <h2>Conflicting Terms</h2>
          <ol>
            <li>
              The Agreement, including these Terms and all referenced pages and
              Order Forms, if applicable, constitutes the entire agreement
              between the parties and supersedes all prior and contemporaneous
              agreements, proposals or representations, written or oral,
              concerning its subject matter. Without limiting the foregoing, the
              Agreement supersedes the terms of any online agreement
              electronically accepted by Client or any Authorized Users If there
              is a conflict between any term of this Agreement and a term of an
              Order Form (OF), the term of the OF will prevail.
            </li>
          </ol>
        </li>
        <li>
          <h2>Incubeta Entity and Law and Jurisdiction</h2>
          <ol>
            <li>
              All references to ‘Incubeta,’ ‘we,’ or ‘us’ under the Agreement,
              what law will apply in any dispute or lawsuit arising out of or in
              connection with the Contract, and which courts have jurisdiction
              over any such dispute or lawsuit, depend on where Client is
              domiciled.
              <table className="table">
                <thead>
                  <tr>
                    <th className="table-header1">Domicile</th>
                    <th className="table-header1">
                      Incubeta Contracting Entity
                    </th>
                    <th className="table-header1">Governing Law</th>
                    <th className="table-header1">Venue</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table-cells">United States and Canada</td>
                    <td className="table-cells">Incubeta US Corp</td>
                    <td className="table-cells">New York</td>
                    <td className="table-cells">New York County</td>
                  </tr>
                  <tr>
                    <td className="table-cells">Rest of the world</td>
                    <td className="table-cells">Incubeta UK Limited</td>
                    <td className="table-cells">England and Wales</td>
                    <td className="table-cells">England</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              The Agreement, and any disputes arising out of or related hereto,
              will be governed exclusively by the applicable governing law
              above, without regard to conflicts of laws rules or the United
              Nations Convention on the International Sale of Goods. The courts
              located in the applicable venue above will have exclusive
              jurisdiction to adjudicate any dispute arising out of or relating
              to the Agreement or its formation, interpretation or enforcement.
              Each party hereby consents and submits to the exclusive
              jurisdiction of such courts. In any action or proceeding to
              enforce rights under the Agreement, the prevailing party will be
              entitled to recover its reasonable costs and attorney’s fees.
            </li>
          </ol>
        </li>
      </ol>

      <h1>Appendix A - Service Level Agreement</h1>
      <p>
        This Service Level Agreement (‘SLA’) describes the levels of service
        that Client will receive from Incubeta under the Seamless Agreement, of
        which this Appendix A is an integral part. The SLA aims to enable the
        Parties to work together effectively.
      </p>
      <ol>
        <li>
          <h2>Definitions</h2>
          <p>
            “Actual Monthly Uptime Percentage” means = (A-B+C)/A, where: A =
            Total Monthly Time (defined below); B = Unavailable Monthly Time
            (defined below); and C = Excluded Monthly Times (defined below).
            “Business Hours” means the operating hours of the Incubeta Support
            team, 9AM-5:30PM (BST). “Excluded Monthly Time” means the events
            where no Unavailable Monthly Time will be deemed to have occurred as
            described in Section 6 of the SLA. “Incident” means a platform
            support request. “Initial Target Response” means the first response
            from Incubeta aimed at the diagnosis of the issues. “Monthly Uptime
            Percentage Threshold” means the percentage listed in the section
            below under the heading, “Uptime Availability”. “Service Credit”
            means the Pound Sterling credit that Incubeta will credit to a
            Client’s eligible account if the Actual Monthly Uptime Percentage is
            less than the Monthly Uptime Percentage Threshold, and which is
            calculated on a pro rata basis based on the monthly fee applicable
            in the calendar month to the platform license only. “Target
            Resolution” means the estimated time for resolution of the issues at
            Incubeta’s sole discretion. “Total Monthly Time” means the total
            number of minutes in the applicable calendar month. “Unavailable
            Monthly Time” means the number of minutes in the applicable calendar
            month during which the Seamless Search Platform was unavailable for
            use.
          </p>
        </li>
        <li>
          <h2>Service Description</h2>
          <p>
            Seamless Search (“Seamless”) is a software developed by Incubeta.
            Seamless requires a high Internet speed (UK broadband minimum -
            10Mbps or equivalent; US broadband minimum- 25mbps); a modern
            desktop browser (no more than 1 year old); and a recommended
            hardware with 8GB RAM, 50GB spare hard drive space, latest operating
            system with full security patches and anti-virus and anti-malware
            software installed.
          </p>
          <p>Seamless supports the following browsers and operating systems:</p>
          <table className="table">
            <thead>
              <tr>
                <th className="table-header1">Supported Desktop Browsers</th>
                <th className="table-header1">Supported Operating Systems</th>
                <th className="table-header1">Browser settings</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table-cells">
                  Latest release version of either Chrome, Fire Fox, Edge,
                  Safari
                </td>
                <td className="table-cells">
                  Latest version of either Windows or MacOS
                </td>
                <td className="table-cells">
                  All defaults must be enabled and Ad Blockers must be disabled
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            **The Platform does not support mobile or tablet browsers. In the
            event that the Client fails to meet the technical requirements
            listed above, then Incubeta will not be obliged to provide the
            support services described in Section 3.
          </p>
        </li>
        <li>
          <h2>Exclusions</h2>
          <p>This SLA does not apply to:</p>
          <ol className="dotted--ol">
            <li>
              Any equipment, software, services, third party platforms, and any
              services not included in the Agreement.
            </li>
            <li>
              Software, equipment or services not purchased via and managed by
              Incubeta.
            </li>
          </ol>
          <p>Additionally, this SLA does not apply when:</p>
          <ol className="dotted--ol">
            <li>
              The problem has been caused by using equipment, software or
              service(s) in a way that is not recommended.
            </li>
            <li>
              Client has made unauthorised changes to the configuration or set
              up of affected equipment, software or services.
            </li>
            <li>
              Client has prevented Incubeta from performing required maintenance
              and update tasks.
            </li>
            <li>
              The issue has been caused by unsupported mobile devices,
              equipment, software or other services
            </li>
          </ol>
          <p>
            This SLA also does not apply if the client is in breach of its
            contract for any reason, including but not limited to, late payment
            of fees, improper use and violation of terms.
          </p>
        </li>
        <li>
          <h2>Monthly Uptime Availability</h2>
          <p>
            Incubeta shall use commercially reasonable endeavours to ensure that
            Seamless Search has a monthly uptime of at least 98.5% (“Monthly
            Uptime Availability”) If, during any month throughout the Term, the
            Actual Uptime Availability Percentage falls below the Monthly Uptime
            Availability threshold, then Client will be eligible to receive a
            Service Credit, subject to Client’s compliance with Section 5 below.
            Service Credit will be calculated on a pro rata basis.
          </p>
        </li>
        <li>
          <h2>Credit Request and Payment Procedure</h2>
          <p>
            To receive a Service Credit for the Services, Client must submit a
            request to{' '}
            <a href="support@seamlesspro.io" target="_blank">
              support@seamlesspro.io
            </a>{' '}
            ., within thirty (30) days from the last day of the calendar month
            in which Client claims Incubeta failed to meet or exceed the Monthly
            Uptime Availability threshold. All submissions must include: (i)
            “SLA Claim” as the subject of the Email; (ii) the dates and times of
            Unavailable Monthly Time; and (iii) any documentation of the
            applicable outage. Each Service Credit will be applied to future
            amounts payable by Client to Incubeta for the Services. No refunds
            or cash value will be given. All SLA claims will be verified against
            Incubeta measurements of the Service availability
          </p>
        </li>
        <li>
          <h2>Excluded Monthly Times</h2>
          <p>
            Notwithstanding any provision in this Agreement to the contrary, no
            Unavailable Monthly Time will be deemed to have occurred if
            downtime: (i) is caused by planned server maintenance and software
            modifications, updates and/or patches; (ii) unavailability of
            service caused by factors outside of Incubeta’s reasonable control,
            including, without limitation, telecommunications provider-related
            problems or issues, Internet access or related problems occurring
            beyond the point in the network where Incubeta maintains access and
            control over the Services; and (iii) results from any actions or
            inactions of Client or any third party. Incubeta shall take all
            reasonable steps to inform the Client in case of any planned or
            unplanned downtime of the service.
          </p>
        </li>
        <li>
          <h2>Platform Support</h2>
          <p>
            Incubeta will provide Client with Platform Support services at no
            additional cost to the Client as described below
          </p>
          <table className="table">
            <thead>
              <tr>
                <th className="table-header1" colspan="2">
                  Severity of tickets
                </th>
                <th className="table-header1">Initial Target Response</th>
                <th className="table-header1">
                  Target resolution or workaround*
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table-cells">Critical</td>
                <td className="table-cells">
                  Critical issues affecting the Account, including platform not
                  availablefor access with no workaround available.
                </td>
                <td className="table-cells">5 Business Hours</td>
                <td className="table-cells">
                  8 Business Hours or as soon as reasonablypossible
                </td>
              </tr>
              <tr>
                <td className="table-cells">High</td>
                <td className="table-cells">
                  Functions in the platform not working,issue affects core
                  functionality or results inperformance degradation
                </td>
                <td className="table-cells">12 Business Hours</td>
                <td className="table-cells">As soon as reasonably possible</td>
              </tr>
              <tr>
                <td className="table-cells">Normal</td>
                <td className="table-cells">Standard functionality issues</td>
                <td className="table-cells">16 Business Hours</td>
                <td className="table-cells">As soon as reasonably possible</td>
              </tr>
              <tr>
                <td className="table-cells">Low</td>
                <td className="table-cells">
                  Minor issues with the platform and general enquiries on the
                  use of the Platform
                </td>
                <td className="table-cells">24 Business Hours</td>
                <td className="table-cells">N/A</td>
              </tr>
            </tbody>
          </table>
          <p>
            *Please note that these are estimated targets as Incubeta is unable
            to provide guaranteed resolution times. This is because the nature
            and causes of problems can vary enormously. For instance, it may be
            possible to resolve a server issue in minutes, simply by restarting
            the server. But if a server fails due to a hardware fault it may
            take much longer to get back up and running. In all cases, Incubeta
            will use reasonable efforts to resolve problems as quickly as
            possible.
          </p>
        </li>
        <li>
          <h2>Ticket Process</h2>
          <p>
            To receive platform support the Client shall submit support requests
            via email to this email address support@seamlesspro.io. The Client
            shall cooperate with Incubeta to resolve an Incident and it should
            have knowledge of their configuration of the platform to provide the
            relevant information needed to resolve any incidents.
          </p>
          <p>
            The Client shall provide the following information attached to the
            email in order to ensure Incubeta’s availability to resolve any
            Incidents.
          </p>
          <ol className="dotted--ol">
            <li>Description of the error in detail.</li>
            <li>The URL where the error is occurring.</li>
            <li>
              Steps taken to reproduce the error and any error message provided.
            </li>
            <li>
              A screenshot or video recording the error and any error message
            </li>
          </ol>
        </li>
        <li>
          <h2>Client Responsibilities</h2>
          <p>
            Client will be responsible for: a) reporting errors promptly; b)
            providing Incubeta necessary information to assess the error and
            take corrective actions; c) following Incubeta’s reasonable
            instructions; and d) designate one employee to be the main contact
            for support issues. In order to provide appropriate support, the
            Client shall provide assistance where necessary including carrying
            out procedures necessary for the rectification of errors or
            malfunctions within a reasonable time. In addition, Client might be
            required to provide remote access to its Account for troubleshooting
            purposes.
          </p>
        </li>
        <li>
          <h2>Additional Support Services</h2>
          <p>
            If an incident reported (or if additional assistance is required by
            Client) is outside the scope of this SLA (i.e. third party platform
            support), Incubeta will notify the Client that the incident is not
            covered by this SLA and reserves the right to charge at Incubeta’s
            current standard hourly rate for all additional services, subject to
            Client approval. Any additional fees will be added in the next
            applicable invoice or will be charged separately.
          </p>
        </li>
        <li>
          <h2>Entire SLA Liability</h2>
          <p>
            With respect to any failure of Incubeta to meet the Monthly Uptime
            Availability, this Appendix states Incubeta’s sole and entire
            liability to Client and Client’s sole remedy.
          </p>
          <h4>Appendix B - Sub-processors</h4>
          <p>
            Incubeta uses the Sub-processors (listed below), to support us in
            providing the Services as described in our Terms of Service.
          </p>
          <table className="table">
            <thead>
              <tr>
                <th className="table-header1">Subprocessor name</th>
                <th className="table-header1">
                  Reason for the processing/purpose
                </th>
                <th className="table-header1">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table-cells">Auth0 Inc</td>
                <td className="table-cells">
                  Service provider for online user identity management platform
                </td>
                <td className="table-cells">South Africa</td>
              </tr>
              <tr>
                <td className="table-cells">Incubeta SA Operations Pty Ltd</td>
                <td className="table-cells">Platform support services</td>
                <td className="table-cells">South Africa</td>
              </tr>
              <tr>
                <td className="table-cells">Google LLC </td>
                <td className="table-cells">Use of Google Cloud</td>
                <td className="table-cells">UK and US</td>
              </tr>
              <tr>
                <td className="table-header1">
                  Subprocessors own Subprocessors
                </td>
                <td className="table-header1">
                  Reason for the processing/purpose
                </td>
                <td className="table-header1">Location</td>
              </tr>
              <tr>
                <td className="table-cells">Auth0 Ltd </td>
                <td className="table-cells">
                  Auth0 subsidiary - Support Services
                </td>
                <td className="table-cells">UK</td>
              </tr>
              <tr>
                <td className="table-cells">Auth0 Argentina S.A </td>
                <td className="table-cells">
                  Auth0 subsidiary - Support Services
                </td>
                <td className="table-cells">Argentina</td>
              </tr>
              <tr>
                <td className="table-cells">Auth0 Australia Pty Ltd </td>
                <td className="table-cells">
                  Auth0 subsidiary - Support Services
                </td>
                <td className="table-cells">Australia</td>
              </tr>
              <tr>
                <td className="table-cells">Auth0 KK </td>
                <td className="table-cells">
                  Auth0 subsidiary - Support Services
                </td>
                <td className="table-cells">Japan</td>
              </tr>
              <tr>
                <td className="table-cells">Auth0 Canada Sub Ltd </td>
                <td className="table-cells">
                  Auth0 subsidiary - Support Services
                </td>
                <td className="table-cells">Canada</td>
              </tr>
              <tr>
                <td className="table-cells">Cloudflare, Inc. </td>
                <td className="table-cells">
                  Content Delivery Network and DDoS Prevention
                </td>
                <td className="table-cells">USA</td>
              </tr>
            </tbody>
          </table>
        </li>
      </ol>
    </div>
  );
};

TermsAndConditions.displayName = 'TermsAndConditionsPage';
