import { createEpicMiddleware } from 'redux-observable';
import reducers, { IRootState } from './reducers';
import epics from './epics';
import { HTTPService } from 'services/HTTP.service';
import { AuthService } from 'services/Auth.service';
import { idb } from 'services/IDB.service';
import { notificationQueue } from 'services/NotificationQueue.service';
import { EnhancedStore, configureStore } from '@reduxjs/toolkit';
import { History } from 'history';
import { auth0config, API_URL } from '../config';

export default function (
  history: History<History.UnknownFacade>,
  preloadedState?: Partial<IRootState>
): {
  store: EnhancedStore;
  authService: AuthService;
} {
  const authService = new AuthService(auth0config);
  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      auth: authService,
      http: new HTTPService(API_URL, authService),
      idb,
      history,
      notificationQueue,
    },
  });

  const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        immutableCheck: false,
      }).concat(epicMiddleware);
      return middleware;
    },
    preloadedState,
  });

  epicMiddleware.run(epics);

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  authService.run(store.dispatch);

  return { store, authService };
}
