import React, { FC, useMemo, useCallback, ChangeEvent } from 'react';
import classNames from 'classnames';

import { getId } from 'utils/getId';

import { ICheckBoxProps, InputTypes, inputDisplayNames } from './types';

import './Input.scss';

export const Checkbox: FC<ICheckBoxProps> = ({
  name,
  value,
  onChange,
  disabled,
  children,
  checked,
  className,
  title,
  intermediate,
}) => {
  const id = useMemo(() => getId(), []);

  const handleCheckboxChange = useCallback(
    (e?: ChangeEvent<HTMLInputElement>) => onChange?.(e),
    [onChange]
  );

  return (
    <label
      className={classNames('input-checkbox', className)}
      htmlFor={id}
      tabIndex={-1}
      title={title || `${name}: ${value}`}>
      <span className="input-checkbox__label">{children}</span>
      <input
        id={id}
        type={InputTypes.Checkbox}
        className={classNames('input-checkbox__box', {
          'input-checkbox__box--intermediate': !!intermediate,
        })}
        onChange={handleCheckboxChange}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        tabIndex={0}
      />
      <span
        className={classNames('input-checkbox__checkmark', {
          disabled: disabled,
        })}
      />
    </label>
  );
};

Checkbox.displayName = inputDisplayNames.checkbox;
