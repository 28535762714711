import { ReactNode, ReactElement } from 'react';
import { IMultiselectItemData, IItemData, DataType } from 'components/ui-kit/Dropdown';
import { NumberValues, StringValues, OptionsValues } from 'constants/filters';

export interface RowData {
  id: string;
  [key: string]: any;
}

export enum TableElements {
  TableBody = 'TableBody',
  TableHeader = 'TableHeader',
  TableFooter = 'TableFooter',
  Table = 'Table',
  TableList = 'TableList',
}

export enum TableCellAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export type RenderHeader<T> = (column?: T[]) => ReactNode;
export type RenderBody = (value: any) => ReactNode;
export type RenderFooter<T> = (column?: T[]) => ReactNode;
export enum TableType {
  Table = 'table',
  List = 'list',
}

export interface BaseTableCellProps {
  colspan?: number;
  rowspan?: number;
  className?: string;
}

export interface TableColumnFilter<S> {
  columnId: S | string;
  constant?: boolean;
}

export type TableHeaderRenderer<T> = (data?: T[]) => string;
export interface TableHeaderCell<T> extends BaseTableCellProps, TableColumnFilter<keyof T> {
  renderer?: TableHeaderRenderer<T> | string;
  onSort?: (data: T[]) => void;
  sortOrder?: boolean;
  align?: TableCellAlign;
}

export type TableBodyRenderer<T> = (data: T) => ReactNode;
export interface TableBodyCell<T> extends BaseTableCellProps {
  field?: keyof T;
  renderer?: TableBodyRenderer<T> | string;
  align?: TableCellAlign;
}

export type TableFooterRenderer<T> = (data: T[]) => ReactNode;
export interface TableFooterCell<T> extends BaseTableCellProps {
  renderer?: TableFooterRenderer<T> | string;
  align?: TableCellAlign;
}

export interface TableBodyProps<T> {
  className?: string;
  data?: T;
  selectable?: boolean;
  selectionDisabled?: boolean;
  selected?: boolean;
  onSelectRow?: (row: T, selected: boolean) => void;
  cells?: Array<TableBodyCell<T>>;
  expandable?: boolean | ((data?: T) => boolean);
  expandedRow?: Array<TableBodyCell<T>>;
}

export interface TableHeaderProps<T> {
  className?: string;
  data?: T[];
  selectable?: boolean;
  selectionDisabled?: boolean;
  intermediate?: boolean;
  selected?: boolean;
  cells?: Array<TableHeaderCell<T>>;
  expandable?: boolean;
  onSelectAllRows?: (selected: boolean) => void;
}

export interface TableFooterProps<T> {
  className?: string;
  data?: T[];
  selectable?: boolean;
  expandable?: boolean;
  cells?: Array<TableFooterCell<T>>;
}

export type ExcludedColumnType = Record<string, string>;

export interface TableBaseProps<T> {
  className?: string;
  children: ReactNode;
  dataSource: T[];
  fullSelectionMetric?: Array<T[keyof T]>;
  selectable?: boolean;
  selectionDisabled?: boolean;
  isLoading?: boolean;
  scrollable?: boolean;
  bodyHeight?: number | string;
  pagination?: {
    totalPages: number;
    totalItems: number;
    limit: number;
    currentPage: number;
    onPageChange: (nextPage: number) => void;
    onLimitChange: (limit: number) => void;
  };
  downloadCsvData?: object[];
  type?: TableType;
  extras?: (args: {
    selectedItems?: T[];
    matchedValues: Array<T[keyof T]>;
    setMatchedValues?: (arg: Array<T[keyof T]>) => void;
    metric: keyof T;
  }) => ReactNode;
  excludedColumns?: ExcludedColumnType;
  selectionCommonField?: keyof T;
  columnFilter?: (arg: IMultiselectItemData[]) => ReactElement;
  errorTableElement?: IErrorTableElement;
}

export interface TableProps<T> {
  className?: string;
  children: ReactNode;
  dataSource: T[];
  fullSelectionMetric?: Array<T[keyof T]>;
  selectable?: boolean;
  selectionDisabled?: boolean;
  isLoading?: boolean;
  scrollable?: boolean;
  bodyHeight?: number | string;
  pagination?: {
    totalPages: number;
    totalItems: number;
    limit: number;
    currentPage: number;
    onPageChange: (nextPage: number) => void;
    onLimitChange: (limit: number) => void;
  };
  downloadCsvData?: object[];
  extras?: (args: {
    selectedItems?: T[];
    matchedValues: Array<T[keyof T]>;
    setMatchedValues?: (arg: Array<T[keyof T]>) => void;
    metric: keyof T;
  }) => ReactNode;
  excludedColumns?: ExcludedColumnType;
  selectionCommonField?: keyof T;
  columnFilter?: (arg: IMultiselectItemData[]) => ReactElement;
  errorTableElement?: IErrorTableElement;
}

export interface TableListProps<T> {
  className?: string;
  children: ReactNode;
  dataSource: T[];
  isLoading?: boolean;
  bodyHeight?: number | string;
}

export interface IColumnFilterProps {
  columnSet: IMultiselectItemData[];
  onExcludeColumn: (arg: string | ExcludedColumnType) => void;
  setPresetFilter: (arg: ExcludedColumnType | null) => void;
  presetsOptions: IItemData[];
  columnFiltersPresets: Record<string, ExcludedColumnType>;
}

export interface FilterState {
  metric: string;
  matcher: NumberValues | StringValues | OptionsValues;
  value: string;
  dataType: DataType;
}

export interface IColumnFilterPropsWithTabs {
  columnSet: IMultiselectItemData[];
  onExcludeColumn: (arg: string | ExcludedColumnType) => void;
  setPresetFilter: (arg: ExcludedColumnType | null) => void;
  presetsOptions: IItemData[];
  columnFiltersPresets: Record<string, ExcludedColumnType>;
  metrics: IItemData[];
  selectOptions?: Record<string, IItemData[]>;
  onSubmitFilter: (filters: FilterState[]) => void;
  defaultValues?: FilterState[];
  customActions: ReactNode;
}

export interface IErrorTableElement {
  enabled: boolean;
  buttonText: string;
  caption: string;
  errorAction: () => void;
}
