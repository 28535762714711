import React, { useEffect, useState } from 'react';
import { SearchInput, TabSelector, ButtonWithDropdown } from '@dqna/seamless-suite-ui-kit';
import { ITabs } from '@dqna/seamless-suite-ui-kit/dist/components/TabSelector/ITabSelectorProps';
import { AccountTreeOutlined, PermIdentityOutlined } from '@mui/icons-material';
import { useLocation, useParams } from 'react-router';
import { OrganisationAccountsTab } from '../OrganisationAccountsTab';
import { OrganisationUsersTab } from '../OrganisationsUsersTab';
import { Routes } from 'types/app.types';
import { OrganisationDetailsProps } from './types';
import { generateRouteWithIds } from 'utils/generateRouteWithIds';
import useSearchInUrl from 'utils/hooks/useSearchInUrl';
import { LinkAccountsNewModal } from 'components/pages/SubAccounts/LinkAccountsModal/LinkAccountsModal.component';
import { AddUserToOrganisationModalNew } from '../AddUserToOrganisationModalNew/AddUserToOrganisationModalNew.component';
import { useFlag } from 'utils/hooks/useFlag';

export const OrganisationDetailsNew: React.FC<OrganisationDetailsProps> = (props) => {
  const {
    setPageTitle,
    getOrganisation,
    organisation,
    getAccountsOfAllOrgs,
    accountsOfAllOrgs,
    deleteAccount,
    isGetAccountsOfAllOrgsLoading,
    deleteReinvitedUser,
    deleteUser,
    reinviteUser,
    newAccount,
    createAccount,
    linkDataSourcesStatuses,
    checkDataSourceIsLinked,
    accessToken,
    authToken,
    checkDataSourceIsVerifiedReset,
    editAccountName,
    getAccounts,
    newAccountStateReset,
    isDataSourceVerified,
    verifyAdverityAdWords,
    verifyAdveritySearchConsole,
    creationError,
    addUserToOrganisation,
  } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const { pathname } = useLocation();
  const { orgId } = useParams<{ orgId: string }>();
  const { handleSearch, search } = useSearchInUrl();
  const [addAccountModalOpenState, setAddAccountModalOpenState] = useState(false);
  const [addUserModalOpenState, setAddUserModalOpenState] = useFlag(false);

  useEffect(() => {
    setPageTitle({
      title: organisation?.name ?? '',
      routes: [
        { breadcrumbCaption: 'Home', breadcrumbUrl: Routes.HOME },
        { breadcrumbCaption: 'Organisations', breadcrumbUrl: Routes.ADMIN },
        {
          breadcrumbCaption: organisation?.name ?? '',
          breadcrumbUrl: generateRouteWithIds({
            route: Routes.ORGANISATION_DETAILS_ACCOUNTS,
            ids: { orgId },
          }),
        },
      ],
    });
  }, [organisation]);

  useEffect(() => {
    setSelectedTab(getDefaultTab());
  }, []);

  useEffect(() => {
    getOrganisation(orgId);
  }, [orgId]);

  const getDefaultTab = () => {
    if (pathname.includes('accounts')) {
      return 0;
    }
    return 1;
  };

  useEffect(() => {
    if (!accountsOfAllOrgs) {
      getAccountsOfAllOrgs();
    }
  }, [accountsOfAllOrgs]);

  return (
    <>
      <LinkAccountsNewModal
        modalOpenState={addAccountModalOpenState}
        setModalOpenState={setAddAccountModalOpenState}
        newAccount={newAccount}
        createAccount={createAccount}
        linkDataSourcesStatuses={linkDataSourcesStatuses}
        checkDataSourceIsLinked={checkDataSourceIsLinked}
        accessToken={accessToken ?? ''}
        authToken={authToken ?? ''}
        deleteAccount={deleteAccount}
        checkDataSourceIsVerifiedReset={checkDataSourceIsVerifiedReset}
        accounts={accountsOfAllOrgs && organisation ? accountsOfAllOrgs[organisation.name] : []}
        editAccountName={editAccountName}
        getAccounts={getAccounts}
        newAccountStateReset={newAccountStateReset}
        isDataSourceVerified={isDataSourceVerified}
        verifyAdveritySearchConsole={verifyAdveritySearchConsole}
        verifyAdverityAdWords={verifyAdverityAdWords}
        creationError={creationError}
        orgName={organisation?.name}
      />
      <AddUserToOrganisationModalNew
        modalOpenState={addUserModalOpenState}
        setModalOpenState={setAddUserModalOpenState}
        addUserToOrganisation={addUserToOrganisation}
        selectedOrganisation={organisation}
      />
      <TabSelector
        controlledValue={selectedTab}
        defaultValue={getDefaultTab()}
        buttons={
          <ButtonWithDropdown
            menuItems={[
              {
                action: () => {
                  setAddAccountModalOpenState(true);
                },
                label: 'Add Account',
                value: 'account',
              },
              {
                action: () => {
                  setAddUserModalOpenState(true);
                },
                label: 'Add User',
                value: 'user',
              },
            ]}>
            Add
          </ButtonWithDropdown>
        }
        searchInput={
          <SearchInput
            placeholder={selectedTab === 0 ? 'Search for account' : 'Search for user'}
            onChange={handleSearch}
            value={search}
          />
        }
        tabItems={[
          {
            tabCaption: 'Accounts',
            tabContent: (
              <OrganisationAccountsTab
                organisation={organisation}
                accountsOfAllOrgs={accountsOfAllOrgs}
                deleteAccount={deleteAccount}
                isGetAccountsOfAllOrgsLoading={isGetAccountsOfAllOrgsLoading}
              />
            ),
            tabIcon: AccountTreeOutlined as ITabs['tabIcon'],
          },
          {
            tabCaption: 'Users',
            tabContent: (
              <OrganisationUsersTab
                users={organisation?.users}
                deleteReinvitedUser={deleteReinvitedUser}
                deleteUser={deleteUser}
                reinviteUser={reinviteUser}
              />
            ),
            tabIcon: PermIdentityOutlined as ITabs['tabIcon'],
          },
        ]}
      />
    </>
  );
};
