import React from 'react';
import { Button, ModalActions } from '@dqna/seamless-suite-ui-kit';
import { CircularProgress, Typography } from '@mui/material';
import { ErrorOutline, CheckCircleOutline, InfoOutlined } from '@mui/icons-material';
import { CreationStatusProps } from './types';
import './CreateKeywordGroupModal.scss';
import { CustomAlert } from 'components/ui-kit/CustomAlert';

export const CreationStatus: React.FC<CreationStatusProps> = (props) => {
  const { groupName, keywordGroupCreationStatus, numberOfSelectedKeywords, onCloseModal } = props;

  if (keywordGroupCreationStatus.isLoading) {
    return (
      <>
        <Typography variant="body2" className="creation-status-alert__info-text">
          It might take a few minutes to add all of the keywords
        </Typography>
        <CustomAlert icon={<CircularProgress color="info" size="2rem" />} severity="info">
          <strong>{groupName}</strong> creation in progress
        </CustomAlert>
      </>
    );
  }

  if (keywordGroupCreationStatus.error) {
    return (
      <>
        <CustomAlert icon={<ErrorOutline fontSize="large" />} severity="error">
          <strong>{groupName}</strong> group creation failed due to a technical error on our end.
          Please try it again.
        </CustomAlert>
        <ModalActions>
          <Button variant="contained" onClick={onCloseModal} size="small">
            Okay, I understand
          </Button>
        </ModalActions>
      </>
    );
  }

  if (keywordGroupCreationStatus.numberOfAddedKeywords === numberOfSelectedKeywords) {
    return (
      <>
        <CustomAlert icon={<CheckCircleOutline />} severity="success">
          <strong>{groupName}</strong> group has been created successfully.
        </CustomAlert>
        <ModalActions>
          <Button variant="contained" onClick={onCloseModal} size="small">
            Done
          </Button>
        </ModalActions>
      </>
    );
  }

  return (
    <>
      <CustomAlert icon={<InfoOutlined />} severity="info">
        <strong>{groupName}</strong> group has been created successfully but we failed to add{' '}
        {numberOfSelectedKeywords - keywordGroupCreationStatus.numberOfAddedKeywords} keyword(s)
        from the {numberOfSelectedKeywords} selected.
      </CustomAlert>
      <ModalActions>
        <Button variant="contained" onClick={onCloseModal} size="small">
          Okay, I understand
        </Button>
      </ModalActions>
    </>
  );
};
