import { oneSignalConfig } from '../config';

export enum Actions {
  Fetch = 'fetch',
  FetchResponseSuccess = 'fetchResponseSuccess',
  FetchResponseFail = 'fetchResponseFail',
}

export interface WorkerMessage<T> {
  action: Actions;
  data: T;
}

const isSwAvailable: boolean = 'serviceWorker' in window.navigator;

export const registerWorker = async (): Promise<ServiceWorkerRegistration | null> => {
  if (isSwAvailable) {
    return await navigator.serviceWorker.register(
      new URL('../OneSignalSDKWorker.js', import.meta.url)
    );
  }
  return null;
};

export const startOneSignal = () => {
  window.OneSignal.push(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { appId, safari_web_id } = oneSignalConfig;

    window.OneSignal.init({
      appId,
      safari_web_id,
    });
    window.OneSignal.isPushNotificationsEnabled((isEnabled: boolean) => {
      if (!isEnabled) {
        window.OneSignal.showSlidedownPrompt();
      }
    });
    window.OneSignal.on('subscriptionChange', (isSubscribed: boolean) => {
      if (isSubscribed) {
        window.location.reload();
      }
    });
  });
};

export const getWorker = (): ServiceWorkerContainer | null => {
  return isSwAvailable ? navigator.serviceWorker : null;
};

export const getWorkerRegistration = async (): Promise<ServiceWorkerRegistration | null> => {
  if (!isSwAvailable) {
    return null;
  }

  const swReg = await navigator.serviceWorker.ready;

  return swReg.active ? swReg : await registerWorker();
};

export const messageWorker = async <T extends object>(message: WorkerMessage<T>) => {
  if (isSwAvailable) {
    const swReg = await getWorkerRegistration();
    if (swReg) {
      swReg.active?.postMessage(message);
    }
  }
};

export const notify = async (title: string, options?: NotificationOptions) => {
  if ('Notification' in window && Notification.permission === 'granted') {
    if (isSwAvailable) {
      const swReg = await getWorkerRegistration();

      if (swReg?.showNotification) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        swReg.showNotification(title, options);
      } else {
        // eslint-disable-next-line no-new
        new Notification(title, options);
      }
    }
  }
};
