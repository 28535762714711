import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchUser } from 'store/reducers/user.reducer';
import { IRootState } from 'store/reducers';

import { DataSourcesTypes } from 'types/accounts.type';

import { Header } from 'components/ui-kit/Header';

const mapStateToProps = (state: IRootState) => {
  const { accounts } = state.accounts;
  const { currentUser } = state.user;

  if (!accounts?.length) {
    return { isRequiredAccountsLinked: false, currentUser };
  }

  const isAdwordsAccountLinked = accounts[0].data_sources.find(
    ({ type }) => type === DataSourcesTypes.GOOGLE_ADWORDS
  );
  const isConsoleAccountLinked = accounts[0].data_sources.find(
    ({ type }) => type === DataSourcesTypes.GOOGLE_SEARCH_CONSOLE
  );

  return {
    isRequiredAccountsLinked: Boolean(isConsoleAccountLinked && isAdwordsAccountLinked),
    currentUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUser: () => dispatch(fetchUser()),
});

export type HeaderContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);
